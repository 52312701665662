<template>
  <MRMenu :links="links">
    <router-view></router-view>
  </MRMenu>
</template>

<script>
import MRMenu from "./MRMenu";

export default {
  components: {
    MRMenu
  },
  computed: {
    links() {
      return [
        { name: "Stats", path: `/mr` },
        { name: "Tasks", path: "/mr/tasks" }
      ];
    }
  }
};
</script>

<style scoped lang="scss"></style>
