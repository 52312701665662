import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { createProvider } from "./vue-apollo";
import HighchartsVue from "highcharts-vue";
import * as VueGoogleMaps from "vue2-google-maps";
import Vuelidate from "vuelidate";
import { VueMaskDirective } from "v-mask";
import DOMPurify from "dompurify";

window.DOMPurify = DOMPurify;

Vue.directive("mask", VueMaskDirective);

Vue.use(Vuelidate);
Vue.use(HighchartsVue);

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBnnnNtNSlPQ-z1_Eu-pbSogg5nBzoGzR4",
    installComponents: true,
  },
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  apolloProvider: createProvider(),
  render: (h) => h(App),
}).$mount("#app");
