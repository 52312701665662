<template>
  <v-card>
    <v-toolbar flat dense>
      <span class="text-subtitle-1">Fees By Week</span>
      <v-spacer></v-spacer>

      <div class="autocomplete">
        <v-autocomplete
          class="pt-5"
          :items="officeOptions"
          v-model="selectedOffice"
        ></v-autocomplete>
      </div>
    </v-toolbar>
    <highcharts ref="feesByWeek" :options="chartOptions"></highcharts>
  </v-card>
</template>

<script>
import gql from "graphql-tag";
import filter from "lodash/filter";
import accounting from "accounting";
import { getFirstDayOfWeekFromWeekNumber } from "../util";

export default {
  data() {
    return {
      officeOptions: [
        { text: "All Markets", value: null },
        {
          text: "(2 - WA Main) Spokane Valley Office",
          value: "(2 - WA Main) Spokane Valley Office",
        },
        { text: "(1 - UT Main) Sandy Office", value: "(1 - UT Main) Sandy Office" },
        {
          text: "(3 - ID Main) Meridian Office",
          value: "(3 - ID Main) Meridian Office",
        },
        { text: "Sea-Tac", value: "Sea-Tac" },
        { text: "Other", value: "Other" },
        { text: "Wyoming", value: "Wyoming" },
        { text: "Montana", value: "Montana" },
        { text: "Colorado", value: "Colorado" },
        { text: "Oregon", value: "Oregon" },
      ],
      selectedOffice: null,
    };
  },
  mounted() {
    setTimeout(() => {
      if (!this.$refs.feesByWeek) return;
      this.$refs.feesByWeek.chart.reflow();
    }, 200);
  },
  computed: {
    // ...mapGetters([]),
    feesForChartSeries() {
      if (!this.feesByWeek) return [];

      let fees = this.feesByWeek;
      let goal = 447048;
      if (this.selectedOffice) {
        fees = filter(
          this.feesByWeekByOffice,
          (f) => f.office === this.selectedOffice
        );
        switch (this.selectedOffice) {
          case "(2 - WA Main) Spokane Valley Office":
            goal = 134400;
            break;
          case "(1 - UT Main) Sandy Office":
            goal = 220920;
            break;
          case "(3 - ID Main) Meridian Office":
            goal = 91728;
            break;
          case "Sea-Tac":
            goal = 0;
            break;
          case "Other":
            goal = 0;
            break;
          case "Wyoming":
            goal = 0;
            break;
          case "Montana":
            goal = 0;
            break;
          case "Colorado":
            goal = 0;
            break;
          case "Oregon":
            goal = 0;
            break;
          default:
            goal = 0;
            break;
        }
      }

      let weeklyFees = {
        name: "Weekly Fees",
        data: fees.map((f) => {
          return parseFloat(f.fees, 10);
        }),
      };
      let array = fees,
        aggregateSum;
      let aggregateFees = {
        name: "Aggregate Fees",
        data: array.map(
          (f) => (aggregateSum = (aggregateSum || 0) + parseFloat(f.fees, 10))
        ),
      };
      let goalSum;
      let goalFees = {
        name: "Goal Fees",
        data: fees.map(() => (goalSum = (goalSum || 0) + goal)),
      };
      return [weeklyFees, aggregateFees, goalFees];
    },
    chartOptions() {
      return {
        credits: {
          enabled: false,
        },
        title: {
          text: "",
        },
        yAxis: {
          title: {
            text: "Fees",
          },
        },
        tooltip: {
          formatter: function() {
            let year = new Date().getFullYear();
            return (
              "<b>" +
              `Week ${this.x}` +
              " - " +
              `${getFirstDayOfWeekFromWeekNumber(year, this.x)}` +
              "</b><br/>" +
              this.series.name +
              ": " +
              accounting.formatMoney(this.y)
            );
          },
        },
        plotOptions: {
          series: {
            pointStart: 1,
          },
        },
        series: this.feesForChartSeries,
      };
    },
  },
  apollo: {
    feesByWeek: {
      query: gql`
        query OrgHomeFeeChartsFeesByWeek {
          feesByWeek {
            week
            year
            fees
          }
        }
      `,
    },
    feesByWeekByOffice: {
      query: gql`
        query OrgHomeFeeChartsFeesByWeekByOffice {
          feesByWeekByOffice {
            week
            year
            fees
            office
          }
        }
      `,
    },
  },
};
</script>

<style scoped>
.autocomplete {
  width: 20%;
}
@media screen and (max-width: 767px) {
  .autocomplete {
    width: 35%;
  }
}
</style>
