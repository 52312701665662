<template>
  <dash-menu>
    <v-card>
      <v-container class="py-0" fluid>
        <v-row align-content="space-around">
          <v-col class="pa-0" cols="12" sm="4">
            <v-card>
              <v-card-title>
                {{ currentCase.clientName }}
              </v-card-title>
              <v-card-subtitle>
                DOI {{ formatDate(currentCase.dateOfIncident) }}
              </v-card-subtitle>
              <v-card-text>
                <div v-for="(item, index) in phoneNumbers" :key="index">
                  {{ item.phoneNumber }} - {{ item.label }}
                </div>
              </v-card-text>
            </v-card>
            <v-card>
              <div class="pa-2">
                <span class="text-subtitle-1">Medical Providers</span>
              </div>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Total</th>
                      <th>Paid</th>
                      <th>Adjusted</th>
                      <th>Balance</th>
                      <th>Final</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in medicalProviders" :key="index">
                      <td>{{ item.medicalProvider }}</td>
                      <td>
                        {{ formatMoney(parseFloat(item.total).toFixed(2)) }}
                      </td>
                      <td>
                        {{ formatMoney(parseFloat(item.paid).toFixed(2)) }}
                      </td>
                      <td>
                        {{ formatMoney(parseFloat(item.adjusted).toFixed(2)) }}
                      </td>
                      <td>
                        {{ formatMoney(parseFloat(item.balance).toFixed(2)) }}
                      </td>
                      <td>
                        <v-icon v-if="item.final">mdi-check-bold</v-icon>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td><b>Total</b></td>
                      <td class="px-2">
                        <b>{{ totalCharged }}</b>
                      </td>
                      <td class="px-2">
                        <b>{{ totalPaid }}</b>
                      </td>
                      <td class="px-2">
                        <b>{{ totalAdjusted }}</b>
                      </td>
                      <td class="px-2">
                        <b>{{ totalBalance }}</b>
                      </td>
                    </tr>
                  </tfoot>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
          <v-col class="pa-0" cols="12" sm="4">
            <!-- <div class="date-of-last-file-review">
                File Review - {{ formatDate(currentCase.dateOfLastFileReview) }}
              </div>
              <div class="last-file-review-note">
                <span v-html="currentCase.lastFileReviewNoteHtml"></span>
              </div> -->
          </v-col>
          <v-col class="pa-0" cols="12" sm="4">
            <case-notes :caseId="$route.params.caseId" />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </dash-menu>
</template>

<script>
import DashMenu from "./DashMenu";
import CaseNotes from "./CaseNotes";
import gql from "graphql-tag";
import { getDaysSinceToday } from "../util";
import accounting from "accounting";

export default {
  data() {
    return {
      currentCase: {}
    };
  },
  components: {
    DashMenu,
    CaseNotes
  },
  computed: {
    phoneNumbers() {
      if (!this.currentCase) return [];
      return this.currentCase.phoneNumbers;
    },
    medicalProviders() {
      if (!this.currentCase) return [];
      return this.currentCase.medicalProviders;
    },
    totalCharged() {
      if (!this.currentCase || !this.currentCase.medicalProviders) return 0;
      let total = this.currentCase.medicalProviders.reduce((prev, cur) => {
        return prev + parseFloat(cur.total);
      }, 0);
      return this.formatMoney(total.toFixed(2));
    },
    totalPaid() {
      if (!this.currentCase || !this.currentCase.medicalProviders) return 0;
      let total = this.currentCase.medicalProviders.reduce((prev, cur) => {
        return prev + parseFloat(cur.paid);
      }, 0);
      return this.formatMoney(total.toFixed(2));
    },
    totalAdjusted() {
      if (!this.currentCase || !this.currentCase.medicalProviders) return 0;
      let total = this.currentCase.medicalProviders.reduce((prev, cur) => {
        return prev + parseFloat(cur.adjusted);
      }, 0);
      return this.formatMoney(total.toFixed(2));
    },
    totalBalance() {
      if (!this.currentCase || !this.currentCase.medicalProviders) return 0;
      let total = this.currentCase.medicalProviders.reduce((prev, cur) => {
        return prev + parseFloat(cur.balance);
      }, 0);
      return this.formatMoney(total.toFixed(2));
    }
  },
  methods: {
    formatDate(date) {
      if (!date) return "";
      let days = getDaysSinceToday(date);
      return `${date} (${days} days)`;
    },
    formatMoney(num) {
      return accounting.formatMoney(num);
    }
  },
  apollo: {
    currentCase: {
      query: gql`
        query($caseId: ID!) {
          currentCase: case(caseId: $caseId) {
            caseId
            type
            status
            stage
            clientName
            paralegal
            dateOpened
            dateOfIncident
            state
            phoneNumbers {
              phoneNumber
              label
            }
            lastFileReviewNoteHtml
            dateOfLastFileReview
            medicalProviders {
              id
              caseId
              medicalProvider
              total
              paid
              adjusted
              balance
              comments
              final
            }
          }
        }
      `,
      variables() {
        return {
          caseId: parseInt(this.$route.params.caseId, 10)
        };
      }
    }
  }
};
</script>

<style scoped></style>
