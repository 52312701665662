<template>
  <v-card>
    <v-container class="py-0" fluid>
      <v-row align-content="space-around">
        <v-col cols="12" sm="2">
          <v-card-title class="pa-0">
            <div>
              <div class="headline">New Providers</div>
              <div class="text-subtitle-1 font-weight-thin">
                {{ newProvidersInPeriod.length }} New Providers
              </div>
            </div>
          </v-card-title>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="1" class="mt-5">
          <v-btn x-small @click="setDay('sub')">
            <v-icon dark>
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <v-btn x-small @click="setDay('add')">
            <v-icon dark>
              mdi-chevron-right
            </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" sm="2">
          <v-menu
            ref="menu"
            v-model="dateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                label="Date Range"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker range v-model="date" no-title scrollable>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            hide-details
            single-line
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
    <v-data-table
      :footer-props="{
        'items-per-page-options': [-1],
      }"
      :headers="headers"
      hide-default-footer
      :item-key="Math.random(10).toString()"
      :items="newProvidersInPeriod"
      :loading="$apollo.loading"
      :search="search"
      :sort-by="['id']"
      :sort-desc="[true]"
    >
      <template v-slot:item.caseId="props">
        <a
          class="go-to-case-link mr-2"
          target="_blank"
          :href="
            `https://cs-web.airdesksolutions.com/SA/Plaintiff_Medical_Provider.aspx?current=true&CaseID=${props.item.caseId}`
          "
        >
          <v-icon size="15">mdi-open-in-new</v-icon>
        </a>
      </template>
      <template v-slot:item.clientName="props">
        <a class="go-to-case-link" @click="openCaseDialog(props.item.caseId)">
          {{ props.item.clientName }}
        </a>
      </template>
      <template v-slot:item.referredDate="props">
        <v-edit-dialog
          @open="openEditReferredDialog(props.item.id)"
          @close="closeEditReferredDialog"
        >
          {{ props.item.referredDate }}
          <template v-slot:input>
            <v-date-picker
              :value="props.item.referredDate"
              no-title
              scrollable
              @change="saveReferred"
            />
            <v-spacer></v-spacer>
            <v-btn class="mb-5" text @click="saveReferred('null')"
              >Remove</v-btn
            >
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:item.office="props">
        <v-edit-dialog
          @open="openEditDialog(props.item.caseId)"
          @close="closeEditDialog"
        >
          {{ props.item.office }}
          <template v-slot:input>
            <v-select
              :items="officeOptions"
              :value="officeValue(props.item.office)"
              @change="saveOffice"
              dense
            ></v-select>
          </template>
        </v-edit-dialog>
      </template>
    </v-data-table>
    <v-dialog v-model="caseDialog" max-width="600px">
      <org-home-intake-leads-case-dialog :caseId="selectedCaseId" />
    </v-dialog>
  </v-card>
</template>

<script>
import gql from "graphql-tag";
import { mapGetters } from "vuex";
import findIndex from "lodash/findIndex";
import groupBy from "lodash/groupBy";
import { ORG_HOME_INTAKE_NEW_PROVIDERS } from "../graphql/cases.gql";
import format from "date-fns/format";
import addDays from "date-fns/addDays";
import subDays from "date-fns/subDays";
import OrgHomeIntakeLeadsCaseDialog from "./OrgHomeIntakeLeadsCaseDialog";

export default {
  data() {
    return {
      search: "",
      headers: [
        { text: "Action", value: "caseId", width: "100px" },
        { text: "Id", value: "id" },
        { text: "Client", value: "clientName" },
        { text: "Provider", value: "medicalProvider" },
        { text: "Ref By", value: "referralSource" },
        { text: "Ref By Other", value: "referralSourceOther" },
        { text: "Referred Date", value: "referredDate" },
        { text: "State", value: "state" },
        { text: "Office", value: "office" },
      ],
      newProvidersInPeriod: [],
      selectedCaseId: "",
      selectedProviderId: "",
      wantedDate: "",
      officeOptions: [
        { text: "(2 - WA Main) Spokane Valley Office", value: "8" },
        { text: "(1 - UT Main) Sandy Office", value: "5" },
        { text: "(3 - ID Main) Meridian Office", value: "7" },
        { text: "Sea-Tac", value: "9" },
        { text: "Other", value: "10" },
        { text: "Wyoming", value: "11" },
        { text: "Montana", value: "12" },
        { text: "Colorado", value: "13" },
      ],
      date: [
        format(new Date(), "yyyy-MM-dd"),
        format(new Date(), "yyyy-MM-dd"),
      ],
      dateMenu: false,
      caseDialog: false,
    };
  },
  components: {
    OrgHomeIntakeLeadsCaseDialog,
  },
  computed: {
    ...mapGetters(["currentUser", "startDate", "endDate"]),
    totalRows() {
      return this.newProvidersInPeriod.length;
    },
  },
  apollo: {
    newProvidersInPeriod: {
      query: ORG_HOME_INTAKE_NEW_PROVIDERS,
      variables() {
        return {
          startDate: this.date[0],
          endDate: this.date[1],
        };
      },
      skip() {
        if (!this.currentUser) return true;
        if (!this.date[0] || !this.date[1]) return true;
        return false;
      },
    },
  },
  methods: {
    openCaseDialog(caseId) {
      this.selectedCaseId = caseId;
      this.caseDialog = true;
      return false;
    },
    setDay(direction) {
      let startDay = new Date(`${this.date[0]}T01:00:00.000-07:00`);
      let newDay;
      if (direction === "add") {
        newDay = format(addDays(startDay, 1), "yyyy-MM-dd");
      } else {
        newDay = format(subDays(startDay, 1), "yyyy-MM-dd");
      }
      this.date = [newDay, newDay];
      return false;
    },
    saveReferred(date) {
      if (!date) return false;
      let inputObj = {};
      if (date === "null") {
        inputObj.referred_date = "null";
        inputObj.is_referred = 0;
      } else {
        inputObj.referred_date = date;
        inputObj.is_referred = 1;
      }
      this.$apollo
        .mutate({
          mutation: gql`
            mutation InsertReferredDate($dateInput: ReferredDate!) {
              insertSaProviderReferredDate(dateInput: $dateInput) {
                referredDate
              }
            }
          `,
          variables: {
            dateInput: {
              provider_id: this.selectedProviderId,
              referred_date: inputObj.referred_date,
              is_referred: inputObj.is_referred,
            },
          },
          update: (store, { data: { insertSaProviderReferredDate } }) => {
            this.updateReferredDateCache(
              store,
              insertSaProviderReferredDate,
              "referredDate"
            );
          },
        })
        .then(() => {})
        .catch((error) => {
          console.error(error);
        });
      return false;
    },
    saveOffice(officeId) {
      if (!officeId) return false;
      this.$apollo
        .mutate({
          mutation: gql`
            mutation InsertSaOffice($office: OfficeInput!) {
              insertSaOffice(office: $office) {
                officeId
              }
            }
          `,
          variables: {
            office: {
              case_id: this.selectedCaseId,
              office_id: parseInt(officeId, 10),
            },
          },
          update: (store, { data: { insertSaOffice } }) => {
            let officeData = {
              office: this.officeText(insertSaOffice.officeId),
            };
            this.updateCache(store, officeData, "office");
          },
        })
        .then(() => {})
        .catch((error) => {
          console.error(error);
        });
      return false;
    },
    updateCache(store, insertData, field) {
      let caseId = this.selectedCaseId;
      const data = store.readQuery({
        query: ORG_HOME_INTAKE_NEW_PROVIDERS,
        variables: {
          startDate: this.date[0],
          endDate: this.date[1],
        },
      });
      let index = findIndex(data.newProvidersInPeriod, (c) => {
        return c.caseId === caseId;
      });
      data.newProvidersInPeriod[index][field] = insertData[field];
      store.writeQuery({
        query: ORG_HOME_INTAKE_NEW_PROVIDERS,
        variables: {
          startDate: this.date[0],
          endDate: this.date[1],
        },
        data,
      });
    },
    updateReferredDateCache(store, insertData, field) {
      let providerId = this.selectedProviderId;
      const data = store.readQuery({
        query: ORG_HOME_INTAKE_NEW_PROVIDERS,
        variables: {
          startDate: this.date[0],
          endDate: this.date[1],
        },
      });
      let index = findIndex(data.newProvidersInPeriod, (c) => {
        return c.id === providerId;
      });
      let newReferredDate;
      if (insertData.referredDate === "null") {
        newReferredDate = "";
      } else {
        newReferredDate = insertData[field];
      }
      data.newProvidersInPeriod[index][field] = newReferredDate;
      store.writeQuery({
        query: ORG_HOME_INTAKE_NEW_PROVIDERS,
        variables: {
          startDate: this.date[0],
          endDate: this.date[1],
        },
        data,
      });
    },
    officeValue(office) {
      let offices = groupBy(this.officeOptions, "text");
      if (!offices[office]) return null;
      return offices[office][0].value;
    },
    officeText(office) {
      let offices = groupBy(this.officeOptions, "value");
      return offices[office][0].text;
    },
    openEditDialog(caseId) {
      this.selectedCaseId = caseId;
      return false;
    },
    closeEditDialog() {
      this.selectedCaseId = "";
      return false;
    },
    openEditReferredDialog(id) {
      this.selectedProviderId = id;
      return false;
    },
    closeEditReferredDialog() {
      this.selectedProviderId = "";
      return false;
    },
  },
};
</script>

<style scoped></style>
