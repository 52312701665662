<template>
  <v-card>
    <v-container class="py-0" fluid>
      <v-row align-content="space-around">
        <v-col cols="12" sm="6">
          <v-card-title class="pa-0">
            <div>
              <div class="headline">Requests</div>
              <div class="text-subtitle-1 font-weight-thin">
                {{ requests.length }} Requests
              </div>
            </div>
          </v-card-title>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            hide-details
            single-line
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
    <v-data-table
      :footer-props="{
        'items-per-page-options': [-1]
      }"
      :headers="headers"
      hide-default-footer
      :items="requests"
      :loading="$apollo.loading"
      :search="search"
      :sort-by="['requestDate']"
      :sort-desc="[false]"
    >
      <template v-slot:item.caseId="{ item }">
        <a
          class="go-to-case-link mr-2"
          target="_blank"
          :href="
            `https://cs-web.airdesksolutions.com/SA/Plaintiff_Medical_Provider.aspx?current=true&CaseID=${item.caseId}`
          "
        >
          <v-icon size="15">mdi-open-in-new</v-icon>
        </a>
      </template>
      <template v-slot:item.clientName="{ item }">
        {{ truncate(item.clientName, 20) }}
      </template>
      <template v-slot:item.medicalProvider="{ item }">
        {{ truncate(item.medicalProvider, 35) }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import gql from "graphql-tag";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      search: "",
      headers: [
        { text: "Action", value: "caseId", width: "80px" },
        { text: "Client", value: "clientName", width: "150px" },
        { text: "Requested", value: "requestDate", width: "100px" },
        { text: "Provider", value: "medicalProvider", width: "250px" },
        { text: "Status", value: "status", width: "80px" },
        { text: "Updated", value: "updated", width: "100px" },
        { text: "Comments", value: "comments" }
      ]
    };
  },
  computed: {
    ...mapGetters(["imitatedUser"]),
    requests() {
      if (!this.user) return [];
      return this.user.uncompletedRequests;
    }
  },
  methods: {
    truncate(string, length) {
      if (!string) return "";
      if (string.length > length) return `${string.slice(0, length)}...`;
      else return string;
    }
  },
  apollo: {
    user: {
      query: gql`
        query($id: ID!) {
          user(id: $id) {
            id
            firstName
            lastName
            watchedCases {
              caseId
            }
            uncompletedRequests {
              id
              caseId
              clientName
              assignedUser
              requestorUser
              status
              medicalProvider
              requestDate
              fromDate
              toDate
              updated
              comments
            }
          }
        }
      `,
      variables() {
        let id = this.imitatedUser
          ? this.imitatedUser.toString()
          : this.$route.params.id;
        return {
          id
        };
      }
    }
  }
};
</script>

<style scoped></style>
