<template>
  <div>
    <highcharts ref="chart" :options="chartOptions" />
  </div>
</template>

<script>
import accounting from "accounting";
export default {
  props: {
    options: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  mounted() {
    setTimeout(() => {
      if (!this.$refs.chart) return;
      this.$refs.chart.chart.reflow();
    }, 200);
  },
  computed: {
    chartOptions() {
      return Object.assign({}, this.options, {
        credits: {
          enabled: false
        },
        chart: {
          type: "column",
          height: 200
        },
        title: {
          text: ""
        },
        legend: {
          enabled: false
        },
        yAxis: [
          {
            min: 0,
            title: {
              text: ""
            }
          }
        ],
        tooltip: {
          shared: true,
          formatter: function() {
            let s = "<b>" + this.x + "</b>";

            return this.points.reduce((prev, cur) => {
              if (cur.series.name === "Resolved") {
                return (prev += "<br/>" + cur.series.name + ": " + cur.y);
              } else {
                return (prev +=
                  "<br/>" +
                  cur.series.name +
                  ": " +
                  accounting.formatMoney(cur.y));
              }
            }, s);
          }
        },
        plotOptions: {
          column: {
            dataLabels: {
              enabled: true,
              crop: false,
              overflow: "none",
              formatter: function() {
                return accounting.formatMoney(this.y);
              }
            }
          }
        }
      });
    }
  }
};
</script>

<style scoped></style>
