<template>
  <v-card>
    <div class="header-container">
      <div class="header-item">
        <div class="text-center text-subtitle-2">Leads</div>
        <div class="text-center text-h4">{{ performance.leads }}</div>
      </div>
      <div class="header-item">
        <div class="text-center text-subtitle-2">Wanted</div>
        <div class="text-center text-h4">{{ performance.wanted }}</div>
        <div class="text-center text-h6" v-if="spend">
          {{ performance.costPerOpportunity }}
        </div>
      </div>
      <div class="header-item">
        <div class="text-center text-subtitle-2">Open Wanted</div>
        <div class="text-center text-h4">{{ performance.wantedNotClosed }}</div>
      </div>
      <div class="header-item">
        <div class="text-center text-subtitle-2">Retained</div>
        <div class="text-center text-h4">{{ performance.retained }}</div>
        <div class="text-center text-h6" v-if="spend">
          {{ performance.costPerAcquisition }}
        </div>
      </div>
      <div class="header-item">
        <div class="text-subtitle-2">Performance</div>
        <div class="text-center text-h4">{{ performance.percentage }}%</div>
      </div>
      <div class="header-search-source">
        <v-autocomplete
          v-model="source"
          :items="referralSources"
        ></v-autocomplete>
      </div>
      <div class="header-item">
        <v-autocomplete
          :items="officeOptions"
          v-model="selectedOffice"
        ></v-autocomplete>
      </div>
      <div>
        <v-text-field
          v-model="spend"
          append-icon="mdi-currency-usd"
          label="Spend"
          hide-details
          single-line
          type="number"
        >
        </v-text-field>
      </div>
    </div>
    <div class="chart-container">
      <highcharts ref="chart" :options="chartOptions" />
    </div>
  </v-card>
</template>

<script>
import gql from "graphql-tag";
import groupBy from "lodash/groupBy";
import { mapGetters } from "vuex";
import accounting from "accounting";

export default {
  data() {
    return {
      leads: [],
      source: "Ref-PPC",
      spend: null,
      selectedOffice: "null",
      officeOptions: [
        { text: "All Markets", value: "null" },
        { text: "(2 - WA Main) Spokane Valley Office", value: "8" },
        { text: "(1 - UT Main) Sandy Office", value: "5" },
        { text: "(3 - ID Main) Meridian Office", value: "7" },
        { text: "Sea-Tac", value: "9" },
        { text: "Other", value: "10" },
        { text: "Wyoming", value: "11" },
        { text: "Montana", value: "12" },
        { text: "Colorado", value: "13" },
        { text: "Oregon", value: "14" },
      ],
    };
  },
  mounted() {
    setTimeout(() => {
      if (!this.$refs.chart) return;
      this.$refs.chart.chart.reflow();
    }, 200);
  },
  apollo: {
    leads: {
      query: gql`
        query OrgLeadsStatsByDayBySource(
          $startDate: String!
          $endDate: String!
          $source: String!
          $office: String
        ) {
          leads: leadsStatsByDayBySource(
            startDate: $startDate
            endDate: $endDate
            source: $source
            office: $office
          ) {
            wanted
            wantedNotClosed
            leads
            retained
            day
            month
            year
            yearMonth
            yearMonthDay
          }
        }
      `,
      variables() {
        return {
          startDate: this.startDate,
          endDate: this.endDate,
          source: this.source,
          office: this.selectedOffice,
        };
      },
      skip() {
        return this.startDate === null || this.endDate === null;
      },
    },
    advertisingSources: {
      query: gql`
        query OrgReferralSources {
          advertisingSources {
            contact
          }
        }
      `,
    },
  },
  methods: {
    buildLeadsStatsByDay() {
      if (!this.leads) return [];
      let dates = this.leads.reduce((prev, cur) => {
        let date = Date.UTC(cur.year, cur.month - 1, cur.day);
        if (prev.indexOf(date) === -1) {
          return prev.concat(date);
        } else {
          return prev;
        }
      }, []);
      let series = ["leads", "wanted", "retained"].map((s) => {
        let prepDate = this.leads.map((d) => {
          return Object.assign({}, d, {
            date: Date.UTC(d.year, d.month - 1, d.day),
          });
        });
        let groupedByDate = groupBy(prepDate, (d) => {
          return d.date;
        });
        let dd = dates.reduce((prev, cur) => {
          if (groupedByDate[cur]) {
            return prev.concat([[cur, groupedByDate[cur][0][s]]]);
          } else {
            return prev.concat([[cur, 0]]);
          }
        }, []);
        return {
          name: s,
          data: dd,
        };
      });
      return series;
    },
  },
  computed: {
    ...mapGetters(["startDate", "endDate"]),
    performance() {
      if (!this.leads)
        return {
          leads: 0,
          wanted: 0,
          wantedNotClosed: 0,
          costPerOpportunity: 0,
          retained: 0,
          costPerAcquisition: 0,
          percentage: 0,
        };
      let leads = this.leads.reduce((prev, cur) => {
        return prev + cur.leads;
      }, 0);
      let wanted = this.leads.reduce((prev, cur) => {
        return prev + cur.wanted;
      }, 0);
      let wantedNotClosed = this.leads.reduce((prev, cur) => {
        return prev + cur.wantedNotClosed;
      }, 0);
      let retained = this.leads.reduce((prev, cur) => {
        return prev + cur.retained;
      }, 0);

      return {
        leads,
        wanted,
        wantedNotClosed,
        costPerOpportunity: this.spend
          ? accounting.formatMoney(this.spend / wanted)
          : 0,
        retained,
        costPerAcquisition: this.spend
          ? accounting.formatMoney(this.spend / retained)
          : 0,
        percentage: Math.round((retained / wanted) * 100 * 10) / 10,
      };
    },
    referralSources() {
      if (!this.advertisingSources) return [];
      return this.advertisingSources.map((s) => {
        return s.contact;
      });
    },
    chartOptions() {
      let options = {};
      return Object.assign({}, options, {
        series: this.buildLeadsStatsByDay(),
        credits: {
          enabled: false,
        },
        chart: {
          height: 200,
          type: "line",
        },
        title: {
          text: "",
        },
        legend: {
          enabled: true,
        },
        xAxis: {
          type: "datetime",
        },
        yAxis: {
          min: 0,
          title: {
            text: "",
          },
        },
        plotOptions: {
          line: {
            dataLables: {
              enabled: true,
            },
          },
        },
      });
    },
  },
};
</script>

<style scoped>
.chart-container {
  height: 220px;
}
.header-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 15px 16px;
}
.header-item {
  margin-right: 25px;
}
.header-search-source {
  margin-left: auto;
  margin-right: 25px;
}
</style>
