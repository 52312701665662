<template>
  <dash-menu :links="links">
    <router-view></router-view>
  </dash-menu>
</template>

<script>
import DashMenu from "./DashMenu";

export default {
  components: {
    DashMenu,
  },
  computed: {
    links() {
      let id = this.$route.params.id;
      return [
        { name: "Users", path: `/org/${id}/users` },
        {
          name: "Departments",
          path: `/org/${id}/users/departments`,
        },
        {
          name: "Offices",
          path: `/org/${id}/users/offices`,
        },
        {
          name: "Teams",
          path: `/org/${id}/users/teams`,
        },
      ];
    },
  },
};
</script>

<style scoped></style>
