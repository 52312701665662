<template>
  <v-container fluid>
    <org-home-stats />
    <org-home-fee-charts />
    <org-home-inventory-charts />
    <org-home-retained-cases-charts />
  </v-container>
</template>

<script>
import OrgHomeStats from "./OrgHomeStats";
import OrgHomeFeeCharts from "./OrgHomeFeeCharts";
import OrgHomeInventoryCharts from "./OrgHomeInventoryCharts";
import OrgHomeRetainedCasesCharts from "./OrgHomeRetainedCasesCharts";

export default {
  components: {
    OrgHomeStats,
    OrgHomeFeeCharts,
    OrgHomeInventoryCharts,
    OrgHomeRetainedCasesCharts
  }
};
</script>

<style scoped lang="scss"></style>
