import { render, staticRenderFns } from "./OrgIntakeAgentStats.vue?vue&type=template&id=4f5a7a28&scoped=true&"
import script from "./OrgIntakeAgentStats.vue?vue&type=script&lang=js&"
export * from "./OrgIntakeAgentStats.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f5a7a28",
  null
  
)

export default component.exports