<template>
  <v-card>
    <v-container class="py-0" fluid>
      <v-row align-content="space-around">
        <v-col cols="12" sm="8">
          <v-card-title class="pa-0">
            <div>
              <div class="headline">Demand Review</div>
              <div class="text-subtitle-1 font-weight-thin">
                {{ user.cases.length }} Need Review
              </div>
            </div>
          </v-card-title>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            hide-details
            single-line
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
    <v-data-table
      :footer-props="{
        'items-per-page-options': [-1]
      }"
      :headers="headers"
      hide-default-footer
      :items="user.cases"
      item-key="caseId"
      :loading="$apollo.loading"
      :search="search"
      :sort-by="['dateOpened']"
      :sort-desc="[true]"
    >
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="item in items" :key="item.caseId">
            <td>
              <a
                target="_blank"
                :href="
                  `https://cs-web.airdesksolutions.com/SA/CaseSummary.aspx?CaseID=${item.caseId}`
                "
              >
                <v-icon size="15">mdi-open-in-new</v-icon>
              </a>
            </td>
            <td>{{ item.clientName }}</td>
            <td>{{ item.daysInStatus }}</td>
            <td>{{ item.paralegal }}</td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import gql from "graphql-tag";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      search: "",
      pagination: { sortBy: "dateOpened", descending: true },
      headers: [
        { text: "Action", value: "", width: "100px" },
        { text: "Client", value: "clientName" },
        { text: "Days", value: "daysInStatus" },
        { text: "Paralegal", value: "paralegal" }
      ],
      user: { cases: [] },
      statusPieChart: false,
      statusPieSeries: false,
      cmPieChart: false,
      cmPieSeries: false
    };
  },
  computed: {
    ...mapGetters(["imitatedUser"]),
    totalRows() {
      return this.user.cases.length;
    }
  },
  apollo: {
    user: {
      query: gql`
        query($id: ID!, $statuses: [ID!]) {
          user(id: $id) {
            id
            first_name
            last_name
            watchedCases {
              caseId
            }
            cases(statuses: $statuses) {
              caseId
              status
              daysInStatus
              clientName
              paralegal
              dateOfLastClientContact
            }
          }
        }
      `,
      variables() {
        let id = this.imitatedUser
          ? this.imitatedUser.toString()
          : this.$route.params.id;
        return {
          id,
          statuses: [1063, 1124]
        };
      }
    }
  },
  methods: {
    trimStatus(status) {
      if (status) return status.substr(0, 3);
      return "";
    }
  }
};
</script>

<style scoped></style>
