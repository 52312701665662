import { render, staticRenderFns } from "./OrgHomeIntakeNewProviders.vue?vue&type=template&id=531c1176&scoped=true&"
import script from "./OrgHomeIntakeNewProviders.vue?vue&type=script&lang=js&"
export * from "./OrgHomeIntakeNewProviders.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "531c1176",
  null
  
)

export default component.exports