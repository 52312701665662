<template>
  <div>
    <v-row>
      <v-col cols="12">
        <retained-by-week></retained-by-week>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12" md="8">
        <v-card>
          <v-toolbar flat dense>
            <span class="text-subtitle-1">Pre-lit Inventory</span>
            <v-spacer></v-spacer>
            <span class="caption font-weight-light"
              >Auto -
              {{ getData(organization, "totalCases.totalAutoCases") }}</span
            >
            <v-divider class="mx-4" inset vertical></v-divider>
            <span class="caption font-weight-light"
              >Active -
              {{
                getData(organization, "totalCases.totalActiveAutoCases")
              }}</span
            >
          </v-toolbar>
          <div class="chart-vis">
            <inventory-by-user :options="inventoryByPreLitAttorney" />
          </div>
        </v-card>
      </v-col>
      <v-col sm="12" md="4">
        <v-card>
          <v-toolbar flat dense>
            <span class="text-subtitle-1">Lit Inventory</span>
          </v-toolbar>
          <div class="chart-vis">
            <inventory-by-user :options="inventoryByLitAttorney" />
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12" md="8">
        <v-card>
          <v-toolbar flat dense>
            <span class="text-subtitle-1">Pre-Lit Inventory Ranks</span>
          </v-toolbar>
          <div>
            <highcharts ref="rankChart" :options="casesByRank" />
          </div>
          <div class="chart-vis">
            <inventory-by-user :options="casesByRankByPreLitAttorney" />
          </div>
        </v-card>
      </v-col>
      <v-col sm="12" md="4">
        <v-card>
          <v-toolbar flat dense>
            <span class="text-subtitle-1">Lit Inventory Ranks</span>
          </v-toolbar>
          <div class="chart-vis">
            <inventory-by-user :options="casesByRankByLitAttorney" />
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import gql from "graphql-tag";
import get from "lodash/get";
import groupBy from "lodash/groupBy";
import { mapGetters } from "vuex";
import InventoryByUser from "../charts/InventoryByUser";
import RetainedByWeek from "../charts/RetainedByWeek.vue";
import {
  buildInventoryByAttorney,
  getPreLitAttorneysDb,
  getLitAttorneysDb
} from "../util";

export default {
  components: {
    InventoryByUser,
    RetainedByWeek
  },
  apollo: {
    organization: {
      query: gql`
        query OrgHomeInventoryCharts($id: ID!) {
          organization(id: $id) {
            id
            name
            departments {
              id
              name
              users {
                staffCode
              }
            }
            inventoryByAttorney {
              staff
              totalIntakeCases
              totalTreatingCases
              totalDemandPrepCases
              totalNegotiationCases
              totalSettledCases
              totalUimCases
              totalReferredOutCases
              totalLitigationCases
              totalClosedCases
              totalNoStatusCases
            }
          }
        }
      `,
      variables() {
        return {
          id: this.$route.params.id
        };
      }
    },
    totalCasesByRankByAttorney: {
      query: gql`
        query OrgCasesByRankByAttorney {
          totalCasesByRankByAttorney {
            totalCases
            attorney
            totalAplusplusCases
            totalAplusCases
            totalACases
            totalBCases
            totalCCases
            totalDCases
            totalUnrankedCases
            totalNoRankCases
          }
        }
      `
    },
    totalCasesByRank: {
      query: gql`
        query OrgCasesByRank {
          totalCasesByRank {
            totalAplusplusCases
            totalAplusCases
            totalACases
            totalBCases
            totalCCases
            totalDCases
            totalUnrankedCases
            totalNoRankCases
          }
        }
      `
    }
  },
  computed: {
    ...mapGetters(["currentUser"]),
    inventoryByPreLitAttorney() {
      if (!this.organization) return {};
      let iba = this.organization.inventoryByAttorney;
      let attorneys = getPreLitAttorneysDb(this.organization);
      let series = buildInventoryByAttorney(iba, attorneys);
      return {
        xAxis: {
          categories: attorneys
        },
        series
      };
    },
    inventoryByLitAttorney() {
      if (!this.organization) return {};
      let iba = this.organization.inventoryByAttorney;
      let attorneys = getLitAttorneysDb(this.organization);
      let series = buildInventoryByAttorney(iba, attorneys);
      return {
        xAxis: {
          categories: attorneys
        },
        series
      };
    },
    casesByRankByPreLitAttorney() {
      if (!this.totalCasesByRankByAttorney || !this.organization) return {};
      let attorneys = getPreLitAttorneysDb(this.organization);
      let groupedByAttorney = groupBy(this.totalCasesByRankByAttorney, d => {
        return d.attorney;
      });
      let series = [
        { name: "A++", color: "#03A9F4", value: "totalAplusplusCases" },
        { name: "A+", color: "#2196F3", value: "totalAplusCases" },
        { name: "A", color: "#3F51B5", value: "totalACases" },
        { name: "B", color: "#673AB7", value: "totalBCases" },
        { name: "C", color: "#9C27B0", value: "totalCCases" },
        { name: "D", color: "#E91E63", value: "totalDCases" },
        { name: "No Rank", color: "#F44336", value: "totalNoRankCases" }
      ].map(s => {
        return {
          name: s.name,
          color: s.color,
          data: attorneys.reduce((prev, cur) => {
            if (!groupedByAttorney[cur]) {
              return prev.concat(0);
            } else {
              return prev.concat(groupedByAttorney[cur][0][s.value]);
            }
          }, [])
        };
      });

      return {
        xAxis: {
          categories: attorneys
        },
        series
      };
    },
    casesByRankByLitAttorney() {
      if (!this.totalCasesByRankByAttorney || !this.organization) return {};
      let attorneys = getLitAttorneysDb(this.organization);
      let groupedByAttorney = groupBy(this.totalCasesByRankByAttorney, d => {
        return d.attorney;
      });
      let series = [
        { name: "A++", color: "#03A9F4", value: "totalAplusplusCases" },
        { name: "A+", color: "#2196F3", value: "totalAplusCases" },
        { name: "A", color: "#3F51B5", value: "totalACases" },
        { name: "B", color: "#673AB7", value: "totalBCases" },
        { name: "C", color: "#9C27B0", value: "totalCCases" },
        { name: "D", color: "#E91E63", value: "totalDCases" },
        { name: "No Rank", color: "#F44336", value: "totalNoRankCases" }
      ].map(s => {
        return {
          name: s.name,
          color: s.color,
          data: attorneys.reduce((prev, cur) => {
            if (!groupedByAttorney[cur]) {
              return prev.concat(0);
            } else {
              return prev.concat(groupedByAttorney[cur][0][s.value]);
            }
          }, [])
        };
      });
      return {
        xAxis: {
          categories: attorneys
        },
        series
      };
    },
    casesByRank() {
      if (!this.organization || !this.totalCasesByRank) return {};
      let totalCases = 0;
      Object.values(this.totalCasesByRank[0]).forEach(val => {
        if (Number.isInteger(val)) {
          totalCases += val;
        }
      });
      return {
        chart: {
          type: "bar",
          height: 100
        },
        title: {
          text: ""
        },
        credits: {
          enabled: false
        },
        xAxis: {
          visible: false,
          labels: {
            enabled: false
          }
        },
        yAxis: {
          min: 0,
          visible: false,
          title: {
            text: ""
          },
          labels: {
            enabled: false
          }
        },
        legend: {
          enabled: false
        },
        plotOptions: {
          series: {
            stacking: "normal"
          },
          bar: {
            dataLabels: {
              enabled: true,
              formatter: function() {
                return `${this.y} (${((this.y / totalCases) * 100).toFixed(
                  2
                )}%)`;
              }
            }
          }
        },
        tooltip: {
          headerFormat: ""
        },
        series: [
          {
            name: "A++ (> $1M)",
            color: "#03A9F4",
            data: [this.totalCasesByRank[0].totalAplusplusCases]
          },
          {
            name: "A+ ($251k - $1M)",
            color: "#2196F3",
            data: [this.totalCasesByRank[0].totalAplusCases]
          },
          {
            name: "A ($101k - $250k)",
            color: "#3F51B5",
            data: [this.totalCasesByRank[0].totalACases]
          },
          {
            name: "B ($51k - $100k)",
            color: "#673AB7",
            data: [this.totalCasesByRank[0].totalBCases]
          },
          {
            name: "C ($15k - $50k)",
            color: "#9C27B0",
            data: [this.totalCasesByRank[0].totalCCases]
          },
          {
            name: "D (<$15k)",
            color: "#E91E63",
            data: [this.totalCasesByRank[0].totalDCases]
          },
          {
            name: "Unrated - Default",
            color: "#F44336",
            data: [this.totalCasesByRank[0].totalUnrankedCases]
          },
          {
            name: "No Rank",
            color: "#FF9800",
            data: [this.totalCasesByRank[0].totalNoRankCases]
          }
        ]
      };
    }
  },
  methods: {
    getData(obj, field) {
      return get(obj, field);
    }
  }
};
</script>

<style scoped lang="scss"></style>
