<template>
  <v-app>
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="6">
          <v-card class="elevation-12">
            <v-toolbar dark color="primary">
              <v-toolbar-title>Password Reset</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-form>
                <v-text-field
                  :rules="[validatePassword]"
                  prepend-icon="mdi-lock"
                  name="password"
                  label="Password"
                  type="password"
                  v-model="password"
                ></v-text-field>
                <v-text-field
                  :rules="[validateConfirmPassword]"
                  prepend-icon="mdi-lock"
                  name="confirm-password"
                  label="Confirm Password"
                  type="password"
                  v-model="confirmPassword"
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                @click="onSubmit"
                :disabled="password != confirmPassword"
                >Change Password</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { RESET_PASSWORD, USER_BY_TOKEN } from "../graphql/user.gql";
import { routeToDashboard } from "../util";

export default {
  name: "PasswordReset",
  data() {
    return {
      password: "",
      confirmPassword: ""
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.user.loginStatus === "loggedIn";
    }
  },
  mounted: function() {
    if (this.loggedIn) {
      let user = this.$store.state.user.currentUser;
      routeToDashboard(user, this.$router);
    }
  },
  methods: {
    validatePassword(data) {
      if (data.length < 8) {
        return "Password must be at least 8 characters";
      }
      return true;
    },
    validateConfirmPassword() {
      if (this.password === this.confirmPassword) return true;
      return "Passwords must match";
    },
    onSubmit(e) {
      e.preventDefault();
      this.$store.commit("setLoginStatus", "loggingIn");
      this.$apollo
        .mutate({
          mutation: RESET_PASSWORD,
          variables: {
            password: this.password,
            token: this.$route.params.token
          }
        })
        .then(
          ({
            data: {
              resetPassword: { token, user }
            }
          }) => {
            localStorage.setItem("RyBase.token", token);
            this.$apollo
              .query({
                query: USER_BY_TOKEN,
                variables: {
                  token
                }
              })
              .then(({ data: { userByToken } }) => {
                this.$store.commit("setCurrentUser", userByToken);
                this.$store.commit("setLoginStatus", "loggedIn");
                routeToDashboard(user, this.$router);
              })
              .catch(error => {
                console.error(error);
                this.$store.commit("setLoginStatus", "error");
              });
          }
        )
        .catch(error => {
          console.error(error);
          this.$store.commit("setLoginStatus", "error");
        });
    }
  }
};
</script>
