<template>
  <dash-menu :links="links">
    <router-view></router-view>
  </dash-menu>
</template>

<script>
import DashMenu from "./DashMenu";

export default {
  components: {
    DashMenu
  },
  computed: {
    links() {
      let id = this.$route.params.id;
      return [
        { name: "Demands", path: `/dash/${id}/demands` },
        {
          name: "Demand Prep",
          path: `/dash/${id}/demands/prep`
        },
        {
          name: "Demand Review",
          path: `/dash/${id}/demands/review`
        },
        {
          name: "Requests",
          path: `/dash/${id}/demands/requests`
        },
        {
          name: "Balances",
          path: `/dash/${id}/demands/balances`
        }
      ];
    }
  }
};
</script>

<style scoped></style>
