<template>
  <div>
    <v-toolbar dense flat color="grey lighten-3" class="scroll">
      <v-btn v-for="(link, i) in links" :key="i" text exact :to="link.path">{{
        link.name
      }}</v-btn>
    </v-toolbar>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: ["links"]
};
</script>

<style></style>
