<template>
  <v-card>
    <v-toolbar flat>
      <h1>Medical Record Stats</h1>
    </v-toolbar>
    <v-card class="pa-5">
      <h3>Total Requests</h3>
      <div class="chart-container">
        <highcharts
          ref="total-requests-chart"
          :options="chartOptions('totalRequests')"
        />
      </div>
      <v-data-table
        dense
        hide-default-footer
        :headers="headers"
        :items="getData('totalRequests')"
        :loading="$apollo.loading"
        :sort-by="['agent']"
      ></v-data-table>
    </v-card>
    <v-card class="pa-5">
      <h3>Total Completed</h3>
      <div class="chart-container">
        <highcharts
          ref="total-completed-chart"
          :options="chartOptions('totalCompleted')"
        />
      </div>
      <v-data-table
        dense
        hide-default-footer
        :headers="headers"
        :items="getData('totalCompleted')"
        :loading="$apollo.loading"
        :sort-by="['agent']"
      ></v-data-table>
    </v-card>
    <v-card class="pa-5">
      <h3>Total Completed in 30 Days</h3>
      <div class="chart-container">
        <highcharts
          ref="total-completed-thirty-chart"
          :options="chartOptions('totalCompletedInThirtyDays')"
        />
      </div>
      <v-data-table
        dense
        hide-default-footer
        :headers="headers"
        :items="getData('totalCompletedInThirtyDays')"
        :loading="$apollo.loading"
        :sort-by="['agent']"
      ></v-data-table>
    </v-card>
  </v-card>
</template>

<script>
import gql from "graphql-tag";
// import filter from "lodash/filter";
import format from "date-fns/format";
import find from "lodash/find";
import groupBy from "lodash/groupBy";
import filter from "lodash/filter";
import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },
  mounted() {
    setTimeout(() => {
      if (!this.$refs.chart) return;
      let charts = [
        "total-requests-chart",
        "total-completed-chart",
        "total-completed-thirty-chart"
      ];
      charts.forEach(c => {
        this.$refs[c].reflow();
      });
    }, 200);
  },
  computed: {
    ...mapGetters(["startDate", "endDate"]),
    headers() {
      if (!this.medicalRequestTeamStatsByMonth) return [];
      let groupedByMonth = groupBy(
        this.medicalRequestTeamStatsByMonth,
        r => r.yearMonth
      );
      let months = Object.keys(groupedByMonth);

      let monthsFormatted = months.map(m => {
        let formatted = this.convertIntToDate(m);
        return { text: formatted, value: formatted, width: "50px" };
      });
      monthsFormatted.unshift({
        text: "Agent",
        value: "agent",
        width: "100px"
      });
      return monthsFormatted;
    }
  },
  methods: {
    getData(dataType) {
      if (!this.medicalRequestTeamStatsByMonth) return [];
      let groupedByAgent = groupBy(
        this.medicalRequestTeamStatsByMonth,
        r => r.agent
      );
      let agents = filter(Object.keys(groupedByAgent), a =>
        ["Minnihan, Shannon", "Joslyn, Olivia", "Perez, Diego"].includes(a)
      );
      let groupedByMonth = groupBy(
        this.medicalRequestTeamStatsByMonth,
        r => r.yearMonth
      );
      let months = Object.keys(groupedByMonth);

      let result = agents.map(a => {
        let agentData = {
          agent: a
        };
        months.forEach(m => {
          let data = find(groupedByMonth[m], m2 => m2.agent === a);
          agentData[this.convertIntToDate(m)] = data[dataType];
        });
        return agentData;
      });
      let groupedByMonthTotals = months.reduce(
        (prev, cur) => {
          let totals = groupedByMonth[cur].reduce((prev2, cur2) => {
            if (agents.indexOf(cur2.agent) > -1) {
              return prev2 + cur2[dataType];
            } else {
              return prev2;
            }
          }, 0);
          prev[this.convertIntToDate(cur)] = totals;
          return prev;
        },
        { agent: "Total" }
      );
      return result.concat(groupedByMonthTotals);
    },
    getChartData(dataType) {
      if (!this.medicalRequestTeamStatsByMonth) return [];

      let groupedByAgent = groupBy(
        this.medicalRequestTeamStatsByMonth,
        r => r.agent
      );
      let agents = filter(Object.keys(groupedByAgent), a =>
        ["Minnihan, Shannon", "Joslyn, Olivia", "Perez, Diego"].includes(a)
      );

      let groupedByMonth = groupBy(
        this.medicalRequestTeamStatsByMonth,
        r => r.yearMonth
      );
      let months = Object.keys(groupedByMonth);

      let result = agents.map(a => {
        let series = {
          name: a,
          data: []
        };
        months.forEach(m => {
          let data = find(groupedByMonth[m], m2 => m2.agent === a);
          series.data = series.data.concat([
            [Date.UTC(data.year, data.month - 1, 2), data[dataType]]
          ]);
        });
        return series;
      });

      let groupedByMonthTotals = months.reduce(
        (prev, cur) => {
          let totals = groupedByMonth[cur].reduce((prev2, cur2) => {
            return prev2 + cur2[dataType];
          }, 0);
          let stringInt = `${cur}`;
          let year = stringInt.substr(0, 4);
          let month = stringInt.substr(4, 5);
          prev.data = prev.data.concat([
            [Date.UTC(year, month - 1, 2), totals]
          ]);
          return prev;
        },
        { name: "Total", data: [] }
      );

      return result.concat(groupedByMonthTotals);
    },
    chartOptions(dataType) {
      let options = {};
      return Object.assign({}, options, {
        series: this.getChartData(dataType),
        credits: {
          enabled: false
        },
        chart: {
          height: 200,
          type: "line"
        },
        title: {
          text: ""
        },
        legend: {
          enabled: true
        },
        xAxis: {
          type: "datetime"
        },
        yAxis: {
          min: 0,
          title: {
            text: ""
          }
        },
        plotOptions: {
          line: {
            dataLables: {
              enabled: true
            }
          }
        }
      });
    },
    convertIntToDate(int) {
      let stringInt = `${int}`;
      let year = stringInt.substr(0, 4);
      let month = stringInt.substr(4, 5);
      return format(new Date(`${year}-${month}-2`), "MMM yyyy");
    }
  },
  apollo: {
    medicalRequestTeamStatsByMonth: {
      query: gql`
        query MRStats($startDate: String!, $endDate: String!) {
          medicalRequestTeamStatsByMonth(
            startDate: $startDate
            endDate: $endDate
          ) {
            totalRequests
            totalCancellations
            totalCompleted
            totalCompletedInThirtyDays
            averageDaysToComplete
            yearMonth
            month
            year
            agent
          }
        }
      `,
      variables() {
        return {
          startDate: this.startDate,
          endDate: this.endDate
        };
      },
      skip() {
        return this.startDate === null || this.endDate === null;
      }
    }
  }
};
</script>

<style>
.chart-container {
  padding-top: 20px;
  height: 200px;
}
</style>
