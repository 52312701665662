import { format, startOfMonth, endOfMonth, subDays } from "date-fns";

let selectedTeam = localStorage.getItem("RyBase.selectedTeam");
selectedTeam = selectedTeam === null ? null : parseInt(selectedTeam);

let imitatedUser = localStorage.getItem("RyBase.imitatedUser");
imitatedUser = imitatedUser === null ? null : parseInt(imitatedUser);

let startDate = localStorage.getItem("RyBase.startDate");
startDate =
  startDate === null
    ? format(startOfMonth(new Date()), "yyyy-MM-dd")
    : startDate;
let endDate = localStorage.getItem("RyBase.endDate");
endDate =
  endDate === null ? format(endOfMonth(new Date()), "yyyy-MM-dd") : endDate;

let past90Days = localStorage.getItem("RyBase.past90Days") === "true";
if (past90Days) {
  startDate = format(subDays(new Date(), 90), "yyyy-MM-dd");
  endDate = format(new Date(), "yyyy-MM-dd");
}

let deadlineToDate = localStorage.getItem("RyBase.deadlineToDate");
deadlineToDate = deadlineToDate === null ? "One Week" : deadlineToDate;

let showTeamDemands = localStorage.getItem("RyBase.showTeamDemands");
showTeamDemands = showTeamDemands === null ? false : showTeamDemands === "true";

let hideFileReviewStages = localStorage.getItem("RyBase.hideFileReviewStages");
hideFileReviewStages =
  hideFileReviewStages === null ? [] : hideFileReviewStages.split(",");

let hideClientContactStages = localStorage.getItem(
  "RyBase.hideClientContactStages"
);
hideClientContactStages =
  hideClientContactStages === null ? [] : hideClientContactStages.split(",");

let inventoryTeamShowActiveOnly = localStorage.getItem(
  "RyBase.inventoryTeamShowActiveOnly"
);
inventoryTeamShowActiveOnly =
  inventoryTeamShowActiveOnly === null
    ? false
    : inventoryTeamShowActiveOnly === "true";

let invViewMode = localStorage.getItem("RyBase.invViewMode");
invViewMode = invViewMode === null ? false : invViewMode === "true";

export default {
  state: {
    showNavigation: true,
    selectedTeam,
    imitatedUser,
    startDate,
    endDate,
    past90Days,
    deadlineToDate,
    showTeamDemands,
    hideFileReviewStages,
    hideClientContactStages,
    inventoryTeamShowActiveOnly,
    invViewMode
  },
  getters: {
    showNavigation(state) {
      return state.showNavigation;
    },
    selectedTeam(state) {
      return state.selectedTeam;
    },
    imitatedUser(state) {
      return state.imitatedUser;
    },
    startDate(state) {
      return state.startDate;
    },
    endDate(state) {
      return state.endDate;
    },
    deadlineToDate(state) {
      return state.deadlineToDate;
    },
    showTeamDemands(state) {
      return state.showTeamDemands;
    },
    hideFileReviewStages(state) {
      return state.hideFileReviewStages;
    },
    hideClientContactStages(state) {
      return state.hideClientContactStages;
    },
    inventoryTeamShowActiveOnly(state) {
      return state.inventoryTeamShowActiveOnly;
    },
    invViewMode(state) {
      return state.invViewMode;
    }
  },
  mutations: {
    toggleShowNavigation(state) {
      state.showNavigation = !state.showNavigation;
    },
    setSelectedTeam(state, payload) {
      state.selectedTeam = payload;
    },
    setImitatedUser(state, payload) {
      state.imitatedUser = payload;
    },
    setPast90Days(state, payload) {
      state.past90Days = payload;
    },
    setStartDate(state, payload) {
      state.startDate = payload;
    },
    setEndDate(state, payload) {
      state.endDate = payload;
    },
    setDeadlineToDate(state, payload) {
      state.deadlineToDate = payload;
    },
    setShowTeamDemands(state, payload) {
      state.showTeamDemands = payload;
    },
    setHideFileReviewStages(state, payload) {
      state.hideFileReviewStages = payload;
    },
    setHideClientContactStages(state, payload) {
      state.hideClientContactStages = payload;
    },
    setInventoryTeamShowActiveOnly(state, payload) {
      state.inventoryTeamShowActiveOnly = payload;
    },
    setInvViewMode(state, payload) {
      state.invViewMode = payload;
    }
  },
  actions: {
    setSelectedTeam({ commit }, payload) {
      localStorage.setItem("RyBase.selectedteam", payload);
      commit("setSelectedTeam", payload);
    },
    setImitatedUser({ commit }, payload) {
      localStorage.setItem("RyBase.imitatedUser", payload);
      commit("setImitatedUser", payload);
    },
    setPast90Days({ commit }, payload) {
      localStorage.setItem("RyBase.past90Days", payload);
      commit("setPast90Days", payload);
    },
    setStartDate({ commit }, payload) {
      localStorage.setItem("RyBase.startDate", payload);
      commit("setStartDate", payload);
    },
    setEndDate({ commit }, payload) {
      localStorage.setItem("RyBase.endDate", payload);
      commit("setEndDate", payload);
    },
    setDeadlineToDate({ commit }, payload) {
      localStorage.setItem("RyBase.deadlineToDate", payload);
      commit("setDeadlineToDate", payload);
    },
    setShowTeamDemands({ commit }, payload) {
      localStorage.setItem("RyBase.showTeamDemands", payload);
      commit("setShowTeamDemands", payload);
    },
    setHideFileReviewStages({ commit }, payload) {
      localStorage.setItem("RyBase.hideFileReviewStages", payload);
      commit("setHideFileReviewStages", payload);
    },
    setHideClientContactStages({ commit }, payload) {
      localStorage.setItem("RyBase.hideClientContactStages", payload);
      commit("setHideClientContactStages", payload);
    },
    setInventoryTeamShowActiveOnly({ commit }, payload) {
      localStorage.setItem("RyBase.inventoryTeamShowActiveOnly", payload);
      commit("setInventoryTeamShowActiveOnly", payload);
    },
    setInvViewMode({ commit }, payload) {
      localStorage.setItem("RyBase.invViewMode", payload);
      commit("setInvViewMode", payload);
    }
  }
};
