<template>
  <main-layout :items="items" v-slot="slotProps">
    <router-view v-slot="{ Component }">
      <component :is="Component" :socket="slotProps.socket" />
    </router-view>
  </main-layout>
</template>

<script>
import MainLayout from "./MainLayout";
import filter from "lodash/filter";
import { hasRole } from "../util";

export default {
  computed: {
    items() {
      let currentUser = this.$store.state.user.currentUser;
      if (!currentUser) return [];
      let routes = [
        {
          icon: "mdi-office-building",
          text: "Home",
          path: `/org/${currentUser.organization.id}`,
          auth: ["admin", "intake-management"],
        },
        {
          icon: "mdi-monitor-dashboard",
          text: "Dashboard",
          path: `/dash/${currentUser.id}`,
          auth: ["admin", "intake-management"],
        },
        {
          icon: "mdi-email-newsletter",
          text: "Demands",
          path: `/org/${currentUser.organization.id}/demands`,
          auth: ["admin"],
        },
        {
          icon: "mdi-chemical-weapon",
          text: "Intake",
          path: `/dash/${currentUser.id}/intake`,
          auth: ["admin"],
        },
        {
          icon: "mdi-account-box",
          text: "CRM",
          path: `/crm`,
          auth: ["admin", "intake-management"],
        },
        // {
        //   icon: "mdi-file-document",
        //   text: "Medical Requests",
        //   path: `/mr`,
        //   auth: ["admin"]
        // },
        {
          icon: "mdi-account-multiple",
          text: "Users",
          path: `/org/${currentUser.organization.id}/users`,
          auth: ["admin"],
        },
        {
          icon: "mdi-message-draw",
          text: "Reviews",
          path: `/org/${currentUser.organization.id}/reviews`,
          auth: ["admin"],
        },
        // {
        //   icon: "mdi-bullseye",
        //   text: "Goals",
        //   path: `/goals`,
        //   auth: ["admin", "intake-management", "attorney", "paralegal", "user"],
        // },
      ];

      return filter(routes, (route) => {
        return route.auth.some((role) => hasRole(currentUser, role));
      });
    },
  },
  components: {
    MainLayout,
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  padding: 0px !important;
  position: fixed;
  top: 56px;
  bottom: 0;
  left: 0;
  z-index: 1000;
  padding: 20px;
  overflow-x: hidden;
  overflow-y: auto;
  border-right: 1px solid #eee;
  color: #fff;
  background: #29363d;

  li > a {
    color: #fff;
    &:hover {
      background: #384952;
    }
  }
  li > a.active {
    background: #384952;
  }
}
</style>
