<template>
  <div>
    <div v-if="$apollo.loading">
      <v-progress-linear indeterminate color="primary" />
    </div>
    <div v-if="!$apollo.loading" class="chart-container">
      <highcharts ref="chart" :options="callsByDayChart" />
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["startDate", "endDate"]),
    callsByDayChart() {
      if (!this.callsByDay) return {};
      let data = this.callsByDay.map(cbd => {
        const [year, month, day] = cbd.date.split("-");
        let utc = Date.UTC(year, month - 1, day);
        return [utc, cbd.totalCalls];
      });
      let series = [
        {
          name: "Calls By Day",
          data
        }
      ];
      let options = {};
      return Object.assign({}, options, {
        series,
        credits: {
          enabled: false
        },
        chart: {
          height: 200,
          type: "line"
        },
        title: {
          text: "CallRail Calls by Day"
        },
        legend: {
          enabled: true
        },
        xAxis: {
          type: "datetime"
        },
        yAxis: {
          min: 0,
          title: {
            text: ""
          }
        },
        plotOptions: {
          line: {
            dataLables: {
              enabled: true
            }
          }
        }
      });
    }
  },
  apollo: {
    callsByDay: {
      query: gql`
        query OrgMarketingCallsByDay($startDate: String!, $endDate: String!) {
          callsByDay: callrailCallsByDay(
            startDate: $startDate
            endDate: $endDate
          ) {
            date
            totalCalls
          }
        }
      `,
      variables() {
        return {
          startDate: this.startDate,
          endDate: this.endDate
        };
      },
      skip() {
        return this.startDate === null || this.endDate === null;
      }
    }
  }
};
</script>

<style scoped>
.chart-container {
  height: 220px;
}
</style>
