import ApolloClient from "apollo-client";
import Vue from "vue";
import VueApollo from "vue-apollo";
import { HttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { InMemoryCache } from "apollo-cache-inmemory";

// Install the vue plugin
Vue.use(VueApollo);

// Name of the localStorage item
// const AUTH_TOKEN = "RyBase.token";

// Http endpoint
const httpEndpoint =
  window.location && !/localhost/.test(window.location.href)
    ? "https://rybase.io/api"
    : "http://localhost:4000/api";
// Files URL root
// export const filesRoot =
//   process.env.VUE_APP_FILES_ROOT ||
//   httpEndpoint.substr(0, httpEndpoint.indexOf("/graphql"));

// Vue.prototype.$filesRoot = filesRoot;

// Config
// const defaultOptions = {
// You can use `https` for secure connection (recommended in production)
// httpEndpoint,
// You can use `wss` for secure connection (recommended in production)
// Use `null` to disable subscriptions
// wsEndpoint: process.env.VUE_APP_GRAPHQL_WS || "ws://localhost:4000/graphql",
// LocalStorage token
// tokenName: AUTH_TOKEN,
// Enable Automatic Query persisting with Apollo Engine
// persisting: false,
// Use websockets for everything (no HTTP)
// You need to pass a `wsEndpoint` for this to work
// websocketsOnly: false,
// Is being rendered on the server?
// ssr: false

// Override default apollo link
// note: don't override httpLink here, specify httpLink options in the
// httpLinkOptions property of defaultOptions.
// link: myLink
// defaultHttpLink: true,

// Override default cache
// cache: myCache

// Override the way the Authorization header is set
// getAuth: (tokenName) => ...

// Additional ApolloClient options
// apollo: { ... }

// Client local data (see apollo-link-state)
// clientState: { resolvers: { ... }, defaults: { ... } }
// };

const httpLink = new HttpLink({
  uri: httpEndpoint
});

const httpLinkAuth = setContext((_, { headers }) => {
  // get the authentication token from localstorage if it exists
  const token = localStorage.getItem("RyBase.token");

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : ""
    }
  };
});

export const apolloClient = new ApolloClient({
  link: httpLinkAuth.concat(httpLink),
  cache: new InMemoryCache()
});

// Call this in the Vue app file
export function createProvider() {
  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        // fetchPolicy: 'cache-and-network',
      }
    },
    errorHandler(error) {
      // eslint-disable-next-line no-console
      console.log(error.message);
      let route =
        window.location && !/localhost/.test(window.location.href)
          ? "https://rybase.io/"
          : "http://localhost:8080/";
      if (error.message === "GraphQL error: unauthorized") {
        console.log("Naughty naughty you teasing me");
        window.location.assign(route);
        return false;
      }

      // RETRY LOGIC
      let failedTime = new Date().getTime();
      let priorFailedTime =
        localStorage.getItem("RyBase.failedQueryTime") &&
        parseInt(localStorage.getItem("RyBase.failedQueryTime"), 10);
      let priorFailedKey = localStorage.getItem("RyBase.failedQueryKey");
      let key = Object.keys(this.$apollo.queries)[0];
      let failedQuery = this.$apollo.queries[key];
      if ((failedTime - priorFailedTime) / 1000 > 15) {
        console.log("Past query retry phase");
        priorFailedTime = false;
      }
      if (!priorFailedKey || !priorFailedTime) {
        console.log("Starting query retry phase");
        localStorage.setItem("RyBase.failedQueryTime", failedTime);
        localStorage.setItem("RyBase.failedQueryKey", key);
        setTimeout(() => {
          failedQuery.refetch();
        }, 2000);
      } else if (
        priorFailedKey === key &&
        (failedTime - priorFailedTime) / 1000 < 10
      ) {
        console.log("In query retry phase");
        setTimeout(() => {
          failedQuery.refetch();
        }, 2000);
      }
    }
  });

  return apolloProvider;
}
