<template>
  <v-card class="case-dialog-container">
    <v-progress-linear
      v-if="$apollo.loading"
      indeterminate
      color="primary"
    ></v-progress-linear>
    <v-row>
      <v-col cols="12" sm="4" class="case-facts-container pt-0">
        <v-row dense class="pa-0" align-content="space-around">
          <v-col cols="12">
            <v-card-title class="pb-1"
              >{{ clientName }}
              <a
                class="go-to-case-link ml-2"
                target="_blank"
                :href="
                  `https://cs-web.airdesksolutions.com/SA/CaseSummary.aspx?CaseID=${caseId}`
                "
              >
                <v-icon size="15">mdi-open-in-new</v-icon>
              </a>
            </v-card-title>
            <div class="caption pl-4">
              {{ lead.type }}
            </div>
            <div class="caption pl-4">
              PL: {{ currentCase && currentCase.primaryLanguage }}
            </div>
            <div class="caption pl-4">
              DOI: {{ incidentDate }} - {{ currentCase && currentCase.state }}
            </div>
            <div class="caption pl-4">
              <div v-for="(item, index) in phoneNumbers" :key="index">
                {{ item.phoneNumber }} - {{ item.label }}
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="incidentFacts">
          <v-col class="pb-0 pt-2" cols="12">
            <v-textarea
              dense
              class="caption"
              outlined
              readonly
              label="Incident Facts"
              :value="sanitize(incidentFacts)"
              hide-details
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row v-if="currentCase">
          <v-col class="pb-0 pt-2" cols="12">
            <v-textarea
              dense
              class="caption"
              outlined
              readonly
              label="Injuries"
              :value="sanitize(currentCase.injurySummary)"
              hide-details
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-select
              hide-details
              label="Status"
              :items="statusOptions"
              :value="getValue(lead.status, statusOptions)"
              @change="saveStatus($event, 'status', statusOptions, 1)"
              dense
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-select
              hide-details
              label="Intake Rank"
              :items="rankOptions"
              :value="getValue(lead.intakeRank, rankOptions)"
              @change="saveStatus($event, 'intakeRank', rankOptions, 73)"
              dense
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-menu>
              <template v-slot:activator="{ on, attr }">
                <v-text-field
                  dense
                  hide-details
                  outlined
                  label="Wanted"
                  :value="lead.wantedDate"
                  readonly
                  v-bind="attr"
                  v-on="on"
                />
              </template>
              <v-date-picker
                :value="lead.wantedDate"
                no-title
                scrollable
                @change="saveWanted"
              />
            </v-menu>
          </v-col>
          <v-col cols="6">
            <v-text-field
              dense
              hide-details
              outlined
              label="Retained"
              :value="lead.retainedDate"
              readonly
              disabled
            />
          </v-col>
        </v-row>
        <v-row v-if="currentCase">
          <v-col cols="6">
            <v-text-field dense hide-details outlined label="Attorney" :value="currentCase.attorney"></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field dense hide-details outlined label="Investigator" :value="currentCase.investigator"></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="currentCase">
          <v-col cols="12">
            <v-text-field dense hide-details outlined label="Appointment Info" :value="currentCase.info.appointmentInfo" @blur="saveAppointmentInfo"></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="currentCase">
          <v-col cols="8">
            <v-select
              label="Referral Opportunity"
              :items="yesNoOptions"
              :value="currentCase.info.referralOpportunity"
              @change="saveReferralOpportunity"
              dense
            ></v-select>
          </v-col>
          <v-col cols="4">
            <a
              class="go-to-case-link mr-2"
              style="font-size: 13px; color: rgba(0, 0, 0, 0.54)"
              target="_blank"
              title="Link to Medical"
              :href="
                `https://cs-web.airdesksolutions.com/SA/Plaintiff_Medical_Provider.aspx?current=true&CaseID=${currentCase.caseId}`
              "
            >
              Medical <v-icon size="15" color="">mdi-open-in-new</v-icon>
            </a>
          </v-col>
        </v-row>
        <v-row v-if="currentCase">
          <v-col cols="4">
            <v-select
              label="K Sent"
              :items="yesNoOptions"
              :value="currentCase.info.retainerSent"
              @change="saveRetainerSent"
              dense
            ></v-select>
          </v-col>
          <v-col cols="4">
            <v-select
              label="Fee %"
              :items="feeStructures"
              :value="currentCase.info.feeStructure"
              @change="saveFeeStructure"
              dense
            ></v-select>
          </v-col>
          <v-col cols="4">
            <a
              class="go-to-case-link mr-2"
              style="font-size: 13px; color: rgba(0, 0, 0, 0.54)"
              target="_blank"
              title="Link to Docs"
              :href="
                `https://cs-web.airdesksolutions.com/SA/case_documents.aspx?CaseID=${currentCase.caseId}`
              "
            >
              Docs <v-icon size="15" color="">mdi-open-in-new</v-icon>
            </a>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="8" class="pt-0">
        <v-row dense>
          <v-col cols="8">
            <v-card-title class="pl-0 pb-1">Notes</v-card-title>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="3" class="pt-5">
            <v-btn
              class="primary"
              v-if="!showNoteForm"
              @click="showNoteForm = true"
              >New Note</v-btn
            >
            <v-btn class="red" v-if="showNoteForm" @click="showNoteForm = false"
              >Cancel</v-btn
            >
          </v-col>
        </v-row>
        <div v-if="!showNoteForm">
          <v-text-field
            v-model="noteFilter"
            hide-details
            placeholder="Search..."
          ></v-text-field>
        </div>
        <div class="note-container" v-if="!showNoteForm">
          <div class="mb-1" v-for="(item, index) in notes" :key="index">
            <v-container fluid style="padding-top: 0px;">
              <v-row class="note-header">
                <v-col cols="3" class="caption">{{
                  formatDate(item.createdAt)
                }}</v-col>
                <v-col cols="3" class="caption">{{ item.title }}</v-col>
                <v-col cols="4" class="caption">{{ item.creatorName }}</v-col>
                <v-spacer></v-spacer>
                <v-col cols="2" class="caption"
                  >{{ index + 1 }}/{{ notes.length }}</v-col
                >
              </v-row>
              <div class="pt-3 note-text">
                <span v-html="sanitize(item.plainText)"></span>
              </div>
            </v-container>
          </div>
        </div>
        <div v-if="showNoteForm">
          <v-row>
            <v-col cols="12">
              <v-textarea
                :autofocus="showNoteForm"
                v-model="newNote"
                placeholder="New Note"
              />
              <v-btn
                :loading="savingNote"
                :disabled="savingNote"
                color="primary"
                @click="saveNote"
                >Save</v-btn
              >
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import gql from "graphql-tag";
import filter from "lodash/filter";
import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";
import findIndex from "lodash/findIndex";
import sanitizeHtml from "sanitize-html";
import debounce from "lodash/debounce";
import format from "date-fns/format";
import { ORG_HOME_INTAKE_LEADS_DIALOG } from "../graphql/cases.gql";
import { ORG_HOME_INTAKE_LEADS } from "../graphql/cases.gql";

export default {
  data() {
    return {
      noteFilter: "",
      showNoteForm: false,
      newNote: "",
      savingNote: false,
      yesNoOptions: ["", "Yes", "No"],
      yesNoOptionsBool: [
        { text: "", value: "" },
        { text: "Yes", value: true },
        { text: "No", value: false }
      ],
      feeStructures: ["", "33", "33/40", "35/40", "37.5/40", "40"]
    };
  },
  props: ["caseId", "date", "lead", "rankOptions", "statusOptions"],
  computed: {
    clientName() {
      return this.currentCase && this.currentCase.clientName;
    },
    incidentFacts() {
      if (!this.currentCase || !this.currentCase.incidentFacts) return "";
      return this.currentCase.incidentFacts;
    },
    incidentDate() {
      if (!this.currentCase || !this.currentCase.dateOfIncident) return "";
      return this.currentCase.dateOfIncident;
    },
    notes() {
      if (this.currentCase) {
        let preSort = this.currentCase.notes;
        if (this.noteFilter) {
          let regex = new RegExp(this.noteFilter, "ig");
          preSort = filter(this.currentCase.notes, n => {
            return regex.test(n.plainText);
          });
        }
        let notes = sortBy(preSort, [
          n => {
            return n.createdAt;
          }
        ]).reverse();
        return notes;
      } else {
        return [];
      }
    },
    phoneNumbers() {
      if (!this.currentCase) return [];
      return this.currentCase.phoneNumbers;
    }
  },
  methods: {
    sanitize(html) {
      return sanitizeHtml(html).replace(/<p><\/p>/gi, "");
    },
    filter: debounce(function() {
      console.log("Boom");
    }, 500),
    formatDate(input) {
      if (!input) return "";
      return format(new Date(input), "MM/d/yyyy h:mm aaa");
    },
    saveNote() {
      if (!this.newNote) return false;
      this.savingNote = true;
      this.$apollo
        .mutate({
          mutation: gql`
            mutation InsertNote($note: NoteInput!) {
              insertSaCaseNote(note: $note) {
                content
              }
            }
          `,
          variables: {
            note: {
              case_id: this.caseId,
              note_type_id: 189,
              content: this.newNote
            }
          }
        })
        .then(() => {
          this.$apollo.queries.currentCase.refetch();
          this.savingNote = false;
          this.showNoteForm = false;
          this.newNote = "";
        })
        .catch(error => {
          console.error(error);
        });
      return false;
    },
    saveWanted(date) {
      if (!date) return false;
      this.$apollo
        .mutate({
          mutation: gql`
            mutation InsertWantedDate($dateInput: WantedDate!) {
              insertSaWantedDate(dateInput: $dateInput) {
                wantedDate
              }
            }
          `,
          variables: {
            dateInput: {
              case_id: this.caseId,
              wanted_date: date
            }
          },
          update: (store, { data: { insertSaWantedDate } }) => {
            this.updateCache(store, insertSaWantedDate, "wantedDate");
          }
        })
        .then(() => {})
        .catch(error => {
          console.error(error);
        });
      return false;
    },
    saveStatus(val, type, options, statusType) {
      if (!val) return false;
      this.$apollo
        .mutate({
          mutation: gql`
            mutation UpdateCaseStatus($status: CaseStatus!) {
              upsertSaStatus(status: $status) {
                statusId
              }
            }
          `,
          variables: {
            status: {
              case_id: this.caseId,
              status_id: val,
              type: statusType
            }
          },
          update: (store, { data: { upsertSaStatus } }) => {
            let data = {
              [type]: this.getText(upsertSaStatus.statusId, options)
            };
            this.updateCache(store, data, type);
          }
        })
        .then()
        .catch(error => {
          console.error(error);
        });
      return false;
    },
    saveAppointmentInfo(data) {
      this.saveInfo(data.target.value, "appointmentInfo");
    },
    saveReferralOpportunity(data) {
      this.saveInfo(data, "referralOpportunity");
    },
    saveRetainerSent(data) {
      this.saveInfo(data, "retainerSent");
    },
    saveFeeStructure(data) {
      this.saveInfo(data, "feeStructure");
    },
    saveInfo(data, field) {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation UpsertCaseInfo($caseInfo: CaseInfoInput!) {
              upsertCaseInfo(caseInfo: $caseInfo) {
                id
                caseId
                referralOpportunity
                referred
                signupType
                handledByBackup
                leadType
                highPriority
                appointmentInfo
                retainerSent
                feeStructure
              }
            }
          `,
          variables: {
            caseInfo: {
              case_id: this.currentCase.caseId,
              [field]: data
            }
          },
          // update: (store, { data: { upsertCaseInfo } }) => {
          //   this.updateInfoCache(store, upsertCaseInfo, field);
          // }
        })
        .catch(error => {
          console.error(error);
        });
      return false;
    },
    updateCache(store, insertData, field) {
      let caseId = this.caseId;
      const data = store.readQuery({
        query: ORG_HOME_INTAKE_LEADS,
        variables: {
          startDate: this.date[0],
          endDate: this.date[1]
        }
      });
      let index = findIndex(data.leadsInPeriod, c => {
        return c.caseId === caseId;
      });
      data.leadsInPeriod[index][field] = insertData[field];
      store.writeQuery({
        query: ORG_HOME_INTAKE_LEADS,
        variables: {
          startDate: this.date[0],
          endDate: this.date[1]
        },
        data
      });
    },
    getValue(val, options) {
      let values = groupBy(options, "text");
      if (!values[val]) return null;
      return values[val][0].value;
    },
    getText(val, options) {
      let values = groupBy(options, "value");
      return values[val][0].text;
    }
  },
  apollo: {
    currentCase: {
      query: ORG_HOME_INTAKE_LEADS_DIALOG,
      variables() {
        return {
          caseId: parseInt(this.caseId, 10)
        };
      },
      skip() {
        if (!this.caseId) return true;
        return false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.highlight {
  background-color: #eef43b;
  color: #515151;
}
.case-dialog-container {
  height: 85vh;
  overflow-y: scroll;
  background: #fff;
}
.case-facts-container .row {
  margin-left: 0;
}
.note-container {
  height: 70vh;
  overflow-y: scroll;
  max-width: 100%;
}
.note-header {
  background: #eee;
}
.note-filter {
  background: #fff;
  border: 1px solid #eee;
}
.note {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}
.note-text {
  font-size: 0.8em !important;
  overflow-x: hidden;
  white-space: pre-wrap;
}
.note-text > table {
  table-layout: fixed;
  width: 100%;
}
.note-text > p {
  margin-block-end: 3px;
  margin-bottom: 3px;
}
.note-meta {
  font-size: 0.85em;
  background: #efefef;
}
.incident-facts-container {
  max-height: 200px;
  overflow-y: scroll;
  white-space: pre-wrap;
}
</style>
