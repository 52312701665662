<template>
  <div>
    <highcharts ref="chart" :options="chartOptions" />
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  mounted() {
    setTimeout(() => {
      if (!this.$refs.chart) return;
      this.$refs.chart.chart.reflow();
    }, 200);
  },
  computed: {
    chartOptions() {
      return Object.assign({}, this.options, {
        chart: {
          type: "column"
        },
        title: {
          text: ""
        },
        subtitle: {
          text: ""
        },
        yAxis: {
          min: 0,
          title: {
            text: ""
          }
        },
        tooltip: {
          headerFormat:
            '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat:
            '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
          footerFormat: "</table>",
          shared: true,
          useHTML: true
        },
        credits: {
          enabled: false
        },
        legend: {
          enabled: true
        }
      });
    }
  }
};
</script>

<style scoped></style>
