<template>
  <main-layout :items="items">
    <router-view />
  </main-layout>
</template>

<script>
import MainLayout from "./MainLayout";
import { hasRole } from "../util";
import filter from "lodash/filter";

export default {
  computed: {
    items() {
      let currentUser = this.$store.state.user.currentUser;
      if (!currentUser) return [];
      let routes = [
        {
          icon: "mdi-office-building",
          text: "Organization",
          path: `/org/${currentUser.organization.id}`,
          auth: ["admin", "intake-management"],
        },
        {
          icon: "mdi-chemical-weapon",
          text: "Intake",
          path: `/dash/${currentUser.id}/intake`,
          auth: ["admin", "intake-management", "intake"],
        },
        {
          icon: "mdi-account-box",
          text: "CRM",
          path: `/crm`,
          auth: ["admin", "intake-management", "intake"],
        },
        // {
        //   icon: "mdi-file-document",
        //   text: "MR",
        //   path: `/mr`,
        //   auth: ["admin", "medical-requester"]
        // },
        {
          icon: "mdi-format-list-bulleted-square",
          text: "Cases",
          path: `/dash/${currentUser.id}/inventory`,
          auth: ["admin", "intake-management", "attorney", "paralegal", "user"],
        },
        {
          icon: "mdi-hospital-box",
          text: "Treating",
          path: `/dash/${currentUser.id}/treating`,
          auth: ["admin", "intake-management", "attorney", "paralegal", "user"],
        },
        {
          icon: "mdi-email-newsletter",
          text: "Demands",
          path: `/dash/${currentUser.id}/demands`,
          auth: ["admin", "intake-management", "attorney", "paralegal", "user"],
        },
        {
          icon: "mdi-account-switch",
          text: "Negotiation",
          path: `/dash/${currentUser.id}/negotiation`,
          auth: ["admin", "intake-management", "attorney", "paralegal", "user"],
        },
        {
          icon: "mdi-scale-balance",
          text: "Litigation",
          path: `/dash/${currentUser.id}/litigation`,
          auth: ["admin", "intake-management", "attorney", "paralegal", "user"],
        },
        {
          icon: "mdi-currency-usd",
          text: "Fees",
          path: `/dash/${currentUser.id}/fees/`,
          auth: ["admin", "intake-management", "attorney", "paralegal", "user"],
        },
        {
          icon: "mdi-message-draw",
          text: "Reviews",
          path: `/dash/${currentUser.id}/reviews`,
          auth: ["admin", "intake-management", "attorney", "paralegal", "user"],
        },
        // {
        //   icon: "mdi-bullseye",
        //   text: "Goals",
        //   path: `/goals`,
        //   auth: ["admin", "intake-management", "attorney", "paralegal", "user"],
        // },
      ];

      return filter(routes, (route) => {
        return route.auth.some((role) => hasRole(currentUser, role));
      });
    },
  },
  components: {
    MainLayout,
  },
};
</script>

<style scoped></style>
