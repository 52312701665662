<template>
  <case-notes-list stage="Litigation"></case-notes-list>
</template>

<script>
import CaseNotesList from "./CaseNotesList";

export default {
  components: {
    CaseNotesList
  }
};
</script>

<style scoped></style>