<template>
  <v-card>
    <v-container class="py-0" fluid>
      <v-row align-content="space-around">
        <v-col cols="12" sm="4">
          <v-card-title class="pa-0">
            <div>
              <div class="headline">Watched Cases</div>
              <div class="text-subtitle-1 font-weight-thin">
                {{ totalRows }} Cases
              </div>
            </div>
          </v-card-title>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            hide-details
            single-line
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
    <v-data-table
      :footer-props="{
        'items-per-page-options': [-1]
      }"
      :headers="headers"
      hide-default-footer
      :items="cases"
      item-key="caseId"
      :loading="$apollo.loading"
      :search="search"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      ref="inventory-table"
    >
      <template v-slot:item.caseId="props">
        <a
          class="go-to-case-link mr-2"
          target="_blank"
          :href="
            `https://cs-web.airdesksolutions.com/SA/CaseSummary.aspx?CaseID=${props.item.caseId}`
          "
        >
          <v-icon size="15">mdi-open-in-new</v-icon>
        </a>
        <a class="mr-2" @click="stopWatching($apollo, props.item.caseId)">
          <v-icon size="15">mdi-star-off</v-icon>
        </a>
      </template>
      <template v-slot:item.case.status="props">
        <v-edit-dialog
          @open="openEditDialog(props.item.caseId)"
          @close="closeEditDialog"
        >
          {{ props.item.case.status && props.item.case.status.substr(0, 20) }}
          <template v-slot:input>
            <v-select
              :items="statusOptions"
              :value="getValue(props.item.case.status, statusOptions)"
              @change="saveStatus($event, 'case.status', statusOptions, 1)"
              dense
            ></v-select>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:item.case.valueFrom="props">
        {{ formatValue(props.item) }}
      </template>
      <template v-slot:item.case.rank="props">
        <v-edit-dialog @open="openRank(props.item.caseId)" @close="closeRank">
          {{ props.item.case.rank }}
          <template v-slot:input>
            <v-select
              :items="rankOptions"
              :value="rankValue(props.item.case.rank)"
              @change="saveRank"
              dense
            ></v-select>
          </template>
        </v-edit-dialog>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import gql from "graphql-tag";
import { mapGetters } from "vuex";
import { formatValue, stopWatching } from "../util";
import groupBy from "lodash/groupBy";
import findIndex from "lodash/findIndex";
import { DASH_WATCHED_CASES_LIST } from "../graphql/user.gql";

export default {
  data() {
    return {
      search: "",
      headers: [
        { text: "Action", value: "caseId", width: "100px" },
        { text: "Type", value: "case.type" },
        { text: "Stage", value: "case.stage" },
        { text: "Status", value: "case.status" },
        { text: "Days in Status", value: "case.daysInStatus" },
        { text: "Client", value: "case.clientName" },
        // { text: "Value", value: "valueFrom" },
        { text: "Rank", value: "case.rank" },
        { text: "Attorney", value: "case.attorney" },
        { text: "Paralegal", value: "case.paralegal" },
        { text: "Opened", value: "case.dateOpened" },
        { text: "DOI", value: "case.dateOfIncident" },
        { text: "State", value: "case.state" }
      ],
      user: { cases: [] },
      rankOptions: [
        { text: "A++ (> $1M)", value: 805 },
        { text: "A+ ($251k - $1M)", value: 806 },
        { text: "A ($101k - $250k)", value: 807 },
        { text: "B ($51k - $100k)", value: 1161 },
        { text: "C ($15k - $50k)", value: 1089 },
        { text: "D (<$15k)", value: 1090 },
        { text: "Unrated - Default", value: 1088 },
        { text: "", value: "" }
      ],
      statusOptions: [
        { stage: "Treating", text: "PL 00 - File Setup", value: 1120 },
        { stage: "Treating", text: "PL 01 - Medical Management", value: 1121 },
        {
          stage: "Demand Prep",
          text: "PL 02 - Evidence Collection",
          value: 1122
        },
        {
          stage: "Demand Prep",
          text: "PL 03 - Demand Preparation",
          value: 1123
        },
        { stage: "Demand Prep", text: "PL 04 - Demand Review", value: 1124 },
        { stage: "Demand Prep", text: "PL 05 - Demand Sent", value: 1125 },
        { stage: "Negotiations", text: "PL 06 - Negotiations", value: 1126 },
        {
          stage: "Negotiations",
          text: "PL 07 - UIM Demand Preparation",
          value: 1127
        },
        { stage: "Negotiations", text: "PL 08 - UIM Demand Sent", value: 1128 },
        {
          stage: "Negotiations",
          text: "PL 09 - UIM Negotiations",
          value: 1129
        },
        { stage: "Litigation", text: "PL 10 - UIM Arbitration", value: 1165 },
        { stage: "Settled", text: "PL 11 - Incoming Fees", value: 1130 },
        {
          stage: "Settled",
          text: "PL 12 - Fees Disbursed or Lien and Balance Resolution",
          value: 1131
        },
        { stage: "Settled", text: "PL 13 - Long-term Trust", value: 1132 },
        { stage: "", text: "", value: "" }
      ],
      selectedCaseId: ""
    };
  },
  computed: {
    ...mapGetters(["imitatedUser"]),
    cases() {
      if (!this.user || !this.user.watchedCases) return [];
      return this.user.watchedCases;
    },
    totalRows() {
      return (
        this.user && this.user.watchedCases && this.user.watchedCases.length
      );
    },
    sortBy() {
      let saved = JSON.parse(localStorage.getItem("RyBase.watchedCasesSortBy"));
      if (!saved) return ["dateOpened"];
      return saved;
    },
    sortDesc() {
      let saved = JSON.parse(
        localStorage.getItem("RyBase.watchedCasesSortDesc")
      );
      if (!saved) return [true];
      return saved;
    }
  },
  methods: {
    formatValue,
    stopWatching,
    updateSort(type, event) {
      if (type === "by") {
        localStorage.setItem(
          "RyBase.watchedCasesSortBy",
          JSON.stringify(event)
        );
      } else if (type === "order") {
        localStorage.setItem(
          "RyBase.watchedCasesSortDesc",
          JSON.stringify(event)
        );
      }
    },
    saveRank(statusId) {
      if (!statusId) return false;
      this.$apollo
        .mutate({
          mutation: gql`
            mutation InsertCaseRankStatus($status: CaseStatus!) {
              insertSaRankStatus(status: $status) {
                statusId
              }
            }
          `,
          variables: {
            status: {
              case_id: this.selectedCaseId,
              status_id: statusId
            }
          },
          update: (store, { data: { insertSaRankStatus } }) => {
            this.updateCache(store, insertSaRankStatus);
          }
        })
        .then(() => {})
        .catch(error => {
          console.error(error);
        });
      return false;
    },
    saveStatus(val, type, options, statusType) {
      if (!val) return false;
      this.$apollo
        .mutate({
          mutation: gql`
            mutation UpdateCaseStatus($status: CaseStatus!) {
              upsertSaStatus(status: $status) {
                statusId
              }
            }
          `,
          variables: {
            status: {
              case_id: this.selectedCaseId,
              status_id: val,
              type: statusType
            }
          },
          update: (store, { data: { upsertSaStatus } }) => {
            this.updateStatusCache(store, upsertSaStatus, "status");
          }
        })
        .then(() => {
          this.$refs["inventory-table"].$el.click();
        })
        .catch(error => {
          console.error(error);
        });
      return false;
    },
    updateCache(store, insertData) {
      let id = this.imitatedUser
        ? this.imitatedUser.toString()
        : this.$route.params.id;
      let caseId = this.selectedCaseId;
      const data = store.readQuery({
        query: DASH_WATCHED_CASES_LIST,
        variables: {
          id
        }
      });
      let index = findIndex(data.user.watchedCases, c => {
        return c.caseId === caseId;
      });
      let newRank = this.rankText(insertData.statusId);
      data.user.watchedCases[index].rank = newRank;
      store.writeQuery({
        query: DASH_WATCHED_CASES_LIST,
        variables: {
          id
        },
        data
      });
    },
    updateStatusCache(store, insertData, field) {
      let id = this.imitatedUser
        ? this.imitatedUser.toString()
        : this.$route.params.id;
      let caseId = this.selectedCaseId;
      const data = store.readQuery({
        query: DASH_WATCHED_CASES_LIST,
        variables: {
          id
        }
      });
      let index = findIndex(data.user.watchedCases, c => {
        return c.caseId === caseId;
      });
      data.user.watchedCases[index][field] = this.getText(
        insertData.statusId,
        this.statusOptions
      );
      data.user.watchedCases[index].stage = this.getStage(
        insertData.statusId,
        this.statusOptions
      );
      store.writeQuery({
        query: DASH_WATCHED_CASES_LIST,
        variables: {
          id
        },
        data
      });
    },
    rankValue(rank) {
      let ranks = groupBy(this.rankOptions, "text");
      if (!ranks[rank]) return null;
      return ranks[rank][0].value;
    },
    rankText(rank) {
      let ranks = groupBy(this.rankOptions, "value");
      return ranks[rank][0].text;
    },
    openRank(caseId) {
      this.selectedCaseId = caseId;
      return false;
    },
    closeRank() {
      this.selectedCaseId = "";
      return false;
    },
    getValue(val, options) {
      let values = groupBy(options, "text");
      if (!values[val]) return null;
      return values[val][0].value;
    },
    getText(val, options) {
      let values = groupBy(options, "value");
      return values[val][0].text;
    },
    getStage(val, options) {
      let values = groupBy(options, "value");
      return values[val][0].stage;
    },
    openEditDialog(caseId) {
      this.selectedCaseId = caseId;
      return false;
    },
    closeEditDialog() {
      this.selectedCaseId = "";
      return false;
    }
  },
  apollo: {
    user: {
      query: DASH_WATCHED_CASES_LIST,
      variables() {
        let id = this.imitatedUser
          ? this.imitatedUser.toString()
          : this.$route.params.id;
        return {
          id
        };
      },
      fetchPolicy: "no-cache"
    }
  }
};
</script>

<style scoped>
.total-settlement-value {
  width: 90%;
  text-align: center;
}
.total-fee-value {
  width: 90%;
  text-align: center;
}
</style>
