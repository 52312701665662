<template>
  <v-row>
    <org-home-stats-fees />
    <org-home-stats-new-cases />
    <org-home-stats-demands />
    <org-home-stats-inventory />
  </v-row>
</template>

<script>
import OrgHomeStatsFees from "./OrgHomeStatsFees";
import OrgHomeStatsNewCases from "./OrgHomeStatsNewCases";
import OrgHomeStatsDemands from "./OrgHomeStatsDemands";
import OrgHomeStatsInventory from "./OrgHomeStatsInventory";

export default {
  components: {
    OrgHomeStatsFees,
    OrgHomeStatsNewCases,
    OrgHomeStatsDemands,
    OrgHomeStatsInventory
  }
};
</script>

<style scoped lang="scss"></style>
