<template>
  <v-card>
    <v-container class="py-0" fluid>
      <v-row align-content="space-around">
        <v-col cols="12" sm="2">
          <v-card-title class="pa-0">
            <div>
              <div class="headline">Rate</div>
            </div>
          </v-card-title>
        </v-col>
        <v-spacer></v-spacer>
        <!-- <v-col cols="12" sm="1" v-if="isAdmin">
          <div class="mr-5">
            <v-switch
              class=""
              label="Show All"
              v-model="showAllFees"
            ></v-switch>
          </div>
        </v-col> -->
        <v-col class="pr-2" cols="4" sm="2">
          <v-text-field
            v-model="search"
            append-icon="mdi-search"
            label="Search"
            hide-details
            single-line
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
    <v-data-table
      :headers="headers"
      :items="rates"
      class="elevation-1"
      :footer-props="{
        'items-per-page-options': [50, 100, -1],
      }"
      :sort-by="['year']"
      :sort-desc="[true]"
      :search="search"
      :item-key="Math.random(10).toString()"
      :loading="$apollo.loading"
      height="inherit"
    >
      <template v-slot:item.caseId="props">
        <a
          class="go-to-case-link mr-2"
          target="_blank"
          :href="
            `https://cs-web.airdesksolutions.com/SA/CaseSummary.aspx?CaseID=${props.item.caseId}`
          "
        >
          <v-icon size="15">mdi-open-in-new</v-icon>
        </a>
      </template>
      <template v-slot:item.avgFees="props">
        {{ formatMoney(props.item.avgFees) }}
      </template>
      <template v-slot:item.rate="props">
        <div v-html="props.item.rate"></div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import gql from "graphql-tag";
import { mapGetters } from "vuex";
import accounting from "accounting";
import { hasRole } from "../util";

export default {
  data() {
    return {
      search: "",
      pagination: { sortBy: "date", descending: true },
      selectedOffice: null,
      selectedState: null,
      selectedReferralSource: null,
      showAllFees: true,
    };
  },
  apollo: {
    rateByYearByAttorneyByType: {
      query: gql`
        query DashFeesRate($startDate: String!, $endDate: String!) {
          rateByYearByAttorneyByType(startDate: $startDate, endDate: $endDate) {
            year
            caseType
            attorney
            avgTodDays
            avgTodMonths
            totalCases
            avgFees
            rate
          }
        }
      `,
      fetchPolicy: "no-cache",
      variables() {
        return {
          startDate: this.startDate,
          endDate: this.endDate,
        };
      },
      skip() {
        return this.startDate === null || this.endDate === null;
      },
    },
  },
  computed: {
    ...mapGetters(["selectedTeam", "startDate", "endDate", "currentUser"]),
    headers() {
      let headers = [
        { text: "Action", value: "caseId" },
        { text: "Year", value: "year" },
        { text: "Case Type", value: "caseType" },
        { text: "Attorney", value: "attorney" },
        { text: "TOD Days", value: "avgTodDays" },
        { text: "TOD Months", value: "avgTodMonths" },
        { text: "Total Cases", value: "totalCases" },
        { text: "Avg Fees", value: "avgFees" },
        { text: "Rate", value: "rate" },
      ];

      return headers;
    },
    rates() {
      if (!this.rateByYearByAttorneyByType) return [];
      return this.rateByYearByAttorneyByType.map((r) => {
        return {
          ...r,
          rate:
            '<span style="color: ' +
            (r.rate > 800 ? "green" : "red") +
            '">' +
            this.formatMoney(r.rate) +
            "</span>",
        };
      });
    },
    showAll() {
      if (!this.currentUser) return false;
      if (this.showAllFees === false) {
        return false;
      }
      return (
        hasRole(this.currentUser, "admin") ||
        hasRole(this.currentUser, "marketing")
      );
    },
    isAdmin() {
      if (!this.currentUser) return false;
      return (
        hasRole(this.currentUser, "admin") ||
        hasRole(this.currentUser, "marketing")
      );
    },
  },
  methods: {
    formatMoney(val) {
      return accounting.formatMoney(val);
    },
    formatColor(val) {
      console.log(val);
    },
  },
};
</script>

<style scoped>
td.status {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  width: 150px;
}
table.table > tbody {
  max-height: 10vh !important;
  font-size: 10px !important;
}
table.table > tbody > td {
  white-space: nowrap !important;
  font-size: 10px !important;
}
.inventory-title {
  font-size: 36px;
}

.inventory-number {
  font-size: 24px;
}
.fee-stat-container {
  padding: 8px 16px;
  text-align: center;
}
.fee-stat-title {
  font-size: 16px;
}
.fee-stat-data {
  font-size: 30px;
  font-weight: bold;
}
</style>
