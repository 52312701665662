<template>
  <v-card>
    <v-container class="py-0" fluid>
      <v-row align-content="space-around">
        <v-col cols="12" sm="6">
          <v-card-title class="pa-0">
            <div>
              <div class="headline">Tasks</div>
              <div class="text-subtitle-1 font-weight-thin">
                {{ tasks.length }} Tasks
              </div>
            </div>
          </v-card-title>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="2">
          <v-switch
            class="pa-4"
            label="Show Requested"
            v-model="showRequested"
          ></v-switch>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            hide-details
            single-line
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
    <v-data-table
      :footer-props="{
        'items-per-page-options': [-1]
      }"
      :headers="headers"
      hide-default-footer
      :items="tasks"
      :loading="$apollo.loading"
      :search="search"
      :sort-by="['dueDate']"
      :sort-desc="[false]"
    >
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="item in items" :key="item.id">
            <td>
              <a
                class="go-to-case-link mr-2"
                target="_blank"
                :href="
                  `https://cs-web.airdesksolutions.com/SA/CaseSummary.aspx?CaseID=${item.caseId}`
                "
              >
                <v-icon size="15">mdi-open-in-new</v-icon>
              </a>
            </td>
            <td>{{ truncate(item.clientName, 20) }}</td>
            <td>{{ item.dueDate }}</td>
            <td>
              {{ showRequested ? item.assignedUser : item.requestorUser }}
            </td>
            <td>{{ item.category }}</td>
            <td>
              <v-tooltip
                v-if="item.summary.length > 30"
                :open-on-hover="true"
                top
                color="black"
              >
                <template v-slot:activator="{ on }">
                  <span v-on="on">{{ truncate(item.summary, 30) }}</span>
                </template>
                <span>{{ item.summary }}</span>
              </v-tooltip>
              <span v-else>{{ item.summary }}</span>
            </td>
            <td>{{ item.caseRank }}</td>
            <td>{{ item.description }}</td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import gql from "graphql-tag";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      search: "",
      headers: [
        { text: "Action", value: "caseId", width: "80px" },
        { text: "Client", value: "clientName", width: "150px" },
        { text: "Due", value: "dueDate", width: "100px" },
        { text: "Requested By", value: "requestorUser", width: "120px" },
        { text: "Category", value: "category", width: "100px" },
        { text: "Summary", value: "summary", width: "200px" },
        { text: "Rank", value: "caseRank", width: "150px" },
        { text: "Description", value: "description" }
      ],
      showRequested: false
    };
  },
  computed: {
    ...mapGetters(["currentUser", "imitatedUser"]),
    tasks() {
      if (!this.user) return [];
      return this.showRequested ? this.user.requestedTasks : this.user.tasks;
    }
  },
  methods: {
    truncate(string, length) {
      return string.slice(0, length);
    }
  },
  apollo: {
    user: {
      query: gql`
        query($id: ID!) {
          user(id: $id) {
            id
            first_name
            last_name
            watchedCases {
              caseId
            }
            tasks {
              id
              caseId
              caseRank
              clientName
              category
              summary
              description
              dueDate
              requestorUser
            }
            requestedTasks {
              id
              caseId
              caseRank
              clientName
              category
              summary
              description
              dueDate
              assignedUser
            }
          }
        }
      `,
      variables() {
        let id = this.imitatedUser
          ? this.imitatedUser.toString()
          : this.currentUser.id;
        return {
          id
        };
      },
      skip() {
        return !this.currentUser;
      }
    }
  }
};
</script>

<style scoped></style>
