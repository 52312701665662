<template>
  <v-card>
    <v-container class="py-0" fluid>
      <v-row align-content="space-around">
        <v-col cols="12" sm="8">
          <v-card-title class="pa-0">
            <div>
              <div class="headline">File Review</div>
              <div class="text-subtitle-1 font-weight-thin">
                {{ user.cases.length }} Cases
              </div>
            </div>
          </v-card-title>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            hide-details
            single-line
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="swatchDialog" max-width="300px">
      <v-card>
        <v-card-title>
          Highlight Row
        </v-card-title>
        <v-card-text>
          <swatches colors="text-advanced" inline @input="saveColor($event)" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-data-table
      :footer-props="{
        'items-per-page-options': [-1]
      }"
      :headers="headers"
      hide-default-footer
      :items="user.cases"
      item-key="caseId"
      :loading="$apollo.loading"
      :search="search"
      :sort-by="['clientName']"
      :sort-desc="[false]"
    >
      <template v-slot:body="{ items }">
        <tbody>
          <tr
            :style="{ background: item.data.negColor }"
            v-for="item in items"
            :key="item.caseId"
          >
            <td>
              <a
                class="go-to-case-link mr-2"
                target="_blank"
                :href="
                  `https://cs-web.airdesksolutions.com/SA/CaseSummary.aspx?CaseID=${item.caseId}`
                "
              >
                <v-icon size="15">mdi-open-in-new</v-icon>
              </a>
              <v-icon
                small
                @click="
                  swatchDialog = true;
                  selectedRow = item;
                "
                >mdi-palette</v-icon
              >
            </td>
            <td>
              <router-link
                :to="{ name: 'case', params: { caseId: item.caseId } }"
                >{{ item.clientName }}</router-link
              >
            </td>
            <td>{{ item.paralegal }}</td>
            <td>{{ item.daysInStatus }}</td>
            <td>{{ formatNoteDate(item.dateOfLastFileReview) }}</td>
            <td>
              <v-edit-dialog lazy @save="saveNote" @open="setItem(item)" large>
                {{ item.lastFileReviewNote }}
                <template v-slot:input>
                  <v-textarea
                    style="width: 500px;"
                    slot="input"
                    label="Edit"
                    :value="item.lastFileReviewNote"
                    v-model="editItemFileReviewNote"
                  ></v-textarea>
                </template>
              </v-edit-dialog>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { USER_CASES_BY_STATUS_QUERY } from "../graphql/user.gql";
import gql from "graphql-tag";
import findIndex from "lodash/findIndex";
import format from "date-fns/format";
import Swatches from "vue-swatches";
import { mapGetters } from "vuex";

let caseStatus = "Negotiation";

export default {
  data() {
    return {
      headers: [
        { text: "Action", value: "data.negColor", width: "100px" },
        { text: "Client", value: "clientName", width: "200px" },
        { text: "CM", value: "paralegal", width: "60px" },
        { text: "Days", value: "daysInStatus", width: "70px" },
        { text: "Updated", value: "dateOfLastFileReview", width: "100px" },
        { text: "Note", value: "lastFileReviewNote" }
      ],
      search: "",
      selectedRow: null,
      swatchDialog: false,
      editItem: null,
      editItemFileReviewNote: null,
      user: { cases: [] }
    };
  },
  computed: {
    ...mapGetters(["imitatedUser"])
  },
  components: {
    Swatches
  },
  apollo: {
    user: {
      query: USER_CASES_BY_STATUS_QUERY,
      variables() {
        let id = this.imitatedUser
          ? this.imitatedUser.toString()
          : this.$route.params.id;
        return {
          id,
          status: caseStatus
        };
      }
    }
  },
  methods: {
    saveNote() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation InsertSaCaseNote($note: NoteInput!) {
              insertSaCaseNote(note: $note) {
                content
              }
            }
          `,
          variables: {
            note: {
              case_id: this.editItem.caseId,
              content: this.editItemFileReviewNote,
              note_type_id: 81
            }
          },
          update: (
            store,
            {
              data: {
                insertSaCaseNote: { content }
              }
            }
          ) => {
            let newItem = Object.assign({}, this.editItem, {
              lastFileReviewNote: content
            });
            this.updateCacheWithLastFileReviewNote(store, newItem);
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    saveTest() {
      console.log(this.editItem);
      console.log(this.editItemFileReviewNote);
    },
    setItem(item) {
      this.editItem = item;
      this.editItemFileReviewNote = item.lastFileReviewNote;
    },
    saveColor(color) {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation UpsertCaseData($caseData: CaseDataInput!) {
              upsertCaseData(caseData: $caseData) {
                id
                caseId
                negNote
                negColor
                negNoteUpdatedAt
                updatedAt
              }
            }
          `,
          variables: {
            caseData: {
              case_id: this.selectedRow.caseId,
              negColor: color,
              user_id: parseInt(this.$route.params.id)
            }
          },
          update: (store, { data: { upsertCaseData } }) => {
            console.log(upsertCaseData);
            this.updateCache(store, upsertCaseData);
          }
        })
        .then(() => {})
        .catch(error => {
          console.error(error);
        });
    },
    updateCacheWithLastFileReviewNote(store, caseData) {
      const data = store.readQuery({
        query: USER_CASES_BY_STATUS_QUERY,
        variables: {
          id: this.$route.params.id,
          status: caseStatus
        }
      });
      let index = findIndex(data.user.cases, c => {
        return c.caseId === caseData.caseId;
      });
      let cd = data.user.cases[index];
      data.user.cases[index] = Object.assign({}, cd, {
        lastFileReviewNote: caseData.lastFileReviewNote
      });
      store.writeQuery({
        query: USER_CASES_BY_STATUS_QUERY,
        variables: {
          id: this.$route.params.id,
          status: caseStatus
        },
        data
      });
    },
    updateCache(store, upsertCaseData) {
      const data = store.readQuery({
        query: USER_CASES_BY_STATUS_QUERY,
        variables: {
          id: this.$route.params.id,
          status: caseStatus
        }
      });
      let index = findIndex(data.user.cases, c => {
        return c.caseId === upsertCaseData.caseId;
      });
      let cd = data.user.cases[index].data;
      data.user.cases[index].data = Object.assign({}, cd, upsertCaseData);
      store.writeQuery({
        query: USER_CASES_BY_STATUS_QUERY,
        variables: {
          id: this.$route.params.id,
          status: caseStatus
        },
        data
      });
    },
    formatNoteDate(date) {
      if (!date) return "";
      let d = new Date(`${date} UTC`);
      return format(d, "MM/dd");
    }
  }
};
</script>

<style scoped></style>
