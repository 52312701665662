<template>
  <v-card>
    <v-container class="py-0" fluid>
      <v-row align-content="space-around">
        <v-col cols="12" sm="4">
          <v-card-title class="pa-0">
            <div>
              <div class="headline">New Cases</div>
              <div class="text-subtitle-1 font-weight-thin">
                {{ organization.newCasesInPeriod.length }} Cases
              </div>
              <div class="subtitle-2 font-weight-thin">
                Closed with No Fee: {{ closedWithNoFee }} ({{
                  closedWithNoFeePercentage
                }}%)
                <a @click.prevent="closedReasonsDialog = !closedReasonsDialog"
                  >Reasons</a
                >
                -
                <a @click.prevent="closedSourcesDialog = !closedSourcesDialog"
                  >Sources</a
                >
                -
                <a
                  @click.prevent="
                    closedAttorneysDialog = !closedAttorneysDialog
                  "
                  >Attorneys</a
                >
                -
                <a @click.prevent="closedTypesDialog = !closedTypesDialog"
                  >Types</a
                >
              </div>
              <div class="subtitle-2 font-weight-thin">
                Fired Rate: {{ firedRate.amount }} ({{ firedRate.percentage }}%)
              </div>
            </div>
          </v-card-title>
        </v-col>
        <v-col cols="12" sm="4">
          <div>
            <highcharts ref="chart" :options="rankChartOptions" />
          </div>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="3">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            hide-details
            single-line
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="1">
          <vue-json-to-csv
            :json-data="organization.newCasesInPeriod"
            csv-title="New_Cases_Report"
          >
            <v-btn class="ml-5 mt-3"><v-icon>mdi-download</v-icon></v-btn>
          </vue-json-to-csv>
        </v-col>
      </v-row>
    </v-container>
    <v-data-table
      :footer-props="{
        'items-per-page-options': [50, 100, -1]
      }"
      :headers="headers"
      :items="organization.newCasesInPeriod"
      :loading="$apollo.loading"
      :search="search"
      :sort-by="['retainedDate']"
      :sort-desc="[true]"
      ref="newCasesTable"
    >
      <template v-slot:item.caseId="props">
        <a
          class="go-to-case-link mr-2"
          target="_blank"
          :href="
            `https://cs-web.airdesksolutions.com/SA/CaseSummary.aspx?CaseID=${props.item.caseId}`
          "
        >
          <v-icon size="15">mdi-open-in-new</v-icon>
        </a>
      </template>
      <!-- <template v-slot:item.info.signupType="props">
        <v-edit-dialog
          @open="openEditDialog(props.item.caseId)"
          @close="closeEditDialog"
        >
          {{ props.item.info.signupType }}
          <template v-slot:input>
            <v-select
              :items="signupTypeOptions"
              :value="props.item.info.signupType"
              @change="saveSignupType"
              dense
            ></v-select>
          </template>
        </v-edit-dialog>
      </template> -->
      <template v-slot:item.info.referralOpportunity="props">
        <v-edit-dialog
          @open="openEditDialog(props.item.caseId)"
          @close="closeEditDialog"
        >
          {{ props.item.info.referralOpportunity }}
          <template v-slot:input>
            <v-select
              :items="yesNoOptions"
              :value="props.item.info.referralOpportunity"
              @change="saveReferralOpportunity"
              dense
            ></v-select>
          </template>
        </v-edit-dialog>
      </template>
      <!-- <template v-slot:item.info.referred="props">
        <v-edit-dialog
          @open="openEditDialog(props.item.caseId)"
          @close="closeEditDialog"
        >
          {{ props.item.info.referred }}
          <template v-slot:input>
            <v-select
              :items="yesNoOptions"
              :value="props.item.info.referred"
              @change="saveReferred"
              dense
            ></v-select>
          </template>
        </v-edit-dialog>
      </template> -->
      <template v-slot:item.wantedDate="props">
        <v-edit-dialog
          @open="openEditDialog(props.item.caseId)"
          @close="closeEditDialog"
        >
          {{ props.item.wantedDate }}
          <template v-slot:input>
            <v-date-picker
              :value="props.item.wantedDate"
              no-title
              scrollable
              @change="saveWanted"
            />
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:item.intakeRank="props">
        <v-edit-dialog
          @open="openEditDialog(props.item.caseId)"
          @close="closeEditDialog"
        >
          <v-rating
            :value="intakeRank(props.item.intakeRank)"
            background-color="white"
            color="yellow accent-4"
            dense
            hover
            readonly
            size="12"
            v-if="intakeRank(props.item.intakeRank) > 0"
          ></v-rating>
          <v-icon
            color="grey"
            size="12"
            v-if="intakeRank(props.item.intakeRank) === 0"
            >mdi-cancel</v-icon
          >
          <template v-slot:input>
            <v-select
              :items="rankOptions"
              :value="getValue(props.item.intakeRank, rankOptions)"
              @change="saveStatus($event, 'intakeRank', rankOptions, 73)"
              dense
            ></v-select>
          </template>
        </v-edit-dialog>
      </template>
    </v-data-table>
    <v-dialog v-model="closedReasonsDialog" width="600px">
      <v-data-table
        :footer-props="{
          'items-per-page-options': [-1]
        }"
        :headers="closedWithNoFeeReasonsHeaders"
        :items="closedWithNoFeeReasons"
        :loading="$apollo.loading"
        :sort-by="['total']"
        :sort-desc="[true]"
        ref="closedReasonsTable"
      >
        <template v-slot:item.total="props">
          <a
            @click.prevent="
              closedReasonsAttorneyDialog = !closedReasonsAttorneyDialog;
              selectedReason = props.item.reason;
            "
          >
            {{ props.item.total }}
          </a>
          ({{
            (
              (props.item.total / organization.newCasesInPeriod.length) *
              100
            ).toFixed(2)
          }}%)
        </template>
      </v-data-table>
    </v-dialog>
    <v-dialog v-model="closedReasonsAttorneyDialog" width="600px">
      <v-toolbar>
        <a
          @click.prevent="
            closedReasonsAttorneyDialog = false;
            selectedReason = false;
          "
          ><v-icon>mdi-keyboard-backspace</v-icon></a
        >
      </v-toolbar>
      <v-data-table
        :footer-props="{
          'items-per-page-options': [-1]
        }"
        :headers="closedWithNoFeeReasonsAttorneyHeaders"
        :items="closedWithNoFeeReasonsAttorney"
        :loading="$apollo.loading"
        :sort-by="['total']"
        :sort-desc="[true]"
        ref="closedReasonsAttorneyTable"
      >
        <template v-slot:item.total="props">
          {{ props.item.total }}
          ({{
            (
              (props.item.total / organization.newCasesInPeriod.length) *
              100
            ).toFixed(2)
          }}%)
        </template>
      </v-data-table>
    </v-dialog>
    <v-dialog v-model="closedSourcesDialog" width="600px">
      <v-data-table
        :footer-props="{
          'items-per-page-options': [-1]
        }"
        :headers="closedWithNoFeeSourcesHeaders"
        :items="closedWithNoFeeSources"
        :loading="$apollo.loading"
        :sort-by="['total']"
        :sort-desc="[true]"
        ref="closedSourcesTable"
      >
        <template v-slot:item.total="props">
          {{ props.item.total }}
          ({{
            (
              (props.item.total / organization.newCasesInPeriod.length) *
              100
            ).toFixed(2)
          }}%)
        </template>
      </v-data-table>
    </v-dialog>
    <v-dialog v-model="closedAttorneysDialog" width="600px">
      <v-data-table
        :footer-props="{
          'items-per-page-options': [-1]
        }"
        :headers="closedWithNoFeeAttorneysHeaders"
        :items="closedWithNoFeeAttorneys"
        :loading="$apollo.loading"
        :sort-by="['total']"
        :sort-desc="[true]"
        ref="closedAttorneysTable"
      >
        <template v-slot:item.total="props">
          {{ props.item.total }}
          ({{
            (
              (props.item.total / organization.newCasesInPeriod.length) *
              100
            ).toFixed(2)
          }}%)
        </template>
      </v-data-table>
    </v-dialog>
    <v-dialog v-model="closedTypesDialog" width="600px">
      <v-data-table
        :footer-props="{
          'items-per-page-options': [-1]
        }"
        :headers="closedWithNoFeeTypeHeaders"
        :items="closedWithNoFeeByType"
        :loading="$apollo.loading"
        :sort-by="['total']"
        :sort-desc="[true]"
        ref="closedTypesTable"
      >
        <template v-slot:item.amount="props">
          {{ props.item.amount }}
          ({{ props.item.percentage }}%)
        </template>
      </v-data-table>
    </v-dialog>
  </v-card>
</template>

<script>
import gql from "graphql-tag";
import { mapGetters } from "vuex";
import { DASH_INTAKE_NEW_CASES } from "../graphql/intake.gql";
import findIndex from "lodash/findIndex";
import groupBy from "lodash/groupBy";
import filter from "lodash/filter";
import VueJsonToCsv from "vue-json-to-csv";

export default {
  data() {
    return {
      search: "",
      headers: [
        { text: "Action", value: "caseId", width: "80px" },
        { text: "Client", value: "clientName" },
        // { text: "Signup", value: "info.signupType", width: "80px" },
        { text: "Type", value: "type" },
        { text: "Ref Opp", value: "info.referralOpportunity", width: "90px" },
        // { text: "Referred", value: "info.referred", width: "90px" },
        { text: "Source", value: "referralSource" },
        { text: "Other Source", value: "referralSourceOther" },
        { text: "CR Source", value: "callrailSource" },
        { text: "Intake Rank", value: "intakeRank" },
        { text: "Rank", value: "rank" },
        { text: "Agent", value: "agent" },
        { text: "Attorney", value: "attorney" },
        { text: "CM", value: "paralegal" },
        { text: "Wanted", value: "wantedDate" },
        { text: "Retained", value: "retainedDate" },
        { text: "Closed", value: "closedDate" },
        { text: "Settled", value: "firstFeeDate" },
        { text: "State", value: "state" },
        { text: "Office", value: "office" }
      ],
      closedWithNoFeeReasonsHeaders: [
        { text: "Reasons", value: "reason" },
        { text: "Total", value: "total" }
      ],
      closedWithNoFeeReasonsAttorneyHeaders: [
        { text: "Attorney", value: "attorney" },
        { text: "Total", value: "total" }
      ],
      closedWithNoFeeSourcesHeaders: [
        { text: "Sources", value: "source" },
        { text: "Total", value: "total" }
      ],
      closedWithNoFeeAttorneysHeaders: [
        { text: "Attorney", value: "attorney" },
        { text: "Total", value: "total" }
      ],
      closedWithNoFeeTypeHeaders: [
        { text: "Type", value: "type" },
        { text: "Total", value: "amount" }
      ],
      rankOptions: [
        { text: "0 Star - Don't Want", value: 1156 },
        {
          text: "1 Star - Minor Injuries or Questionable Liability",
          value: 1157
        },
        { text: "2 Star - Soft Tissue and Clear Liability", value: 1158 },
        { text: "3 Star - Serious Injury", value: 1159 },
        { text: "4 Star - Catastrophic Injury", value: 1160 }
      ],
      organization: { newCasesInPeriod: [] },
      selectedCaseId: "",
      signupTypeOptions: ["", "E-Sign", "In Person"],
      yesNoOptions: ["", "Yes", "No"],
      closedReasonsDialog: false,
      closedSourcesDialog: false,
      closedAttorneysDialog: false,
      closedTypesDialog: false,
      closedReasonsAttorneyDialog: false,
      selectedReason: false
    };
  },
  components: {
    VueJsonToCsv
  },
  computed: {
    ...mapGetters(["currentUser", "startDate", "endDate"]),
    totalRows() {
      return this.organization.newCasesInPeriod.length;
    },
    closedWithNoFee() {
      if (!this.organization.newCasesInPeriod) return 0;
      return filter(this.organization.newCasesInPeriod, c => {
        return c.closedDate && !c.firstFeeDate;
      }).length;
    },
    closedWithNoFeeByType() {
      if (!this.organization.newCasesInPeriod) return 0;
      let closedWithNoFee = filter(this.organization.newCasesInPeriod, c => {
        return c.closedDate && !c.firstFeeDate;
      });
      let typeObj = groupBy(closedWithNoFee, c => {
        return c.type;
      });
      let types = Object.keys(typeObj).map(t => {
        return {
          type: t,
          amount: typeObj[t].length,
          percentage: (
            (typeObj[t].length / closedWithNoFee.length) *
            100
          ).toFixed(2)
        };
      });
      return types;
    },
    closedWithNoFeePercentage() {
      if (!this.organization.newCasesInPeriod) return 0;
      return (
        (this.closedWithNoFee / this.organization.newCasesInPeriod.length) *
        100
      ).toFixed(2);
    },
    firedRate() {
      if (!this.organization.newCasesInPeriod) return 0;
      let fired = filter(this.organization.newCasesInPeriod, c => {
        return (
          (c.closedDate &&
            (c.closedReason === "INTAKE - PC REJECTED FIRM" ||
              c.closedReason === "INTAKE - RETAINED ANOTHER ATTORNEY" ||
              c.closedReason === "CSA GOT FIRED BY CLIENT")) ||
          c.closedReason === "REJECT - RETAINED ANOTHER ATTORNEY"
        );
      }).length;
      return {
        amount: fired,
        percentage: (
          (fired / this.organization.newCasesInPeriod.length) *
          100
        ).toFixed(2)
      };
    },
    closedWithNoFeeReasons() {
      if (!this.organization.newCasesInPeriod) return 0;
      let casesClosedWithNoFee = filter(
        this.organization.newCasesInPeriod,
        c => {
          return c.closedDate && !c.firstFeeDate;
        }
      );

      let groupedByClosedReason = groupBy(casesClosedWithNoFee, c => {
        return c.closedReason;
      });

      let reasons = Object.keys(groupedByClosedReason);

      return reasons.map(r => {
        return { reason: r, total: groupedByClosedReason[r].length };
      });
    },
    closedWithNoFeeReasonsAttorney() {
      if (!this.organization.newCasesInPeriod) return 0;
      let casesClosedWithNoFee = filter(
        this.organization.newCasesInPeriod,
        c => {
          return (
            c.closedDate &&
            !c.firstFeeDate &&
            c.closedReason === this.selectedReason
          );
        }
      );

      let groupedByAttorney = groupBy(casesClosedWithNoFee, c => {
        return c.attorney;
      });

      let attorneys = Object.keys(groupedByAttorney);

      return attorneys.map(a => {
        return { attorney: a, total: groupedByAttorney[a].length };
      });
    },
    closedWithNoFeeSources() {
      if (!this.organization.newCasesInPeriod) return 0;
      let casesClosedWithNoFee = filter(
        this.organization.newCasesInPeriod,
        c => {
          return c.closedDate && !c.firstFeeDate;
        }
      );

      let groupedBySource = groupBy(casesClosedWithNoFee, c => {
        return c.referralSource;
      });

      let sources = Object.keys(groupedBySource);

      return sources.map(s => {
        return { source: s, total: groupedBySource[s].length };
      });
    },
    closedWithNoFeeAttorneys() {
      if (!this.organization.newCasesInPeriod) return 0;
      let casesClosedWithNoFee = filter(
        this.organization.newCasesInPeriod,
        c => {
          return c.closedDate && !c.firstFeeDate;
        }
      );

      let groupedByAttorney = groupBy(casesClosedWithNoFee, c => {
        return c.attorney;
      });

      let attorneys = Object.keys(groupedByAttorney);

      return attorneys.map(a => {
        return { attorney: a, total: groupedByAttorney[a].length };
      });
    },
    rankChartOptions() {
      if (!this.organization) return {};
      let casesGrouped = groupBy(this.organization.newCasesInPeriod, "rank");
      return {
        chart: {
          type: "bar",
          height: 100
        },
        title: {
          text: ""
        },
        credits: {
          enabled: false
        },
        xAxis: {
          visible: false,
          labels: {
            enabled: false
          }
        },
        yAxis: {
          min: 0,
          visible: false,
          title: {
            text: ""
          },
          labels: {
            enabled: false
          }
        },
        legend: {
          enabled: false
        },
        plotOptions: {
          series: {
            stacking: "normal"
          }
        },
        tooltip: {
          headerFormat: ""
        },
        series: [
          {
            name: "A++ (> $1M)",
            color: "#03A9F4",
            data: [
              casesGrouped["A++ (> $1M)"]
                ? casesGrouped["A++ (> $1M)"].length
                : 0
            ]
          },
          {
            name: "A+ ($251k - $1M)",
            color: "#2196F3",
            data: [
              casesGrouped["A+ ($251k - $1M)"]
                ? casesGrouped["A+ ($251k - $1M)"].length
                : 0
            ]
          },
          {
            name: "A ($101k - $250k)",
            color: "#3F51B5",
            data: [
              casesGrouped["A ($101k - $250k)"]
                ? casesGrouped["A ($101k - $250k)"].length
                : 0
            ]
          },
          {
            name: "B ($51k - $100k)",
            color: "#673AB7",
            data: [
              casesGrouped["B ($51k - $100k)"]
                ? casesGrouped["B ($51k - $100k)"].length
                : 0
            ]
          },
          {
            name: "C ($15k - $50k)",
            color: "#9C27B0",
            data: [
              casesGrouped["C ($15k - $50k)"]
                ? casesGrouped["C ($15k - $50k)"].length
                : 0
            ]
          },
          {
            name: "D (<$15k)",
            color: "#E91E63",
            data: [
              casesGrouped["D (<$15k)"] ? casesGrouped["D (<$15k)"].length : 0
            ]
          },
          {
            name: "Unrated - Default",
            color: "#F44336",
            data: [
              casesGrouped["Unrated - Default"]
                ? casesGrouped["Unrated - Default"].length
                : 0
            ]
          },
          {
            name: "No Rank",
            color: "#FF9800",
            data: [casesGrouped["null"] ? casesGrouped["null"].length : 0]
          }
        ]
      };
    }
  },
  apollo: {
    organization: {
      query: DASH_INTAKE_NEW_CASES,
      variables() {
        return {
          id: this.currentUser.organization.id,
          startDate: this.startDate,
          endDate: this.endDate
        };
      },
      skip() {
        return this.currentUser ? false : true;
      }
    }
  },
  methods: {
    intakeRank(rank) {
      if (!rank) return null;
      let num = rank.substr(0, 1);
      return parseInt(num, 10);
    },
    saveSignupType(data) {
      this.saveInfo(data, "signupType");
    },
    saveReferralOpportunity(data) {
      this.saveInfo(data, "referralOpportunity");
    },
    saveReferred(data) {
      this.saveInfo(data, "referred");
      this.$refs["referredDialog"].isActive = false;
    },
    saveInfo(data, field) {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation UpsertCaseInfo($caseInfo: CaseInfoInput!) {
              upsertCaseInfo(caseInfo: $caseInfo) {
                id
                caseId
                referralOpportunity
                referred
                signupType
              }
            }
          `,
          variables: {
            caseInfo: {
              case_id: this.selectedCaseId,
              [field]: data
            }
          },
          update: (store, { data: { upsertCaseInfo } }) => {
            this.updateInfoCache(store, upsertCaseInfo, field);
          }
        })
        .then(() => {
          this.$refs["newCasesTable"].$el.click();
        })
        .catch(error => {
          console.error(error);
        });
      return false;
    },
    saveWanted(date) {
      if (!date) return false;
      this.$apollo
        .mutate({
          mutation: gql`
            mutation InsertWantedDate($dateInput: WantedDate!) {
              insertSaWantedDate(dateInput: $dateInput) {
                wantedDate
              }
            }
          `,
          variables: {
            dateInput: {
              case_id: this.selectedCaseId,
              wanted_date: date
            }
          },
          update: (store, { data: { insertSaWantedDate } }) => {
            this.updateCache(store, insertSaWantedDate, "wantedDate");
          }
        })
        .then(() => {
          this.$refs["newCasesTable"].$el.click();
        })
        .catch(error => {
          console.error(error);
        });
      return false;
    },
    saveStatus(val, type, options, statusType) {
      if (!val) return false;
      this.$apollo
        .mutate({
          mutation: gql`
            mutation UpdateCaseStatus($status: CaseStatus!) {
              upsertSaStatus(status: $status) {
                statusId
              }
            }
          `,
          variables: {
            status: {
              case_id: this.selectedCaseId,
              status_id: val,
              type: statusType
            }
          },
          update: (store, { data: { upsertSaStatus } }) => {
            let data = {
              [type]: this.getText(upsertSaStatus.statusId, options)
            };
            this.updateCache(store, data, type);
          }
        })
        .then(() => {
          this.$refs["intakeLeadsTable"].$el.click();
        })
        .catch(error => {
          console.error(error);
        });
      return false;
    },
    updateInfoCache(store, insertData, field) {
      let caseId = this.selectedCaseId;
      const data = store.readQuery({
        query: DASH_INTAKE_NEW_CASES,
        variables: {
          id: this.currentUser.organization.id,
          startDate: this.startDate,
          endDate: this.endDate
        }
      });
      let index = findIndex(data.organization.newCasesInPeriod, c => {
        return c.caseId === caseId;
      });
      data.organization.newCasesInPeriod[index]["info"][field] =
        insertData[field];
      store.writeQuery({
        query: DASH_INTAKE_NEW_CASES,
        variables: {
          id: this.currentUser.organization.id,
          startDate: this.startDate,
          endDate: this.endDate
        },
        data
      });
    },
    updateCache(store, insertData, field) {
      let caseId = this.selectedCaseId;
      const data = store.readQuery({
        query: DASH_INTAKE_NEW_CASES,
        variables: {
          id: this.currentUser.organization.id,
          startDate: this.startDate,
          endDate: this.endDate
        }
      });
      let index = findIndex(data.organization.newCasesInPeriod, c => {
        return c.caseId === caseId;
      });
      data.organization.newCasesInPeriod[index][field] = insertData[field];
      store.writeQuery({
        query: DASH_INTAKE_NEW_CASES,
        variables: {
          id: this.currentUser.organization.id,
          startDate: this.startDate,
          endDate: this.endDate
        },
        data
      });
    },
    getValue(val, options) {
      let values = groupBy(options, "text");
      if (!values[val]) return null;
      return values[val][0].value;
    },
    getText(val, options) {
      let values = groupBy(options, "value");
      return values[val][0].text;
    },
    openEditDialog(caseId) {
      this.selectedCaseId = caseId;
      return false;
    },
    closeEditDialog() {
      this.selectedCaseId = "";
      return false;
    }
  }
};
</script>

<style scoped></style>
