import { render, staticRenderFns } from "./OrgToDByAttorney.vue?vue&type=template&id=1fe13e5e&scoped=true&"
import script from "./OrgToDByAttorney.vue?vue&type=script&lang=js&"
export * from "./OrgToDByAttorney.vue?vue&type=script&lang=js&"
import style0 from "./OrgToDByAttorney.vue?vue&type=style&index=0&id=1fe13e5e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fe13e5e",
  null
  
)

export default component.exports