var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[(_vm.alert)?_c('v-alert',{attrs:{"type":_vm.alertType}},[_vm._v(_vm._s(_vm.alertMessage))]):_vm._e(),_c('v-container',{staticClass:"py-0",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align-content":"space-around"}},[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-card-title',{staticClass:"pa-0"},[_c('div',[_c('div',{staticClass:"headline"},[_vm._v("Referrals")]),(_vm.listView)?_c('div',{staticClass:"text-subtitle-1 font-weight-thin"},[_vm._v(" "+_vm._s(_vm.referrals.length)+" Cases ")]):_vm._e()])])],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.listView),callback:function ($$v) {_vm.listView=$$v},expression:"listView"}},[_c('v-radio',{attrs:{"label":"List","value":true}}),_c('v-radio',{attrs:{"label":"Aggregate","value":false}})],1)],1),_c('v-spacer'),(_vm.listView)?_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.referredBy),callback:function ($$v) {_vm.referredBy=$$v},expression:"referredBy"}},[_c('v-radio',{attrs:{"label":"By","value":true}}),_c('v-radio',{attrs:{"label":"To","value":false}})],1)],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","hide-details":"","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),(_vm.listView)?_c('v-data-table',{attrs:{"footer-props":{
      'items-per-page-options': [-1],
    },"headers":_vm.headers,"hide-default-footer":"","items":_vm.referrals,"loading":_vm.$apollo.loading,"search":_vm.search,"sort-by":_vm.referredBy ? ['retainedDate'] : ['referredDate'],"sort-desc":[false]},scopedSlots:_vm._u([{key:"item.caseId",fn:function({ item }){return [_c('a',{staticClass:"go-to-case-link mr-2",attrs:{"target":"_blank","href":`https://cs-web.airdesksolutions.com/SA/CaseSummary.aspx?CaseID=${item.caseId}`}},[_c('v-icon',{attrs:{"size":"15"}},[_vm._v("mdi-open-in-new")])],1)]}},{key:"item.referralSourceOther",fn:function({ item }){return [_vm._v(" "+_vm._s(item.referralSourceOther)+" "),(item.contact.name)?_c('v-icon',{attrs:{"small":"","color":"green"}},[_vm._v("mdi-alpha-c-circle")]):_vm._e(),(!item.contact.name)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.openNewContactDialog(item)}}},[_vm._v("mdi-plus")]):_vm._e()]}},{key:"item.medicalProvider",fn:function({ item }){return [_vm._v(" "+_vm._s(item.medicalProvider)+" "),(item.contact.name)?_c('v-icon',{attrs:{"small":"","color":"green"}},[_vm._v("mdi-alpha-c-circle")]):_vm._e(),(!item.contact.name)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.openNewContactDialog(item)}}},[_vm._v("mdi-plus")]):_vm._e()]}}],null,false,306094399)}):_vm._l((_vm.referrals),function(market){return _c('v-card',{key:market.office},[_c('v-card-title',[_vm._v(" "+_vm._s(market.office)+" ")]),_c('v-data-table',{attrs:{"footer-props":{
        'items-per-page-options': [-1],
      },"headers":_vm.headers,"hide-default-footer":"","items":market.medicalProviders,"loading":_vm.$apollo.loading,"search":_vm.search,"sort-by":['medicalProvider'],"sort-desc":[false]},scopedSlots:_vm._u([{key:"item.referredTo",fn:function({ item }){return [_c('a',{on:{"click":function($event){return _vm.openAggDialog(item, 'to')}}},[_vm._v(_vm._s(item.referredTo))])]}},{key:"item.referredBy",fn:function({ item }){return [_c('a',{on:{"click":function($event){return _vm.openAggDialog(item, 'by')}}},[_vm._v(_vm._s(item.referredBy))])]}},{key:"item.difference",fn:function({ item }){return [_c('v-chip',{attrs:{"x-small":"","color":item.referredTo - item.referredBy < 0 ? 'red' : 'green'}},[_vm._v(_vm._s(item.referredTo - item.referredBy))])]}}],null,true)})],1)}),_c('v-dialog',{attrs:{"width":"80%"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline grey lighten-2"},[_vm._v(" "+_vm._s(_vm.dialogProvider)+" ")]),_c('v-data-table',{attrs:{"footer-props":{
          'items-per-page-options': [-1],
        },"headers":_vm.dialogHeaders,"hide-default-footer":"","items":_vm.dialogList,"loading":_vm.$apollo.loading,"sort-by":_vm.dialogDirection === 'by' ? ['retainedDate'] : ['referredDate'],"sort-desc":[false]},scopedSlots:_vm._u([{key:"item.caseId",fn:function({ item }){return [_c('a',{staticClass:"go-to-case-link mr-2",attrs:{"target":"_blank","href":`https://cs-web.airdesksolutions.com/SA/CaseSummary.aspx?CaseID=${item.caseId}`}},[_c('v-icon',{attrs:{"size":"15"}},[_vm._v("mdi-open-in-new")])],1)]}}])})],1)],1),_c('v-dialog',{attrs:{"hide-overlay":"","width":"300"},model:{value:(_vm.contactDialog),callback:function ($$v) {_vm.contactDialog=$$v},expression:"contactDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("New Contact")]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.selectedContact.referralSourceOther)+" ")]),_c('v-card-text',[_c('v-select',{attrs:{"items":_vm.contactTypeOptions,"label":"Contact Type","dense":""},model:{value:(_vm.selectedContactType),callback:function ($$v) {_vm.selectedContactType=$$v},expression:"selectedContactType"}}),_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.selectedContactType},on:{"click":_vm.addContact}},[_vm._v("Add Contact")])],1)],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }