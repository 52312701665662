<template>
  <dash-menu :links="links">
    <router-view></router-view>
  </dash-menu>
</template>

<script>
import DashMenu from "./DashMenu";

export default {
  components: {
    DashMenu
  },
  computed: {
    links() {
      let id = this.$route.params.id;
      return [{ name: "Treating", path: `/dash/${id}/treating` }];
    }
  }
};
</script>

<style scoped></style>
