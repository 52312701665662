<template>
  <div>
    <v-tabs>
      <v-tab v-for="(link, index) in links" :key="index">
        <v-btn text exact :to="link.path">{{ link.name }}</v-btn>
      </v-tab>
    </v-tabs>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  computed: {
    links() {
      let id = this.$route.params.id;
      return [{ name: "Report", path: `/org/${id}/marketing` }];
    }
  }
};
</script>

<style scoped></style>
