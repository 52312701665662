<template>
  <v-card>
    <v-container class="py-0" fluid>
      <v-row align-content="space-around">
        <v-col cols="12" sm="4">
          <v-card-title class="pa-0">
            <div>
              <div class="headline">File Review</div>
              <div class="text-subtitle-1 font-weight-thin">
                {{ filteredCases.length }} Cases
              </div>
            </div>
          </v-card-title>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="4">
          <v-select
            small-chips
            label="Hide Stages"
            v-model="hideFileReviewStages"
            :items="[
              'Intake',
              'Treating',
              'Demand Prep',
              'Negotiations',
              'Litigation',
              'Settled',
              'Closed',
              'Referred Out Stage',
              'Inactive',
            ]"
            multiple
            single-line
          ></v-select>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            hide-details
            single-line
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
    <v-data-table
      :footer-props="{
        'items-per-page-options': [-1],
      }"
      :headers="headers"
      hide-default-footer
      :items="filteredCases"
      :loading="$apollo.loading"
      :search="search"
      :sort-by="['dateOfLastFileReview']"
      :sort-desc="[false]"
    >
      <template v-slot:body="{ items }">
        <tbody>
          <tr
            :style="{ background: getDateColor(item.dateOfLastFileReview) }"
            v-for="item in items"
            :key="item + Math.random(10).toString()"
          >
            <td>
              <a
                class="go-to-case-link mr-2"
                target="_blank"
                :href="
                  `https://cs-web.airdesksolutions.com/SA/CaseSummary.aspx?CaseID=${item.caseId}`
                "
              >
                <v-icon size="15">mdi-open-in-new</v-icon>
              </a>
            </td>
            <td>{{ item.stage }}</td>
            <td>{{ item.clientName }}</td>
            <td>{{ formatLastContactDate(item.dateOfLastFileReview) }}</td>
            <td>{{ item.paralegal }}</td>
            <!-- <td>
              <v-edit-dialog lazy @save="saveNote" @open="setItem(item)" large>
                Review
                <template v-slot:input>
                  <v-row class="flex">
                    <v-col cols="6">
                      <div class="file-review-header">
                        File Review - {{ item.clientName }}
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <div class="file-review-template">
                        <v-select
                          :items="fileReviewTemplateOptions"
                          label="Template"
                          :value="selectedFileReviewTemplate"
                          @change="setSelectedFileReviewTemplate"
                        ></v-select>
                      </div>
                    </v-col>
                  </v-row>
                  <vue-trix
                    style="width: 700px; height: 400px; overflow-y: scroll;"
                    v-model="editItemFileReviewNote"
                    rows="10"
                  ></vue-trix>
                </template>
              </v-edit-dialog>
            </td> -->
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import gql from "graphql-tag";
import filter from "lodash/filter";
import findIndex from "lodash/findIndex";
import { getDaysSinceToday } from "../util";
import { mapGetters } from "vuex";
import { DASH_FILE_REVIEW_CASES_LIST } from "../graphql/user.gql";
// import VueTrix from "vue-trix";

export default {
  components: {
    // VueTrix,
  },
  data() {
    return {
      search: "",
      pagination: { sortBy: "dateOfLastFileReview", descending: false },
      headers: [
        { text: "Action", value: "", width: "100px" },
        { text: "Stage", value: "stage" },
        { text: "Client", value: "clientName" },
        { text: "Last Review", value: "dateOfLastFileReview" },
        { text: "Paralegal", value: "paralegal" },
        // { text: "Note", value: "lastFileReviewNote" },
      ],
      user: { cases: [] },
      editItem: null,
      editItemFileReviewNote: null,
      selectedFileReviewTemplate: null,
    };
  },
  computed: {
    ...mapGetters(["imitatedUser"]),
    hideFileReviewStages: {
      get() {
        return this.$store.getters.hideFileReviewStages;
      },
      set(value) {
        this.$store.dispatch("setHideFileReviewStages", value);
      },
    },
    filteredCases() {
      let stages = this.$store.getters.hideFileReviewStages;
      let filteredCases = filter(this.user.cases, (c) => {
        return stages.indexOf(c.stage) === -1;
      });
      return filteredCases;
    },
    fileReviewTemplateOptions() {
      if (this.fileReviewTemplates) {
        return this.fileReviewTemplates.map((t) => {
          return { text: t.emailTemplateName, value: t.emailTemplateBody };
        });
      }
      return [];
    },
  },
  apollo: {
    user: {
      query: DASH_FILE_REVIEW_CASES_LIST,
      variables() {
        let id = this.imitatedUser
          ? this.imitatedUser.toString()
          : this.$route.params.id;
        return {
          id,
        };
      },
    },
    fileReviewTemplates: {
      query: gql`
        query {
          fileReviewTemplates {
            emailTemplateName
            emailTemplateBody
          }
        }
      `,
    },
  },
  methods: {
    trimStatus(status) {
      if (status) return status.substr(0, 3);
      return "";
    },
    formatLastContactDate(date) {
      if (!date) return "";
      let days = getDaysSinceToday(date);
      return `${date} (${days} days)`;
    },
    getDateColor(date) {
      if (!date) return "#EF9A9A";
      let days = getDaysSinceToday(date);
      if (days <= 30) return "#ffffff";
      else if (days > 30 && days <= 45) return "#FFF59D";
      else return "#EF9A9A";
    },
    saveNote() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation InsertSaCaseNote($note: NoteInput!) {
              insertSaCaseNote(note: $note) {
                content
              }
            }
          `,
          variables: {
            note: {
              case_id: this.editItem.caseId,
              content: this.editItemFileReviewNote,
              note_type_id: 81,
            },
          },
          update: (
            store,
            {
              data: {
                insertSaCaseNote: { content },
              },
            }
          ) => {
            let newItem = Object.assign({}, this.editItem, {
              lastFileReviewNote: content,
            });
            this.updateCacheWithLastFileReviewNote(store, newItem);
          },
        })
        .catch((error) => {
          console.error(error);
        });
    },
    updateCacheWithLastFileReviewNote(store, caseData) {
      const data = store.readQuery({
        query: DASH_FILE_REVIEW_CASES_LIST,
        variables: {
          id: this.$route.params.id,
        },
      });
      let index = findIndex(data.user.cases, (c) => {
        return c.caseId === caseData.caseId;
      });
      let cd = data.user.cases[index];
      data.user.cases[index] = Object.assign({}, cd, {
        lastFileReviewNote: caseData.lastFileReviewNote,
      });
      store.writeQuery({
        query: DASH_FILE_REVIEW_CASES_LIST,
        variables: {
          id: this.$route.params.id,
        },
        data,
      });
    },
    setItem(item) {
      this.editItem = item;
      this.editItemFileReviewNote = item.lastFileReviewNoteHtml;
    },
    setSelectedFileReviewTemplate(template) {
      this.selectedFileReviewTemplate = template;
      this.editItemFileReviewNote = template;
    },
  },
};
</script>

<style scoped>
.file-review-header {
  font-size: 1.2rem;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
}
input[type="text"] {
  width: 100%;
  padding: 10px 0;
}
</style>
