<template>
  <v-app>
    <v-card v-if="goals" class="pa-5" flat>
      <h1>4th Quarter Demands</h1>
      <v-progress-linear
        height="50"
        color="primary"
        :value="goals.demandGoalPercent"
      >
        <strong>{{
          `${goals.demands} of ${goals.demandGoal} (${goals.demandGoalPercentText})`
        }}</strong>
      </v-progress-linear>
    </v-card>
    <v-card v-if="goals" class="pa-5" flat>
      <h1>4th Quarter Settlements</h1>
      <v-progress-linear
        height="50"
        color="red lighten-1"
        :value="goals.bankedGoalPercent"
      >
        <strong>{{
          `${goals.banked} of ${goals.bankedGoal} (${goals.bankedGoalPercentText})`
        }}</strong>
      </v-progress-linear>
    </v-card>
  </v-app>
</template>

<script>
import gql from "graphql-tag";
export default {
  computed: {
    goals() {
      if (!this.totalDemandsInPeriod || !this.bankedCasesInPeriod) return null;
      return {
        demands: this.totalDemandsInPeriod,
        demandGoal: 487,
        demandGoalPercent: ((this.totalDemandsInPeriod / 487) * 100).toFixed(0),
        demandGoalPercentText: `${(
          (this.totalDemandsInPeriod / 487) *
          100
        ).toFixed(0)}%`,
        banked: this.bankedCasesInPeriod,
        bankedGoal: 403,
        bankedGoalPercent: ((this.bankedCasesInPeriod / 403) * 100).toFixed(0),
        bankedGoalPercentText: `${(
          (this.bankedCasesInPeriod / 403) *
          100
        ).toFixed(0)}%`
      };
    }
  },
  apollo: {
    totalDemandsInPeriod: {
      query: gql`
        query totalDemandsInPeriod($startDate: String!, $endDate: String!) {
          totalDemandsInPeriod(startDate: $startDate, endDate: $endDate)
        }
      `,
      variables() {
        return {
          startDate: "2022-10-01",
          endDate: "2022-12-31"
        };
      },
      pollInterval: 1000 * 60 * 5
    },
    bankedCasesInPeriod: {
      query: gql`
        query bankedCasesInPeriod($startDate: String!, $endDate: String!) {
          bankedCasesInPeriod(startDate: $startDate, endDate: $endDate)
        }
      `,
      variables() {
        return {
          startDate: "2022-10-01",
          endDate: "2022-12-31"
        };
      },
      pollInterval: 1000 * 60 * 5
    }
  }
};
</script>

<style></style>
