<template>
  <v-card>
    <v-row>
      <v-col cols="12" sm="2">
        <div class="fee-stat-container">
          <div class="fee-stat-title">Fees</div>
          <div class="fee-stat-data">
            {{ feesTotal }}
          </div>
          <div>{{ averageFee }}</div>
        </div>
      </v-col>
      <v-col cols="12" sm="1">
        <div class="fee-stat-container">
          <div class="fee-stat-title">Total Cases</div>
          <div class="fee-stat-data">
            {{ totalCasesResolved }}
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="2">
        <div class="fee-stat-container">
          <div class="fee-stat-title">Avg Cases Per Month</div>
          <div class="fee-stat-data">
            {{ averageCasesResolved }}
          </div>
        </div>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="1" v-if="isAdmin">
        <div class="mr-5">
          <v-switch class="" label="Show All" v-model="showAllFees"></v-switch>
        </div>
      </v-col>
      <v-col cols="12" sm="1" v-if="isAdmin">
        <div class="mr-5">
          <v-switch
            class=""
            label="Exclude Shared Fees"
            v-model="excludeSharedFees"
          ></v-switch>
        </div>
      </v-col>
      <v-col cols="12" sm="1" v-if="isAdmin">
        <div class="mr-5">
          <v-autocomplete
            :items="officeOptions"
            v-model="selectedOffice"
          ></v-autocomplete>
        </div>
      </v-col>
      <v-col cols="12" sm="1" v-if="isAdmin">
        <div class="mr-5">
          <v-autocomplete
            :items="stateOptions"
            v-model="selectedState"
          ></v-autocomplete>
        </div>
      </v-col>
      <v-col cols="12" sm="1" v-if="isAdmin">
        <div class="mr-5">
          <v-autocomplete
            :items="referralSources"
            v-model="selectedReferralSource"
          ></v-autocomplete>
        </div>
      </v-col>
      <v-col class="pr-2" cols="4" sm="2">
        <v-text-field
          v-model="search"
          append-icon="mdi-search"
          label="Search"
          hide-details
          single-line
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <org-cases-resolved-by-month :options="feesByMonthChartOptions" />
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="fees"
      class="elevation-1"
      :footer-props="{
        'items-per-page-options': [50, 100, -1],
      }"
      :sort-by="['date']"
      :sort-desc="[true]"
      :search="search"
      :item-key="Math.random(10).toString()"
      :loading="$apollo.loading"
      height="inherit"
    >
      <template v-slot:item.caseId="props">
        <a
          class="go-to-case-link mr-2"
          target="_blank"
          :href="
            `https://cs-web.airdesksolutions.com/SA/CaseSummary.aspx?CaseID=${props.item.caseId}`
          "
        >
          <v-icon size="15">mdi-open-in-new</v-icon>
        </a>
      </template>
      <template v-slot:item.fee="props">
        {{ formatMoney(props.item.fee) }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import gql from "graphql-tag";
import { mapGetters } from "vuex";
import accounting from "accounting";
import filter from "lodash/filter";
import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";
import OrgCasesResolvedByMonth from "../charts/OrgCasesResolvedByMonth.vue";
import { hasRole } from "../util";
import uniq from "lodash/uniq";

export default {
  data() {
    return {
      search: "",
      pagination: { sortBy: "date", descending: true },
      selectedOffice: null,
      selectedState: null,
      selectedReferralSource: null,
      officeOptions: [
        { text: "All Markets", value: null },
        {
          text: "(2 - WA Main) Spokane Valley Office",
          value: "(2 - WA Main) Spokane Valley Office",
        },
        {
          text: "(1 - UT Main) Sandy Office",
          value: "(1 - UT Main) Sandy Office",
        },
        {
          text: "(3 - ID Main) Meridian Office",
          value: "(3 - ID Main) Meridian Office",
        },
        { text: "Sea-Tac", value: "Sea-Tac" },
        { text: "Other", value: "Other" },
        { text: "Wyoming", value: "Wyoming" },
        { text: "Montana", value: "Montana" },
        { text: "Colorado", value: "Colorado" },
        { text: "Oregon", value: "Oregon" },
      ],
      showAllFees: true,
      excludeSharedFees: false,
    };
  },
  components: {
    OrgCasesResolvedByMonth,
  },
  apollo: {
    organization: {
      query: gql`
        query($id: ID!, $startDate: String!, $endDate: String!) {
          organization(id: $id) {
            id
            name
            teams {
              id
              name
            }
            sa_individual_fees_in_period_by_attorney(
              startDate: $startDate
              endDate: $endDate
            ) {
              clientName
              attorney
              caseId
              fee
              date
              office
              state
              referralSource
              feeType
              sharedFee
            }
          }
        }
      `,
      variables() {
        return {
          id: 2,
          startDate: this.startDate,
          endDate: this.endDate,
        };
      },
      fetchPolicy: "no-cache",
      skip() {
        return this.startDate === null || this.endDate === null;
      },
    },
    advertisingSources: {
      query: gql`
        query DashFeesListReferralSources {
          advertisingSources {
            contact
            contactId
          }
        }
      `,
    },
  },
  computed: {
    ...mapGetters(["selectedTeam", "startDate", "endDate", "currentUser"]),
    headers() {
      let headers = [
        { text: "Action", value: "caseId" },
        { text: "Client", value: "clientName" },
        { text: "Attorney", value: "attorney" },
        { text: "Fee", value: "fee" },
        { text: "Fee Type", value: "feeType" },
        { text: "Shared Fee", value: "sharedFee" },
        { text: "Office", value: "office" },
        { text: "State", value: "state" },
        { text: "Date", value: "date" },
      ];

      if (this.isAdmin) {
        headers.splice(3, 0, { text: "Source", value: "referralSource" });
      }

      return headers;
    },
    showAll() {
      if (!this.currentUser) return false;
      if (this.showAllFees === false) {
        return false;
      }
      return (
        hasRole(this.currentUser, "admin") ||
        hasRole(this.currentUser, "marketing")
      );
    },
    isAdmin() {
      if (!this.currentUser) return false;
      return (
        hasRole(this.currentUser, "admin") ||
        hasRole(this.currentUser, "marketing")
      );
    },
    referralSources() {
      if (!this.advertisingSources) return [];
      let options = this.advertisingSources.map((s) => {
        return { text: s.contact, value: s.contact };
      });
      options.unshift({ text: "All Sources", value: null });
      return options;
    },
    stateOptions() {
      if (!this.organization) return [];
      let options = uniq(
        this.organization.sa_individual_fees_in_period_by_attorney.map(
          (fee) => fee.state
        )
      ).map((state) => {
        return { text: state, value: state };
      });
      options.unshift({ text: "All States", value: null });
      return options;
    },
    fees() {
      if (!this.organization) return [];

      // First, filter fees based on the selected team or attorney
      let fees = filter(
        this.organization.sa_individual_fees_in_period_by_attorney,
        (f) => {
          if (this.team === "LS") {
            return (
              f.attorney === "LS" ||
              f.attorney === "KNP" ||
              f.attorney === "EMR"
            );
          } else if (this.team === "CLP") {
            return f.attorney === "CLP" || f.attorney === "PPR";
          } else if (this.team === "ACS") {
            return (
              f.attorney === "ACS" ||
              f.attorney === "TRS" ||
              f.attorney === "RAS"
            );
          } else {
            return f.attorney === this.team;
          }
        }
      );

      // If showing all fees, use all fees
      if (this.showAll) {
        fees = this.organization.sa_individual_fees_in_period_by_attorney;
      }
      // If not showing all fees, include shared case IDs
      else {
        // Get all case IDs from the filtered fees
        const caseIds = fees.map((fee) => fee.caseId);

        // Check if the attorney is a litigation attorney
        const isLitigationAttorney = [
          "MCA",
          "ECD",
          "JRT",
          "LCK",
          "MT",
          "LDM",
        ].includes(this.team);

        // If we're filtering for a specific attorney, include all fees with the same case IDs
        if (this.team && isLitigationAttorney) {
          const sharedFees = filter(
            this.organization.sa_individual_fees_in_period_by_attorney,
            (fee) => caseIds.includes(fee.caseId) && fee.attorney !== this.team
          );

          // Add the shared fees to the filtered fees
          fees = [...fees, ...sharedFees];
        }
      }

      // Apply additional filters
      if (this.excludeSharedFees) {
        fees = filter(
          fees,
          (f) => !f.sharedFee || (f.sharedFee && f.feeType === "Litigation")
        );
      }
      if (this.selectedOffice) {
        fees = filter(fees, (f) => f.office === this.selectedOffice);
      }
      if (this.selectedState) {
        fees = filter(fees, (f) => f.state === this.selectedState);
      }
      if (this.selectedReferralSource) {
        fees = filter(
          fees,
          (f) => f.referralSource === this.selectedReferralSource
        );
      }
      return fees;
    },
    team() {
      if (!this.organization) return null;
      if (this.selectedTeam) {
        return filter(
          this.organization.teams,
          (t) => t.id === this.selectedTeam
        )[0].name;
      } else {
        return "No Team";
      }
    },
    feesTotal() {
      if (!this.organization) return accounting.formatMoney(0);
      let total_fees = this.fees.reduce((prev, cur) => {
        return prev + cur.fee;
      }, 0);
      return accounting.formatMoney(total_fees);
    },
    feesByMonth() {
      if (!this.organization) return [];
      let groupByCaseId = groupBy(this.fees, "caseId");
      let caseIdKeys = Object.keys(groupByCaseId);
      let mapCasesToMonths = caseIdKeys.map((caseId) => {
        let fees = groupByCaseId[caseId];
        let firstFee = fees[0];
        let month = firstFee.date.split("-")[1];
        let year = firstFee.date.split("-")[0];
        let monthName = Date.UTC(year, month - 1, 1);
        return {
          month: monthName,
          caseId: caseId,
        };
      });
      let casesGroupedByMonth = groupBy(mapCasesToMonths, "month");
      let monthKeys = Object.keys(casesGroupedByMonth);
      let casesByMonth = monthKeys.map((m) => {
        return [
          parseInt(m),
          casesGroupedByMonth[m].reduce((prev) => {
            return prev + 1;
          }, 0),
        ];
      });
      return {
        name: "Cases Resolved by Month",
        data: sortBy(casesByMonth, ([month]) => month),
      };
    },
    averageCasesResolved() {
      if (!this.feesByMonth || !this.feesByMonth.data) return 0;
      return (
        this.feesByMonth.data.reduce((prev, cur) => {
          return prev + cur[1];
        }, 0) / this.feesByMonth.data.length
      ).toFixed(2);
    },
    averageFee() {
      if (!this.fees) return 0;
      let total_fees = this.fees.reduce((prev, cur) => {
        return prev + cur.fee;
      }, 0);
      return accounting.formatMoney(
        (total_fees / this.totalCasesResolved).toFixed(2)
      );
    },
    totalCasesResolved() {
      if (!this.feesByMonth || !this.feesByMonth.data) return 0;
      return this.feesByMonth.data.reduce((prev, cur) => {
        return prev + cur[1];
      }, 0);
    },
    feesByMonthChartOptions() {
      return {
        series: this.feesByMonth,
      };
    },
  },
  methods: {
    formatMoney(val) {
      return accounting.formatMoney(val);
    },
  },
};
</script>

<style scoped>
td.status {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  width: 150px;
}
table.table > tbody {
  max-height: 10vh !important;
  font-size: 10px !important;
}
table.table > tbody > td {
  white-space: nowrap !important;
  font-size: 10px !important;
}
.inventory-title {
  font-size: 36px;
}

.inventory-number {
  font-size: 24px;
}
.fee-stat-container {
  padding: 8px 16px;
  text-align: center;
}
.fee-stat-title {
  font-size: 16px;
}
.fee-stat-data {
  font-size: 30px;
  font-weight: bold;
}
</style>
