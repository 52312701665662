<template>
  <v-col sm="6" md="3">
    <v-card>
      <v-progress-linear
        color="primary"
        indeterminate
        v-if="$apollo.loading"
      ></v-progress-linear>
      <v-toolbar flat dense>
        <span class="text-subtitle-1">Fees in Period</span>
      </v-toolbar>
      <div class="stat-data">
        {{ feesInPeriod }}
        <span class="stat-data-desc"> AVG {{ feesAvgInPeriod }} </span>
      </div>
    </v-card>
  </v-col>
</template>

<script>
import gql from "graphql-tag";
import { mapGetters } from "vuex";
import accounting from "accounting";

export default {
  apollo: {
    organization: {
      query: gql`
        query OrgHomeStats($id: ID!, $startDate: String!, $endDate: String!) {
          organization(id: $id) {
            id
            saFeesInPeriod(startDate: $startDate, endDate: $endDate)
            saFeesAvgInPeriod(startDate: $startDate, endDate: $endDate)
          }
        }
      `,
      variables() {
        return {
          id: this.$route.params.id,
          startDate: this.startDate,
          endDate: this.endDate
        };
      },
      skip() {
        return this.startDate === null || this.endDate === null;
      }
    }
  },
  computed: {
    ...mapGetters(["currentUser", "startDate", "endDate"]),
    feesInPeriod() {
      if (!this.organization) return accounting.formatMoney(0);
      return accounting.formatMoney(this.organization.saFeesInPeriod);
    },
    feesAvgInPeriod() {
      if (!this.organization) return accounting.formatMoney(0);
      return accounting.formatMoney(this.organization.saFeesAvgInPeriod);
    }
  }
};
</script>

<style scoped lang="scss"></style>
