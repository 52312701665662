<template>
  <v-card>
    <div class="header-container">
      <div class="header-item">
        <div class="text-center text-subtitle-2">Retained</div>
        <div class="text-center text-h4">{{ performance.retained }}</div>
      </div>
      <div class="exclude-source">
        <v-autocomplete
          placeholder="Exclude Sources"
          :items="referralSources"
          v-model="excludeSources"
          multiple
        ></v-autocomplete>
      </div>
      <div class="header-search">
        <v-autocomplete
          :items="officeOptions"
          v-model="selectedOffice"
        ></v-autocomplete>
      </div>
    </div>
    <div class="chart-container">
      <highcharts ref="chart" :options="chartOptions" />
    </div>
  </v-card>
</template>

<script>
import gql from "graphql-tag";
import groupBy from "lodash/groupBy";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      leads: [],
      selectedOffice: "null",
      excludeSources: [],
      officeOptions: [
        { text: "All Markets", value: "null" },
        { text: "(2 - WA Main) Spokane Valley Office", value: "8" },
        { text: "(1 - UT Main) Sandy Office", value: "5" },
        { text: "(3 - ID Main) Meridian Office", value: "7" },
        { text: "Sea-Tac", value: "9" },
        { text: "Other", value: "10" },
        { text: "Wyoming", value: "11" },
        { text: "Montana", value: "12" },
        { text: "Colorado", value: "13" },
        { text: "Oregon", value: "14" },
      ],
    };
  },
  mounted() {
    setTimeout(() => {
      if (!this.$refs.chart) return;
      this.$refs.chart.chart.reflow();
    }, 200);
  },
  apollo: {
    retained: {
      query: gql`
        query OrgLeadsStatsByDay(
          $startDate: String!
          $endDate: String!
          $office: String
          $excludeSources: [Int]
        ) {
          retained: retainedByDay(
            startDate: $startDate
            endDate: $endDate
            office: $office
            excludeSources: $excludeSources
          ) {
            retained
            day
            month
            year
            yearMonth
            yearMonthDay
          }
        }
      `,
      variables() {
        return {
          startDate: this.startDate,
          endDate: this.endDate,
          office: this.selectedOffice,
          excludeSources: this.excludeSources,
        };
      },
      skip() {
        return this.startDate === null || this.endDate === null;
      },
    },
    advertisingSources: {
      query: gql`
        query OrgReferralSources {
          advertisingSources {
            contact
            contactId
          }
        }
      `,
    },
  },
  methods: {
    buildRetainedByDay() {
      if (!this.retained) return [];
      let dates = this.retained.reduce((prev, cur) => {
        let date = Date.UTC(cur.year, cur.month - 1, cur.day);
        if (prev.indexOf(date) === -1) {
          return prev.concat(date);
        } else {
          return prev;
        }
      }, []);
      let series = ["retained"].map((s) => {
        let prepDate = this.retained.map((d) => {
          return Object.assign({}, d, {
            date: Date.UTC(d.year, d.month - 1, d.day),
          });
        });
        let groupedByDate = groupBy(prepDate, (d) => {
          return d.date;
        });
        let dd = dates.reduce((prev, cur) => {
          if (groupedByDate[cur]) {
            return prev.concat([[cur, groupedByDate[cur][0][s]]]);
          } else {
            return prev.concat([[cur, 0]]);
          }
        }, []);
        return {
          name: s,
          data: dd,
        };
      });
      return series;
    },
  },
  computed: {
    ...mapGetters(["startDate", "endDate"]),
    performance() {
      if (!this.retained)
        return {
          retained: 0,
        };
      let retained = this.retained.reduce((prev, cur) => {
        return prev + cur.retained;
      }, 0);

      return {
        retained,
      };
    },
    referralSources() {
      if (!this.advertisingSources) return [];
      return this.advertisingSources.map((s) => {
        return { text: s.contact, value: s.contactId };
      });
    },
    chartOptions() {
      let options = {};
      return Object.assign({}, options, {
        series: this.buildRetainedByDay(),
        credits: {
          enabled: false,
        },
        chart: {
          height: 200,
          type: "line",
        },
        title: {
          text: "",
        },
        legend: {
          enabled: true,
        },
        xAxis: {
          type: "datetime",
        },
        yAxis: {
          min: 0,
          title: {
            text: "",
          },
        },
        plotOptions: {
          line: {
            dataLables: {
              enabled: true,
            },
          },
        },
      });
    },
  },
};
</script>

<style scoped>
.chart-container {
  height: 220px;
}
.header-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 15px 16px;
}
.header-item {
  margin-right: 25px;
}
.header-search {
}
.exclude-source {
  margin-left: auto;
  margin-right: 25px;
}
</style>
