<template>
  <v-card>
    <v-toolbar flat>
      <v-toolbar-title class="headline" v-if="conflictCheckDone"
        >New Lead</v-toolbar-title
      >
      <v-toolbar-title class="headline" v-if="!conflictCheckDone"
        >Conflict Check</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="clearForm">Clear Form</v-btn>
    </v-toolbar>
    <v-container v-if="!conflictCheckDone">
      <v-form>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="$v.conflictCheckData.firstName.$model"
              autofocus
              label="First Name"
              required
              :error="
                $v.conflictCheckData.firstName.$invalid &&
                  $v.conflictCheckData.firstName.$dirty
              "
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="$v.conflictCheckData.lastName.$model"
              label="Last Name"
              required
              :error="
                $v.conflictCheckData.lastName.$invalid &&
                  $v.conflictCheckData.lastName.$dirty
              "
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <div style="border-top: 1px solid #eee; height: 3px;"></div>
            <div
              style="margin: 0 auto; margin-top: -16px; width: 30px; border-radius: 25px; border: 1px solid #eee; background-color: #fff; text-align: center;"
            >
              OR
            </div>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col class="dense" cols="12" sm="6">
            <v-text-field
              v-model="$v.conflictCheckData.phoneNumber.$model"
              label="Phone Number"
              required
              :error="
                $v.conflictCheckData.phoneNumber.$invalid &&
                  $v.conflictCheckData.phoneNumber.$dirty
              "
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-toolbar flat clas="pr-0 pl-0">
            <v-btn
              :disabled="$v.conflictCheckData.$invalid"
              class="primary"
              @click="runConflictCheck"
              >Check for Conflicts</v-btn
            >
            <span
              style="display: inline-block"
              class="pl-5"
              v-if="conflictCheckResultsTableShow"
              >{{ conflictCheckResults.length }} Possible Conflicts</span
            >
            <v-spacer></v-spacer>
            <v-btn
              v-if="conflictCheckResultsTableShow"
              @click="
                conflictCheckDone = true;
                editedLead.firstName = conflictCheckData.firstName;
                editedLead.lastName = conflictCheckData.lastName;
                editedLead.phoneNumber = conflictCheckData.phoneNumber;
              "
              >No Conflict</v-btn
            >
          </v-toolbar>
        </v-row>
      </v-form>
      <v-row v-if="conflictCheckResultsTableShow">
        <v-col cols="12">
          <div class="text-body-1" v-if="conflictCheckResults.length === 0">
            No Results
          </div>
          <v-card v-for="(item, index) in conflictCheckResults" :key="index">
            <v-card-text>
              <div class="text--primary">
                <span class="body-1 font-weight-bold">
                  {{ `${item.firstName} ${item.lastName}` }}
                </span>
                <span
                  class="text-body-2 font-weight-medium"
                  v-if="item.contactRole"
                  >{{ `- ${item.contactRole}` }}</span
                >
                <span
                  v-if="item.caseId"
                  style="display:inline-block; float:right;"
                  class="caption text-right"
                  >Opened{{ ` - ${format(item.openingDate)}` }}
                </span>
              </div>
              <div class="text--primary" v-if="item.caseId">
                {{ `DOI: ${format(item.incidentDate)}` }}
                <span
                  v-if="item.closingDate"
                  style="display:inline-block; float:right;"
                  class="caption text-right"
                  >Closed{{ ` - ${format(item.closingDate)}` }}
                </span>
              </div>
              <div class="text--primary" v-if="item.phoneNumber">
                {{ item.phoneNumber }}
              </div>
              <div class="text--primary" v-if="item.address">
                {{ `${item.address}, ${item.city}, ${item.state} ${item.zip}` }}
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn text color="primary" @click="selectContact(item)"
                >Select</v-btn
              >
              <v-btn
                text
                color="primary"
                v-if="item.caseId"
                target="_blank"
                :href="
                  `https://cs-web.airdesksolutions.com/SA/CaseSummary.aspx?CaseID=${item.caseId}`
                "
                >Go to Case</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="conflictCheckDone">
      <v-form>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="$v.editedLead.firstName.$model"
              label="First Name *"
              required
              :error="
                $v.editedLead.firstName.$invalid &&
                  $v.editedLead.firstName.$dirty
              "
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="$v.editedLead.lastName.$model"
              label="Last Name *"
              required
              :error="
                $v.editedLead.lastName.$invalid && $v.editedLead.lastName.$dirty
              "
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-autocomplete
              :autofocus="!conflictContactId"
              v-model="$v.editedLead.gender.$model"
              label="Gender *"
              :items="genderOptions"
              dense
              required
              :error="
                $v.editedLead.gender.$invalid && $v.editedLead.gender.$dirty
              "
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6">
            <v-autocomplete
              v-model="$v.editedLead.language.$model"
              :items="languageOptions"
              dense
              label="Language *"
              required
              :error="
                $v.editedLead.language.$invalid && $v.editedLead.language.$dirty
              "
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-text-field
              :autofocus="!!conflictContactId"
              v-model="$v.editedLead.phoneNumber.$model"
              label="Phone Number *"
              required
              :error="
                $v.editedLead.phoneNumber.$invalid &&
                  $v.editedLead.phoneNumber.$dirty
              "
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="editedLead.email"
              label="Email"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-autocomplete
              v-model="$v.editedLead.type.$model"
              label="Type *"
              :items="caseTypes"
              item-text="type"
              item-value="typeId"
              dense
              required
              :error="$v.editedLead.type.$invalid && $v.editedLead.type.$dirty"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6">
            <v-autocomplete
              :disabled="!editedLead.type"
              v-model="$v.editedLead.subType.$model"
              :items="caseSubTypes"
              item-text="type"
              item-value="typeId"
              dense
              label="Sub Type *"
              required
              :error="
                $v.editedLead.subType.$invalid && $v.editedLead.subType.$dirty
              "
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="6" class="pt-1">
            <v-autocomplete
              v-model="$v.editedLead.caseState.$model"
              label="Incident State *"
              :items="caseStates"
              item-text="state"
              item-value="stateId"
              dense
              required
              :error="
                $v.editedLead.caseState.$invalid &&
                  $v.editedLead.caseState.$dirty
              "
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6">
            <v-menu>
              <template v-slot:activator="{ on, attr }">
                <v-text-field
                  class="mt-0 pt-0"
                  label="Incident Date *"
                  :value="$v.editedLead.incidentDate.$model"
                  :error="
                    $v.editedLead.incidentDate.$invalid &&
                      $v.editedLead.incidentDate.$dirty
                  "
                  readonly
                  v-bind="attr"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="$v.editedLead.incidentDate.$model"
                no-title
                scrollable
              />
            </v-menu>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <v-textarea
              v-model="$v.editedLead.incidentDetails.$model"
              :error="
                $v.editedLead.incidentDetails.$invalid &&
                  $v.editedLead.incidentDetails.$dirty
              "
              label="Incident Details *"
              required
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <v-textarea
              v-model="$v.editedLead.injurySummary.$model"
              :error="
                $v.editedLead.injurySummary.$invalid &&
                  $v.editedLead.injurySummary.$dirty
              "
              label="Injury Summary *"
              required
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-autocomplete
              label="Advertising Source *"
              :items="referralSourceOptions"
              v-model="$v.editedLead.advertisingSource.$model"
              dense
              :error="
                $v.editedLead.advertisingSource.$invalid &&
                  $v.editedLead.advertisingSource.$dirty
              "
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6">
            <v-autocomplete
              v-model="$v.editedLead.office.$model"
              :items="caseOffices"
              item-text="office"
              item-value="officeId"
              dense
              label="Market *"
              required
              :error="
                $v.editedLead.office.$invalid && $v.editedLead.office.$dirty
              "
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-autocomplete
              label="Wanted *"
              :items="yesNoOptions"
              v-model="$v.editedLead.wantedDate.$model"
              dense
              :error="
                $v.editedLead.wantedDate.$invalid &&
                  $v.editedLead.wantedDate.$dirty
              "
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6">
            <v-autocomplete
              label="Intake Rank *"
              :items="rankOptions"
              v-model="$v.editedLead.intakeRank.$model"
              dense
              :error="
                $v.editedLead.intakeRank.$invalid &&
                  $v.editedLead.intakeRank.$dirty
              "
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-autocomplete
              label="Handled By Alert? *"
              :items="yesNoStringOptions"
              v-model="$v.editedLead.handledByBackup.$model"
              dense
              :error="
                $v.editedLead.handledByBackup.$invalid &&
                  $v.editedLead.handledByBackup.$dirty
              "
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6">
            <v-autocomplete
              label="Medical Referral Opportunity *"
              :items="yesNoStringOptions"
              v-model="$v.editedLead.referralOpportunity.$model"
              dense
              :error="
                $v.editedLead.referralOpportunity.$invalid &&
                  $v.editedLead.referralOpportunity.$dirty
              "
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-autocomplete
              label="Lead Type *"
              :items="leadTypeOptions"
              v-model="$v.editedLead.leadType.$model"
              dense
              :error="
                $v.editedLead.leadType.$invalid && $v.editedLead.leadType.$dirty
              "
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" sm="6">
            <v-autocomplete
              label="Priority *"
              :items="yesNoOptionsBool"
              v-model="$v.editedLead.highPriority.$model"
              dense
              :error="
                $v.editedLead.highPriority.$invalid &&
                  $v.editedLead.highPriority.$dirty
              "
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <v-textarea
              v-model="editedLead.initialNote"
              label="Initial Note"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-btn
              :disabled="$v.$invalid || savingLeadLoader"
              :loading="savingLeadLoader"
              class="primary"
              @click="saveCase"
              >Save Case</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </v-card>
</template>

<script>
import gql from "graphql-tag";
import { required } from "vuelidate/lib/validators";
import format from "date-fns/format";
import { parsePhoneNumber, isPossiblePhoneNumber } from "libphonenumber-js";
import filter from "lodash/filter";

export default {
  data() {
    return {
      headers: [
        { text: "Action", value: "caseId" },
        { text: "Choose", value: "caseNumber" },
        { text: "Name", value: "firstName" },
        { text: "DOB", value: "dob" },
        { text: "Phone", value: "phoneNumber" },
        { text: "Address", value: "address" },
        { text: "Role", value: "contactRole" },
      ],
      conflictCheckData: {
        firstName: null,
        lastName: null,
        phoneNumber: null,
      },
      editedLead: {
        firstName: null,
        lastName: null,
        phoneNumber: null,
        email: null,
        gender: null,
        language: "English",
        type: null,
        subType: null,
        caseState: null,
        office: null,
        wantedDate: null,
        intakeRank: null,
        advertisingSource: null,
        incidentDate: null,
        incidentDetails: null,
        injurySummary: null,
        handledByBackup: null,
        referralOpportunity: null,
        leadType: null,
        initialNote: null,
        highPriority: null,
      },
      defaultLead: {
        firstName: null,
        lastName: null,
        phoneNumber: null,
        email: null,
        gender: null,
        language: "English",
        type: null,
        subType: null,
        caseState: null,
        office: null,
        wantedDate: null,
        intakeRank: null,
        advertisingSource: null,
        incidentDate: null,
        incidentDetails: null,
        injurySummary: null,
        handledByBackup: null,
        referralOpportunity: null,
        leadType: null,
        initialNote: null,
        highPriority: null,
      },
      yesNoOptions: [
        { text: "", value: "" },
        { text: "Yes", value: 1 },
        { text: "No", value: 0 },
      ],
      yesNoOptionsBool: [
        { text: "", value: "" },
        { text: "Yes", value: true },
        { text: "No", value: false },
      ],
      yesNoStringOptions: [
        { text: "", value: null },
        { text: "Yes", value: "Yes" },
        { text: "No", value: "No" },
      ],
      genderOptions: [
        { text: "", value: null },
        { text: "M", value: 1 },
        { text: "F", value: 2 },
      ],
      prefixOptions: [
        { text: "", value: null },
        { text: "Mr.", value: "Mr." },
        { text: "Ms.", value: "Ms." },
        { text: "Mrs.", value: "Mrs." },
      ],
      languageOptions: [
        { text: "English", value: "English" },
        { text: "Spanish", value: "Spanish" },
      ],
      rankOptions: [
        { text: "0 Star - Don't Want", value: 1156 },
        {
          text: "1 Star - Minor Injuries or Questionable Liability",
          value: 1157,
        },
        { text: "2 Star - Soft Tissue and Clear Liability", value: 1158 },
        { text: "3 Star - Serious Injury", value: 1159 },
        { text: "4 Star - Catastrophic Injury", value: 1160 },
      ],
      leadTypeOptions: [
        { text: "Call", value: "Call" },
        { text: "Chat", value: "Chat" },
        { text: "Quiz", value: "Quiz" },
        { text: "Referral", value: "Referral" },
        { text: "Text", value: "Text" },
        { text: "Walk In", value: "Walk In" },
        { text: "Web Form", value: "Web Form" },
      ],
      conflictCheckDone: false,
      conflictCheckResultsTableShow: false,
      conflictCheckResults: [],
      conflictContactId: null,
      savingLeadLoader: false,
    };
  },
  validations: {
    conflictCheckData: {
      firstName: {},
      lastName: {},
      phoneNumber: {},
    },
    editedLead: {
      firstName: {
        required,
      },
      lastName: {
        required,
      },
      phoneNumber: {
        required,
      },
      gender: {
        required,
      },
      language: {
        required,
      },
      type: {
        required,
      },
      subType: {
        required,
      },
      caseState: {
        required,
      },
      office: {
        required,
      },
      wantedDate: {
        required,
      },
      intakeRank: {
        required,
      },
      advertisingSource: {
        required,
      },
      incidentDate: {
        required,
      },
      incidentDetails: {
        required,
      },
      injurySummary: {
        required,
      },
      handledByBackup: {
        required,
      },
      referralOpportunity: {
        required,
      },
      leadType: {
        required,
      },
      highPriority: {
        required,
      },
    },
  },
  mounted() {
    let editedLead = JSON.parse(
      localStorage.getItem("RyBase.LeadDialog.editedLead")
    );
    if (editedLead) {
      this.editedLead = editedLead;
    }
  },
  watch: {
    // "editedLead.type"() {
    //   this.editedLead.subType = null;
    // },
    editedLead: {
      handler(val) {
        localStorage.setItem(
          "RyBase.LeadDialog.editedLead",
          JSON.stringify(val)
        );
      },
      deep: true,
    },
  },
  props: ["leadQuery"],
  computed: {
    referralSourceOptions() {
      if (!this.advertisingSources) return [];
      return filter(
        this.advertisingSources.map((a) => {
          return { text: a.contact, value: a.contactId };
        }),
        (a) => {
          return a.text !== "Ref-Apex Chat";
        }
      );
    },
  },
  apollo: {
    caseTypes: {
      query: gql`
        query {
          caseTypes {
            typeId
            type
          }
        }
      `,
    },
    caseSubTypes: {
      query: gql`
        query($typeId: ID!) {
          caseSubTypes(typeId: $typeId) {
            typeId
            type
          }
        }
      `,
      variables() {
        return {
          typeId: this.editedLead.type,
        };
      },
      skip() {
        return this.editedLead.type ? false : true;
      },
    },
    caseStates: {
      query: gql`
        query {
          caseStates {
            stateId
            state
          }
        }
      `,
    },
    caseOffices: {
      query: gql`
        query {
          caseOffices {
            officeId
            office
          }
        }
      `,
    },
    advertisingSources: {
      query: gql`
        query {
          advertisingSources {
            contactId
            contact
          }
        }
      `,
    },
  },
  methods: {
    saveCase() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }
      this.savingLeadLoader = true;
      let newLead = Object.assign({}, this.editedLead, {
        contactId: this.conflictContactId,
        phoneNumber: parsePhoneNumber(
          `+1${this.editedLead.phoneNumber}`
        ).formatNational(),
        firstName: this.editedLead.firstName.trim(),
        lastName: this.editedLead.lastName.trim(),
        email: this.editedLead.email ? this.editedLead.email.trim() : null,
        prefix: this.editedLead.gender === 1 ? "Mr." : "Ms.",
      });
      this.$apollo
        .mutate({
          mutation: gql`
            mutation($newLead: NewLeadInput!) {
              insertNewLead(newLead: $newLead) {
                caseId
              }
            }
          `,
          variables: {
            newLead,
          },
        })
        .then(
          ({
            data: {
              insertNewLead: { caseId },
            },
          }) => {
            this.savingLeadLoader = false;
            this.leadQuery.refetch();
            this.clearForm();
            this.$emit("closeLeadDialog");
            window.open(
              `https://cs-web.airdesksolutions.com/SA/CaseSummary.aspx?CaseID=${caseId}`
            );
          }
        )
        .catch((error) => {
          this.savingLeadLoader = false;
          console.log(error);
        });
      return false;
    },
    runConflictCheck() {
      // Check to make sure some data is entered before running conflict check
      if (
        !this.conflictCheckData.firstName &&
        !this.conflictCheckData.lastName &&
        !this.conflictCheckData.phoneNumber
      ) {
        window.alert("Please enter a name or phone number to continue");
        return;
      }
      // Check to make sure both a first and last name are entered if searching by name
      if (
        (!this.conflictCheckData.firstName &&
          this.conflictCheckData.lastName) ||
        (this.conflictCheckData.firstName && !this.conflictCheckData.lastName)
      ) {
        window.alert("You need to add both a first and last name to continue");
        return;
      }
      let phoneNumber = null;
      if (
        this.conflictCheckData.phoneNumber &&
        isPossiblePhoneNumber(this.conflictCheckData.phoneNumber, "US") ===
          false
      ) {
        window.alert("Bad Phone Number");
        return;
      }
      if (
        this.conflictCheckData.phoneNumber &&
        isPossiblePhoneNumber(this.conflictCheckData.phoneNumber, "US")
      ) {
        phoneNumber = parsePhoneNumber(
          `+1${this.conflictCheckData.phoneNumber}`
        ).formatNational();
      }
      this.$apollo
        .query({
          query: gql`
            query($firstName: String, $lastName: String, $phone: String) {
              conflictCheck(
                firstName: $firstName
                lastName: $lastName
                phone: $phone
              ) {
                firstName
                lastName
                gender
                prefix
                contactId
                contactCtg
                dob
                address
                city
                state
                zip
                phoneNumber
                contactRole
                caseId
                caseNumber
                incidentDate
                openingDate
                closingDate
              }
            }
          `,
          variables: {
            firstName: this.conflictCheckData.firstName.trim(),
            lastName: this.conflictCheckData.lastName.trim(),
            phone: phoneNumber,
          },
        })
        .then(({ data: { conflictCheck } }) => {
          this.conflictCheckResultsTableShow = true;
          this.conflictCheckResults = conflictCheck;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    clearForm() {
      this.editedLead = this.defaultLead;
      this.conflictCheckData = {
        firstName: null,
        lastName: null,
        phoneNumber: null,
      };
      this.conflictCheckDone = false;
      this.conflictCheckResultsTableShow = false;
      this.conflictCheckResults = [];
      this.conflictContactId = null;
      this.$v.$reset();
      localStorage.removeItem("RyBase.LeadDialog.editedLead");
    },
    selectContact(item) {
      this.editedLead.firstName = item.firstName;
      this.editedLead.lastName = item.lastName;
      this.editedLead.phoneNumber = item.phoneNumber;
      this.editedLead.gender = item.gender;
      this.editedLead.prefix = item.prefix;
      this.conflictContactId = item.contactId;
      this.conflictCheckDone = true;
    },
    format(date) {
      if (!date) return "";
      return format(new Date(date), "MMM dd, yyyy");
    },
  },
};
</script>

<style></style>
