<template>
  <v-container fluid>
    <org-home-intake-stats-pacing />
    <v-row>
      <v-col cols="6">
        <org-intake-agent-stats />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-toolbar flat dense>
            <span class="text-subtitle-1">Retained in Period</span>
          </v-toolbar>
          <org-retained-by-day />
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-toolbar flat dense>
            <span class="text-subtitle-1">Leads in Period</span>
          </v-toolbar>
          <org-leads-stats-by-day />
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-toolbar flat dense>
            <span class="text-subtitle-1">Leads in Period by Source</span>
          </v-toolbar>
          <org-leads-stats-by-day-by-source />
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-toolbar flat dense>
            <span class="text-subtitle-1">Leads By Month</span>
          </v-toolbar>
          <org-leads-wanted-by-month />
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-toolbar flat dense>
            <span class="text-subtitle-1">Retained Cases By Month</span>
          </v-toolbar>
          <org-retained-cases-by-month :options="retainedCasesByMonth" />
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-toolbar flat dense>
            <span class="text-subtitle-1"
              >Net Retained Cases By Month (Not closed within 60 days)</span
            >
          </v-toolbar>
          <org-retained-cases-by-month :options="netRetainedCasesByMonth" />
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <intake-referrals :options="intakeReferralsOptions" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import gql from "graphql-tag";
import { mapGetters } from "vuex";
import IntakeReferrals from "../charts/IntakeReferrals";
import OrgRetainedCasesByMonth from "../charts/OrgRetainedCasesByMonth";
import OrgRetainedByDay from "../charts/OrgRetainedByDay";
import OrgLeadsWantedByMonth from "../charts/OrgLeadsWantedByMonth";
import OrgLeadsStatsByDay from "../charts/OrgLeadsStatsByDay";
import OrgLeadsStatsByDayBySource from "../charts/OrgLeadsStatsByDayBySource";
import OrgIntakeAgentStats from "../charts/OrgIntakeAgentStats";
import OrgHomeIntakeStatsPacing from "./OrgHomeIntakeStatsPacing";
import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";
import { buildRetainedCasesByMonth } from "../util";

export default {
  data() {
    return {
      organization: {
        newCasesInPeriod: [],
        retainedCasesByMonth: [],
        netRetainedCasesByMonth: []
      }
    };
  },
  components: {
    IntakeReferrals,
    OrgRetainedCasesByMonth,
    OrgRetainedByDay,
    OrgLeadsWantedByMonth,
    OrgLeadsStatsByDay,
    OrgLeadsStatsByDayBySource,
    OrgHomeIntakeStatsPacing,
    OrgIntakeAgentStats
  },
  computed: {
    ...mapGetters(["currentUser", "startDate", "endDate"]),
    totalRows() {
      return this.organization.newCasesInPeriod.length;
    },
    intakeReferralsOptions() {
      let groupedReferrals = groupBy(this.organization.newCasesInPeriod, c => {
        return c.referralSource;
      });
      let series = Object.keys(groupedReferrals).map(r => {
        let name = r === "null" ? "No Referral Source" : r;
        return { name, y: groupedReferrals[r].length };
      });
      series = sortBy(series, [s => s.name]);
      return {
        xAxis: {
          type: "category"
        },
        series: [
          {
            name: "Referral Sources",
            data: series
          }
        ]
      };
    },
    retainedCasesByMonth() {
      let rcbm = this.organization.retainedCasesByMonth;
      let series = buildRetainedCasesByMonth(rcbm);
      return {
        series
      };
    },
    netRetainedCasesByMonth() {
      let nrcbm = this.organization.netRetainedCasesByMonth;
      let series = buildRetainedCasesByMonth(nrcbm);
      return {
        series
      };
    }
  },
  apollo: {
    organization: {
      query: gql`
        query DashIntakeNewCases(
          $id: ID!
          $startDate: String!
          $endDate: String!
        ) {
          organization(id: $id) {
            id
            name
            newCasesInPeriod(startDate: $startDate, endDate: $endDate) {
              caseId
              retainedDate
              clientName
              state
              office
              referralSource
              attorney
              paralegal
            }
            retainedCasesByMonth {
              count
              office
              month
              year
              yearMonth
            }
            netRetainedCasesByMonth {
              count
              office
              month
              year
              yearMonth
            }
          }
        }
      `,
      variables() {
        return {
          id: this.currentUser.organization.id,
          startDate: this.startDate,
          endDate: this.endDate
        };
      },
      skip() {
        return this.currentUser ? false : true;
      }
    }
  }
};
</script>

<style scoped></style>
