<template>
  <v-card>
    <v-container class="py-0" fluid>
      <v-row align-content="space-around">
        <v-col cols="12" sm="2">
          <v-card-title class="pa-0">
            <div>
              <div class="headline">Follow Up</div>
              <div class="text-subtitle-1 font-weight-thin">
                {{ leadsInFollowUp.length }} Leads
                <v-icon size="15" @click="refreshQuery">mdi-refresh</v-icon>
              </div>
            </div>
          </v-card-title>
        </v-col>
        <v-col cols="12" sm="3" class="d-flex flex-row justify-space-between">
          <div>
            <div class="text-subtitle-2">Wanted But Not Retained</div>
            <div class="text-center text-h4">
              {{ leadStats.wantedButNotRetained }}
            </div>
          </div>
          <div>
            <div class="text-subtitle-2">Investigator Appt Not Signed</div>
            <div class="text-center text-h4">
              {{ leadStats.wantedInvestigator }}
            </div>
          </div>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="2">
          <v-text-field
            @keyup="updateSearch"
            append-icon="mdi-magnify"
            label="Search"
            hide-details
            single-line
          ></v-text-field>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" md="1" sm="2">
          <v-btn class="primary" @click="leadDialog = true">New Lead</v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-data-table
      :footer-props="{
        'items-per-page-options': [-1],
      }"
      hide-default-footer
      :headers="headers"
      :items="priorityLeads"
      :item-class="followUpColor"
      :loading="$apollo.loading"
      :search="search"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      @update:sort-by="updateSort('by', $event)"
      @update:sort-desc="updateSort('order', $event)"
      ref="intakeLeadsTable"
    >
      <template v-slot:item.caseId="props">
        <a
          class="go-to-case-link mr-2"
          target="_blank"
          title="Link to Case"
          :href="
            `https://cs-web.airdesksolutions.com/SA/CaseSummary.aspx?CaseID=${props.item.caseId}`
          "
        >
          <v-icon size="15">mdi-open-in-new</v-icon>
        </a>
        <a
          class="go-to-case-link mr-2"
          target="_blank"
          title="Link to Contact Card"
          :href="
            `https://cs-web.airdesksolutions.com/SA/Edit_Contact.aspx?ID=${props.item.plContactId}&CTG=1`
          "
        >
          <v-icon size="15">mdi-account-box</v-icon>
        </a>
        <a
          class="go-to-case-link"
          title="Close Case"
          @click="openCloseCaseDialog(props.item.caseId)"
        >
          <v-icon size="15" color="red">mdi-close</v-icon>
        </a>
      </template>
      <template v-slot:item.info.highPriority="props">
        <a class="go-to-case-link" @click="togglePriority(props.item)">
          <v-icon size="20" :color="props.item.info.highPriority ? 'red' : ''"
            >mdi-alert-octagon</v-icon
          >
        </a>
      </template>
      <template v-slot:item.clientName="props">
        <a class="go-to-case-link" @click="openCaseDialog(props.item)">
          {{ props.item.clientName }}
        </a>
      </template>
      <template v-slot:item.referralSource="props">
        <v-edit-dialog
          @open="openEditDialog(props.item.caseId)"
          @close="closeEditDialog"
        >
          {{ props.item.referralSource }}
          <template v-slot:input>
            <v-autocomplete
              :items="referralSourceOptions"
              :value="
                getValue(props.item.referralSource, referralSourceOptions)
              "
              @change="
                saveReferralSource(
                  $event,
                  'referralSource',
                  referralSourceOptions
                )
              "
              dense
            ></v-autocomplete>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:item.wantedDate="props">
        <v-edit-dialog
          @open="openEditDialog(props.item.caseId)"
          @close="closeEditDialog"
        >
          {{ props.item.wantedDate }}
          <template v-slot:input>
            <v-date-picker
              :value="props.item.wantedDate"
              no-title
              scrollable
              @change="saveWanted"
            />
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:item.office="props">
        <v-edit-dialog
          @open="openEditDialog(props.item.caseId)"
          @close="closeEditDialog"
        >
          {{ props.item.office }}
          <template v-slot:input>
            <v-select
              :items="officeOptions"
              :value="getValue(props.item.office, officeOptions)"
              @change="saveOffice"
              dense
            ></v-select>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:item.intakeRank="props">
        <v-edit-dialog
          @open="openEditDialog(props.item.caseId)"
          @close="closeEditDialog"
        >
          <v-rating
            :value="intakeRank(props.item.intakeRank)"
            background-color="white"
            color="yellow accent-4"
            dense
            hover
            readonly
            size="12"
            v-if="intakeRank(props.item.intakeRank) > 0"
          ></v-rating>
          <v-icon
            color="grey"
            size="12"
            v-if="intakeRank(props.item.intakeRank) === 0"
            >mdi-cancel</v-icon
          >
          <template v-slot:input>
            <v-select
              :items="rankOptions"
              :value="getValue(props.item.intakeRank, rankOptions)"
              @change="saveStatus($event, 'intakeRank', rankOptions, 73)"
              dense
            ></v-select>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:item.status="props">
        <v-edit-dialog
          @open="openEditDialog(props.item.caseId)"
          @close="closeEditDialog"
        >
          {{ trimStatus(props.item.status) }}
          <template v-slot:input>
            <v-select
              :items="statusOptions"
              :value="getValue(props.item.status, statusOptions)"
              @change="saveStatus($event, 'status', statusOptions, 1)"
              dense
            ></v-select>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:item.dateOfLastIntakeNote="props">
        {{ formatTimeAgo(props.item.dateOfLastIntakeNote) }}
      </template>
    </v-data-table>
    <div style="height: 30px;"></div>
    <v-data-table
      :footer-props="{
        'items-per-page-options': [25, 50, 100, -1],
      }"
      :headers="headers"
      :items="leads"
      :item-class="followUpColor"
      :loading="$apollo.loading"
      :search="search"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      @update:sort-by="updateSort('by', $event)"
      @update:sort-desc="updateSort('order', $event)"
      ref="intakeLeadsTable"
    >
      <template v-slot:item.caseId="props">
        <a
          class="go-to-case-link mr-2"
          target="_blank"
          title="Link to Case"
          :href="
            `https://cs-web.airdesksolutions.com/SA/CaseSummary.aspx?CaseID=${props.item.caseId}`
          "
        >
          <v-icon size="15">mdi-open-in-new</v-icon>
        </a>
        <a
          class="go-to-case-link mr-2"
          target="_blank"
          title="Link to Contact Card"
          :href="
            `https://cs-web.airdesksolutions.com/SA/Edit_Contact.aspx?ID=${props.item.plContactId}&CTG=1`
          "
        >
          <v-icon size="15">mdi-account-box</v-icon>
        </a>
        <a
          class="go-to-case-link"
          title="Close Case"
          @click="openCloseCaseDialog(props.item.caseId)"
        >
          <v-icon size="15" color="red">mdi-close</v-icon>
        </a>
      </template>
      <template v-slot:item.info.highPriority="props">
        <a class="go-to-case-link" @click="togglePriority(props.item)">
          <v-icon size="20" :color="props.item.info.highPriority ? 'red' : ''"
            >mdi-alert-octagon</v-icon
          >
        </a>
      </template>
      <template v-slot:item.clientName="props">
        <a class="go-to-case-link" @click="openCaseDialog(props.item)">
          {{ props.item.clientName }}
        </a>
      </template>
      <template v-slot:item.referralSource="props">
        <v-edit-dialog
          @open="openEditDialog(props.item.caseId)"
          @close="closeEditDialog"
        >
          {{ props.item.referralSource }}
          <template v-slot:input>
            <v-autocomplete
              :items="referralSourceOptions"
              :value="
                getValue(props.item.referralSource, referralSourceOptions)
              "
              @change="
                saveReferralSource(
                  $event,
                  'referralSource',
                  referralSourceOptions
                )
              "
              dense
            ></v-autocomplete>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:item.wantedDate="props">
        <v-edit-dialog
          @open="openEditDialog(props.item.caseId)"
          @close="closeEditDialog"
        >
          {{ props.item.wantedDate }}
          <template v-slot:input>
            <v-date-picker
              :value="props.item.wantedDate"
              no-title
              scrollable
              @change="saveWanted"
            />
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:item.office="props">
        <v-edit-dialog
          @open="openEditDialog(props.item.caseId)"
          @close="closeEditDialog"
        >
          {{ props.item.office }}
          <template v-slot:input>
            <v-select
              :items="officeOptions"
              :value="getValue(props.item.office, officeOptions)"
              @change="saveOffice"
              dense
            ></v-select>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:item.intakeRank="props">
        <v-edit-dialog
          @open="openEditDialog(props.item.caseId)"
          @close="closeEditDialog"
        >
          <v-rating
            :value="intakeRank(props.item.intakeRank)"
            background-color="white"
            color="yellow accent-4"
            dense
            hover
            readonly
            size="12"
            v-if="intakeRank(props.item.intakeRank) > 0"
          ></v-rating>
          <v-icon
            color="grey"
            size="12"
            v-if="intakeRank(props.item.intakeRank) === 0"
            >mdi-cancel</v-icon
          >
          <template v-slot:input>
            <v-select
              :items="rankOptions"
              :value="getValue(props.item.intakeRank, rankOptions)"
              @change="saveStatus($event, 'intakeRank', rankOptions, 73)"
              dense
            ></v-select>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:item.status="props">
        <v-edit-dialog
          @open="openEditDialog(props.item.caseId)"
          @close="closeEditDialog"
        >
          {{ trimStatus(props.item.status) }}
          <template v-slot:input>
            <v-select
              :items="statusOptions"
              :value="getValue(props.item.status, statusOptions)"
              @change="saveStatus($event, 'status', statusOptions, 1)"
              dense
            ></v-select>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:item.dateOfLastIntakeNote="props">
        {{ formatTimeAgo(props.item.dateOfLastIntakeNote) }}
      </template>
    </v-data-table>
    <v-dialog v-model="caseDialog" max-width="900px">
      <org-home-intake-leads-case-dialog
        :caseId="selectedCaseId"
        :lead="selectedLead"
        :date="date"
        :rankOptions="rankOptions"
        :statusOptions="statusOptions"
      />
    </v-dialog>
    <v-dialog v-model="leadDialog" max-width="600px">
      <lead-dialog
        :leadQuery="$apollo.queries.leadsInFollowUp"
        @closeLeadDialog="closeLeadDialog"
      />
    </v-dialog>
    <v-dialog v-model="closeCaseDialog" max-width="600px">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title class="headline"
            >Close Case - {{ client && client.clientName }}</v-toolbar-title
          >
        </v-toolbar>
        <v-container>
          <v-form>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  v-model="selectedCloseReason"
                  :items="closeReasonOptions"
                  label="Close Reason"
                  autofocus
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="closeReasonComment"
                  label="Comment"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="client && client.retainedDate">
              <v-col cols="12">
                <v-checkbox
                  color="primary"
                  v-model="sentClosingLetter"
                  :ripple="false"
                  label="Sent Closing Letter"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-btn
                  :disabled="
                    !selectedCloseReason ||
                      (client.retainedDate && !this.sentClosingLetter)
                  "
                  :loading="closeCaseLoading"
                  class="primary"
                  @click="closeCase"
                  >Close Case</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import gql from "graphql-tag";
import { mapGetters } from "vuex";
import findIndex from "lodash/findIndex";
import find from "lodash/find";
import groupBy from "lodash/groupBy";
import filter from "lodash/filter";
import debounce from "lodash/debounce";
import { INTAKE_FOLLOW_UP } from "../graphql/cases.gql";
import format from "date-fns/format";
import addDays from "date-fns/addDays";
import subDays from "date-fns/subDays";
import * as timeago from "timeago.js";
import OrgHomeIntakeLeadsCaseDialog from "./OrgHomeIntakeLeadsCaseDialog";
import LeadDialog from "./LeadDialog.vue";

export default {
  data() {
    return {
      search: "",
      headers: [
        { text: "Action", value: "caseId", width: "100px" },
        { text: "Priority", value: "info.highPriority" },
        { text: "Client", value: "clientName" },
        { text: "Type", value: "type" },
        // { text: "Sub", value: "subType" },
        { text: "Source", value: "referralSource" },
        { text: "Agent", value: "agent" },
        { text: "Investigator", value: "investigator" },
        { text: "Attorney", value: "attorney" },
        { text: "Rank", value: "intakeRank" },
        { text: "Status", value: "status" },
        { text: "Last Follow Up", value: "dateOfLastIntakeNote" },
        { text: "Opened", value: "dateOpened" },
        { text: "Wanted", value: "wantedDate" },
        { text: "Retained", value: "retainedDate" },
        { text: "State", value: "state" },
        { text: "Office", value: "office" },
      ],
      leadsInFollowUp: [],
      totalFilteredLeads: 0,
      selectedCaseId: "",
      selectedLead: null,
      selectedCloseReason: null,
      closeReasonComment: "",
      closeCaseLoading: false,
      sentClosingLetter: false,
      wantedDate: "",
      officeOptions: [
        { text: "(2 - WA Main) Spokane Valley Office", value: "8" },
        { text: "(1 - UT Main) Sandy Office", value: "5" },
        { text: "(3 - ID Main) Meridian Office", value: "7" },
        { text: "Sea-Tac", value: "9" },
        { text: "Other", value: "10" },
        { text: "Wyoming", value: "11" },
        { text: "Montana", value: "12" },
        { text: "Colorado", value: "13" },
        { text: "Oregon", value: "14" },
      ],
      rankOptions: [
        { text: "0 Star - Don't Want", value: 1156 },
        {
          text: "1 Star - Minor Injuries or Questionable Liability",
          value: 1157,
        },
        { text: "2 Star - Soft Tissue and Clear Liability", value: 1158 },
        { text: "3 Star - Serious Injury", value: 1159 },
        { text: "4 Star - Catastrophic Injury", value: 1160 },
      ],
      statusOptions: [
        { text: "INT 00 - Lead", value: 1079 },
        { text: "INT 01 - Follow Up", value: 1080 },
        { text: "INT 02 - Investigator Appointment", value: 1081 },
        { text: "REF OUT 1 - Pending", value: 1055 },
        { text: "REF OUT 2 - Rejected", value: 1056 },
        { text: "REF OUT 3 - Accepted", value: 1057 },
      ],
      date: [
        format(new Date(), "yyyy-MM-dd"),
        format(new Date(), "yyyy-MM-dd"),
      ],
      dateMenu: false,
      caseDialog: false,
      leadDialog: false,
      closeCaseDialog: false,
    };
  },
  components: {
    OrgHomeIntakeLeadsCaseDialog,
    LeadDialog,
  },
  computed: {
    ...mapGetters(["currentUser", "startDate", "endDate"]),
    leads() {
      if (!this.leadsInFollowUp) return [];
      return filter(
        this.leadsInFollowUp.map((l) => {
          return {
            ...l,
            shortType:
              l.type.length > 15 ? l.type.substring(0, 15) + "..." : l.type,
            shortSource: l.referralSource
              ? l.referralSource.length > 15
                ? l.referralSource.substring(0, 15) + "..."
                : l.referralSource
              : "",
            shortCRSource: l.callrailSource
              ? l.callrailSource.length > 20
                ? l.callrailSource.substring(0, 20) + "..."
                : l.callrailSource
              : "",
          };
        }),
        (l) => {
          return (
            !l.info.highPriority ||
            ["INT 00 - Lead", "INT 01 - Follow Up"].includes(l.status) == false
          );
        }
      );
    },
    priorityLeads() {
      if (!this.leadsInFollowUp) return [];
      return filter(
        this.leadsInFollowUp.map((l) => {
          return {
            ...l,
            shortType:
              l.type.length > 15 ? l.type.substring(0, 15) + "..." : l.type,
            shortSource: l.referralSource
              ? l.referralSource.length > 15
                ? l.referralSource.substring(0, 15) + "..."
                : l.referralSource
              : "",
            shortCRSource: l.callrailSource
              ? l.callrailSource.length > 20
                ? l.callrailSource.substring(0, 20) + "..."
                : l.callrailSource
              : "",
          };
        }),
        (l) => {
          return (
            l.info.highPriority &&
            ["INT 00 - Lead", "INT 01 - Follow Up"].includes(l.status)
          );
        }
      );
    },
    client() {
      if (!this.selectedCaseId) return "";
      return find(this.leadsInFollowUp, (l) => {
        return l.caseId === this.selectedCaseId;
      });
    },
    leadStats() {
      if (!this.leadsInFollowUp) return { wantedButNotRetained: 0 };
      let wantedButNotRetained = filter(this.leadsInFollowUp, (l) => {
        return !!l.wantedDate && !l.retainedDate && !l.isMassTort;
      });
      let wantedInvestigator = filter(wantedButNotRetained, (l) => {
        return l.status === "INT 02 - Investigator Appointment";
      });
      return {
        wantedButNotRetained: wantedButNotRetained.length,
        wantedInvestigator: wantedInvestigator.length,
      };
    },
    sortBy() {
      let saved = JSON.parse(localStorage.getItem("RyBase.followUpSortBy"));
      if (!saved) return ["wantedDate"];
      return saved;
    },
    sortDesc() {
      let saved = JSON.parse(localStorage.getItem("RyBase.followUpSortDesc"));
      if (!saved) return [true];
      return saved;
    },
    referralSourceOptions() {
      if (!this.advertisingSources) return [];
      return this.advertisingSources.map((a) => {
        return { text: a.contact, value: a.contactId };
      });
    },
    closeReasonOptions() {
      if (!this.closeReasons) return [];
      return this.closeReasons.map((a) => {
        return { text: a.reason, value: a.reasonId };
      });
    },
  },
  apollo: {
    leadsInFollowUp: {
      query: INTAKE_FOLLOW_UP,
      skip() {
        if (!this.currentUser) return true;
        return false;
      },
    },
    advertisingSources: {
      query: gql`
        query IntakeFollowUpAdvertisingSources {
          advertisingSources {
            contact
            contactId
          }
        }
      `,
    },
    closeReasons: {
      query: gql`
        query IntakeLeadsCloseReasons {
          closeReasons {
            reason
            reasonId
          }
        }
      `,
    },
  },
  methods: {
    updateSearch: debounce(function(e) {
      this.search = e.target.value;
    }, 500),
    refreshQuery() {
      this.$apollo.queries.leadsInFollowUp.refetch();
      return false;
    },
    followUpColor(item) {
      let fd = new Date(item.dateOfLastIntakeNote).getTime();
      let now = new Date().getTime();
      let dif = now - fd;
      let color = "none";
      if (item.status === "INT 00 - Lead") {
        // Between 1 and 2 hours
        if (dif > 3600000 && dif <= 7200000) {
          color = "yellow";
        }
        // Between 2 and 3 hours
        if (dif > 7200000 && dif <= 10800000) {
          color = "orange";
        }
        // After 3 hours
        if (dif > 10800000 || !item.dateOfLastIntakeNote) {
          color = "red accent-1";
        }
      }
      if (item.status === "INT 01 - Follow Up") {
        // Between 3 and 4 hours
        if (dif > 10800000 && dif <= 14400000) {
          color = "yellow";
        }
        // Between 4 and 5 hours
        if (dif > 14400000 && dif <= 18000000) {
          color = "orange";
        }
        // After 5 hours
        if (dif > 18000000) {
          color = "red accent-1";
        }
      }
      if (item.status === "INT 02 - Investigator Appointment") {
        if (dif > 129600000 && dif <= 172800000) {
          color = "yellow";
        }
        if (dif > 172800000) {
          color = "red accent-1";
        }
      }
      if (
        item.status === "INT 02 - Investigator Appointment" ||
        item.status === "INT 03 - Appointment Complete - Pending Documents" ||
        item.status === "INT 04 - Attorney Review"
      ) {
        // Between 24 and 27 hours
        if (dif > 86400000 && dif <= 97200000) {
          color = "orange";
        }
        // After 27 hours
        if (dif > 97200000) {
          color = "red accent-1";
        }
      }
      return color;
    },
    updateSort(type, event) {
      if (type === "by") {
        localStorage.setItem("RyBase.followUpSortBy", JSON.stringify(event));
      } else if (type === "order") {
        localStorage.setItem("RyBase.followUpSortDesc", JSON.stringify(event));
      }
    },
    intakeRank(rank) {
      if (!rank) return null;
      let num = rank.substr(0, 1);
      return parseInt(num, 10);
    },
    trimStatus(status) {
      if (status) return status.substr(0, 7);
      return "";
    },
    formatTimeAgo(date) {
      if (!date) return "";
      return timeago.format(new Date(date));
    },
    openCaseDialog(lead) {
      this.selectedCaseId = lead.caseId;
      this.selectedLead = lead;
      this.caseDialog = true;
      return false;
    },
    openCloseCaseDialog(caseId) {
      this.closeCaseDialog = !this.closeCaseDialog;
      this.sentClosingLetter = false;
      this.selectedCaseId = caseId;
      this.closeReasonComment = "";
      this.selectedCloseReason = null;
    },
    setDay(direction) {
      let startDay = new Date(`${this.date[0]}T01:00:00.000-07:00`);
      let newDay;
      if (direction === "add") {
        newDay = format(addDays(startDay, 1), "yyyy-MM-dd");
      } else {
        newDay = format(subDays(startDay, 1), "yyyy-MM-dd");
      }
      this.date = [newDay, newDay];
      return false;
    },
    closeCase() {
      if (!this.selectedCloseReason) return false;
      let comment = `${this.getText(
        this.selectedCloseReason,
        this.closeReasonOptions
      )}: ${this.closeReasonComment}`;
      let closeCase = {
        caseId: this.selectedCaseId,
        comment,
        closeReason: this.selectedCloseReason,
        retained: !!find(this.leadsInFollowUp, (l) => {
          return l.caseId === this.selectedCaseId;
        }).retained,
      };
      this.closeCaseLoading = true;
      this.$apollo
        .mutate({
          mutation: gql`
            mutation CloseCase($closeCase: closeCaseInput!) {
              closeCase(closeCase: $closeCase) {
                caseId
              }
            }
          `,
          variables: {
            closeCase,
          },
          update: (store, { data: { closeCase } }) => {
            const data = store.readQuery({
              query: INTAKE_FOLLOW_UP,
            });
            let index = findIndex(data.leadsInFollowUp, (c) => {
              return c.caseId === closeCase.caseId;
            });
            data.leadsInFollowUp[
              index
            ].dateClosed = new Date().toISOString().substr(0, 10);
            data.leadsInFollowUp[index].wantedDate = "";
            data.leadsInFollowUp[index].status = "CLO-CASE CLOSED";
            store.writeQuery({
              query: INTAKE_FOLLOW_UP,
              data,
            });
          },
        })
        .then(() => {
          this.$refs["intakeLeadsTable"].$el.click();
          this.closeCaseLoading = false;
          this.selectedCloseReason = null;
          this.closeReasonComment = "";
          this.closeCaseDialog = false;
          this.selectedCaseId = null;
        })
        .catch((error) => {
          console.error(error);
        });
      return false;
    },
    saveWanted(date) {
      if (!date) return false;
      this.$apollo
        .mutate({
          mutation: gql`
            mutation InsertWantedDate($dateInput: WantedDate!) {
              insertSaWantedDate(dateInput: $dateInput) {
                wantedDate
              }
            }
          `,
          variables: {
            dateInput: {
              case_id: this.selectedCaseId,
              wanted_date: date,
            },
          },
          update: (store, { data: { insertSaWantedDate } }) => {
            this.updateCache(store, insertSaWantedDate, "wantedDate");
          },
        })
        .then(() => {
          this.$refs["intakeLeadsTable"].$el.click();
        })
        .catch((error) => {
          console.error(error);
        });
      return false;
    },
    saveOffice(officeId) {
      if (!officeId) return false;
      this.$apollo
        .mutate({
          mutation: gql`
            mutation InsertSaOffice($office: OfficeInput!) {
              insertSaOffice(office: $office) {
                officeId
              }
            }
          `,
          variables: {
            office: {
              case_id: this.selectedCaseId,
              office_id: parseInt(officeId, 10),
            },
          },
          update: (store, { data: { insertSaOffice } }) => {
            let officeData = {
              office: this.getText(insertSaOffice.officeId, this.officeOptions),
            };
            this.updateCache(store, officeData, "office");
          },
        })
        .then(() => {
          this.$refs["intakeLeadsTable"].$el.click();
        })
        .catch((error) => {
          console.error(error);
        });
      return false;
    },
    saveStatus(val, type, options, statusType) {
      if (!val) return false;
      this.$apollo
        .mutate({
          mutation: gql`
            mutation UpdateCaseStatus($status: CaseStatus!) {
              upsertSaStatus(status: $status) {
                statusId
              }
            }
          `,
          variables: {
            status: {
              case_id: this.selectedCaseId,
              status_id: val,
              type: statusType,
            },
          },
          update: (store, { data: { upsertSaStatus } }) => {
            let data = {
              [type]: this.getText(upsertSaStatus.statusId, options),
            };
            this.updateCache(store, data, type);
          },
        })
        .then(() => {
          this.$refs["intakeLeadsTable"].$el.click();
        })
        .catch((error) => {
          console.error(error);
        });
      return false;
    },
    saveReferralSource(val, type, options) {
      if (!val) return false;
      this.$apollo
        .mutate({
          mutation: gql`
            mutation UpdateCaseReferralSource(
              $advertisingSource: AdvertisingSourceInput!
            ) {
              upsertSaPaidAdvertising(advertisingSource: $advertisingSource) {
                contactId
              }
            }
          `,
          variables: {
            advertisingSource: {
              case_id: this.selectedCaseId,
              contact_id: val,
            },
          },
          update: (store, { data: { upsertSaPaidAdvertising } }) => {
            let data = {
              [type]: this.getText(upsertSaPaidAdvertising.contactId, options),
            };
            this.updateCache(store, data, type);
          },
        })
        .then(() => {
          this.$refs["intakeLeadsTable"].$el.click();
        })
        .catch((error) => {
          console.error(error);
        });
      return false;
    },
    togglePriority(data) {
      this.selectedCaseId = data.caseId;
      this.saveInfo(!data.info.highPriority, "highPriority");
    },
    saveInfo(data, field) {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation UpsertCaseInfo($caseInfo: CaseInfoInput!) {
              upsertCaseInfo(caseInfo: $caseInfo) {
                id
                caseId
                referralOpportunity
                referred
                signupType
                handledByBackup
                leadType
                highPriority
              }
            }
          `,
          variables: {
            caseInfo: {
              case_id: this.selectedCaseId,
              [field]: data,
            },
          },
          update: (store, { data: { upsertCaseInfo } }) => {
            this.updateInfoCache(store, upsertCaseInfo, field);
          },
        })
        .then(() => {
          this.$refs["intakeLeadsTable"].$el.click();
        })
        .catch((error) => {
          console.error(error);
        });
      return false;
    },
    updateCache(store, insertData, field) {
      let caseId = this.selectedCaseId;
      const data = store.readQuery({
        query: INTAKE_FOLLOW_UP,
      });
      let index = findIndex(data.leadsInFollowUp, (c) => {
        return c.caseId === caseId;
      });
      data.leadsInFollowUp[index][field] = insertData[field];
      store.writeQuery({
        query: INTAKE_FOLLOW_UP,
        data,
      });
    },
    updateInfoCache(store, insertData, field) {
      let caseId = this.selectedCaseId;
      const data = store.readQuery({
        query: INTAKE_FOLLOW_UP,
      });
      let index = findIndex(data.leadsInFollowUp, (c) => {
        return c.caseId === caseId;
      });
      data.leadsInFollowUp[index]["info"][field] = insertData[field];
      store.writeQuery({
        query: INTAKE_FOLLOW_UP,
        data,
      });
    },
    getValue(val, options) {
      let values = groupBy(options, "text");
      if (!values[val]) return null;
      return values[val][0].value;
    },
    getText(val, options) {
      let values = groupBy(options, "value");
      return values[val][0].text;
    },
    openEditDialog(caseId) {
      this.selectedCaseId = caseId;
      return false;
    },
    closeEditDialog() {
      this.selectedCaseId = "";
      return false;
    },
    closeLeadDialog() {
      this.leadDialog = false;
    },
  },
};
</script>

<style scoped>
.yellow {
  background-color: #ffee58;
}
.orange {
  background-color: #ffa726;
}
.red {
  background-color: #ff8a80;
}
</style>
