<template>
  <dash-menu :links="links">
    <router-view></router-view>
  </dash-menu>
</template>

<script>
import DashMenu from "./DashMenu";
import { hasRole } from "../util";

export default {
  components: {
    DashMenu,
  },
  computed: {
    links() {
      let id = this.$route.params.id;
      let links = [
        { name: "Fees", path: `/dash/${id}/fees/` },
        { name: "Settlements", path: `/dash/${id}/fees/fee-settlements` },
      ];
      let currentUser = this.$store.state.user.currentUser;
      if (!currentUser) return links;
      if (
        hasRole(currentUser, "attorney") ||
        hasRole(currentUser, "admin") ||
        hasRole(currentUser, "marketing")
      ) {
        links = links.concat([
          { name: "Fee Comparison", path: `/dash/${id}/fees/fee-comparison` },
          { name: "Rate", path: `/dash/${id}/fees/fee-rate` },
        ]);
      }
      return links;
    },
  },
};
</script>

<style scoped></style>
