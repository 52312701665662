<template>
  <v-card>
    <v-row>
      <v-toolbar flat>
        <div>
          <div class="headline pl-1">CM Scorecard</div>
        </div>
        <v-spacer></v-spacer>
        <div class="mr-5 pt-5" v-if="isAdmin">
          <v-switch
            class=""
            label="Sort By Team"
            v-model="sortByTeam"
          ></v-switch>
        </div>
        <div class="mr-5 pt-5" style="width: 300px" v-if="isAdmin">
          <v-text-field v-model="search" placeholder="Search..."></v-text-field>
        </div>
      </v-toolbar>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="scorecard"
      class="elevation-1"
      :footer-props="{
        'items-per-page-options': [50, 100, -1],
      }"
      :sort-by="['staff']"
      :sort-desc="[true]"
      :search="search"
      :item-key="Math.random(10).toString()"
      :loading="$apollo.loading"
      height="inherit"
      :group-by="sortByTeam ? 'team' : null"
    >
      <template v-slot:item.numCases="props">
        <span :style="activeColor(props.item.numCases)">{{
          props.item.numCases
        }}</span>
      </template>
      <template v-slot:item.avgReviewsByWeek="props">
        <span :style="reviewsColor(props.item.avgReviewsByWeek)">{{
          props.item.avgReviewsByWeek
        }}</span>
      </template>
      <template v-slot:item.fileReviewScore="props">
        <span :style="scoreColor(props.item.fileReviewScore)">{{
          props.item.fileReviewScore
        }}</span>
      </template>
      <template v-slot:item.contactScore="props">
        <span :style="scoreColor(props.item.contactScore)">{{
          props.item.contactScore
        }}</span>
      </template>
      <!-- <template v-slot:item.avgDemandsByDay="props">
        <span :style="avgDemandsPerDayColor(props.item.avgDemandsByDay)">{{
          props.item.avgDemandsByDay
        }}</span>
      </template> -->
      <template v-slot:item.avgDemandsByWeek="props">
        <span :style="avgDemandsPerWeekColor(props.item.avgDemandsByWeek)">{{
          props.item.avgDemandsByWeek
        }}</span>
      </template>
      <!-- <template v-slot:item.avgDemandsByMonth="props">
        <span :style="avgDemandsPerMonthColor(props.item.avgDemandsByMonth)">{{
          props.item.avgDemandsByMonth
        }}</span>
      </template> -->
      <template v-slot:item.performance="props">
        <span :style="performanceColor(props.item.performance)">{{
          props.item.performance
        }}</span>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import gql from "graphql-tag";
import { mapGetters } from "vuex";
import differenceInBusinessDays from "date-fns/differenceInBusinessDays";
import filter from "lodash/filter";
import { hasRole } from "../util";
import format from "date-fns/format";
import groupBy from "lodash/groupBy";

export default {
  data() {
    return {
      search: "",
      sortByTeam: false,
      reviewOptions: [
        { text: "5 Star", value: 1150 },
        { text: "4 Star", value: 1151 },
        { text: "3 Star", value: 1152 },
        { text: "2 Star", value: 1153 },
        { text: "1 Star", value: 1154 },
        // { text: "Intake - 5 Star", value: 1166 },
        // { text: "Intake - 4 Star", value: 1167 },
        // { text: "Intake - 3 Star", value: 1168 },
        // { text: "Intake - 2 Star", value: 1169 },
        // { text: "Intake - 1 Star", value: 1170 },
        { text: "Delete Status", value: "delete" },
      ],
    };
  },
  apollo: {
    organization: {
      query: gql`
        query($id: ID!, $startDate: String!, $endDate: String!) {
          organization(id: $id) {
            id
            name
            users {
              id
              firstName
              lastName
              active
              staffCode
              teams {
                id
                name
              }
            }
            teams {
              id
              name
              users {
                staffCode
              }
            }
            reviewsInPeriod(startDate: $startDate, endDate: $endDate) {
              reviewId
              rating
              comments
              reviewUrl
              sourceType
              reviewDate
              recommended
              firstName
              lastName
              nickName
              user {
                id
                firstName
                lastName
                staffCode
              }
              team {
                id
                name
              }
            }
          }
        }
      `,
      variables() {
        return {
          id: 2,
          startDate: this.startDate,
          endDate: this.endDate,
        };
      },
    },
    cmClientContactScores: {
      query: gql`
        query OrgStaffScorecardScores {
          cmClientContactScores {
            staff
            fullName
            numCases
            numCasesNotContacted
            contactScore
          }
        }
      `,
      fetchPolicy: "no-cache",
    },
    cmFileReviewScores: {
      query: gql`
        query OrgStaffFileReviewScores {
          cmFileReviewScores {
            staff
            fullName
            numCases
            numCasesNotFileReviewed
            fileReviewScore
          }
        }
      `,
      fetchPolicy: "no-cache",
    },
    getTotalDemandsInPeriodByParalegal: {
      query: gql`
        query OrgStaffTotalDemands($startDate: String!, $endDate: String!) {
          getTotalDemandsInPeriodByParalegal(
            startDate: $startDate
            endDate: $endDate
          ) {
            paralegal
            total
          }
        }
      `,
      variables() {
        return {
          startDate: this.startDate,
          endDate: this.endDate,
        };
      },
      fetchPolicy: "no-cache",
    },
    getAverageDemandsInPeriodByParalegalByWeek: {
      query: gql`
        query OrgStaffAvgDemandsByWeek($startDate: String!, $endDate: String!) {
          getAverageDemandsInPeriodByParalegalByWeek(
            startDate: $startDate
            endDate: $endDate
          ) {
            paralegal
            avg
          }
        }
      `,
      variables() {
        return {
          startDate: this.startDate,
          endDate: this.endDate,
        };
      },
      fetchPolicy: "no-cache",
    },
    // getAverageDemandsInPeriodByParalegalByMonth: {
    //   query: gql`
    //     query OrgStaffAvgDemandsByMonth(
    //       $startDate: String!
    //       $endDate: String!
    //     ) {
    //       getAverageDemandsInPeriodByParalegalByMonth(
    //         startDate: $startDate
    //         endDate: $endDate
    //       ) {
    //         paralegal
    //         avg
    //       }
    //     }
    //   `,
    //   variables() {
    //     return {
    //       startDate: this.startDate,
    //       endDate: this.endDate,
    //     };
    //   },
    //   fetchPolicy: "no-cache",
    // },
    reviews: {
      query: gql`
        query OrgSAReviews(
          $startDate: String!
          $endDate: String!
          $includeIntakeReviews: Boolean!
        ) {
          reviews: casesWithReviewsInPeriod(
            startDate: $startDate
            endDate: $endDate
            includeIntakeReviews: $includeIntakeReviews
          ) {
            caseId
            caseNumber
            statusId
            statusType
            clientName
            review
            date
            creator
            modifier
            attorney
            paralegal
          }
        }
      `,
      variables() {
        return {
          startDate: this.startDate,
          endDate: this.endDate,
          includeIntakeReviews: false,
        };
      },
      fetchPolicy: "no-cache",
    },
  },
  computed: {
    ...mapGetters(["selectedTeam", "startDate", "endDate", "currentUser"]),
    isAdmin() {
      if (!this.currentUser) return false;
      return hasRole(this.currentUser, "admin");
    },
    demandGoal() {
      let demandGoal = 1.25;

      if (this.isAdmin) {
        demandGoal = 1.25;
      } else if (this.getTeam(this.selectedTeam) === "KBL") {
        demandGoal = 1.75;
      }

      return demandGoal;
    },
    headers() {
      let headers = [
        { text: "Staff", value: "staff", width: "100px" },
        { text: "Full Name", value: "fullName", width: "150px" },
        { text: "Team", value: "team", width: "100px" },
        { text: "Active", value: "numCases", width: "100px" },
        { text: "CM Score", value: "performance", width: "100px" },
        { text: "Reviews", value: "totalReviews", width: "100px" },
        {
          text: "Avg Reviews/Week (0.5)",
          value: "avgReviewsByWeek",
          width: "175px",
        },
        { text: "FR Score", value: "fileReviewScore", width: "100px" },
        { text: "CC Score", value: "contactScore", width: "100px" },
        {
          text: "CC Not Contacted",
          value: "numCasesNotContacted",
          width: "150px",
        },
        { text: "Total Demands", value: "totalDemands", width: "150px" },
        // {
        //   text: "Avg Demands/Day (0.25)",
        //   value: "avgDemandsByDay",
        //   width: "175px"
        // },
        {
          text: `Avg Demands/Week (${this.demandGoal})`,
          value: "avgDemandsByWeek",
          width: "190px",
        },
        // { text: "Avg Demands/Month (5.3)", value: "avgDemandsByMonth" }
      ];

      return headers;
    },
    nonClientReviews() {
      if (!this.organization || !this.organization.reviewsInPeriod) return [];
      return this.organization.reviewsInPeriod.map((r) => {
        let year = parseInt(r.reviewDate.substr(0, 4), 10);
        let month = parseInt(r.reviewDate.substr(5, 2), 10) - 1;
        let day = parseInt(r.reviewDate.substr(8, 2), 10);
        return {
          caseNumber: "Non-Client",
          review: this.reviewText(parseInt(r.rating)),
          clientName: `${r.firstName} ${r.lastName}`,
          date: format(new Date(year, month, day), "yyyy-MM-dd"),
          attorney: r.team.name,
          paralegal: r.user.staffCode,
        };
      });
    },
    teams() {
      if (!this.organization) return [];
      let activeUsers = this.organization.users.filter((t) => t.active);
      return activeUsers.reduce((acc, user) => {
        acc[user.staffCode] = user.teams.map((t) => t.name);
        return acc;
      }, {});
    },
    reviewsByParalegal() {
      if (!this.reviews || !this.nonClientReviews) return {};
      let combinedReviews = this.reviews.concat(this.nonClientReviews);
      let fiveStarReviews = filter(combinedReviews, (r) => {
        return r.review === "4 Star" || r.review === "5 Star";
      });
      return fiveStarReviews.reduce((acc, review) => {
        if (!acc[review.paralegal]) {
          acc[review.paralegal] = 0;
        }
        acc[review.paralegal] += 1;
        return acc;
      }, {});
    },
    scorecard() {
      if (
        !this.cmClientContactScores ||
        !this.cmFileReviewScores ||
        !this.reviews
      )
        return [];

      let paralegalStats = this.cmClientContactScores.map((c) => {
        let fileReviewScore =
          this.cmFileReviewScores.find((f) => f.staff === c.staff)
            ?.fileReviewScore || 0;
        return {
          staff: c.staff,
          team: this.teams[c.staff]?.join(", "),
          fullName: c.fullName,
          numCases: c.numCases,
          numCasesNotContacted: c.numCasesNotContacted,
          contactScore: c.contactScore,
          fileReviewScore: fileReviewScore,
          totalDemands: this.totalDemands(c.staff),
          totalReviews: this.reviewsByParalegal[c.staff] || 0,
          avgReviewsByWeek: this.avgPerWeek(this.reviewsByParalegal[c.staff]),
          avgDemandsByDay: (
            this.totalDemands(c.staff) /
            differenceInBusinessDays(
              new Date(this.endDate),
              new Date(this.startDate)
            )
          ).toFixed(2),
          avgDemandsByWeek: this.avgPerWeek(this.totalDemands(c.staff)),
          // avgDemandsByMonth:
          //   this.getAverageDemandsInPeriodByParalegalByMonth?.find(
          //     (a) => a.paralegal === c.staff
          //   )?.avg || 0,
          performance: this.cmScore(
            c.contactScore,
            this.avgPerWeek(this.reviewsByParalegal[c.staff]),
            this.avgPerWeek(this.totalDemands(c.staff))
          ),
        };
      });
      if (this.isAdmin) {
        return filter(paralegalStats, (s) => {
          return ["KCS", "AKJ", "MMA"].indexOf(s.staff) == -1;
        });
      } else {
        return filter(paralegalStats, (p) => {
          if (!this.selectedTeam || !p.team) {
            return p.staff === this.currentUser.staffCode;
          } else {
            return this.teams[p.staff].includes(
              this.getTeam(this.selectedTeam)
            );
          }
        });
      }
    },
  },
  methods: {
    getTeam(id) {
      if (!this.organization) return null;
      let team = this.organization.teams.find((t) => t.id === id);
      return team?.name;
    },
    reviewText(review) {
      let reviews = groupBy(this.reviewOptions, "value");
      return reviews[review][0].text;
    },
    avgPerWeek(stat) {
      return (
        (
          (stat || 0) /
          differenceInBusinessDays(
            new Date(this.endDate),
            new Date(this.startDate)
          )
        ).toFixed(2) * 5
      ).toFixed(2);
    },
    activeColor(score) {
      if (score > 70) {
        return "color: red";
      } else if (score > 60) {
        return "color: orange";
      } else {
        return "color: green";
      }
    },
    reviewsColor(score) {
      if (score < 0.4) {
        return "color: red";
      } else if (score < 0.5) {
        return "color: orange";
      } else {
        return "color: green";
      }
    },
    scoreColor(score) {
      if (score < 75) {
        return "color: red";
      } else if (score < 90) {
        return "color: orange";
      } else {
        return "color: green";
      }
    },
    avgDemandsPerDayColor(avg) {
      if (avg < 0.2) {
        return "color: red";
      } else if (avg < 0.25) {
        return "color: orange";
      } else {
        return "color: green";
      }
    },
    avgDemandsPerWeekColor(avg) {
      if (avg < this.demandGoal - this.demandGoal * 0.25) {
        return "color: red";
      } else if (avg < this.demandGoal) {
        return "color: orange";
      } else {
        return "color: green";
      }
    },
    avgDemandsPerMonthColor(avg) {
      if (avg < 4.3) {
        return "color: red";
      } else if (avg < 5.3) {
        return "color: orange";
      } else {
        return "color: green";
      }
    },
    performanceColor(avg) {
      if (avg < 75) {
        return "color: red";
      } else if (avg < 90) {
        return "color: orange";
      } else {
        return "color: green";
      }
    },
    cmScore(contactScore, reviewPerWeek, avgDemands) {
      let cc = (contactScore / 100) * 0.4;
      cc = cc > 0.4 ? 0.4 : cc;
      let rpw = (reviewPerWeek / 0.5) * 0.1;
      rpw = rpw > 0.1 ? 0.1 : rpw;
      let ad = (avgDemands / this.demandGoal) * 0.5;
      ad = ad > 0.5 ? 0.5 : ad;
      return ((cc + rpw + ad) * 100).toFixed(1);
    },
    getWeekOfYear(date) {
      let d = new Date(date);
      d.setHours(0, 0, 0, 0);
      d.setDate(d.getDate() + 4 - (d.getDay() || 7));
      let yearStart = new Date(d.getFullYear(), 0, 1);
      return Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
    },
    weeksInQuery() {
      let weeks = [];
      let start = new Date(this.startDate);
      let end = new Date(this.endDate);
      while (start <= end) {
        weeks.push(this.getWeekOfYear(start));
        start.setDate(start.getDate() + 7);
      }
      return weeks.length;
    },
    totalDemands(staff) {
      if (!this.getTotalDemandsInPeriodByParalegal) return 0;
      return this.getTotalDemandsInPeriodByParalegal.find(
        (d) => d.paralegal === staff
      )
        ? this.getTotalDemandsInPeriodByParalegal.find(
            (d) => d.paralegal === staff
          ).total
        : 0;
    },
  },
};
</script>

<style scoped>
td.status {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  width: 150px;
}
table.table > tbody {
  max-height: 10vh !important;
  font-size: 10px !important;
}
table.table > tbody > td {
  white-space: nowrap !important;
  font-size: 10px !important;
}
.inventory-title {
  font-size: 36px;
}

.inventory-number {
  font-size: 24px;
}
.fee-stat-container {
  padding: 8px 16px;
  text-align: center;
}
.fee-stat-title {
  font-size: 16px;
}
.fee-stat-data {
  font-size: 30px;
  font-weight: bold;
}
</style>
