<template>
  <v-card>
    <v-alert class="alert" :type="alertType" v-if="alert">{{
      alertMessage
    }}</v-alert>
    <v-toolbar dark color="primary" class="fixed-bar" v-if="investigation">
      <v-btn icon dark @click="closeModal">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title
        >Investigation -
        {{
          `${investigation.plFirstName}
        ${investigation.plLastName}`
        }}
        <a
          class="go-to-case-link mr-2"
          target="_blank"
          title="Link to Case"
          :href="
            `https://cs-web.airdesksolutions.com/SA/CaseSummary.aspx?CaseID=${caseId}`
          "
        >
          <v-icon size="15">mdi-open-in-new</v-icon>
        </a>
      </v-toolbar-title>
      <!-- <v-switch
        class="pa-5 mt-5"
        label="View Mode"
        v-model="viewMode"
      ></v-switch> -->
      <v-spacer></v-spacer>
      <a
        class="toolbar-link"
        target="_blank"
        title="Link to Documents"
        :href="
          `https://cs-web.airdesksolutions.com/SA/case_documents.aspx?CaseID=${caseId}`
        "
        >Upload Documents</a
      >
      <v-btn
        :disabled="
          nonIntakeStatus(investigation.status) ||
            pendingStatus(investigation.status) ||
            completedStatus(investigation.status)
        "
        @click="completeInvestigation('partial-complete')"
        class="mr-2"
        >Partial Completion</v-btn
      >
      <v-btn
        :disabled="
          nonIntakeStatus(investigation.status) ||
            completedStatus(investigation.status)
        "
        @click="completeInvestigation('complete')"
        >Intake Complete</v-btn
      >
    </v-toolbar>
    <v-container
      fluid
      v-if="investigation && incident && udfs && !$apollo.loading"
    >
      <v-row dense>
        <h3 class="pb-3">Case Information</h3>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="1">
          <v-text-field
            dense
            readonly
            label="Attorney"
            :value="investigation.attorney"
            :error="!investigation.attorney"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="1">
          <v-text-field
            dense
            disabled
            label="Agent"
            :value="investigation.agent"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="1">
          <v-text-field
            dense
            disabled
            label="Investigator"
            :value="investigation.investigator"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="2">
          <v-autocomplete
            label="Referral Source"
            :disabled="$route.path !== '/org/2/intake/investigations'"
            :items="referralSourceOptions"
            :value="
              getValue(investigation.referralSource, referralSourceOptions)
            "
            @change="srs($event)"
            dense
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="3">
          <v-text-field
            dense
            label="Comments"
            :disabled="$route.path !== '/org/2/intake/investigations'"
            v-model="referralSourceComments"
            @keyup="saveReferralSourceComments"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="4">
          <!-- How did you hear about us? -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25536)"></udf>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <h2>
            Client Information
            <v-icon @click="refreshInvestigation" size="15">mdi-refresh</v-icon>
          </h2>
          <a
            target="_blank"
            title="Link to Contact Card"
            :href="
              `https://cs-web.airdesksolutions.com/SA/Edit_Contact.aspx?ID=${investigation.plContactId}&CTG=1`
            "
            >Edit</a
          >
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="2">
          <v-text-field
            dense
            disabled
            label="First Name"
            :value="investigation.plFirstName"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="2">
          <v-text-field
            dense
            disabled
            label="Last Name"
            :value="investigation.plLastName"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="1">
          <v-autocomplete
            label="Gender"
            disabled
            :value="investigation.plGender"
            :items="genderOptions"
            dense
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="2">
          <v-text-field
            dense
            disabled
            label="Phone Number"
            v-mask="'(###) ###-####'"
            :value="investigation.plPhoneNumber"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="3">
          <v-text-field
            dense
            disabled
            label="Email"
            :value="investigation.plEmail"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="3">
          <v-text-field
            dense
            disabled
            label="Address"
            :value="investigation.plAddress"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="2">
          <v-text-field
            dense
            disabled
            label="City"
            :value="investigation.plCity"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="2">
          <v-autocomplete
            :value="investigation.plStateId"
            label="State"
            :items="caseStates"
            item-text="state"
            item-value="stateId"
            dense
            disabled
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="2">
          <v-text-field
            dense
            disabled
            label="Zip"
            :value="investigation.plZip"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="2">
          <v-text-field
            class="mt-0 pt-0"
            label="Date of Birth *"
            :value="formatDate(investigation.plDob)"
            disabled
          />
        </v-col>
        <v-col cols="12" sm="2">
          <v-text-field
            dense
            disabled
            label="SSN"
            v-mask="'NNN-NN-NNNN'"
            :value="
              investigation.plSsn.trim() == '-  -' ? '' : investigation.plSsn
            "
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row dense>
        <h3 class="pb-3">Additional Client Information</h3>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="2">
          <!-- Occupation -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25455)"></udf>
        </v-col>
        <v-col cols="12" sm="2">
          <!-- Employer -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25456)"></udf>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="2">
          <!-- Alternate Contact -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25457)"></udf>
        </v-col>
        <v-col cols="12" sm="2">
          <!-- Alternate Contact Phone -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25458)"></udf>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="2">
          <!-- Bankruptcy -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25537)"></udf>
        </v-col>
        <v-col cols="12" sm="2">
          <!-- Bankruptcy Information -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25538)"></udf>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="2">
          <!-- Phone Carrier -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25467)"></udf>
        </v-col>
        <v-col cols="12" sm="3">
          <!-- Public Benefits -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25468)"></udf>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <h2>
            Incident Facts
            <v-icon @click="refreshIncident" size="15">mdi-refresh</v-icon>
          </h2>
          <a
            target="_blank"
            title="Link to Incident"
            :href="
              `https://cs-web.airdesksolutions.com/SA/Case_Incident.aspx?CaseID=${caseId}`
            "
            >Edit</a
          >
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="2">
          <v-text-field
            dense
            disabled
            label="Incident Date"
            :value="formatDate(incident.incidentDate)"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="2">
          <v-text-field
            dense
            disabled
            label="Incident Time"
            :value="formatTime(incident.incidentTime)"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="2">
          <v-autocomplete
            v-model="incident.stateId"
            label="State"
            :items="caseStates"
            item-text="state"
            item-value="stateId"
            disabled
            dense
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="2">
          <!-- Location -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 19862)"></udf>
        </v-col>
        <v-col cols="12" sm="2">
          <!-- City -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 19865)"></udf>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="2">
          <!-- Client Position -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25459)"></udf>
        </v-col>
        <v-col cols="12" sm="2">
          <!-- Driver if Client is Passenger -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25460)"></udf>
        </v-col>
        <v-col cols="12" sm="2">
          <!-- Purpose of Trip -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25461)"></udf>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="2">
          <!-- Police Investigated -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25462)"></udf>
        </v-col>
        <v-col cols="12" sm="2">
          <!-- Police Department -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25463)"></udf>
        </v-col>
        <v-col cols="12" sm="2">
          <!-- Police Report No. -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25464)"></udf>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="3">
          <!-- Incident Facts -->
          <v-textarea
            dense
            label="Incident Facts"
            v-model="incident.incidentFacts"
            @keyup="updateIncident"
          ></v-textarea>
        </v-col>
        <v-col cols="12" sm="3">
          <!-- Injury Summary -->
          <v-textarea
            dense
            label="Injuries"
            v-model="injurySummary"
            @keyup="updateInjury"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row dense>
        <h4>Quick Injury</h4>
      </v-row>
      <v-row dense class="pb-5 mb-5">
        <v-col v-for="inj in quickInjuries" :key="inj.injuryId" cols="1">
          <v-checkbox
            dense
            hide-details
            class="mt-0"
            v-model="injuries"
            :label="inj.injury"
            :value="inj.injuryId"
            @change="updateQuickInjury"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="3">
          <!-- Prior Injuries -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25465)"></udf>
        </v-col>
        <v-col cols="12" sm="3">
          <!-- Other Accidents -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25466)"></udf>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12"><h2>Medical Care</h2></v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="2">
          <!-- Transported By Ambulance -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25469)"></udf>
        </v-col>
        <v-col cols="12" sm="2">
          <!-- Ambulance Provider -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25470)"></udf>
        </v-col>
        <v-col cols="12" sm="2">
          <!-- Date of Transport -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25471)"></udf>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="2">
          <!-- Emergency Room -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25472)"></udf>
        </v-col>
        <v-col cols="12" sm="2">
          <!-- Hospital -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25473)"></udf>
        </v-col>
        <v-col cols="12" sm="2">
          <!-- Date of ER -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25474)"></udf>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="3">
          <!-- Other Treatment -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25475)"></udf>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="2">
          <!-- Referred -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25476)"></udf>
        </v-col>
        <v-col cols="12" sm="2">
          <!-- Referred To -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25477)"></udf>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12"><h2>Host Vehicle</h2></v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="2">
          <!-- Owner -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25478)"></udf>
        </v-col>
        <v-col cols="12" sm="1">
          <!-- License -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25481)"></udf>
        </v-col>
        <v-col cols="12" sm="1">
          <!-- Year -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25479)"></udf>
        </v-col>
        <v-col cols="12" sm="1">
          <!-- Make -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25480)"></udf>
        </v-col>
        <v-col cols="12" sm="1">
          <!-- Model -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25534)"></udf>
        </v-col>
        <v-col cols="12" sm="1">
          <!-- Color -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25482)"></udf>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="1">
          <!-- Damage -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25483)"></udf>
        </v-col>
        <v-col cols="12" sm="1">
          <!-- Status -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25485)"></udf>
        </v-col>
        <v-col cols="12" sm="1">
          <!-- Towed -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25484)"></udf>
        </v-col>
        <v-col cols="12" sm="1">
          <!-- Towed By -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25488)"></udf>
        </v-col>
        <v-col cols="12" sm="1">
          <!-- PD Amount -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25486)"></udf>
        </v-col>
        <v-col cols="12" sm="2">
          <!-- PD Paid By -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25487)"></udf>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="4">
          <!-- PD Status Description -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25539)"></udf>
        </v-col>
        <v-col cols="12" sm="2">
          <!-- PD Handled By -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25540)"></udf>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12"><h2>Host Vehicle Insurance</h2></v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="2">
          <!-- Named Insured -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25489)"></udf>
        </v-col>
        <v-col cols="12" sm="2">
          <!-- Insurance Company -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25490)"></udf>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="2">
          <!-- Policy Number -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25491)"></udf>
        </v-col>
        <v-col cols="12" sm="2">
          <!-- Claim Number -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25492)"></udf>
        </v-col>
        <v-col cols="12" sm="2">
          <!-- Adjuster -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25493)"></udf>
        </v-col>
        <v-col cols="12" sm="3">
          <!-- Adjuster Contact Info -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25494)"></udf>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12"><h2>Personal Vehicle Insurance</h2></v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="2">
          <!-- Named Insured -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25495)"></udf>
        </v-col>
        <v-col cols="12" sm="2">
          <!-- Insurance Company -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25496)"></udf>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="2">
          <!-- Policy Number -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25497)"></udf>
        </v-col>
        <v-col cols="12" sm="2">
          <!-- Claim Number -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25498)"></udf>
        </v-col>
        <v-col cols="12" sm="2">
          <!-- Adjuster -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25499)"></udf>
        </v-col>
        <v-col cols="12" sm="3">
          <!-- Adjuster Contact Info -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25500)"></udf>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12"><h2>Client Health Insurance</h2></v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="2">
          <!-- Named Insured -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25501)"></udf>
        </v-col>
        <v-col cols="12" sm="2">
          <!-- Insurance Company -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25502)"></udf>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="2">
          <!-- Group Number -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25503)"></udf>
        </v-col>
        <v-col cols="12" sm="2">
          <!-- ID Number -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25504)"></udf>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12"><h2>Client Worker's Compensation Insurance</h2></v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="2">
          <!-- Employer -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25456)"></udf>
        </v-col>
        <v-col cols="12" sm="2">
          <!-- Insurance Company -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25505)"></udf>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="2">
          <!-- Claim Number -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25506)"></udf>
        </v-col>
        <v-col cols="12" sm="2">
          <!-- Adjuster -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25507)"></udf>
        </v-col>
        <v-col cols="12" sm="3">
          <!-- Adjuster Contact Info -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25508)"></udf>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12"><h2>Adverse Driver</h2></v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="2">
          <!-- Adverse Name -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25509)"></udf>
        </v-col>
        <v-col cols="12" sm="1">
          <!-- Adverse Gender -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25510)"></udf>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="2">
          <!-- Adverse Address -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25511)"></udf>
        </v-col>
        <v-col cols="12" sm="2">
          <!-- Adverse City, State, Zip -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25512)"></udf>
        </v-col>
        <v-col cols="12" sm="2">
          <!-- Adverse Phone -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25513)"></udf>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12"><h2>Adverse Owner</h2></v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="2">
          <!-- Owner Name -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25514)"></udf>
        </v-col>
        <v-col cols="12" sm="2">
          <!-- Owner Address -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25515)"></udf>
        </v-col>
        <v-col cols="12" sm="2">
          <!-- Owner City, State, Zip -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25516)"></udf>
        </v-col>
        <v-col cols="12" sm="2">
          <!-- Owner Phone -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25517)"></udf>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12"><h2>Adverse Vehicle</h2></v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="2">
          <!-- Owner -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25518)"></udf>
        </v-col>
        <v-col cols="12" sm="1">
          <!-- License -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25521)"></udf>
        </v-col>
        <v-col cols="12" sm="1">
          <!-- Year -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25519)"></udf>
        </v-col>
        <v-col cols="12" sm="1">
          <!-- Make -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25520)"></udf>
        </v-col>
        <v-col cols="12" sm="1">
          <!-- Model -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25535)"></udf>
        </v-col>
        <v-col cols="12" sm="1">
          <!-- Color -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25522)"></udf>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="1">
          <!-- Damage -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25523)"></udf>
        </v-col>
        <v-col cols="12" sm="1">
          <!-- Status -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25524)"></udf>
        </v-col>
        <v-col cols="12" sm="1">
          <!-- Towed By -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25525)"></udf>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12"><h2>Adverse Vehicle Insurance</h2></v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="2">
          <!-- Named Insured -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25526)"></udf>
        </v-col>
        <v-col cols="12" sm="2">
          <!-- Insurance Company -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25527)"></udf>
        </v-col>
        <v-col cols="12" sm="2">
          <!-- Policy Number -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25528)"></udf>
        </v-col>
        <v-col cols="12" sm="2">
          <!-- Claim Number -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25529)"></udf>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="2">
          <!-- Adjuster -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25530)"></udf>
        </v-col>
        <v-col cols="12" sm="3">
          <!-- Adjuster Contact Info -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25531)"></udf>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12"><h2>Investigator Notes</h2></v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="6">
          <!-- Investigator Notes -->
          <udf :udf="udfs.find(udf => udf.udfTypeId === 25532)"></udf>
        </v-col>
      </v-row>
    </v-container>
    <v-progress-circular
      class="mt-5"
      v-if="!investigation || !incident || !udfs"
      indeterminate
    ></v-progress-circular>
  </v-card>
</template>

<script>
import gql from "graphql-tag";
import format from "date-fns/format";
import debounce from "lodash/debounce";
import Udf from "./Udf";
import { INCIDENT_UDFS } from "../graphql/udf.gql";
import groupBy from "lodash/groupBy";

export default {
  components: {
    Udf
  },
  mounted() {
    this.$root.$on("alert", this.setAlert);
  },
  data() {
    return {
      alert: false,
      alertType: "",
      alertMessage: "",
      injurySummary: "",
      referralSourceComments: "",
      genderOptions: [
        { text: "", value: null },
        { text: "M", value: 1 },
        { text: "F", value: 2 }
      ],
      yesNoOptions: [
        { text: "", value: null },
        { text: "Yes", value: " Yes" },
        { text: "No", value: " No" }
      ],
      publicBenefits: ["SSI", "SSD", "Medicaid", "Medicare", "Food/Housing"],
      phoneCarriers: ["Verizon", "AT&T", "Sprint", "T-Mobile", "Other"],
      incident: {
        incidentFacts: "",
        incidentDate: "",
        incidentTime: "",
        stateId: "",
        caseId: "",
        injurySummary: "",
        injuryId: ""
      },
      injuries: []
    };
  },
  watch: {
    rawIncident(val) {
      this.injurySummary = val.injurySummary;
      this.incident = val;
    },
    investigation(val) {
      this.referralSourceComments = val.referralSourceComments;
    },
    plaintiffInjuries(val) {
      if (val && val.length) {
        this.injuries = val.map(i => {
          return i.injuryId;
        });
      } else {
        this.injuries = [];
      }
    }
  },
  computed: {
    udfs() {
      if (!this.incidentUdfs) return false;
      return this.incidentUdfs.map(udf => {
        let mask = null;
        if ([25458, 25513, 25517].indexOf(udf.udfTypeId) > -1) {
          mask = "(###) ###-####";
        }
        return {
          ...udf,
          mask,
          caseId: this.caseId
        };
      });
    },
    referralSourceOptions() {
      if (!this.advertisingSources) return [];
      return this.advertisingSources.map(a => {
        return { text: a.contact, value: a.contactId };
      });
    },
    viewMode: {
      get() {
        return this.$store.getters.invViewMode;
      },
      set(value) {
        this.$store.dispatch("setInvViewMode", value);
      }
    }
  },
  props: ["closeInvestigationDialog", "caseId", "saveReferralSource"],
  apollo: {
    investigation: {
      query: gql`
        query investigation($caseId: Int!) {
          investigation(caseId: $caseId) {
            caseId
            retainedDate
            wantedDate
            dateOpened
            dateClosed
            clientName
            state
            office
            referralSource
            referralSourceComments
            referralSourceOther
            attorney
            paralegal
            agent
            investigator
            type
            subType
            status
            dateOfLastIntakeNote
            intakeRank
            caseRank
            phoneNumber
            plContactId
            plPlaintiffId
            info {
              handledByBackup
              leadType
            }
            plFirstName
            plLastName
            plGender
            plAddress
            plCity
            plState
            plStateId
            plZip
            plPhoneNumber
            plEmail
            plSsn
            plDob
          }
        }
      `,
      variables() {
        return {
          caseId: this.caseId
        };
      },
      fetchPolicy: "no-cache",
      skip() {
        return !this.caseId;
      }
    },
    advertisingSources: {
      query: gql`
        query IntakeLeadsAdvertisingSources {
          advertisingSources {
            contact
            contactId
          }
        }
      `
    },
    incidentUdfs: {
      query: INCIDENT_UDFS,
      variables() {
        return {
          caseId: this.caseId
        };
      },
      fetchPolicy: "no-cache",
      skip() {
        return !this.caseId;
      }
    },
    rawIncident: {
      query: gql`
        query incident($caseId: ID!) {
          rawIncident: incident(caseId: $caseId) {
            caseId
            incidentId
            incidentDate
            incidentTime
            stateId
            incidentFacts
            injurySummary
            injuryId
          }
        }
      `,
      variables() {
        return {
          caseId: this.caseId
        };
      },
      fetchPolicy: "no-cache",
      skip() {
        return !this.caseId;
      }
    },
    caseStates: {
      query: gql`
        query caseStates {
          caseStates {
            state
            stateId
          }
        }
      `
    },
    quickInjuries: {
      query: gql`
        query quickInjuries {
          quickInjuries {
            injuryId
            injury
          }
        }
      `
    },
    plaintiffInjuries: {
      query: gql`
        query plaintiffInjuries($caseId: Int!, $plaintiffId: Int!) {
          plaintiffInjuries(caseId: $caseId, plaintiffId: $plaintiffId) {
            injuryId
            injury
          }
        }
      `,
      variables() {
        return {
          caseId: this.caseId,
          plaintiffId: this.investigation.plPlaintiffId
        };
      },
      skip() {
        return !this.investigation || !this.caseId;
      }
    }
  },
  methods: {
    closeModal() {
      this.closeInvestigationDialog();
    },
    refreshInvestigation() {
      this.$apollo.queries.investigation.refetch();
    },
    refreshIncident() {
      this.$apollo.queries.incident.refetch();
    },
    nonIntakeStatus(status) {
      return (
        [
          "INT 00 - Lead",
          "INT 01 - Follow Up",
          "INT 02 - Investigator Appointment",
          "INT 03 - Appointment Complete - Pending Documents",
          "INT 04 - Attorney Review"
        ].indexOf(status) === -1
      );
    },
    pendingStatus(status) {
      return status === "INT 03 - Appointment Complete - Pending Documents";
    },
    completedStatus(status) {
      return status === "INT 04 - Attorney Review";
    },
    formatTime(time) {
      if (!time) {
        return "";
      }
      let tempDate = new Date("2022-01-01 " + time);
      return format(tempDate, "h:mm a");
    },
    formatDate(date) {
      if (!date) {
        return "";
      }
      let tempDate = date.substr(0, 10);
      let day = tempDate.split("-")[2];
      let month = tempDate.split("-")[1];
      let year = tempDate.split("-")[0];
      return `${month}/${day}/${year}`;
    },
    setAlert(type, message) {
      this.alert = true;
      this.alertType = type;
      this.alertMessage = message;
      setTimeout(() => {
        this.alert = false;
      }, 1000);
    },
    getValue(val, options) {
      let values = groupBy(options, "text");
      if (!values[val]) return null;
      return values[val][0].value;
    },
    getText(val, options) {
      let values = groupBy(options, "value");
      return values[val][0].text;
    },
    saveReferralSourceComments: debounce(function() {
      if (!this.saveReferralSource) {
        this.saveReferralSource2(
          this.getValue(
            this.investigation.referralSource,
            this.referralSourceOptions
          ),
          "referralSource",
          this.referralSourceOptions,
          this.referralSourceComments
        );
      } else {
        this.saveReferralSource(
          this.getValue(
            this.investigation.referralSource,
            this.referralSourceOptions
          ),
          "referralSource",
          this.referralSourceOptions,
          this.referralSourceComments
        );
      }
    }, 500),
    completeInvestigation(type) {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation completeInvestigation(
              $investigation: InvestigationInput!
            ) {
              completeInvestigation(investigation: $investigation) {
                statusId
              }
            }
          `,
          variables: {
            investigation: {
              caseId: this.caseId,
              type
            }
          }
        })
        .then(({ data }) => {
          if (data.completeInvestigation) {
            this.closeInvestigationDialog();
          }
        })
        .catch(err => {
          this.setAlert("error", "Failed to complete investigation");
          console.error(err);
        });
    },
    updateInjury: debounce(function() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation updateInjury($injuryId: Int!, $injurySummary: String!) {
              updateInjury(injuryId: $injuryId, injurySummary: $injurySummary) {
                injurySummary
              }
            }
          `,
          variables: {
            injuryId: this.rawIncident.injuryId,
            injurySummary: this.injurySummary
          }
        })
        .catch(err => {
          this.setAlert("error", "Failed to update injury");
          console.error(err);
        });
    }, 500),
    updateQuickInjury() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation updateQuickInjury(
              $caseId: Int!
              $plaintiffId: Int!
              $injuries: [Int]
            ) {
              updateQuickInjury(
                caseId: $caseId
                plaintiffId: $plaintiffId
                injuries: $injuries
              ) {
                caseId
              }
            }
          `,
          variables: {
            caseId: this.caseId,
            plaintiffId: this.investigation.plPlaintiffId,
            injuries: this.injuries
          }
        })
        .catch(err => {
          this.setAlert("error", "Failed to update quick injury");
          console.error(err);
        });
    },
    updateIncident: debounce(function() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation updateIncident(
              $caseId: Int!
              $incidentFacts: String!
              $incidentTime: String
            ) {
              updateIncident(
                caseId: $caseId
                incidentFacts: $incidentFacts
                incidentTime: $incidentTime
              ) {
                caseId
              }
            }
          `,
          variables: {
            caseId: this.caseId,
            incidentFacts: this.incident.incidentFacts,
            incidentTime: this.incident.incidentTime
          }
        })
        .catch(err => {
          this.setAlert("error", "Failed to update incident");
          console.error(err);
        });
    }, 500),
    updateIncidentDate: debounce(function() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation updateIncidentDate($caseId: Int!, $incidentDate: String!) {
              updateIncidentDate(caseId: $caseId, incidentDate: $incidentDate) {
                caseId
              }
            }
          `,
          variables: {
            caseId: this.caseId,
            incidentDate: this.rawIncident.incidentDate
          }
        })
        .catch(err => {
          this.setAlert("error", "Failed to update incident date");
          console.error(err);
        });
    }, 500),
    updateIncidentState: debounce(function() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation updateIncidentState($caseId: Int!, $stateId: Int!) {
              updateIncidentState(caseId: $caseId, stateId: $stateId) {
                caseId
              }
            }
          `,
          variables: {
            caseId: this.caseId,
            stateId: this.incident.stateId
          }
        })
        .catch(err => {
          this.setAlert("error", "Failed to update incident date");
          console.error(err);
        });
    }, 500),
    saveReferralSource2(val, type, options, comments) {
      if (!val) return false;
      this.$apollo
        .mutate({
          mutation: gql`
            mutation UpdateCaseReferralSource(
              $advertisingSource: AdvertisingSourceInput!
              $comments: String!
            ) {
              upsertSaPaidAdvertising(
                advertisingSource: $advertisingSource
                comments: $comments
              ) {
                contactId
              }
            }
          `,
          variables: {
            advertisingSource: {
              case_id: this.caseId,
              contact_id: val
            },
            comments
          }
        })
        .catch(error => {
          console.error(error);
        });
      return false;
    },
    srs(event) {
      if (!this.saveReferralSource) {
        this.saveReferralSource2(
          event,
          "referralSource",
          this.referralSourceOptions,
          ""
        );
      } else {
        this.saveReferralSource(
          event,
          "referralSource",
          this.referralSourceOptions,
          ""
        );
      }
    }
  }
};
</script>

<style>
.alert {
  position: fixed;
  left: 50%;
  bottom: 50px;
  transform: translate(-50%, -50%);
  margin: 0 auto;
}
.fixed-bar {
  position: sticky;
  position: -webkit-sticky; /* for Safari */
  top: 0;
  z-index: 2;
}
.toolbar-link {
  margin-right: 1rem;
  color: #fff !important;
  font-size: 1rem;
  /* font-weight: bold; */
  text-decoration: none;
}
.view-mode-table {
  width: 300px;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 12px;
}
.view-mode-table thead td {
  font-weight: bold;
}
</style>
