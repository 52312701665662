<template>
  <v-card>
    <v-container class="py-0" fluid>
      <v-row align-content="space-around">
        <v-col cols="12" sm="2">
          <v-card-title class="pa-0">
            <div>
              <div class="headline">Referrals Map</div>
            </div>
          </v-card-title>
        </v-col>
        <v-col cols="12" sm="2">
          <v-select
            class="mt-5"
            :items="locationOptions"
            label="Location"
            :value="location"
            @change="changeLocation"
            dense
          ></v-select>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="11" sm="3">
          <v-form @submit.prevent="searchAddress">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              hide-details
            >
              <template v-slot:prepend>
                <v-icon
                  @click="
                    search = '';
                    searchMarker = false;
                  "
                  >mdi-delete</v-icon
                >
              </template>
            </v-text-field>
          </v-form>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <GmapMap
            :center="mapCenter"
            :zoom="11"
            map-type-id="roadmap"
            style="width: 100%; height: 500px"
            :options="mapOptions"
          >
            <GmapMarker
              :key="index"
              v-for="(m, index) in markers"
              :position="m.position"
              :clickable="true"
              :draggable="false"
              :title="m.title"
              :icon="m.icon"
              @click="showInfo(m)"
            />
            <GmapInfoWindow
              v-if="showInfoWindow"
              :position="infoWindowPosition"
              @closeclick="closeInfo"
            >
              <div>
                <b>{{ infoWindowContact.name }}</b>
              </div>
              <div class="mt-2">
                Address: {{ infoWindowContact.address }},
                {{ infoWindowContact.city }}, {{ infoWindowContact.state }}
                {{ infoWindowContact.zip }}
              </div>
              <div>Phone: {{ infoWindowContact.phone }}</div>
              <div>Email: {{ infoWindowContact.email }}</div>
              <div class="mt-2">
                To: {{ infoWindowContact.outboundReferrals }}
              </div>
              <div>From: {{ infoWindowContact.inboundReferrals }}</div>
            </GmapInfoWindow>
          </GmapMap>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import gql from "graphql-tag";
import { mapGetters } from "vuex";
import filter from "lodash/filter";

export default {
  data() {
    return {
      search: "",
      organization: { newCasesInPeriod: [] },
      utah: { lat: 40.6146903, lng: -111.816059 },
      idaho: { lat: 43.6216535, lng: -116.2844614 },
      washington: { lat: 47.703469, lng: -117.1408785 },
      mapCenter: { lat: 40.6146903, lng: -111.816059 },
      locationOptions: [
        { text: "Utah", value: "utah" },
        { text: "Idaho", value: "idaho" },
        { text: "Washington", value: "washington" },
      ],
      mapOptions: {
        zoomControl: true,
        mapTypeControl: true,
        scaleControl: true,
        streetViewControl: true,
        rotateControl: true,
      },
      showInfoWindow: false,
      infoWindowPosition: {},
      infoWindowText: "",
      searchMarker: false,
    };
  },
  computed: {
    ...mapGetters(["currentUser", "startDate", "endDate"]),
    location() {
      return "utah";
    },
    markers() {
      if (!this.contacts) return [];
      let priority = filter(this.contacts, (c) => {
        return c.priority === true;
      });
      let markers = priority.map((c) => {
        let url;
        // if (c.inboundReferrals === null || c.outboundReferrals === null) {
        //   url = "https://maps.google.com/mapfiles/ms/icons/purple-dot.png";
        // } else if (c.inboundReferrals === c.outboundReferrals) {
        //   url = "https://maps.google.com/mapfiles/ms/icons/blue-dot.png";
        // } else if (c.inboundReferrals > c.outboundReferrals) {
        // url = "https://maps.google.com/mapfiles/ms/icons/green-dot.png";
        // } else if (c.inboundReferrals < c.outboundReferrals) {
        url = "https://maps.google.com/mapfiles/ms/icons/red-dot.png";
        // }
        return Object.assign({}, c, {
          position: {
            lat: parseFloat(c.lat),
            lng: parseFloat(c.lng),
          },
          title: c.name,
          icon: {
            url,
          },
        });
      });
      if (this.searchMarker) {
        markers = markers.concat(this.searchMarker);
      }
      return markers;
    },
  },
  methods: {
    changeLocation(e) {
      this.mapCenter = this[e];
    },
    showInfo(e) {
      this.infoWindowContact = e;
      this.showInfoWindow = true;
      this.infoWindowPosition = e.position;
      this.infoWindowText =
        e.title +
        ` Inbound: ${e.inboundReferrals}, Outbound: ${e.outboundReferrals}`;
    },
    closeInfo() {
      this.showInfoWindow = false;
    },
    searchAddress() {
      let address = this.search.replace(/\s+/g, "+").replace("#", "Suite+");
      let url = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyBnnnNtNSlPQ-z1_Eu-pbSogg5nBzoGzR4`;
      fetch(url)
        .then((response) => response.json())
        .then(({ results }) => {
          if (results.length > 0) {
            let position = results[0].geometry.location;
            this.mapCenter = position;
            this.searchMarker = {
              position,
              icon: {
                url: "https://maps.google.com/mapfiles/ms/icons/pink-dot.png",
              },
            };
          }
        });
    },
  },
  apollo: {
    contacts: {
      query: gql`
        query DashContacts {
          contacts {
            id
            saId
            saCtg
            contactType {
              name
            }
            name
            address
            city
            state
            zip
            phone
            email
            priority
            inboundReferrals
            outboundReferrals
            lat
            lng
          }
        }
      `,
      skip() {
        return this.currentUser ? false : true;
      },
    },
  },
};
</script>

<style scoped></style>
