<template>
  <div>
    <div v-if="udf.inputType === 'Text'">
      <v-text-field
        @blur="upsertUdf"
        dense
        v-mask="udf.mask"
        :label="label(udf)"
        v-model="value"
      ></v-text-field>
    </div>
    <div v-if="udf.inputType === 'Currency'">
      <v-text-field
        @blur="upsertUdf"
        dense
        :label="label(udf)"
        v-model="value"
        type="number"
      ></v-text-field>
    </div>
    <div v-if="udf.inputType === 'MultiText5'">
      <v-textarea
        @blur="upsertUdf"
        dense
        :label="label(udf)"
        v-model="value"
      ></v-textarea>
    </div>
    <div v-if="udf.inputType === 'MultiText10'">
      <v-textarea
        @blur="upsertUdf"
        dense
        rows="10"
        :label="label(udf)"
        v-model="value"
      ></v-textarea>
    </div>
    <div v-if="udf.inputType === 'Dropdown'">
      <v-autocomplete
        dense
        :label="label(udf)"
        :items="udf.options.split('~')"
        v-model="value"
        @change="upsertUdf"
      />
    </div>
    <div v-if="udf.inputType === 'Multiselect'">
      <v-autocomplete
        dense
        multiple
        :label="label(udf)"
        :items="udf.options.split('~')"
        v-model="value"
        @change="upsertUdf"
      />
    </div>
    <div v-if="udf.inputType === 'Date'">
      <v-menu transition="scale-transition" offset-y min-width="auto">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            dense
            :value="value"
            :label="label(udf)"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker @change="upsertUdf" v-model="value" no-title scrollable>
        </v-date-picker>
      </v-menu>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
// import debounce from "lodash/debounce";
import accounting from "accounting";
// import findIndex from "lodash/findIndex";
// import { INCIDENT_UDFS } from "../graphql/udf.gql";

export default {
  props: ["udf"],
  data() {
    let value = this.udf.udfValue;
    if (this.udf.inputType === "Date") {
      value = this.parseDate(value);
    }
    if (this.udf.inputType === "Multiselect") {
      value = value ? value.split("|") : [];
    }
    if (this.udf.inputType === "Currency") {
      value = accounting.unformat(value);
    }
    return {
      value
    };
  },
  watch: {
    udf(val) {
      let value = val.udfValue;
      if (this.udf.inputType === "Date") {
        value = this.parseDate(value);
      }
      if (this.udf.inputType === "Multiselect") {
        value = value ? value.split("|") : [];
      }
      if (this.udf.inputType === "Currency") {
        value = accounting.unformat(value);
      }
      this.value = value;
    }
  },
  methods: {
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    label(udf) {
      if (udf.udfShortName) return udf.udfShortName;
      return udf.udfLabel;
    },
    upsertUdf() {
      // upsertUdf: debounce(function() {
      let value = this.value;
      if (this.udf.inputType === "Multiselect") {
        value = value.join("|");
      }
      if (this.udf.inputType === "Date") {
        value = this.formatDate(value);
      }
      if (this.udf.inputType === "Currency") {
        value = accounting.formatMoney(value);
      }
      let udf = {
        caseId: this.udf.caseId,
        udfNewValue: value,
        udfValueId: this.udf.udfValueId,
        screen: this.udf.screen,
        udfTypeId: this.udf.udfTypeId
      };
      this.$apollo
        .mutate({
          mutation: gql`
            mutation($udf: UdfInput!) {
              upsertUdf(udf: $udf) {
                udfValueId
                udfValue
              }
            }
          `,
          variables: {
            udf
          }
        })
        .catch(err => {
          this.$root.$emit(
            "alert",
            "error",
            `${this.udf.udfLabel} failed to save`
          );
          console.log(err);
        });
      // }, 100)
    }
  }
};
</script>

<style></style>
