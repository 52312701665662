<template>
  <v-card>
    <v-container class="py-0" fluid>
      <v-row align-content="space-around">
        <v-col cols="12" sm="8">
          <v-card-title class="pa-0">
            <div>
              <div class="headline">Intake Rotation</div>
            </div>
          </v-card-title>
        </v-col>
      </v-row>
    </v-container>
    <v-row>
      <v-col
        class="col"
        sm="6"
        md="3"
        v-for="department in departments"
        v-bind:key="department.name"
      >
        <v-card>
          <v-card-title>
            <div>
              <div class="body-1">{{ department.name }}</div>
            </div>
          </v-card-title>
          <v-data-table
            :footer-props="{
              'items-per-page-options': [-1],
            }"
            :headers="headers"
            hide-default-footer
            :items="department.users"
            item-key="staffCode"
            :loading="$apollo.loading"
            :sort-by="['weightedAvg']"
          >
            <template v-slot:item.newCasesInPastWeek="{ item }">
              <div v-if="!isIntakeAdmin">
                {{ item.newCasesInPastWeek }}
              </div>
              <v-edit-dialog lazy v-if="isIntakeAdmin">
                {{ item.newCasesInPastWeek }}
                <template v-slot:input>
                  <v-text-field
                    @keyup.enter="updateUserNewCases(item, $event)"
                    label="Edit"
                    single-line
                    type="number"
                    :value="item.newCasesInPastWeek"
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </template>
            <template v-slot:item.percentageFull="{ item }">
              {{ item.percentageFull }}%
            </template>
            <template v-slot:item.weightedAvg="{ item }">
              {{ item.weightedAvg }}%
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import gql from "graphql-tag";
import filter from "lodash/filter";
import sortBy from "lodash/sortBy";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      search: "",
      pagination: { sortBy: "percentageFull", descending: false },
      headers: [
        { text: "Staff", value: "staffCode", width: "70px" },
        { text: "Past Week", value: "newCasesInPastWeek" },
        { text: "% Full", value: "percentageFull" },
        { text: "Score", value: "weightedAvg" },
      ],
      user: { organization: {} },
      statusPieChart: false,
      statusPieSeries: false,
      cmPieChart: false,
      cmPieSeries: false,
      departments: [],
    };
  },
  watch: {
    "user.organization"(val) {
      this.departments = this.prepareDepartments(val);
    },
  },
  apollo: {
    user: {
      query: gql`
        query($id: ID!) {
          user(id: $id) {
            id
            first_name
            last_name
            watchedCases {
              caseId
            }
            organization {
              id
              name
              departments {
                id
                name
                showOnIntakeAssignment
                users {
                  id
                  newCasesInPastWeek
                  staffCode
                  capacity
                  inventoryTotals {
                    totalActiveAutoCases
                  }
                  totalNewCasesInPastWeek
                }
              }
            }
          }
        }
      `,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
      pollInterval: 1000 * 60 * 5,
    },
  },
  computed: {
    ...mapGetters(["isIntakeAdmin"]),
  },
  methods: {
    trimStatus(status) {
      if (status) return status.substr(0, 3);
      return "";
    },
    weightedAvg(numCases, fullPercentage) {
      let nc = (numCases / 5) * 0.3;
      let fp = (fullPercentage / 100) * 0.7;
      return ((nc + fp) * 100).toFixed(1);
    },
    getNextInLineColor(staffCode, users) {
      let sortedUsers = sortBy(users, [(u) => parseFloat(u.weightedAvg)]);
      if (staffCode == sortedUsers[0].staffCode) {
        return "#EF9A9A";
      } else {
        return "#ffffff";
      }
    },
    prepareDepartments(organization) {
      let filteredDepartments = filter(organization.departments, (d) => {
        return d.showOnIntakeAssignment == true;
      });
      return filteredDepartments.map((d) => {
        let series = d.users.map((u) => {
          let percentageFull = parseFloat(
            (
              100 *
              (u.inventoryTotals.totalActiveAutoCases / u.capacity)
            ).toFixed(2)
          );
          return {
            id: u.id,
            staffCode: u.staffCode,
            totalActiveAutoCases: u.inventoryTotals.totalActiveAutoCases,
            capacity: u.capacity ? u.capacity : 10,
            totalNewCasesInPastWeek: u.newCasesInPastWeek,
            newCasesInPastWeek: u.newCasesInPastWeek,
            percentageFull,
            weightedAvg: this.weightedAvg(u.newCasesInPastWeek, percentageFull),
          };
        });
        let sortedByPercentageFull = sortBy(series, [(u) => u.weightedAvg]);
        return {
          users: sortedByPercentageFull,
          name: d.name,
        };
      });
    },
    updateUserNewCases(user, { target }) {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation($user: UpdateUserNewCasesInput) {
              updateUserNewCases(user: $user) {
                id
              }
            }
          `,
          variables: {
            user: {
              id: user.id,
              newCasesInPastWeek: parseInt(target.value, 0),
            },
          },
        })
        .then(({ data }) => {
          console.log(data);
          this.dialog = false;
          setTimeout(() => {
            this.editedItem = Object.assign({}, this.defaultItem);
            this.editedIndex = -1;
          });
          this.$apollo.queries.user.refetch();
        })
        .catch((error) => {
          console.error(error);
        });
      return user;
    },
  },
};
</script>

<style scoped>
td.status {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  width: 150px;
}
table.table > tbody {
  max-height: 10vh !important;
  font-size: 10px !important;
}
table.table > tbody > td {
  white-space: nowrap !important;
  font-size: 10px !important;
}
.inventory-title {
  font-size: 36px;
}

.inventory-number {
  font-size: 24px;
}
.department {
  padding: 10px 10px;
}
</style>
