import get from "lodash/get";
import router from "../router";
import { hasRole } from "../util";

export default {
  state: {
    currentUser: null,
    loginStatus: "loggedOut",
    socket: null,
    presenceChannel: null,
    onlineUsers: [],
  },
  getters: {
    socket(state) {
      return state.socket;
    },
    currentUser(state) {
      return state.currentUser;
    },
    isAdmin(state) {
      if (!state.currentUser) return false;
      return hasRole(state.currentUser, "admin");
    },
    isIntakeAdmin(state) {
      if (!state.currentUser) return false;
      return (
        hasRole(state.currentUser, "admin") ||
        hasRole(state.currentUser, "intake-management")
      );
    },
    currentUserId(state) {
      return get(state, "currentUser.id");
    },
    currentUserOrgId(state) {
      return get(state, "currentUser.organization.id");
    },
    loginStatus(state) {
      return state.loginStatus;
    },
    presenceChannel(state) {
      return state.presenceChannel;
    },
    onlineUsers(state) {
      return state.onlineUsers;
    },
  },
  mutations: {
    setSocket(state, payload) {
      state.socket = payload;
    },
    setCurrentUser(state, payload) {
      state.currentUser = payload;
    },
    setLoginStatus(state, payload) {
      state.loginStatus = payload;
    },
    setPresenceChannel(state, payload) {
      state.presenceChannel = payload;
    },
    addWatchedCase(state, payload) {
      if (!state.currentUser) return;
      if (!state.currentUser.watchedCases) state.currentUser.watchedCases = [];
      state.currentUser.watchedCases.push({ caseId: payload });
    },
    removeWatchedCase(state, payload) {
      if (!state.currentUser) return;
      if (!state.currentUser.watchedCases) state.currentUser.watchedCases = [];
      state.currentUser.watchedCases = state.currentUser.watchedCases.filter(
        (c) => c.caseId !== payload
      );
    },
    setOnlineUsers(state, payload) {
      state.onlineUsers = payload;
    },
  },
  actions: {
    logOut({ commit }) {
      localStorage.removeItem("RyBase.token");
      commit("setCurrentUser", null);
      commit("setLoginStatus", "loggedOut");
      router.push("/");
    },
  },
};
