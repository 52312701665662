<template>
  <v-container fluid>
    <v-progress-linear
      v-if="$apollo.loading"
      indeterminate
      color="primary"
    ></v-progress-linear>
    <v-row class="px-3">
      <v-col cols="12" sm="4">
        <v-card>
          <v-toolbar flat dense>
            <span class="text-subtitle-1">Team Inventory</span>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-switch
                class="pa-4"
                label="Show Active Only"
                v-model="inventoryTeamShowActiveOnly"
              ></v-switch>
            </v-toolbar-items>
          </v-toolbar>
          <inventory-by-user :options="inventoryByUser" />
        </v-card>
      </v-col>
      <v-col cols="12" sm="4">
        <v-card>
          <v-toolbar flat dense>
            <span class="text-subtitle-1">File Reviews</span>
          </v-toolbar>
          <team-file-reviews-by-day :options="teamFileReviewsByDay" />
        </v-card>
      </v-col>
      <v-col cols="12" sm="4">
        <v-card>
          <v-toolbar flat dense>
            <span class="text-subtitle-1"
              >Cases With 4 or 5 Star Reviews By Team</span
            >
          </v-toolbar>
          <v-data-table
            class="review-table"
            :footer-props="{
              'items-per-page-options': [-1],
            }"
            :headers="teamReviewsHeaders"
            hide-default-footer
            :items="reviewsByAllTeams"
            item-key="team"
            :loading="$apollo.loading"
            :sort-by="['total']"
            :sort-desc="[true]"
          ></v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <org-staff-paralegal-stats />
  </v-container>
</template>

<script>
import gql from "graphql-tag";
import sortBy from "lodash/sortBy";
import groupBy from "lodash/groupBy";
import filter from "lodash/filter";
import format from "date-fns/format";
import get from "lodash/get";
// import accounting from "accounting";
import { mapGetters } from "vuex";
import { getAllAttorneysDb } from "../util";
import InventoryByUser from "../charts/InventoryByUser";
import TeamFileReviewsByDay from "../charts/TeamFileReviewsByDay";
import OrgStaffParalegalStats from "./OrgStaffParalegalStats";

export default {
  data() {
    return {
      team: {
        name: "",
      },
      tab: "tab-1",
      commentDialog: false,
      selectedReview: null,
      search: "",
      headers: [
        { text: "User/Team", value: "", width: "120px" },
        { text: "Source", value: "sourceType", width: "80px" },
        { text: "Reviewer", value: "nickName", width: "150px" },
        { text: "Rating", value: "rating", width: "90px" },
        { text: "Date", value: "reviewDate", width: "100px" },
        { text: "Review", value: "comments" },
      ],
      teamReviewsHeaders: [
        { text: "Team", value: "attorney" },
        { text: "Reviews", value: "total" },
      ],
    };
  },
  components: {
    InventoryByUser,
    TeamFileReviewsByDay,
    OrgStaffParalegalStats,
  },
  apollo: {
    team: {
      query: gql`
        query($id: ID, $startDate: String, $endDate: String) {
          team(id: $id) {
            id
            name
            organization {
              id
              name
              departments {
                id
                name
                users {
                  staffCode
                }
              }
            }
            users {
              id
              firstName
              lastName
              staffCode
              saContactId
              roles {
                name
              }
              inventoryTotals {
                staff
                totalIntakeCases
                totalTreatingCases
                totalDemandPrepCases
                totalNegotiationCases
                totalSettledCases
                totalLitigationCases
                totalUimCases
                totalReferredOutCases
                totalClosedCases
                totalNoStatusCases
              }
            }
            # feesTotal(startDate: $startDate, endDate: $endDate)
            # feesAvg(startDate: $startDate, endDate: $endDate)
            teamFileReviewsByDay(startDate: $startDate, endDate: $endDate) {
              day
              month
              year
              count
              staff
            }

            reviewsInPeriod(startDate: $startDate, endDate: $endDate) {
              reviewId
              rating
              comments
              reviewUrl
              sourceType
              reviewDate
              recommended
              firstName
              lastName
              nickName
              user {
                id
                firstName
                lastName
              }
              team {
                id
                name
              }
            }
          }
        }
      `,
      variables() {
        return {
          id: this.selectedTeam,
          startDate: this.startDate,
          endDate: this.endDate,
        };
      },
      skip() {
        return !this.selectedTeam;
      },
    },
    reviewsByTeam: {
      query: gql`
        query($startDate: String, $endDate: String) {
          reviewsByTeam: total_4_and_5_star_reviews_by_attorney_in_period(
            startDate: $startDate
            endDate: $endDate
          ) {
            attorney
            total
          }
        }
      `,
      variables() {
        return {
          startDate: this.startDate,
          endDate: this.endDate,
        };
      },
    },
  },
  computed: {
    ...mapGetters([
      "selectedTeam",
      "inventoryTeamShowActiveOnly",
      "startDate",
      "endDate",
    ]),
    inventoryTeamShowActiveOnly: {
      get() {
        return this.$store.getters.inventoryTeamShowActiveOnly;
      },
      set(value) {
        this.$store.dispatch("setInventoryTeamShowActiveOnly", value);
      },
    },
    reviews() {
      if (!this.team) return [];
      if (!this.team.reviewsInPeriod) return [];
      return this.team.reviewsInPeriod.map((r) => {
        return Object.assign({}, r, {
          reviewDate: format(new Date(r.reviewDate), "yyyy-MM-dd"),
        });
      });
    },
    ids() {
      if (!this.team) return [];
      return filter(this.team.users, (t) => {
        let roles = t.roles.map((r) => {
          return r.name;
        });
        return roles.indexOf("paralegal") != -1;
      }).map((u) => {
        return u.saContactId;
      });
    },
    reviewsByAllTeams() {
      if (!this.team || !this.team.organization || !this.reviewsByTeam)
        return [];
      let groupedReviews = groupBy(this.reviewsByTeam, (t) => {
        return t.attorney;
      });
      let allTeams = getAllAttorneysDb(this.team.organization);
      return allTeams.map((t) => {
        return {
          attorney: t,
          total: groupedReviews[t] ? groupedReviews[t][0].total : 0,
        };
      });
    },
    reviewsByUser() {
      if (!this.team) return {};
      if (!this.team.reviewsInPeriod) return {};
      let teamStaff = filter(this.team.users, (t) => {
        let roles = t.roles.map((r) => {
          return r.name;
        });
        return roles.indexOf("paralegal") != -1;
      }).map((u) => {
        return u.staffCode;
      });
      let mappedReviews = filter(this.team.reviewsInPeriod, (r) => {
        return r.rating >= 5 || r.recommended;
      }).map((r) => {
        return Object.assign({}, r, {
          staff: r.user ? r.user.firstName : "No User",
        });
      });
      let groupedReviews = groupBy(mappedReviews, (r) => {
        return r.staff;
      });
      let series = teamStaff.map((r) => {
        return { name: r, y: groupedReviews[r] ? groupedReviews[r].length : 0 };
      });
      series = sortBy(series, [(s) => s.name]);
      return {
        xAxis: {
          type: "category",
        },
        series: [
          {
            name: "User",
            data: series,
          },
        ],
      };
    },
    inventoryByUser() {
      if (!this.team) return {};
      let sortedUsers = sortBy(this.team.users, ["staffCode"]);
      let categories = sortedUsers.reduce((prev, cur) => {
        return prev.concat(cur.staffCode);
      }, []);
      let series = [
        { name: "No Status", value: "totalNoStatusCases" },
        { name: "Closed", value: "totalClosedCases" },
        { name: "Referred Out", value: "totalReferredOutCases" },
        { name: "Settled", value: "totalSettledCases" },
        { name: "UIM", value: "totalUimCases" },
        { name: "Litigation", value: "totalLitigationCases" },
        { name: "Negotiation", value: "totalNegotiationCases" },
        { name: "Demand Prep", value: "totalDemandPrepCases" },
        { name: "Treating", value: "totalTreatingCases" },
        { name: "Intake", value: "totalIntakeCases" },
      ];
      if (this.inventoryTeamShowActiveOnly) {
        for (var i = 0; i < 4; i++) {
          series.shift();
        }
      }
      series = series.map((s) => {
        return {
          name: s.name,
          data: sortedUsers.reduce((prev, cur) => {
            return prev.concat(cur.inventoryTotals[s.value]);
          }, []),
        };
      });
      return {
        xAxis: {
          categories,
        },
        series,
      };
    },
    teamFileReviewsByDay() {
      if (!this.team) return {};
      let groupedByStaff = groupBy(this.team.teamFileReviewsByDay, (f) => {
        return f.staff;
      });
      let series = [];
      Object.keys(groupedByStaff).map((key) => {
        groupedByStaff[key] = groupedByStaff[key].map((s) => {
          return [Date.UTC(s.year, s.month - 1, s.day), s.count];
        });
        series.push({ name: key, data: groupedByStaff[key] });
      });
      return {
        series,
      };
    },
    // teamFees() {
    //   if (!this.team) return accounting.formatMoney(0);
    //   return accounting.formatMoney(
    //     this.team.feesTotal ? this.team.feesTotal[0] : 0
    //   );
    // },
    // teamFeesAvg() {
    //   if (!this.team) return accounting.formatMoney(0);
    //   return accounting.formatMoney(
    //     this.team.feesAvg ? this.team.feesAvg[0] : 0
    //   );
    // }
  },
  methods: {
    displayTeamAndUser(item) {
      let team = item.team ? get(item, "team.name") : "";
      let user = item.user ? get(item, "user.firstName") : "";
      return `${user} ${team ? `(${team})` : ""}`;
    },
    truncate(comment) {
      if (!comment) return "";
      return comment.slice(0, 50);
    },
  },
};
</script>

<style scoped>
.review-table {
  height: 200px;
  max-height: 200px;
  padding-top: 20px;
  overflow-y: scroll;
}
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
