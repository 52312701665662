<template>
  <v-card>
    <v-row>
      <v-col cols="12" sm="2">
        <div class="fee-stat-container">
          <div class="fee-stat-title">Demands Overdue</div>
          <div class="fee-stat-data">
            {{ demandsTotal }}
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="2">
        <div class="fee-stat-container">
          <div class="fee-stat-title">Percentage Overdue</div>
          <div class="fee-stat-data">{{ percentageOverdue }}%</div>
        </div>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="1" v-if="isAdmin">
        <div class="mr-5">
          <v-switch class="" label="Show All" v-model="showAll"></v-switch>
        </div>
      </v-col>
      <v-col cols="12" sm="1" v-if="isAdmin">
        <div class="mr-5">
          <v-autocomplete
            :items="officeOptions"
            v-model="selectedOffice"
          ></v-autocomplete>
        </div>
      </v-col>
      <v-col class="pr-2" cols="4" sm="2">
        <v-text-field
          v-model="search"
          append-icon="mdi-search"
          label="Search"
          hide-details
          single-line
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <org-demands-by-attorney :options="demandsByAttorneyChartOptions" />
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="demands"
      class="elevation-1"
      :footer-props="{
        'items-per-page-options': [50, 100, -1],
      }"
      :sort-by="['prepDays']"
      :sort-desc="[true]"
      :search="search"
      :item-key="Math.random(10).toString()"
      :loading="$apollo.loading"
      height="inherit"
    >
      <template v-slot:item.caseId="props">
        <a
          class="go-to-case-link mr-2"
          target="_blank"
          :href="
            `https://cs-web.airdesksolutions.com/SA/CaseSummary.aspx?CaseID=${props.item.caseId}`
          "
        >
          <v-icon size="15">mdi-open-in-new</v-icon>
        </a>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import gql from "graphql-tag";
import { mapGetters } from "vuex";
import filter from "lodash/filter";
import groupBy from "lodash/groupBy";
import OrgDemandsByAttorney from "../charts/OrgDemandsByAttorney.vue";
import { hasRole, getAllAttorneysDb } from "../util";

export default {
  data() {
    return {
      search: "",
      pagination: { sortBy: "prepDays", descending: true },
      showAll: true,
      selectedOffice: null,
      officeOptions: [
        { text: "All Markets", value: null },
        {
          text: "(2 - WA Main) Spokane Valley Office",
          value: "(2 - WA Main) Spokane Valley Office",
        },
        {
          text: "(1 - UT Main) Sandy Office",
          value: "(1 - UT Main) Sandy Office",
        },
        {
          text: "(3 - ID Main) Meridian Office",
          value: "(3 - ID Main) Meridian Office",
        },
        { text: "Sea-Tac", value: "Sea-Tac" },
        { text: "Other", value: "Other" },
        { text: "Wyoming", value: "Wyoming" },
        { text: "Montana", value: "Montana" },
        { text: "Colorado", value: "Colorado" },
        { text: "Oregon", value: "Oregon" },
      ],
    };
  },
  components: {
    OrgDemandsByAttorney,
  },
  apollo: {
    organization: {
      query: gql`
        query($id: ID!) {
          organization(id: $id) {
            id
            name
            teams {
              id
              name
            }
            departments {
              id
              name
              users {
                staffCode
              }
            }
          }
        }
      `,
      variables() {
        return {
          id: 2,
        };
      },
    },
    overdueDemands: {
      query: gql`
        query OrgDemandsOverdue {
          overdueDemands {
            clientName
            attorney
            paralegal
            caseId
            type
            office
            state
            prepDays
          }
        }
      `,
      fetchPolicy: "no-cache",
    },
  },
  computed: {
    ...mapGetters(["selectedTeam", "startDate", "endDate", "currentUser"]),
    headers() {
      let headers = [
        { text: "Action", value: "caseId" },
        { text: "Client", value: "clientName" },
        { text: "Type", value: "type" },
        { text: "Attorney", value: "attorney" },
        { text: "Paralegal", value: "paralegal" },
        { text: "Office", value: "office" },
        { text: "Days", value: "prepDays" },
      ];

      return headers;
    },
    isAdmin() {
      if (!this.currentUser) return false;
      return hasRole(this.currentUser, "admin");
    },
    team() {
      if (!this.organization || !this.selectedTeam) return null;
      return filter(
        this.organization.teams,
        (t) => t.id === this.selectedTeam
      )[0].name;
    },
    demands() {
      if (!this.overdueDemands) return [];
      let demands = filter(this.overdueDemands, (d) => d.prepDays >= 45);
      if (this.selectedOffice) {
        demands = filter(demands, (f) => f.office === this.selectedOffice);
      }
      if (!this.showAll && this.selectedTeam) {
        demands = filter(demands, (f) => f.attorney === this.team);
      }
      return demands;
    },
    demandsTotal() {
      if (!this.overdueDemands) return 0;
      return this.demands.length;
    },
    percentageOverdue() {
      if (!this.overdueDemands) return 0;
      return Math.round(
        (this.demands.length / this.overdueDemands.length) * 100
      );
    },
    demandsByAttorney() {
      if (!this.organization || !this.overdueDemands) return [];
      let attorneys = getAllAttorneysDb(this.organization);
      let groupedByAttorney = groupBy(this.overdueDemands, "attorney");
      let demandsByAttorney = attorneys.map((a) => {
        return {
          name: a,
          y: groupedByAttorney[a] ? groupedByAttorney[a].length : 0,
        };
      });
      return {
        name: "Demands By Attorney",
        data: demandsByAttorney,
      };
    },
    demandsByAttorneyChartOptions() {
      return {
        xAxis: {
          type: "category",
        },
        series: this.demandsByAttorney,
      };
    },
  },
};
</script>

<style scoped>
td.status {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  width: 150px;
}
table.table > tbody {
  max-height: 10vh !important;
  font-size: 10px !important;
}
table.table > tbody > td {
  white-space: nowrap !important;
  font-size: 10px !important;
}
.inventory-title {
  font-size: 36px;
}

.inventory-number {
  font-size: 24px;
}
.fee-stat-container {
  padding: 8px 16px;
  text-align: center;
}
.fee-stat-title {
  font-size: 16px;
}
.fee-stat-data {
  font-size: 30px;
  font-weight: bold;
}
</style>
