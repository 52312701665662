<template>
  <div class="chart-container">
    <highcharts ref="chart" :options="chartOptions" />
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  mounted() {
    setTimeout(() => {
      if (!this.$refs.chart) return;
      this.$refs.chart.chart.redraw();
    }, 200);
  },
  computed: {
    chartOptions() {
      let options = Object.assign({}, this.options, {
        credits: {
          enabled: false
        },
        chart: {
          height: 200,
          type: "line"
        },
        title: {
          text: ""
        },
        legend: {
          enabled: true
        },
        xAxis: {
          type: "datetime"
        },
        yAxis: {
          min: 0,
          title: {
            text: ""
          }
        },
        plotOptions: {
          line: {
            dataLables: {
              enabled: true
            }
          }
        }
      });

      if (this.options.plotOptions) {
        options = Object.assign({}, options, {
          chart: {
            height: 200
          }
        });
        options.plotOptions = Object.assign(
          {},
          options.plotOptions,
          this.options.plotOptions
        );
      }
      return options;
    }
  }
};
</script>

<style>
.chart-container {
  height: 220px;
}
</style>
