import gql from "graphql-tag";
import filter from "lodash/filter";
import store from "../store";

export function startWatching(apollo, caseId) {
  if (!caseId) return;
  apollo
    .mutate({
      mutation: gql`
        mutation startWatching($watchedCase: WatchedCaseInput!) {
          startWatching(watchedCase: $watchedCase) {
            caseId
          }
        }
      `,
      variables: {
        watchedCase: {
          caseId
        }
      }
    })
    .then(() => {
      apollo.queries.user.refetch();
      store.commit("addWatchedCase", caseId);
    })
    .catch(err => {
      console.error(err);
    });
}

export function stopWatching(apollo, caseId) {
  if (!caseId) return;
  apollo
    .mutate({
      mutation: gql`
        mutation stopWatching($watchedCase: WatchedCaseInput!) {
          stopWatching(watchedCase: $watchedCase) {
            caseId
          }
        }
      `,
      variables: {
        watchedCase: {
          caseId
        }
      }
    })
    .then(() => {
      apollo.queries.user.refetch();
      store.commit("removeWatchedCase", caseId);
    })
    .catch(err => {
      console.error(err);
    });
}

export function isWatching(user, caseId) {
  if (!user || !user.watchedCases || !caseId) return false;
  let cases = filter(user.watchedCases, c => {
    return c.caseId === caseId;
  });
  return cases.length > 0;
}
