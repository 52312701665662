<template>
  <v-card>
    <v-toolbar flat>
      <v-toolbar-title class="headline"
        >Close Case -
        {{ caseToClose && caseToClose.clientName }}</v-toolbar-title
      >
    </v-toolbar>
    <v-container>
      <v-form>
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              v-model="selectedCloseReason"
              :items="closeReasonOptions"
              label="Close Reason"
              autofocus
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="closeReasonComment"
              label="Comment"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="caseToClose && caseToClose.retainedDate">
          <v-col cols="12">
            <v-checkbox
              color="primary"
              v-model="sentClosingLetter"
              :ripple="false"
              label="Sent Closing Letter"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn
              :disabled="
                !selectedCloseReason ||
                  (caseToClose.retainedDate && !this.sentClosingLetter)
              "
              :loading="closeCaseLoading"
              class="primary"
              @click="closeCase"
              >Close Case</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </v-card>
</template>

<script>
import gql from "graphql-tag";
import groupBy from "lodash/groupBy";

export default {
  props: ["caseToClose", "updateQuery", "closeDialog"],
  // caseToClose = {
  //  caseId: "caseId",
  //  clientName: "clientName",
  //  retainedDate: "retainedDate"
  // }
  data() {
    return {
      closeReasonComment: "",
      closeCaseLoading: false,
      sentClosingLetter: false,
      selectedCloseReason: null
    };
  },
  apollo: {
    closeReasons: {
      query: gql`
        query IntakeLeadsCloseReasons {
          closeReasons {
            reason
            reasonId
          }
        }
      `
    }
  },
  computed: {
    closeReasonOptions() {
      if (!this.closeReasons) return [];
      return this.closeReasons.map(a => {
        return { text: a.reason, value: a.reasonId };
      });
    }
  },
  methods: {
    closeCase() {
      if (!this.selectedCloseReason) return false;
      let comment = `${this.getText(
        this.selectedCloseReason,
        this.closeReasonOptions
      )}: ${this.closeReasonComment}`;
      let closeCase = {
        caseId: this.caseToClose.caseId,
        comment,
        closeReason: this.selectedCloseReason,
        retained: !!this.caseToClose.retainedDate
      };
      this.closeCaseLoading = true;
      let updateQuery = this.updateQuery;
      this.$apollo
        .mutate({
          mutation: gql`
            mutation CloseCase($closeCase: closeCaseInput!) {
              closeCase(closeCase: $closeCase) {
                caseId
              }
            }
          `,
          variables: {
            closeCase
          },
          update: store => {
            updateQuery(store, this.caseToClose.caseId);
          }
        })
        .then(() => {
          this.closeDialog();
          this.closeCaseLoading = false;
          this.selectedCloseReason = null;
          this.closeReasonComment = "";
        })
        .catch(error => {
          console.error(error);
        });
      return false;
    },
    getText(val, options) {
      let values = groupBy(options, "value");
      return values[val][0].text;
    }
  }
};
</script>

<style></style>
