<template>
  <v-row align-content="space-around">
    <v-col cols="12" sm="3" md="2">
      <v-card>
        <v-list-item three-line>
          <v-list-item-content>
            <div class="overline">
              Total
              <span
                :style="
                  retainedCases < retainedCasesGoal(10.2)
                    ? 'color: #F44336'
                    : 'color: #4CAF50'
                "
              >
                {{ goalPercentage(retainedCases, 10.2) + "%" }}
              </span>
            </div>
            <v-list-item-title>{{ retainedCases }} Cases</v-list-item-title>
            <v-list-item-subtitle
              >{{ retainedCasesGoal(10.2) }} Goal</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-col>
    <v-col
      cols="12"
      sm="3"
      md="2"
      v-for="(item, index) in retainedCasesInPeriodByOffice"
      :key="index"
    >
      <v-card>
        <v-list-item three-line>
          <v-list-item-content>
            <div class="overline">
              {{ item.office }}
              <span
                :style="
                  item.count < officeGoal(item.office)
                    ? 'color: #F44336'
                    : 'color: #4CAF50'
                "
              >
                {{ officeGoalPercentage(item.count, item.office) }}
              </span>
            </div>
            <v-list-item-title>{{ item.count }} Cases</v-list-item-title>
            <v-list-item-subtitle
              >{{ officeGoal(item.office) }} Goal</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import differenceInBusinessDays from "date-fns/differenceInBusinessDays";
import addDays from "date-fns/addDays";
import { mapGetters } from "vuex";
import gql from "graphql-tag";

export default {
  data() {
    return {
      search: "",
      headers: [
        { text: "Action", value: "caseId", width: "100px" },
        { text: "Type", value: "type" },
        { text: "State", value: "state" },
        { text: "Client", value: "clientName" },
        { text: "Attorney", value: "attorney" },
        { text: "Paralegal", value: "paralegal" },
        { text: "Opened", value: "dateOpened" },
        { text: "Closed", value: "dateClosed" },
        { text: "Reason", value: "reasonClosed" },
      ],
      closedCases: [],
      retainedOnly: false,
    };
  },
  computed: {
    ...mapGetters(["startDate", "endDate", "currentUser"]),
    retainedCases() {
      if (!this.organization) return 0;
      return this.organization.retainedCasesInPeriod;
    },
    businessDays() {
      let start = this.startDate.split("-").map((x) => parseInt(x));
      let startDate = new Date(start[0], start[1] - 1, start[2]);
      let end = this.endDate.split("-").map((x) => parseInt(x));
      let endDate = new Date(end[0], end[1] - 1, end[2]);
      // We only want to count business days up to today if range is current month
      // If historical range we will count business days through range
      if (endDate > new Date()) {
        endDate = new Date();
      }
      // Add a day to endDate so we can include the last day
      endDate = addDays(endDate, 1);

      return differenceInBusinessDays(endDate, startDate);
    },
    retainedCasesInPeriodByOffice() {
      if (!this.organization) return [];
      return this.organization.retainedCasesInPeriodByOffice;
    },
  },
  methods: {
    retainedCasesGoal(multiplier) {
      return Math.round(this.businessDays * multiplier * 10) / 10;
    },
    goalPercentage(retainedCases, multiplier) {
      return (
        Math.round(
          (retainedCases / this.retainedCasesGoal(multiplier)) * 100 * 10
        ) / 10
      );
    },
    officeGoal(office) {
      switch (office) {
        case "(3 - ID Main) Meridian Office":
          // (13*52)/252
          return this.retainedCasesGoal(2.7);
        case "Sea-Tac":
          // 5/21
          return this.retainedCasesGoal(0.25);
        case "(2 - WA Main) Spokane Valley Office":
          // (14*52)/252
          return this.retainedCasesGoal(2.9);
        case "(1 - UT Main) Sandy Office":
          // (22*52)/252
          return this.retainedCasesGoal(4.6);
        case "Wyoming":
          // 5/21
          return this.retainedCasesGoal(0.25);
        case "Montana":
          // 5/21
          return this.retainedCasesGoal(0.25);
        default:
          return this.retainedCasesGoal(0);
      }
    },
    officeGoalPercentage(count, office) {
      switch (office) {
        case "(3 - ID Main) Meridian Office":
          // 40/21
          return `${this.goalPercentage(count, 2.7)}%`;
        case "Sea-Tac":
          // 5/21
          return `${this.goalPercentage(count, 0.25)}%`;
        case "(2 - WA Main) Spokane Valley Office":
          // 80/21
          return `${this.goalPercentage(count, 2.9)}%`;
        case "(1 - UT Main) Sandy Office":
          // 80/21
          return `${this.goalPercentage(count, 4.6)}%`;
        case "Wyoming":
          // 5/21
          return `${this.goalPercentage(count, 0.25)}%`;
        case "Montana":
          // 5/21
          return `${this.goalPercentage(count, 0.25)}%`;
        default:
          return `${this.goalPercentage(count, 0)}%`;
      }
    },
  },
  apollo: {
    organization: {
      query: gql`
        query OrgHomeIntakeStatsPacing(
          $id: ID!
          $startDate: String!
          $endDate: String!
        ) {
          organization(id: $id) {
            id
            retainedCasesInPeriod(startDate: $startDate, endDate: $endDate)
            retainedCasesInPeriodByOffice(
              startDate: $startDate
              endDate: $endDate
            ) {
              office
              count
            }
          }
        }
      `,
      variables() {
        return {
          id: this.currentUser.organization.id,
          startDate: this.startDate,
          endDate: this.endDate,
        };
      },
      skip() {
        return (
          this.startDate === null ||
          this.endDate === null ||
          this.currentUser === null
        );
      },
    },
  },
};
</script>

<style></style>
