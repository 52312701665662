var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-alert',{staticClass:"alert",attrs:{"transition":"scale-transition","type":_vm.alertType,"value":_vm.alert}},[_vm._v(_vm._s(_vm.alertMessage))]),_c('v-container',{staticClass:"py-0",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align-content":"space-around"}},[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-card-title',{staticClass:"pa-0"},[_c('div',[_c('div',{staticClass:"headline"},[_vm._v("Client Contact")]),_c('div',{staticClass:"text-subtitle-1 font-weight-thin"},[_vm._v(" "+_vm._s(_vm.filteredCases.length)+" Cases Not Contacted in Over 45 Days ")])])])],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-select',{attrs:{"small-chips":"","label":"Hide Stages","items":[
            'Intake',
            'Treating',
            'Demand Prep',
            'Negotiations',
            'Litigation',
            'Settled',
            'Closed',
            'Referred Out Stage',
            'Inactive',
          ],"multiple":"","single-line":""},model:{value:(_vm.hideClientContactStages),callback:function ($$v) {_vm.hideClientContactStages=$$v},expression:"hideClientContactStages"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","hide-details":"","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-data-table',{attrs:{"footer-props":{
      'items-per-page-options': [-1],
    },"headers":_vm.headers,"hide-default-footer":"","items":_vm.filteredCases,"loading":_vm.$apollo.loading,"search":_vm.search,"sort-by":['dateOfLastClientContact'],"sort-desc":[false]},scopedSlots:_vm._u([{key:"body",fn:function({ items }){return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:`${item.caseId}-${Math.random().toString()}`,style:({
            background: _vm.getDateColor(item),
          })},[_c('td',[_c('a',{staticClass:"go-to-case-link mr-2",attrs:{"target":"_blank","href":`https://cs-web.airdesksolutions.com/SA/CaseSummary.aspx?CaseID=${item.caseId}`}},[_c('v-icon',{attrs:{"size":"15"}},[_vm._v("mdi-open-in-new")])],1),_c('a',[_c('v-icon',{attrs:{"size":"15"},on:{"click":function($event){return _vm.sendOverdueContactEmail(item)}}},[_vm._v("mdi-email-arrow-right-outline")])],1)]),_c('td',[_vm._v(_vm._s(item.stage))]),_c('td',[_vm._v(_vm._s(item.clientName))]),_c('td',[_vm._v(_vm._s(item.paralegal))]),_c('td',[_vm._v(_vm._s(item.attorney))]),_c('td',[_vm._v(" "+_vm._s(_vm.formatLastContactDate(item.dateOfLastClientContact))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.formatLastContactDateAttempt(item))+" ")])])}),0)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }