<template>
  <crm-menu :links="links">
    <router-view></router-view>
  </crm-menu>
</template>

<script>
import CrmMenu from "./CRMMenu";

export default {
  components: {
    CrmMenu
  },
  computed: {
    links() {
      return [
        { name: "Activity", path: `/crm` },
        { name: "Contacts", path: `/crm/contacts` }
      ];
    }
  }
};
</script>

<style scoped lang="scss"></style>
