<template>
  <v-card>
    <v-container class="py-0" fluid>
      <v-row align-content="space-around">
        <v-col cols="12" sm="2">
          <v-card-title class="pa-0">
            <div>
              <div class="headline">Referred Out</div>
              <div class="text-subtitle-1 font-weight-thin">
                {{ totalReferredOut }} Cases
                <v-icon @click="refreshQuery" size="15">mdi-refresh</v-icon>
              </div>
            </div>
          </v-card-title>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="1">
          <v-autocomplete
            v-model="statusFilter"
            label="Status"
            :items="statusOptionsForFilter"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="1">
          <v-autocomplete
            v-model="typeFilter"
            label="Type"
            :items="typeOptions"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="1">
          <v-autocomplete
            v-model="firmFilter"
            label="Firm"
            :items="firmOptions"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="2">
          <v-text-field
            @keyup="updateSearch"
            append-icon="mdi-magnify"
            label="Search"
            hide-details
            single-line
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
    <v-data-table
      :footer-props="{
        'items-per-page-options': [100, -1],
      }"
      :headers="headers"
      :items="filteredCases"
      :loading="$apollo.loading"
      :search="search"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      @update:sort-by="updateSort('by', $event)"
      @update:sort-desc="updateSort('order', $event)"
      ref="intakeReferredOutTable"
      @current-items="getCurrentItems"
    >
      <template v-slot:item.caseId="props">
        <a
          class="go-to-case-link mr-2"
          target="_blank"
          :href="
            `https://cs-web.airdesksolutions.com/SA/CaseSummary.aspx?CaseID=${props.item.caseId}`
          "
        >
          <v-icon size="15">mdi-open-in-new</v-icon>
        </a>
        <a
          class="go-to-case-link"
          title="Close Case"
          @click="openCloseCaseDialog(props.item.caseId)"
        >
          <v-icon size="15" color="red">mdi-close</v-icon>
        </a>
      </template>

      <template v-slot:item.clientName="props">
        <a class="go-to-case-link">
          {{ props.item.clientName }}
        </a>
      </template>
      <template v-slot:item.status="props">
        <v-edit-dialog
          @open="openEditDialog(props.item.caseId)"
          @close="closeEditDialog"
        >
          {{ props.item.status }}
          <template v-slot:input>
            <v-select
              :items="statusOptions"
              :value="getValue(props.item.status, statusOptions)"
              @change="saveStatus($event, 'status', statusOptions, 1)"
              dense
            ></v-select>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:item.office="props">
        <v-edit-dialog
          @open="openEditDialog(props.item.caseId)"
          @close="closeEditDialog"
        >
          {{ props.item.office }}
          <template v-slot:input>
            <v-select
              :items="officeOptions"
              :value="getValue(props.item.office, officeOptions)"
              @change="saveOffice"
              dense
            ></v-select>
          </template>
        </v-edit-dialog>
      </template>
    </v-data-table>
    <v-dialog v-model="closeCaseDialog" max-width="600px">
      <close-case-dialog
        :caseToClose="selectedLead"
        :closeDialog="closeDialog"
        :updateQuery="updateQuery"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import gql from "graphql-tag";
import { mapGetters } from "vuex";
import findIndex from "lodash/findIndex";
import groupBy from "lodash/groupBy";
import get from "lodash/get";
import filter from "lodash/filter";
import debounce from "lodash/debounce";
import { INTAKE_REFERRED_OUT_CASES } from "../graphql/cases.gql";
import format from "date-fns/format";
import CloseCaseDialog from "./CloseCaseDialog.vue";

export default {
  components: {
    CloseCaseDialog,
  },
  data() {
    return {
      search: "",
      headers: [
        { text: "Action", value: "caseId", width: "100px" },
        { text: "Client", value: "clientName" },
        { text: "Type", value: "type" },
        // { text: "Sub", value: "subType" },
        { text: "Attorney", value: "attorney" },
        { text: "Status", value: "status" },
        { text: "Status Date", value: "statusDate" },
        { text: "Firm", value: "referredOutFirm" },
        { text: "State", value: "state" },
        { text: "Office", value: "office" },
      ],
      referredOutCases: [],
      totalReferredOut: 0,
      selectedCaseId: "",
      selectedLead: null,
      firmFilter: "All",
      typeFilter: "All",
      statusFilter: "All",
      officeOptions: [
        { text: "(2 - WA Main) Spokane Valley Office", value: "8" },
        { text: "(1 - UT Main) Sandy Office", value: "5" },
        { text: "(3 - ID Main) Meridian Office", value: "7" },
        { text: "Sea-Tac", value: "9" },
        { text: "Other", value: "10" },
        { text: "Wyoming", value: "11" },
        { text: "Montana", value: "12" },
        { text: "Colorado", value: "13" },
        { text: "Oregon", value: "14" },
      ],
      statusOptions: [
        { text: "REF OUT 1 - Pending", value: 1055 },
        { text: "REF OUT 2 - Rejected", value: 1056 },
        { text: "REF OUT 3 - Accepted", value: 1057 },
      ],
      date: [
        format(new Date(), "yyyy-MM-dd"),
        format(new Date(), "yyyy-MM-dd"),
      ],
      dateMenu: false,
      caseDialog: false,
      closeCaseDialog: false,
      yesNoOptions: ["", "Yes", "No"],
      // Can't get cache to remove cases so will remove in memory
      deletedCases: [],
    };
  },
  computed: {
    ...mapGetters(["currentUser", "startDate", "endDate"]),
    filteredCases() {
      if (!this.referredOutCases) return [];
      let items = this.referredOutCases;
      if (this.statusFilter !== "All") {
        items = filter(items, (c) => {
          return c.status === this.statusFilter;
        });
      }
      if (this.typeFilter !== "All") {
        items = filter(items, (c) => {
          return c.type === this.typeFilter;
        });
      }
      if (this.firmFilter !== "All") {
        items = filter(items, (c) => {
          return c.referredOutFirm === this.firmFilter;
        });
      }
      if (this.deletedCases.length > 0) {
        items = filter(items, (c) => {
          return !this.deletedCases.includes(c.caseId);
        });
      }
      return items;
    },
    statusOptionsForFilter() {
      if (!this.referredOutCases) return [];
      let grouped = groupBy(this.referredOutCases, (c) => {
        return c.status;
      });
      let items = Object.keys(grouped).sort();
      items.unshift("All");
      return items;
    },
    typeOptions() {
      if (!this.referredOutCases) return [];
      let grouped = groupBy(this.referredOutCases, (c) => {
        return c.type;
      });
      let items = Object.keys(grouped).sort();
      items.unshift("All");
      return items;
    },
    firmOptions() {
      if (!this.referredOutCases) return [];
      let grouped = groupBy(this.referredOutCases, (c) => {
        return c.referredOutFirm;
      });
      let items = Object.keys(grouped).sort();
      items.unshift("All");
      return items;
    },
    sortBy() {
      let saved = JSON.parse(localStorage.getItem("RyBase.referredOutSortBy"));
      if (!saved) return ["type"];
      return saved;
    },
    sortDesc() {
      let saved = JSON.parse(
        localStorage.getItem("RyBase.referredOutSortDesc")
      );
      if (!saved) return [false];
      return saved;
    },
  },
  apollo: {
    referredOutCases: {
      query: INTAKE_REFERRED_OUT_CASES,
    },
  },
  methods: {
    updateSearch: debounce(function(e) {
      this.search = e.target.value;
    }, 500),
    refreshQuery() {
      this.$apollo.queries.referredOutCases.refetch();
      return false;
    },
    getCurrentItems() {
      let items = get(
        this.$refs,
        "intakeReferredOutTable.$children[0].filteredItems"
      );
      this.totalReferredOut = items ? items.length : 0;
      return false;
    },
    updateSort(type, event) {
      if (type === "by") {
        localStorage.setItem("RyBase.referredOutSortBy", JSON.stringify(event));
      } else if (type === "order") {
        localStorage.setItem(
          "RyBase.referredOutSortDesc",
          JSON.stringify(event)
        );
      }
    },
    openCaseDialog(lead) {
      this.selectedCaseId = lead.caseId;
      this.selectedLead = lead;
      this.caseDialog = true;
      return false;
    },
    saveOffice(officeId) {
      if (!officeId) return false;
      this.$apollo
        .mutate({
          mutation: gql`
            mutation InsertSaOffice($office: OfficeInput!) {
              insertSaOffice(office: $office) {
                officeId
              }
            }
          `,
          variables: {
            office: {
              case_id: this.selectedCaseId,
              office_id: parseInt(officeId, 10),
            },
          },
          update: (store, { data: { insertSaOffice } }) => {
            let officeData = {
              office: this.getText(insertSaOffice.officeId, this.officeOptions),
            };
            this.updateCache(store, officeData, "office");
          },
        })
        .then(() => {
          this.$refs["intakeReferredOutTable"].$el.click();
        })
        .catch((error) => {
          console.error(error);
        });
      return false;
    },
    saveStatus(val, type, options, statusType) {
      if (!val) return false;
      this.$apollo
        .mutate({
          mutation: gql`
            mutation UpdateCaseStatus($status: CaseStatus!) {
              upsertSaStatus(status: $status) {
                statusId
              }
            }
          `,
          variables: {
            status: {
              case_id: this.selectedCaseId,
              status_id: val,
              type: statusType,
            },
          },
          update: (store, { data: { upsertSaStatus } }) => {
            let data = {
              [type]: this.getText(upsertSaStatus.statusId, options),
            };
            this.updateCache(store, data, type);
          },
        })
        .then(() => {
          this.$refs["intakeReferredOutTable"].$el.click();
        })
        .catch((error) => {
          console.error(error);
        });
      return false;
    },
    saveInfo(data, field) {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation UpsertCaseInfo($caseInfo: CaseInfoInput!) {
              upsertCaseInfo(caseInfo: $caseInfo) {
                id
                caseId
                referralOpportunity
                referred
                signupType
                handledByBackup
                leadType
              }
            }
          `,
          variables: {
            caseInfo: {
              case_id: this.selectedCaseId,
              [field]: data,
            },
          },
          update: (store, { data: { upsertCaseInfo } }) => {
            this.updateInfoCache(store, upsertCaseInfo, field);
          },
        })
        .then(() => {
          this.$refs["intakeLeadsTable"].$el.click();
        })
        .catch((error) => {
          console.error(error);
        });
      return false;
    },
    updateCache(store, insertData, field) {
      let caseId = this.selectedCaseId;
      const data = store.readQuery({
        query: INTAKE_REFERRED_OUT_CASES,
      });
      let index = findIndex(data.referredOutCases, (c) => {
        return c.caseId === caseId;
      });
      data.referredOutCases[index][field] = insertData[field];
      store.writeQuery({
        query: INTAKE_REFERRED_OUT_CASES,
        data,
      });
    },
    updateQuery(store, caseId) {
      this.deletedCases.push(caseId);
    },
    updateInfoCache(store, insertData, field) {
      let caseId = this.selectedCaseId;
      const data = store.readQuery({
        query: INTAKE_REFERRED_OUT_CASES,
      });
      let index = findIndex(data.referredOutCases, (c) => {
        return c.caseId === caseId;
      });
      data.referredOutCases[index]["info"][field] = insertData[field];
      store.writeQuery({
        query: INTAKE_REFERRED_OUT_CASES,
        data,
      });
    },
    getValue(val, options) {
      let values = groupBy(options, "text");
      if (!values[val]) return null;
      return values[val][0].value;
    },
    getText(val, options) {
      let values = groupBy(options, "value");
      return values[val][0].text;
    },
    openEditDialog(caseId) {
      this.selectedCaseId = caseId;
      return false;
    },
    closeEditDialog() {
      this.selectedCaseId = "";
      return false;
    },
    closeLeadDialog() {
      this.leadDialog = false;
    },
    openCloseCaseDialog(caseId) {
      this.selectedCaseId = caseId;
      let index = findIndex(this.referredOutCases, (c) => {
        return c.caseId === caseId;
      });
      this.selectedLead = this.referredOutCases[index];
      this.closeCaseDialog = true;
      return false;
    },
    closeDialog() {
      this.closeCaseDialog = false;
    },
  },
};
</script>

<style scoped></style>
