<template>
  <div class="loading-container">
    <div class="la-ball-clip-rotate-pulse la-2x">
      <div />
      <div />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.loading-container {
  margin: 0 auto;
  padding: 50px 0px 50px 0px;
  width: 64px;
  height: 64px;
}

.la-ball-clip-rotate-pulse {
  color: #000;
}
</style>
