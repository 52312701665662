<template>
  <v-card>
    <v-toolbar flat>
      <v-toolbar-title>Teams</v-toolbar-title>
      <v-spacer></v-spacer>
      <div class="mr-5 pt-5">
        <v-switch class="" label="Show All" v-model="showAll"></v-switch>
      </div>
      <v-btn
        @click.native.stop="dialog = true"
        color="primary"
        dark
        class="mb-2"
        >New Team</v-btn
      >
    </v-toolbar>
    <v-row>
      <v-col cols="12">
        <v-dialog v-model="dialog" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.name"
                      label="Team Name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-switch
                      v-model="editedItem.active"
                      label="Active"
                    ></v-switch>
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="editedItem.userIds"
                      label="Users"
                      :items="userOptions"
                      chips
                      multiple
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click.native="close"
                >Cancel</v-btn
              >
              <v-btn color="blue darken-1" text @click.native="save"
                >Save</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-data-table
          :footer-props="{
            'items-per-page-options': [-1]
          }"
          :headers="headers"
          hide-default-footer
          :items="teams"
          :loading="$apollo.loading"
          :sort-by="['name']"
          :sort-desc="[false]"
        >
          <template v-slot:body="{ items }">
            <tbody>
              <tr v-for="item in items" :key="item.id">
                <td>{{ item.name }}</td>
                <td>{{ item.users }}</td>
                <td>
                  <v-simple-checkbox
                    color="primary"
                    :value="item.active"
                    :ripple="false"
                  />
                </td>
                <td class="justify-center layout px-0">
                  <v-icon
                    small
                    class="mr-2"
                    color="teal"
                    @click="editItem(item)"
                    >mdi-account-edit</v-icon
                  >
                  <v-icon small color="pink" @click="deleteItem(item)"
                    >mdi-delete</v-icon
                  >
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import gql from "graphql-tag";
import get from "lodash/get";
import sortBy from "lodash/sortBy";
import filter from "lodash/filter";

export default {
  data() {
    return {
      dialog: false,
      headers: [
        { text: "Name", value: "name" },
        { text: "Users", value: "users" },
        { text: "Active", value: "active" },
        { text: "Actions", align: "center", value: "name", sortable: false }
      ],
      editedIndex: -1,
      editedItem: {
        name: "",
        active: true,
        userIds: []
      },
      defaultItem: {
        name: "",
        active: true,
        userIds: []
      },
      userOptions: [],
      showAll: false
    };
  },
  computed: {
    teams() {
      let teams = get(this.organization, "teams");
      if (teams && teams.length > 0) {
        let mappedTeams = teams.map(t => {
          let userIds = t.users.map(u => {
            return u.id;
          });
          return {
            id: t.id,
            name: t.name,
            active: t.active,
            users: t.users
              .map(u => {
                return `${u.firstName} ${u.lastName}`;
              })
              .join(", "),
            userIds
          };
        });
        if (this.showAll) {
          return mappedTeams;
        } else {
          return filter(mappedTeams, t => t.active);
        }
      } else {
        return [];
      }
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Team" : "Edit Team";
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    "organization.users"(val) {
      this.userOptions = sortBy(
        val.map(u => {
          return { text: `${u.firstName} ${u.lastName}`, value: u.id };
        }),
        [item => item.text]
      );
    }
  },
  apollo: {
    organization: {
      query: gql`
        query($id: ID!) {
          organization(id: $id) {
            id
            name
            users {
              id
              firstName
              lastName
            }
            teams {
              id
              name
              active
              users {
                id
                firstName
                lastName
                email
                roles {
                  name
                }
              }
            }
          }
        }
      `,
      variables() {
        return {
          id: this.$route.params.id
        };
      }
    }
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.teams.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem() {
      confirm("Are you sure you want to delete this team?");
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      delete this.editedItem.users;
      console.log(this.editedItem);
      this.$apollo
        .mutate({
          mutation: gql`
            mutation($team: UpsertTeamInput) {
              upsertTeam(team: $team) {
                id
                name
                active
              }
            }
          `,
          variables: {
            team: this.editedItem
          }
        })
        .then(({ data }) => {
          console.log(data);
          this.dialog = false;
          setTimeout(() => {
            this.editedItem = Object.assign({}, this.defaultItem);
            this.editedIndex = -1;
          });
          this.$apollo.queries.organization.refetch();
        })
        .catch(error => {
          console.error(error);
        });
    }
  }
};
</script>

<style scoped></style>
