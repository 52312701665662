<template>
  <v-card>
    <v-toolbar flat>
      <v-toolbar-title>Offices</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        @click.native.stop="dialog = true"
        color="primary"
        dark
        class="mb-2"
        >New Office</v-btn
      >
    </v-toolbar>
    <v-row>
      <v-col cols="12">
        <v-dialog v-model="dialog" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.name"
                      label="Office Name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="editedItem.userIds"
                      label="Users"
                      :items="userOptions"
                      chips
                      multiple
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click.native="close"
                >Cancel</v-btn
              >
              <v-btn color="blue darken-1" text @click.native="save"
                >Save</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-data-table
          :footer-props="{
            'items-per-page-options': [-1]
          }"
          :headers="headers"
          hide-default-footer
          :items="offices"
          :loading="$apollo.loading"
          :sort-by="['name']"
          :sort-desc="[false]"
        >
          <template v-slot:body="{ items }">
            <tbody>
              <tr v-for="item in items" :key="item.id">
                <td>{{ item.name }}</td>
                <td>{{ item.users }}</td>
                <td class="justify-center layout px-0">
                  <v-icon
                    small
                    class="mr-2"
                    color="teal"
                    @click="editItem(item)"
                    >mdi-account-edit</v-icon
                  >
                  <v-icon small color="pink" @click="deleteItem(item)"
                    >mdi-delete</v-icon
                  >
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import gql from "graphql-tag";
import get from "lodash/get";
import sortBy from "lodash/sortBy";

export default {
  data() {
    return {
      dialog: false,
      headers: [
        { text: "Name", value: "name" },
        { text: "Users", value: "users" },
        { text: "Actions", align: "center", value: "name", sortable: false }
      ],
      editedIndex: -1,
      editedItem: {
        name: "",
        userIds: []
      },
      defaultItem: {
        name: "",
        userIds: []
      },
      userOptions: []
    };
  },
  computed: {
    offices() {
      let offices = get(this.organization, "offices");
      if (offices && offices.length > 0) {
        let mappedOffices = offices.map(o => {
          let userIds = o.users.map(u => {
            return u.id;
          });
          return {
            id: o.id,
            name: o.name,
            users: o.users
              .map(u => {
                return `${u.firstName} ${u.lastName}`;
              })
              .join(", "),
            userIds
          };
        });
        return mappedOffices;
      } else {
        return [];
      }
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Office" : "Edit Office";
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    "organization.users"(val) {
      this.userOptions = sortBy(
        val.map(u => {
          return { text: `${u.firstName} ${u.lastName}`, value: u.id };
        }),
        [item => item.text]
      );
    }
  },
  apollo: {
    organization: {
      query: gql`
        query($id: ID!) {
          organization(id: $id) {
            id
            name
            users {
              id
              firstName
              lastName
            }
            offices {
              id
              name
              users {
                id
                firstName
                lastName
                email
                roles {
                  name
                }
              }
            }
          }
        }
      `,
      variables() {
        return {
          id: this.$route.params.id
        };
      }
    }
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.offices.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem() {
      confirm("Are you sure you want to delete this office?");
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      delete this.editedItem.users;
      console.log(this.editedItem);
      this.$apollo
        .mutate({
          mutation: gql`
            mutation($office: UpsertOfficeInput) {
              upsertOffice(office: $office) {
                id
              }
            }
          `,
          variables: {
            office: this.editedItem
          }
        })
        .then(({ data }) => {
          console.log(data);
          this.dialog = false;
          setTimeout(() => {
            this.editedItem = Object.assign({}, this.defaultItem);
            this.editedIndex = -1;
          });
          this.$apollo.queries.organization.refetch();
        })
        .catch(error => {
          console.error(error);
        });
    }
  }
};
</script>

<style scoped></style>
