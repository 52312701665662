<template>
  <v-col sm="6" md="3">
    <v-card>
      <v-progress-linear
        color="primary"
        indeterminate
        v-if="$apollo.loading"
      ></v-progress-linear>
      <v-toolbar flat dense>
        <span class="text-subtitle-1">Demands in Period</span>
      </v-toolbar>
      <div
        class="stat-data"
        v-if="totalDemandsInPeriod && totalDemandsInPeriodByOffice"
      >
        {{ totalDemandsInPeriod }}
        <span
          class="stat-data-desc"
          v-for="(item, index) in totalDemandsInPeriodByOffice"
          :key="index"
        >
          {{ formatOffice(item.office) }} ({{ item.count }})
        </span>
      </div>
    </v-card>
  </v-col>
</template>

<script>
import gql from "graphql-tag";
import { mapGetters } from "vuex";
import { formatOffice } from "../util";

export default {
  apollo: {
    totalDemandsInPeriod: {
      query: gql`
        query totalDemandsInPeriod($startDate: String!, $endDate: String!) {
          totalDemandsInPeriod(startDate: $startDate, endDate: $endDate)
        }
      `,
      variables() {
        return {
          startDate: this.startDate,
          endDate: this.endDate
        };
      }
    },
    totalDemandsInPeriodByOffice: {
      query: gql`
        query totalDemandsInPeriodByOffice(
          $startDate: String!
          $endDate: String!
        ) {
          totalDemandsInPeriodByOffice(
            startDate: $startDate
            endDate: $endDate
          ) {
            office
            count
          }
        }
      `,
      variables() {
        return {
          startDate: this.startDate,
          endDate: this.endDate
        };
      }
    }
  },
  computed: {
    ...mapGetters(["currentUser", "startDate", "endDate"]),
    demandsInPeriodByOffice() {
      if (!this.organization) return 0;
      return this.organization.demandsInPeriodByOffice;
    }
  },
  methods: {
    formatOffice(office) {
      return formatOffice(office);
    }
  }
};
</script>

<style scoped lang="scss"></style>
