<template>
  <v-card class="note-container">
    <v-text-field
      hide-details
      solo
      text
      placeholder="Note search..."
      v-model="noteFilter"
    />
    <v-progress-linear
      v-if="$apollo.loading"
      indeterminate
      color="primary"
    ></v-progress-linear>
    <!-- <div class="flex xs12 note" v-for="(item, index) in notes" :key="index"> -->
    <div
      class="mb-1"
      style="width: 100%;"
      v-for="(item, index) in notes"
      :key="index"
    >
      <v-container fluid style="padding-top: 0px;">
        <v-row class="note-header">
          <v-col cols="2" class="caption">{{ item.createdAt }}</v-col>
          <v-col cols="3" class="caption">{{ item.title }}</v-col>
          <v-col cols="4" class="caption">{{ item.creatorName }}</v-col>
          <v-spacer></v-spacer>
          <v-col cols="2" class="caption"
            >{{ index + 1 }}/{{ notes.length }}</v-col
          >
        </v-row>
        <div class="pt-3 note-text">
          <span v-html="sanitize(item.plainText)"></span>
        </div>
      </v-container>
    </div>

    <!-- <div class="layout wrap px-2 py-1 note-meta">
          <div class="flex xs3 lg2 note-created-at">
            <div class="header">{{ item.createdAt }}</div>
          </div>
          <div class="flex xs3 lg4 note-title">
            <div class="mono">{{ item.title }}</div>
          </div>
          <div class="flex xs3 lg4 note-creator">
            <div class="mono">{{ item.creatorName }}</div>
          </div>
          <div class="flex xs3 lg2 note-number">
            <div>{{ index + 1 }}/{{ notes.length }}</div>
          </div>
        </div>
        <div class="layout wrap pa-2">
          <div class="flex xs12 note-text">
            <span v-html="sanitize(item.plainText)"></span>
          </div>
        </div> -->
    <!-- </div> -->
  </v-card>
</template>

<script>
import gql from "graphql-tag";
import reverse from "lodash/reverse";
// import filter from "lodash/filter";
import sortBy from "lodash/sortBy";
import sanitizeHtml from "sanitize-html";
import debounce from "lodash/debounce";

export default {
  data() {
    return {
      currentCase: {},
      noteFilter: ""
    };
  },
  props: ["caseId"],
  computed: {
    notes() {
      if (this.currentCase) {
        let notes = reverse(
          sortBy(this.currentCase.notes, [
            n => {
              return n.createdAt;
            }
          ])
        );
        // if (this.noteFilter) {
        //   return filter(notes, n => {
        //     let plainText = this.sanitize(n.plainText);
        //     return (
        //       plainText.toLowerCase().indexOf(this.noteFilter.toLowerCase()) !==
        //         -1 ||
        //       n.title.toLowerCase().indexOf(this.noteFilter.toLowerCase()) !==
        //         -1
        //     );
        //   });
        // }
        return notes;
      } else {
        return [];
      }
    }
  },
  methods: {
    sanitize(html) {
      return sanitizeHtml(html).replace(/<p><\/p>/gi, "");
    },
    filter: debounce(function() {
      console.log("Boom");
    }, 500)
  },
  apollo: {
    currentCase: {
      query: gql`
        query($caseId: ID!) {
          currentCase: case(caseId: $caseId) {
            notes {
              noteId
              createdAt
              modifiedAt
              title
              description
              plainText
              creatorId
              creatorName
            }
          }
        }
      `,
      variables() {
        return {
          caseId: parseInt(this.caseId, 10)
        };
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.note-container {
  /* margin-top: -28px; */
  height: 85vh;
  overflow-y: scroll;
  // border: 1px solid #eee;
  background: #fff;
  /* border-bottom: 1px solid #eee; */
}
.note-header {
  background: #eee;
}
.note-filter {
  background: #fff;
  border: 1px solid #eee;
}
.note {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}
.note-text {
  font-size: 0.8em !important;
}
.note-text > p {
  margin-block-end: 3px;
  margin-bottom: 3px;
}
.note-meta {
  font-size: 0.85em;
  background: #efefef;
}
</style>
