<template>
  <div>
    <v-toolbar dense flat color="grey lighten-3" class="scroll">
      <v-btn v-for="(link, i) in links" :key="i" text exact :to="link.path">{{
        link.name
      }}</v-btn>
      <v-spacer></v-spacer>
      <div class="user-selection-container" v-if="isTeamPage()">
        <v-select
          class="mr-3"
          :items="teams"
          v-model="selectedTeam"
          item-value="id"
          item-text="name"
          v-if="isAdminOrAttorney()"
        ></v-select>
      </div>
      <div class="user-selection-container">
        <v-autocomplete
          v-model="imitatedUser"
          :items="users"
          item-value="id"
          item-text="fullName"
          v-if="isAdminOrAttorney()"
        >
        </v-autocomplete>
      </div>
    </v-toolbar>
    <slot></slot>
  </div>
</template>

<script>
import get from "lodash/get";
import sortBy from "lodash/sortBy";
import filter from "lodash/filter";
import { mapGetters } from "vuex";
import { hasRole } from "../util";

export default {
  computed: {
    ...mapGetters(["currentUser", "imitatedUser"]),
    users() {
      if (!this.currentUser) return [];
      let users = filter(this.currentUser.organization.users, (u) => u.active);
      users = users.map((u) => {
        return Object.assign(u, { fullName: `${u.firstName} ${u.lastName}` });
      });
      return sortBy(users, ["firstName", "lastName"]);
    },
    teams() {
      if (!this.currentUser) return [];
      let teams = filter(this.currentUser.organization.teams, (u) => u.active);
      return sortBy(teams, ["name"]);
    },
    imitatedUser: {
      get() {
        return this.$store.getters.imitatedUser;
      },
      set(value) {
        this.$store.dispatch("setImitatedUser", value);
      },
    },
    selectedTeam: {
      get() {
        return this.$store.getters.selectedTeam;
      },
      set(value) {
        this.$store.dispatch("setSelectedTeam", value);
      },
    },
  },
  props: ["links"],
  mounted() {
    if (!this.imitatedUser) {
      let imitatedUser = get(this.currentUser, "id");
      this.$store.dispatch("setImitatedUser", imitatedUser);
    }
    if (!this.selectedTeam) {
      let selectedTeam = get(this.currentUser, "teams[0].id");
      this.$store.dispatch("setSelectedTeam", selectedTeam);
    }
  },
  methods: {
    isTeamPage() {
      return (
        this.$route.name === "inventory-team" ||
        this.$route.name === "fees-list" ||
        this.$route.name === "fees-settlements" ||
        this.$route.name === "org-fees-list" ||
        this.$route.name === "org-fees-settlements" ||
        this.$route.name === "demands-list" ||
        this.$route.name === "demands-overdue" ||
        this.$route.name === "demands-index" ||
        this.$route.name === "cm-scorecard" ||
        this.$route.name === "dash-cm-scorecard"
      );
    },
    isAdminOrAttorney() {
      if (get(this.currentUser, "roles")) {
        return hasRole(this.currentUser, "admin");
        // || hasRole(this.currentUser, "attorney")
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped>
.scroll {
  overflow-x: auto;
  overflow-y: hidden;
}
.user-selection-container {
  width: 200px;
  height: 48px;
}
</style>
