<template>
  <v-col sm="6" md="3">
    <v-card>
      <v-progress-linear
        color="primary"
        indeterminate
        v-if="$apollo.loading"
      ></v-progress-linear>
      <v-toolbar flat dense>
        <span class="text-subtitle-1">Inventory</span>
      </v-toolbar>
      <div class="stat-data">
        <div>
          <v-icon color="red" v-if="totalDifference < 0"
            >mdi-arrow-down-thick</v-icon
          >
          <v-icon color="green" v-if="totalDifference > 0"
            >mdi-arrow-up-thick</v-icon
          >
          {{ totalDifference }}
        </div>
        <span class="stat-data-desc">
          {{ totalRetained }} Retained / {{ totalClosed }} Closed
        </span>
      </div>
    </v-card>
  </v-col>
</template>

<script>
import gql from "graphql-tag";
import { mapGetters } from "vuex";

export default {
  apollo: {
    comparison: {
      query: gql`
        query OrgHomeStatsInventory($startDate: String!, $endDate: String!) {
          comparison: totalInventoryComparisonInPeriod(
            startDate: $startDate
            endDate: $endDate
          ) {
            totalClosedCases
            totalRetainedCases
          }
        }
      `,
      variables() {
        return {
          startDate: this.startDate,
          endDate: this.endDate
        };
      },
      skip() {
        return this.startDate === null || this.endDate === null;
      }
    }
  },
  computed: {
    ...mapGetters(["currentUser", "startDate", "endDate"]),
    totalRetained() {
      if (!this.comparison) return 0;
      return this.comparison.totalRetainedCases;
    },
    totalClosed() {
      if (!this.comparison) return 0;
      return this.comparison.totalClosedCases;
    },
    totalDifference() {
      return this.totalRetained - this.totalClosed;
    }
  }
};
</script>

<style scoped lang="scss"></style>
