<template>
  <v-card>
    <v-toolbar flat dense>
      <span class="text-subtitle-1">Referral Sources in Period</span>
      <v-spacer />
      <div class="pt-5 mr-5">
        <v-autocomplete
          :items="openedInPeriodOptions"
          v-model="openedInPeriod"
        ></v-autocomplete>
      </div>
      <div class="pt-5">
        <v-autocomplete
          :items="officeOptions"
          v-model="selectedOffice"
        ></v-autocomplete>
      </div>
    </v-toolbar>
    <div>
      <highcharts ref="chart" :options="chartOptions" />
    </div>
  </v-card>
</template>

<script>
import gql from "graphql-tag";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      selectedOffice: "null",
      officeOptions: [
        { text: "All Markets", value: "null" },
        { text: "(2 - WA Main) Spokane Valley Office", value: "8" },
        { text: "(1 - UT Main) Sandy Office", value: "5" },
        { text: "(3 - ID Main) Meridian Office", value: "7" },
        { text: "Sea-Tac", value: "9" },
        { text: "Other", value: "10" },
        { text: "Wyoming", value: "11" },
        { text: "Montana", value: "12" },
        { text: "Colorado", value: "13" },
        { text: "Oregon", value: "14" },
      ],
      openedInPeriod: "Yes",
      openedInPeriodOptions: [
        { text: "Opened In Period", value: "Yes" },
        { text: "Retained In Period", value: "No" },
      ],
    };
  },
  mounted() {
    setTimeout(() => {
      if (!this.$refs.chart) return;
      this.$refs.chart.chart.reflow();
    }, 200);
  },
  apollo: {
    referralSourcesInPeriod: {
      query: gql`
        query IntakeReferrals(
          $startDate: String!
          $endDate: String!
          $office: String!
          $openedInPeriod: String!
        ) {
          referralSourcesInPeriod(
            startDate: $startDate
            endDate: $endDate
            office: $office
            openedInPeriod: $openedInPeriod
          ) {
            y
            name
          }
        }
      `,
      variables() {
        return {
          startDate: this.startDate,
          endDate: this.endDate,
          office: this.selectedOffice,
          openedInPeriod: this.openedInPeriod,
        };
      },
      skip() {
        return this.startDate === null || this.endDate === null;
      },
    },
  },
  computed: {
    ...mapGetters(["startDate", "endDate"]),
    chartOptions() {
      return {
        credits: {
          enabled: false,
        },
        chart: {
          type: "column",
          height: 200,
        },
        title: {
          text: "",
        },
        yAxis: {
          min: 0,
          title: {
            text: "",
          },
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          headerFormat: "",
          pointFormat: "{point.name}: {point.y}",
        },
        xAxis: {
          type: "category",
        },
        series: [
          {
            name: "Referral Sources",
            data: this.referralSourcesInPeriod,
          },
        ],
      };
    },
  },
};
</script>

<style scoped></style>
