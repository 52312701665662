<template>
  <div>
    <highcharts ref="chart" :options="chartOptions" />
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  mounted() {
    setTimeout(() => {
      if (!this.$refs.chart) return;
      this.$refs.chart.chart.reflow();
    }, 200);
  },
  computed: {
    chartOptions() {
      return Object.assign({}, this.options, {
        credits: {
          enabled: false
        },
        chart: {
          height: 200,
          type: "line"
        },
        title: {
          text: ""
        },
        legend: {
          enabled: true
        },
        xAxis: {
          type: "datetime"
        },
        yAxis: {
          min: 0,
          title: {
            text: ""
          }
        },
        plotOptions: {
          line: {
            dataLables: {
              enabled: true
            }
          }
        }
      });
    }
  }
};
</script>

<style scoped></style>
