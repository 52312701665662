<template>
  <dash-menu :links="links">
    <router-view></router-view>
  </dash-menu>
</template>

<script>
import DashMenu from "./DashMenu";
import gql from "graphql-tag";
import { hasRole } from "../util";

export default {
  data() {
    return {
      user: {
        teams: [],
      },
      selectedTeam: null,
    };
  },
  components: {
    DashMenu,
  },
  computed: {
    links() {
      let id = this.$route.params.id;
      let links = [
        { name: "All Cases", path: `/dash/${id}/inventory` },
        {
          name: "Client Contact",
          path: `/dash/${id}/inventory/client-contact`,
        },
        {
          name: "Deadlines",
          path: `/dash/${id}/inventory/deadlines`,
        },
        {
          name: "Deadline Review",
          path: `/dash/${id}/inventory/deadline-review`,
        },
        {
          name: "File Review",
          path: `/dash/${id}/inventory/file-review`,
        },
        { name: "Tasks", path: `/dash/${id}/inventory/tasks` },
        { name: "Team", path: `/dash/${id}/inventory/team` },
        { name: "Scorecard", path: `/dash/${id}/inventory/scorecard` },
        { name: "Intake", path: `/dash/${id}/inventory/intake-rotation` },
      ];
      if (this.user && hasRole(this.user, "admin")) {
        links.push({
          name: "Watched Cases",
          path: `/dash/${id}/inventory/watched-cases`,
        });
      }
      return links;
    },
  },
  apollo: {
    user: {
      query: gql`
        query($id: ID!) {
          user(id: $id) {
            id
            first_name
            last_name
            watchedCases {
              caseId
            }
            roles {
              id
              name
            }
            teams {
              id
              name
            }
          }
        }
      `,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
    },
  },
};
</script>

<style scoped>
td.status {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  width: 150px;
}
table.table > tbody {
  max-height: 10vh !important;
  font-size: 10px !important;
}
table.table > tbody > td {
  white-space: nowrap !important;
  font-size: 10px !important;
}
.inventory-title {
  font-size: 36px;
}

.inventory-number {
  font-size: 24px;
}
</style>
