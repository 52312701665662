<template>
  <div>
    <div v-if="$apollo.loading">
      <v-progress-linear indeterminate color="primary" />
    </div>
    <div v-if="!$apollo.loading" class="chart-container">
      <highcharts ref="chart" :options="callsByDayChart" />
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["startDate", "endDate"]),
    callsByDayChart() {
      if (!this.callsBySource) return {};
      let data = this.callsBySource.map(cbd => {
        return {
          name: cbd.key,
          y: cbd.totalCalls
        };
      });
      let series = [
        {
          name: "Calls By Source",
          colorByPoint: true,
          data
        }
      ];
      let options = {};
      return Object.assign({}, options, {
        series,
        credits: {
          enabled: false
        },
        chart: {
          height: 200,
          type: "pie"
        },
        title: {
          text: "CallRail Calls by Source"
        },
        legend: {
          enabled: true
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              enabled: true,
              format: "<b>{point.name}</b>: {point.percentage:.1f} %"
            }
          }
        }
      });
    }
  },
  apollo: {
    callsBySource: {
      query: gql`
        query OrgMarketingCallsBySource(
          $startDate: String!
          $endDate: String!
        ) {
          callsBySource: callrailCallsBySource(
            startDate: $startDate
            endDate: $endDate
          ) {
            key
            totalCalls
          }
        }
      `,
      variables() {
        return {
          startDate: this.startDate,
          endDate: this.endDate
        };
      },
      skip() {
        return this.startDate === null || this.endDate === null;
      }
    }
  }
};
</script>

<style scoped>
.chart-container {
  height: 220px;
}
</style>
