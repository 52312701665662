<template>
  <dash-menu :links="links">
    <router-view></router-view>
  </dash-menu>
</template>

<script>
import DashMenu from "./DashMenu";
import { mapGetters } from "vuex";
import gql from "graphql-tag";
import { hasRole } from "../util";
import filter from "lodash/filter";

export default {
  data() {
    return {
      user: {
        teams: []
      },
      selectedTeam: null
    };
  },
  components: {
    DashMenu
  },
  computed: {
    ...mapGetters(["currentUser"]),
    links() {
      let id = this.currentUser
        ? this.currentUser.id.toString()
        : this.$route.params.id;
      let isMgmt =
        hasRole(this.currentUser, "intake-management") ||
        hasRole(this.currentUser, "admin");
      let routes = [
        {
          name: "Assignment",
          path: `/dash/${id}/intake`
        },
        {
          name: "Leads",
          path: `/dash/${id}/intake/leads`
        },
        {
          name: "Follow Up",
          path: `/dash/${id}/intake/follow-up`
        },
        {
          name: "Investigations",
          path: `/dash/${id}/intake/investigations`
        },
        // {
        //   name: "Calendar",
        //   path: `/dash/${id}/intake/calendar`
        // },
        {
          name: "New Cases",
          path: `/dash/${id}/intake/new-cases`
        },
        {
          name: "Referrals",
          path: `/dash/${id}/intake/referrals`
        },
        {
          name: "Referrals Map",
          path: `/dash/${id}/intake/referrals-map`
        },
        {
          name: "New Providers",
          path: `/dash/${id}/intake/new-providers`,
          auth: true
        },
        {
          name: "Stats",
          path: `/dash/${id}/intake/stats`
        },
        {
          name: "PPC Report",
          path: `/dash/${id}/intake/ppc-report`
        },
        {
          name: "Closed Cases",
          path: `/dash/${id}/intake/closed-cases`,
          auth: true
        }
      ];
      return filter(routes, r => {
        if (r.auth) return isMgmt;
        return true;
      });
    }
  },
  apollo: {
    user: {
      query: gql`
        query($id: ID!) {
          user(id: $id) {
            id
            first_name
            last_name
            watchedCases {
              caseId
            }
            teams {
              id
              name
            }
          }
        }
      `,
      variables() {
        let id = this.currentUser
          ? this.currentUser.id.toString()
          : this.$route.params.id;
        return {
          id
        };
      }
    }
  }
};
</script>

<style scoped>
td.status {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  width: 150px;
}
table.table > tbody {
  max-height: 10vh !important;
  font-size: 10px !important;
}
table.table > tbody > td {
  white-space: nowrap !important;
  font-size: 10px !important;
}
.inventory-title {
  font-size: 36px;
}

.inventory-number {
  font-size: 24px;
}
</style>
