<template>
  <div>
    <highcharts ref="chart" :options="chartOptions" />
  </div>
</template>

<script>
import gql from "graphql-tag";
import groupBy from "lodash/groupBy";

export default {
  data() {
    return {
      leadsWanted: []
    };
  },
  mounted() {
    setTimeout(() => {
      if (!this.$refs.chart) return;
      this.$refs.chart.chart.reflow();
    }, 200);
  },
  apollo: {
    leadsWanted: {
      query: gql`
        query OrgLeadsWantedByMonth {
          leadsWanted: leadsWantedByMonth {
            wanted
            leads
            retained
            month
            year
            yearMonth
          }
        }
      `
    }
  },
  methods: {
    buildLeadsWantedByMonth() {
      if (!this.leadsWanted) return [];
      let dates = this.leadsWanted.reduce((prev, cur) => {
        let date = Date.UTC(cur.year, cur.month - 1, 1);
        if (prev.indexOf(date) === -1) {
          return prev.concat(date);
        } else {
          return prev;
        }
      }, []);
      let series = ["leads", "wanted", "retained"].map(s => {
        let prepDate = this.leadsWanted.map(d => {
          return Object.assign({}, d, {
            date: Date.UTC(d.year, d.month - 1, 1)
          });
        });
        let groupedByDate = groupBy(prepDate, d => {
          return d.date;
        });
        let dd = dates.reduce((prev, cur) => {
          if (groupedByDate[cur]) {
            return prev.concat([[cur, groupedByDate[cur][0][s]]]);
          } else {
            return prev.concat([[cur, 0]]);
          }
        }, []);
        return {
          name: s,
          data: dd
        };
      });
      return series;
    }
  },
  computed: {
    chartOptions() {
      let options = {};
      return Object.assign({}, options, {
        series: this.buildLeadsWantedByMonth(),
        credits: {
          enabled: false
        },
        chart: {
          height: 200,
          type: "line"
        },
        title: {
          text: ""
        },
        legend: {
          enabled: true
        },
        xAxis: {
          type: "datetime"
        },
        yAxis: {
          min: 0,
          title: {
            text: ""
          }
        },
        plotOptions: {
          line: {
            dataLables: {
              enabled: true
            }
          }
        }
      });
    }
  }
};
</script>

<style scoped></style>
