<template>
  <v-card>
    <v-alert :type="alertType" v-if="alert">{{ alertMessage }}</v-alert>
    <v-toolbar flat>
      <v-toolbar-title class="headline">Contact Activity</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click.native.stop="eventFormDialog = true"
        >New Event</v-btn
      >
    </v-toolbar>
    <v-container class="py-0" fluid>
      <v-row align-content="space-around">
        <v-spacer></v-spacer>
        <v-col cols="8" sm="4">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            hide-details
            single-line
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
    <v-data-table
      :footer-props="{
        'items-per-page-options': [-1]
      }"
      :headers="headers"
      hide-default-footer
      :items="contactEvents"
      item-key="id"
      :loading="$apollo.loading"
      :search="search"
      :sort-by="['timestamp']"
      :sort-desc="[true]"
    >
      <template v-slot:item.saId="{ item }">
        <a
          class="go-to-case-link mr-2"
          target="_blank"
          :href="
            `https://cs-web.airdesksolutions.com/SA/Edit_Contact.aspx?ID=${item.saId}&CTG=${item.saCtg}`
          "
        >
          {{ item.saId }}
        </a>
      </template>
      <template v-slot:item.timestamp="{ item }">
        {{ formatDate(item) }}
      </template>
      <template v-slot:item.user.firstName="{ item }">
        {{ item.user.firstName + " " + item.user.lastName }}
      </template>
    </v-data-table>
    <v-dialog v-model="eventFormDialog" max-width="800px">
      <v-card>
        <v-container>
          <v-row>
            <v-col cols="6" sm="5">
              <date-picker
                v-model="eventFormDate"
                valueType="timestamp"
                type="datetime"
                format="MMMM D YYYY, h:mm a"
                placeholder="Event Date"
                :minute-step="15"
              ></date-picker>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="6" sm="3">
              <v-autocomplete
                class="pt-0 mt-0"
                v-model="eventFormEventType"
                :items="eventTypeOptions"
                placeholder="Type"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" sm="3">
              <v-autocomplete
                class="pt-0 mt-0"
                v-model="eventFormContact"
                :items="contactOptions"
                placeholder="Contact"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-textarea
                class="pt-0 mt-0"
                label="Comment"
                v-model="eventFormComment"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="2" class="pt-0 pb-0 mr-2">
              <v-btn class="primary" @click="addEvent" :disabled="formDisabled"
                >Add Event</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import gql from "graphql-tag";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import sortBy from "lodash/sortBy";
import format from "date-fns/format";

export default {
  data() {
    return {
      alert: false,
      alertType: false,
      alertMessage: false,
      eventFormComment: "",
      eventFormContact: "",
      eventFormDialog: false,
      eventFormDate: "",
      eventFormDateMenu: false,
      eventFormEventType: "",
      headers: [
        { text: "Date", value: "timestamp", width: "100px" },
        { text: "Type", value: "contactEventType.name", width: "80px" },
        { text: "User", value: "user.firstName", width: "150px" },
        { text: "Contact", value: "contact.name", width: "200px" },
        { text: "Comment", value: "comment" }
      ],
      search: ""
    };
  },
  components: {
    DatePicker
  },
  methods: {
    addEvent() {
      let contactEvent = {
        timestamp: this.eventFormDate.toString(),
        contact_event_type_id: parseInt(this.eventFormEventType, 10),
        contact_id: parseInt(this.eventFormContact, 10),
        comment: this.eventFormComment
      };
      this.$apollo
        .mutate({
          mutation: gql`
            mutation CreateContactEvent($contactEvent: ContactEventInput!) {
              createContactEvent(contactEvent: $contactEvent) {
                id
              }
            }
          `,
          variables: {
            contactEvent
          },
          update: () => {
            this.$apollo.queries.contactEvents.refetch();
          }
        })
        .then(() => {
          this.eventFormDialog = false;
          this.eventFormComment = "";
          this.eventFormContact = "";
          this.eventFormEventType = "";
        })
        .catch(error => {
          console.error(error);
          this.alert = true;
          this.alertType = "error";
          this.alertMessage = error.message;
          setTimeout(() => {
            this.alert = false;
          }, 3000);
        });
      return false;
    },
    formatDate(item) {
      return format(new Date(parseInt(item.timestamp, 10)), "MMM dd, yyyy");
    }
  },
  computed: {
    eventTypeOptions() {
      if (!this.contactEventTypes) return [];
      return sortBy(
        this.contactEventTypes.map(c => {
          return {
            text: c.name,
            value: c.id
          };
        }),
        "text"
      );
    },
    contactOptions() {
      if (!this.contacts) return [];
      return sortBy(
        this.contacts.map(c => {
          return {
            text: c.name,
            value: c.id
          };
        }),
        "text"
      );
    },
    formDisabled() {
      if (!this.eventFormEventType) return true;
      if (!this.eventFormContact) return true;
      if (!this.eventFormDate) return true;
      if (!this.eventFormComment) return true;
      return false;
    }
  },
  apollo: {
    contactEvents: {
      query: gql`
        query CRMActivity {
          contactEvents {
            id
            comment
            timestamp
            contactEventType {
              name
            }
            user {
              firstName
              lastName
            }
            contact {
              name
            }
          }
        }
      `
    },
    contactEventTypes: {
      query: gql`
        query ContactEventTypes {
          contactEventTypes {
            id
            name
          }
        }
      `
    },
    contacts: {
      query: gql`
        query CRMContacts {
          contacts {
            id
            name
          }
        }
      `
    }
  }
};
</script>

<style scoped lang="scss"></style>
