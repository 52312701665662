<template>
  <div id="app">
    <div v-if="loggingIn">
      <loader></loader>
    </div>
    <div v-else>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { USER_BY_TOKEN } from "./graphql/user.gql";
import Loader from "./components/Loader";

export default {
  components: {
    Loader
  },
  computed: {
    loggingIn() {
      return this.$store.state.user.loginStatus === "loggingIn";
    }
  },
  mounted: function() {
    /* 
      When the app loads we check to see if a user token exists. 
      If so, we log the user in. If not, we send the user to the
      login screen
      */
    let token = localStorage.getItem("RyBase.token");
    if (token) {
      this.$store.commit("setLoginStatus", "loggingIn");
      this.$apollo
        .query({
          query: USER_BY_TOKEN,
          variables: {
            token
          }
        })
        .then(({ data: { userByToken } }) => {
          this.$store.commit("setCurrentUser", userByToken);
          this.$store.commit("setLoginStatus", "loggedIn");
        })
        .catch(error => {
          console.error(error);
          localStorage.removeItem("RyBase.token");
          this.$store.commit("setCurrentUser", null);
          this.$store.commit("setLoginStatus", "loggedOut");
          if (this.$router.history.pending) {
            this.$router.push({
              path: "/",
              query: { redirect: this.$router.history.pending.path }
            });
          } else {
            this.$router.push("/");
          }
        });
    }
  }
};
</script>

<style>
/* 
 Reduce table row height 
*/
table tbody td,
table tbody th {
  height: 28px !important;
  font-size: 12px !important;
}

table > tbody > tr > td > .btn {
  padding: 0px;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

table thead th,
table tbody td {
  padding: 0 10px !important;
}

/* 
 Case link 
*/
a.go-to-case-link {
  text-decoration: none;
}

/* 
 Dashboard Styles 
*/
.stat-container {
  width: 90%;
  margin: 5% 5%;
  background: #fff;
}
.stat-title {
  padding: 10px 10px;
  border-top: 2px solid #eee;
  border-bottom: 1px solid #eee;
  font-size: 14px;
}
.stat-data {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 10px;
  font-size: 20px;
  font-weight: 200;
}
.stat-data-desc {
  font-size: 12px;
  font-weight: 200;
}
.chart-container {
  background: #fff;
  margin: 0px 15px 15px 15px;
}
.chart-title {
  display: flex;
  padding: 10px 10px;
  border-top: 2px solid #eee;
  border-bottom: 1px solid #eee;
  font-size: 14px;
}
.chart-title-section {
  margin-right: auto;
}
.chart-title-info {
  margin-left: 10px;
  font-size: 12px;
  font-weight: 200;
}
.chart-vis {
  height: 200px;
}
</style>
