<template>
  <v-card>
    <v-row>
      <v-col cols="12" sm="1">
        <div class="fee-stat-container">
          <div class="fee-stat-title">Demands</div>
          <div class="fee-stat-data">
            {{ demandsTotal }}
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="2">
        <div class="fee-stat-container">
          <div v-if="!showByWeek" class="fee-stat-title">
            Avg Demands Per Month
          </div>
          <div v-if="showByWeek" class="fee-stat-title">
            Avg Demands Per Week
          </div>
          <div class="fee-stat-data">
            {{ showByWeek ? averageDemandsPerWeek : averageDemandsPerMonth }}
          </div>
        </div>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="1" v-if="isAdmin">
        <div class="mr-5">
          <v-switch class="" label="Show All" v-model="showAll"></v-switch>
        </div>
      </v-col>
      <v-col cols="12" sm="1" v-if="isAdmin">
        <div class="mr-5">
          <v-switch class="" label="By Week" v-model="showByWeek"></v-switch>
        </div>
      </v-col>
      <v-col cols="12" sm="1" v-if="isAdmin">
        <div class="mr-5">
          <v-autocomplete
            :items="officeOptions"
            v-model="selectedOffice"
          ></v-autocomplete>
        </div>
      </v-col>
      <v-col class="pr-2" cols="4" sm="2">
        <v-text-field
          v-model="search"
          append-icon="mdi-search"
          label="Search"
          hide-details
          single-line
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <org-cases-resolved-by-month :options="feesByMonthChartOptions" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <org-demands-by-attorney :options="demandsByAttorneyChartOptions" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <org-demands-by-attorney :options="demandsByParalegalChartOptions" />
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="demands"
      class="elevation-1"
      :footer-props="{
        'items-per-page-options': [50, 100, -1],
      }"
      :sort-by="['demandDate']"
      :sort-desc="[true]"
      :search="search"
      :item-key="Math.random(10).toString()"
      :loading="$apollo.loading"
      height="inherit"
    >
      <template v-slot:item.caseId="props">
        <a
          class="go-to-case-link mr-2"
          target="_blank"
          :href="
            `https://cs-web.airdesksolutions.com/SA/CaseSummary.aspx?CaseID=${props.item.caseId}`
          "
        >
          <v-icon size="15">mdi-open-in-new</v-icon>
        </a>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import gql from "graphql-tag";
import { mapGetters } from "vuex";
import filter from "lodash/filter";
import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";
import getWeek from "date-fns/getWeek";
import startOfWeek from "date-fns/startOfWeek";
import OrgCasesResolvedByMonth from "../charts/OrgCasesResolvedByMonth.vue";
import OrgDemandsByAttorney from "../charts/OrgDemandsByAttorney.vue";
import { hasRole, getAllAttorneysDb } from "../util";

export default {
  data() {
    return {
      search: "",
      pagination: { sortBy: "demandDate", descending: true },
      showAll: true,
      showByWeek: true,
      selectedOffice: null,
      officeOptions: [
        { text: "All Markets", value: null },
        {
          text: "(2 - WA Main) Spokane Valley Office",
          value: "(2 - WA Main) Spokane Valley Office",
        },
        {
          text: "(1 - UT Main) Sandy Office",
          value: "(1 - UT Main) Sandy Office",
        },
        {
          text: "(3 - ID Main) Meridian Office",
          value: "(3 - ID Main) Meridian Office",
        },
        { text: "Sea-Tac", value: "Sea-Tac" },
        { text: "Other", value: "Other" },
        { text: "Wyoming", value: "Wyoming" },
        { text: "Montana", value: "Montana" },
        { text: "Colorado", value: "Colorado" },
        { text: "Oregon", value: "Oregon" },
      ],
    };
  },
  components: {
    OrgCasesResolvedByMonth,
    OrgDemandsByAttorney,
  },
  apollo: {
    organization: {
      query: gql`
        query($id: ID!) {
          organization(id: $id) {
            id
            name
            teams {
              id
              name
            }
            paralegals {
              staffCode
            }
            departments {
              id
              name
              users {
                staffCode
              }
            }
          }
        }
      `,
      variables() {
        return {
          id: 2,
        };
      },
    },
    allDemandsInPeriod: {
      query: gql`
        query($startDate: String!, $endDate: String!) {
          allDemandsInPeriod(startDate: $startDate, endDate: $endDate) {
            clientName
            attorney
            paralegal
            caseId
            demandDate
            type
            office
          }
        }
      `,
      variables() {
        return {
          startDate: this.startDate,
          endDate: this.endDate,
        };
      },
      fetchPolicy: "no-cache",
      skip() {
        return this.startDate === null || this.endDate === null;
      },
    },
  },
  computed: {
    ...mapGetters(["selectedTeam", "startDate", "endDate", "currentUser"]),
    headers() {
      let headers = [
        { text: "Action", value: "caseId" },
        { text: "Client", value: "clientName" },
        { text: "Type", value: "type" },
        { text: "Attorney", value: "attorney" },
        { text: "Paralegal", value: "paralegal" },
        { text: "Office", value: "office" },
        { text: "Date", value: "demandDate" },
      ];

      return headers;
    },
    isAdmin() {
      if (!this.currentUser) return false;
      return hasRole(this.currentUser, "admin");
    },
    team() {
      if (!this.organization || !this.selectedTeam) return null;
      return filter(
        this.organization.teams,
        (t) => t.id === this.selectedTeam
      )[0].name;
    },
    demands() {
      if (!this.allDemandsInPeriod) return [];
      let demands = this.allDemandsInPeriod;
      if (this.selectedOffice) {
        demands = filter(demands, (f) => f.office === this.selectedOffice);
      }
      if (!this.showAll && this.selectedTeam) {
        demands = filter(demands, (f) => f.attorney === this.team);
      }
      return demands;
    },
    demandsTotal() {
      if (!this.allDemandsInPeriod) return 0;
      return this.demands.length;
    },
    demandsLiabilityTotal() {
      if (!this.allDemandsInPeriod) return 0;
      return filter(this.demands, (d) => d.type === "Liability Demand").length;
    },
    demandsUIMTotal() {
      if (!this.allDemandsInPeriod) return 0;
      return filter(this.demands, (d) => d.type === "UIM Demand").length;
    },
    demandsByAttorney() {
      if (!this.organization || !this.allDemandsInPeriod) return [];
      let attorneys = getAllAttorneysDb(this.organization);
      let groupedByAttorney = groupBy(this.allDemandsInPeriod, "attorney");
      let demandsByAttorney = attorneys.map((a) => {
        return {
          name: a,
          y: groupedByAttorney[a] ? groupedByAttorney[a].length : 0,
        };
      });
      return {
        name: "Demands By Attorney",
        data: demandsByAttorney,
      };
    },
    demandsByParalegal() {
      if (!this.organization || !this.allDemandsInPeriod) return [];
      let paralegals = sortBy(this.organization.paralegals, ["staffCode"]).map(
        (p) => p.staffCode
      );
      let groupedByParalegal = groupBy(this.allDemandsInPeriod, "paralegal");
      let demandsByParalegal = paralegals.map((p) => {
        return {
          name: p,
          y: groupedByParalegal[p] ? groupedByParalegal[p].length : 0,
        };
      });
      return {
        name: "Demands By Paralegal",
        data: demandsByParalegal,
      };
    },
    demandsByWeek() {
      if (!this.allDemandsInPeriod) return [];
      let mapCasesToWeek = this.demands.map((d) => {
        let day = d.demandDate.split("-")[2];
        let month = d.demandDate.split("-")[1];
        let year = d.demandDate.split("-")[0];
        let date = new Date(year, month - 1, day);
        return {
          week: getWeek(date, {
            weekStartsOn: 1,
          }),
          caseId: d.caseId,
        };
      });
      let casesGroupedByWeek = groupBy(mapCasesToWeek, "week");
      let weekKeys = Object.keys(casesGroupedByWeek);

      let casesByWeek = weekKeys.map((m) => {
        let demand = filter(this.demands, (d) => {
          return d.caseId === casesGroupedByWeek[m][0].caseId;
        });
        let day = demand[0].demandDate.split("-")[2];
        let month = demand[0].demandDate.split("-")[1];
        let year = demand[0].demandDate.split("-")[0];
        return [
          startOfWeek(new Date(year, month - 1, day)).getTime(),
          casesGroupedByWeek[m].reduce((prev) => {
            return prev + 1;
          }, 0),
        ];
      });
      return {
        name: "Demands by Week",
        data: casesByWeek,
      };
    },
    demandsByMonth() {
      if (!this.allDemandsInPeriod) return [];
      let mapCasesToMonths = this.demands.map((d) => {
        let month = d.demandDate.split("-")[1];
        let year = d.demandDate.split("-")[0];
        let monthName = Date.UTC(year, month - 1, 1);
        return {
          month: monthName,
          caseId: d.caseId,
        };
      });
      let casesGroupedByMonth = groupBy(mapCasesToMonths, "month");
      let monthKeys = Object.keys(casesGroupedByMonth);
      let casesByMonth = monthKeys.map((m) => {
        return [
          parseInt(m),
          casesGroupedByMonth[m].reduce((prev) => {
            return prev + 1;
          }, 0),
        ];
      });
      return {
        name: "Demands by Month",
        data: sortBy(casesByMonth, ([month]) => month),
      };
    },
    averageDemandsPerMonth() {
      if (!this.demandsByMonth || !this.demandsByMonth.data) return 0;
      return (
        this.demandsByMonth.data.reduce((prev, cur) => {
          return prev + cur[1];
        }, 0) / this.demandsByMonth.data.length
      ).toFixed(2);
    },
    averageDemandsPerWeek() {
      if (!this.demandsByMonth || !this.demandsByWeek.data) return 0;
      return (
        this.demandsByWeek.data.reduce((prev, cur) => {
          return prev + cur[1];
        }, 0) / this.demandsByWeek.data.length
      ).toFixed(2);
    },
    demandsByAttorneyChartOptions() {
      return {
        xAxis: {
          type: "category",
        },
        series: this.demandsByAttorney,
      };
    },
    demandsByParalegalChartOptions() {
      return {
        xAxis: {
          type: "category",
        },
        series: this.demandsByParalegal,
      };
    },
    feesByMonthChartOptions() {
      let options = {
        series: this.demandsByMonth,
      };
      if (this.showByWeek) {
        options = Object.assign({}, options, {
          series: this.demandsByWeek,
          plotOptions: {
            series: {
              pointStart: 1,
            },
          },
        });
      }
      return options;
    },
  },
};
</script>

<style scoped>
td.status {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  width: 150px;
}
table.table > tbody {
  max-height: 10vh !important;
  font-size: 10px !important;
}
table.table > tbody > td {
  white-space: nowrap !important;
  font-size: 10px !important;
}
.inventory-title {
  font-size: 36px;
}

.inventory-number {
  font-size: 24px;
}
.fee-stat-container {
  padding: 8px 16px;
  text-align: center;
}
.fee-stat-title {
  font-size: 16px;
}
.fee-stat-data {
  font-size: 30px;
  font-weight: bold;
}
</style>
