import filter from "lodash/filter";
import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";
import format from "date-fns/format";

export * from "./route";
export * from "./watch";

export const hasRole = function(user, role) {
  if (!user || !user.roles) return false;
  return (
    filter(user.roles, function(r) {
      return r.name === role;
    }).length > 0
  );
};

export const getPreLitAttorneys = function() {
  return [
    "BLA",
    "CAG",
    "CKS",
    "DB",
    "DJO",
    "GWG",
    "JTA",
    "KBL",
    "LP",
    "PHC",
    "RCS",
    "RJE",
    "RJL",
  ];
};

export const getInvestigatorsDb = function({ departments }) {
  let users = filter(departments, (d) => {
    return d.name === "Investigators";
  })[0].users;
  return sortBy(users, [(u) => u.staffCode]).map((u) => {
    return u.staffCode;
  });
};

export const getPreLitAttorneysDb = function({ departments }) {
  let users = filter(departments, (d) => {
    return d.name === "Pre-Litigation Attorneys";
  })[0].users;
  return sortBy(users, [(u) => u.staffCode]).map((u) => {
    return u.staffCode;
  });
};

export const getLitAttorneys = function() {
  return ["ECD", "MJK", "RG", "SF"];
};

export const getLitAttorneysDb = function({ departments }) {
  let users = filter(departments, (d) => {
    return d.name === "Litigation Attorneys";
  })[0].users;
  return sortBy(users, [(u) => u.staffCode]).map((u) => {
    return u.staffCode;
  });
};

export const getAllAttorneysDb = function({ departments }) {
  let filteredUsers = filter(departments, (d) => {
    return (
      d.name === "Litigation Attorneys" || d.name === "Pre-Litigation Attorneys"
    );
  });
  let users = filteredUsers[0].users.concat(filteredUsers[1].users);
  return sortBy(users, [(u) => u.staffCode]).map((u) => {
    return u.staffCode;
  });
};

export function getDaysSinceToday(date) {
  let now = new Date();
  let noteDate = new Date(date).getTime();
  let daysSinceToday = (now - noteDate) / 24 / 60 / 60 / 1000;
  return daysSinceToday.toFixed(0);
}

export const buildDataForPreLitAttorneys = function(data) {
  let preLit = getPreLitAttorneys();
  let groupedByAttorney = groupBy(data, (d) => {
    return d.attorney;
  });
  return preLit.map((a) => {
    if (groupedByAttorney[a]) return groupedByAttorney[a][0].count;
    else return 0;
  });
};

export const buildSeriesFromData = function(data, categories) {
  // let groupedByStatus = groupBy(data, d => {
  //   return d.status;
  // });
  // let groupedByAttorney = groupBy(data, d => {
  //   return d.attorney;
  // });
  // let allStatuses = Object.keys(groupedByStatus);
  return categories.map((c) => {
    return c;
  });
};

export const buildInventoryByAttorney = function(data, categories) {
  // let sortedByAttorney = sortBy(data, d => {
  //   return d.staff;
  // });
  let groupedByAttorney = groupBy(data, (d) => {
    return d.staff;
  });
  let series = [
    { name: "No Status", value: "totalNoStatusCases" },
    { name: "Closed", value: "totalClosedCases" },
    { name: "Reffered Out", value: "totalReferredOutCases" },
    { name: "Settled", value: "totalSettledCases" },
    { name: "UIM", value: "totalUimCases" },
    { name: "Litigation", value: "totalLitigationCases" },
    { name: "Negotiation", value: "totalNegotiationCases" },
    { name: "Demand Prep", value: "totalDemandPrepCases" },
    { name: "Treating", value: "totalTreatingCases" },
    { name: "Intake", value: "totalIntakeCases" },
  ].map((s) => {
    return {
      name: s.name,
      data: categories.reduce((prev, cur) => {
        if (!groupedByAttorney[cur]) {
          return prev.concat(0);
        } else {
          return prev.concat(groupedByAttorney[cur][0][s.value]);
        }
      }, []),
    };
  });
  return series;
};

export const buildFeesInPeriodByAttorney = function(data, categories) {
  let cleanData = data.map((d) => {
    return { attorney: d.attorney.replace(/-P/i, ""), amount: d.amount };
  });
  let groupedByAttorney = groupBy(cleanData, (d) => {
    if (d.attorney === "RLG") {
      return "RG";
    } else if (d.attorney == "ESM") {
      return "EM";
    } else {
      return d.attorney;
    }
  });
  return categories.map((c) => {
    if (groupedByAttorney[c])
      return parseFloat(groupedByAttorney[c][0].amount, 10);
    else return 0;
  });
};

export const buildFeesAvgInPeriodByAttorney = function(data, categories) {
  let cleanData = data.map((d) => {
    return {
      attorney: d.attorney.replace(/-P/i, ""),
      averageFee: d.averageFee,
      casesResolved: d.casesResolved,
    };
  });
  let groupedByAttorney = groupBy(cleanData, (d) => {
    if (d.attorney === "RLG") {
      return "RG";
    } else if (d.attorney == "ESM") {
      return "EM";
    } else {
      return d.attorney;
    }
  });
  return categories.map((c) => {
    if (groupedByAttorney[c])
      return parseFloat(groupedByAttorney[c][0].averageFee, 10);
    else return 0;
  });
};

export const buildCasesResolvedInPeriodByAttorney = function(data, categories) {
  let cleanData = data.map((d) => {
    return {
      attorney: d.attorney.replace(/-P/i, ""),
      averageFee: d.averageFee,
      casesResolved: d.casesResolved,
    };
  });
  let groupedByAttorney = groupBy(cleanData, (d) => {
    if (d.attorney === "RLG") {
      return "RG";
    } else if (d.attorney == "ESM") {
      return "EM";
    } else {
      return d.attorney;
    }
  });
  return categories.map((c) => {
    if (groupedByAttorney[c]) return groupedByAttorney[c][0].casesResolved;
    else return 0;
  });
};

export const buildRetainedCasesByMonth = function(data) {
  let dates = data.reduce((prev, cur) => {
    let date = Date.UTC(cur.year, cur.month - 1, 1);
    if (prev.indexOf(date) === -1) {
      return prev.concat(date);
    } else {
      return prev;
    }
  }, []);
  let series = [
    "Sea-Tac",
    "(2 - WA Main) Spokane Valley Office",
    "(3 - ID Main) Meridian Office",
    "(1 - UT Main) Sandy Office",
    "Wyoming",
    "Oregon",
    "Montana",
    "Colorado",
    "Other",
  ].map((o) => {
    let filteredByOffice = filter(data, (d) => {
      return d.office === o;
    }).map((d) => {
      return Object.assign({}, d, { date: Date.UTC(d.year, d.month - 1, 1) });
    });
    let groupedByDate = groupBy(filteredByOffice, (d) => {
      return d.date;
    });
    let dd = dates.reduce((prev, cur) => {
      if (groupedByDate[cur]) {
        return prev.concat([[cur, groupedByDate[cur][0].count]]);
      } else {
        return prev.concat([[cur, 0]]);
      }
    }, []);
    return {
      name: o,
      data: dd,
    };
  });
  let totalDates = series[0].data.length;
  let totalsByDate = Array.apply(null, { length: totalDates }).map((val, i) => {
    return [dates[i], 0];
  });

  totalsByDate = series.reduce((prev, cur) => {
    return cur.data.reduce((innerPrev, [, val], i) => {
      innerPrev[i][1] = innerPrev[i][1] += val;
      return innerPrev;
    }, prev);
  }, totalsByDate);

  return series.concat({
    name: "Total",
    data: totalsByDate,
  });
};

export const buildRetainedVsSettledCasesByMonth = function(
  organization,
  retained,
  settled
) {
  let retainedGroupedByAttorney = groupBy(retained, (r) => {
    return r.attorney;
  });
  let settledGroupedByAttorney = groupBy(settled, (s) => {
    return s.attorney;
  });
  let attorneys = getPreLitAttorneysDb(organization).map((a) => {
    let settledTotal = 0;
    if (settledGroupedByAttorney[a]) {
      settledTotal = settledGroupedByAttorney[a].reduce((prev, cur) => {
        return prev + cur.count;
      }, 0);
    }
    let retainedTotal = 0;
    if (retainedGroupedByAttorney[a]) {
      retainedTotal = retainedGroupedByAttorney[a].reduce((prev, cur) => {
        return prev + cur.count;
      }, 0);
    }
    return {
      attorney: a,
      settledTotal,
      retainedTotal,
    };
  });
  let series = [
    { data: "retainedTotal", name: "Retained" },
    { data: "settledTotal", name: "Settled" },
  ].map((s) => {
    return {
      name: s.name,
      data: attorneys.map((a) => {
        return a[s.data];
      }),
      dataLabels: {
        enabled: true,
      },
    };
  });
  return {
    series,
    attorneys: getPreLitAttorneysDb(organization),
  };
};

export const formatValue = function(item) {
  if (item.valueFrom === null) return "";
  let valueFrom = parseInt(item.valueFrom);
  let valueTo = parseInt(item.valueTo);
  let from =
    Math.abs(valueFrom) > 999
      ? Math.abs(valueFrom) > 999998
        ? Math.sign(valueFrom) * (Math.abs(valueFrom) / 1000000).toFixed(1) +
          "M"
        : Math.sign(valueFrom) * (Math.abs(valueFrom) / 1000).toFixed(1) + "k"
      : Math.sign(valueFrom) * Math.abs(valueFrom);
  let to =
    Math.abs(valueTo) > 999
      ? Math.abs(valueTo) > 999998
        ? Math.sign(valueTo) * (Math.abs(valueTo) / 1000000).toFixed(1) + "M"
        : Math.sign(valueTo) * (Math.abs(valueTo) / 1000).toFixed(1) + "k"
      : Math.sign(valueTo) * Math.abs(valueTo);
  return `$${from} - $${to}`;
};

export const getFirstDayOfWeekFromWeekNumber = function(year, week) {
  var d = new Date(year, 0, 1 + (week - 1) * 7);
  return format(d, "MMM do");
};

export const formatOffice = function(office) {
  switch (office) {
    case "(3 - ID Main) Meridian Office":
      return "BOI";
    case "Sea-Tac":
      return "SEA";
    case "(2 - WA Main) Spokane Valley Office":
      return "SPO";
    case "(1 - UT Main) Sandy Office":
      return "UT";
    case "Wyoming":
      return "WY";
    case "Oregon":
      return "OR";
    case "Tri-Cities":
      return "TRI";
    case "Montana":
      return "MT";
    default:
      return "?";
  }
};
