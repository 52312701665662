import { render, staticRenderFns } from "./DashFeesComparison.vue?vue&type=template&id=78b22908&scoped=true&"
import script from "./DashFeesComparison.vue?vue&type=script&lang=js&"
export * from "./DashFeesComparison.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78b22908",
  null
  
)

export default component.exports