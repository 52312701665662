<template>
  <v-card>
    <v-container class="py-0" fluid>
      <v-row align-content="space-around">
        <v-col cols="12" sm="3">
          <v-card-title class="pa-0">
            <div>
              <div class="headline">Closed Cases in Period</div>
              <div class="text-subtitle-1 font-weight-thin">
                {{ totalFilteredResults }} Cases
              </div>
              <div class="text-subtitle-1 font-weight-thin">
                {{ notSettledAfterDemand }} Not Settled After Demand ({{
                  notSettledAfterDemandPercentage
                }}%)
              </div>
              <div class="text-subtitle-1 font-weight-thin">
                Average TOD: {{ averageTod }}
              </div>
            </div>
          </v-card-title>
        </v-col>
        <v-col cols="12" sm="2">
          <v-switch
            class="pa-4"
            label="Retained Only"
            v-model="retainedOnly"
          ></v-switch>
        </v-col>
        <v-col cols="12" sm="2">
          <v-switch
            class="pa-4"
            label="By Attorney"
            v-model="showByAttorney"
          ></v-switch>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
      <v-row align-content="space-around">
        <v-col cols="12" sm="8">
          <org-closed-cases-in-period
            :options="
              showByAttorney ? closedByAttorneyOptions : orgClosedCasesOptions
            "
          ></org-closed-cases-in-period>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="3">
          <v-text-field
            @keyup="updateSearch"
            append-icon="mdi-magnify"
            label="Search"
            hide-details
            single-line
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="1">
          <vue-json-to-csv :json-data="casesFiltered" csv-title="Closed_Report">
            <v-btn class="ml-5 mt-3"><v-icon>mdi-download</v-icon></v-btn>
          </vue-json-to-csv>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <org-to-d-by-attorney :attorney-data="averageTodByAttorney" />
        </v-col>
      </v-row>
    </v-container>
    <v-data-table
      :footer-props="{
        'items-per-page-options': [-1],
      }"
      :headers="headers"
      hide-default-footer
      :items="casesFiltered"
      :item-key="Math.random(10).toString()"
      :loading="$apollo.loading"
      :search="search"
      :sort-by="['dateClosed']"
      :sort-desc="[true]"
      @current-items="getFiltered"
    >
      <template v-slot:item.caseId="props">
        <a
          class="go-to-case-link mr-2"
          target="_blank"
          :href="
            `https://cs-web.airdesksolutions.com/SA/CaseSummary.aspx?CaseID=${props.item.caseId}`
          "
        >
          <v-icon size="15">mdi-open-in-new</v-icon>
        </a>
      </template>
      <template v-slot:item.intakeRank="props">
        <v-rating
          :value="intakeRank(props.item.intakeRank)"
          background-color="white"
          color="yellow accent-4"
          dense
          hover
          readonly
          size="12"
          v-if="intakeRank(props.item.intakeRank) > 0"
        ></v-rating>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import gql from "graphql-tag";
import { mapGetters } from "vuex";
import filter from "lodash/filter";
import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";
import debounce from "lodash/debounce";
// import get from "lodash/get";
import OrgClosedCasesInPeriod from "../charts/OrgClosedCasesInPeriod";
import OrgToDByAttorney from "../charts/OrgToDByAttorney";
import VueJsonToCsv from "vue-json-to-csv";

export default {
  data() {
    return {
      search: "",
      headers: [
        { text: "Action", value: "caseId", width: "100px" },
        { text: "Type", value: "type" },
        { text: "State", value: "state" },
        { text: "Client", value: "clientName" },
        { text: "Attorney", value: "attorney" },
        { text: "Paralegal", value: "paralegal" },
        { text: "Case Rank", value: "caseRank" },
        { text: "ToD", value: "tod" },
        { text: "Opened", value: "dateOpened" },
        { text: "Wanted", value: "wantedDate" },
        { text: "Retained", value: "retainedDate" },
        { text: "Demand", value: "demandDate" },
        { text: "Closed", value: "dateClosed" },
        { text: "Reason", value: "reasonClosed" },
      ],
      closedCases: [],
      retainedOnly: true,
      showByAttorney: false,
      filteredResults: [],
      totalFilteredResults: 0,
      excludedAttorneys: [
        "null",
        "Townsend Jeff",
        "Dyer Erin",
        "Black Laura",
        "Anderson Mike",
        "Swapp Ryan",
        "Berrett Cameron",
      ],
    };
  },
  components: {
    OrgClosedCasesInPeriod,
    VueJsonToCsv,
    OrgToDByAttorney,
  },
  methods: {
    updateSearch: debounce(function(e) {
      this.search = e.target.value;
    }, 500),
    shortenLabel(label) {
      if (/REJECT -/.test(label)) {
        return label.substr(9, 100);
      }
      if (/CASE SETTLED/.test(label)) {
        return "SETTLED";
      }
      return label;
    },
    getFiltered(items) {
      if (this.totalFilteredResults !== items?.length) {
        this.totalFilteredResults = items?.length;
      }
      if (JSON.stringify(this.filteredResults) !== JSON.stringify(items)) {
        this.filteredResults = items ? [...items] : [];
      }
      return false;
    },
    intakeRank(rank) {
      if (!rank) return null;
      let num = rank.substr(0, 1);
      return parseInt(num, 10);
    },
  },
  computed: {
    ...mapGetters(["startDate", "endDate"]),
    averageTod() {
      if (this.filteredResults?.length === 0) return 0;
      let casesSettled = this.filteredResults?.filter(
        (c) =>
          c.reasonClosed === "CASE SETTLED" &&
          !this.excludedAttorneys.includes(c.attorney)
      );
      let totalTod = casesSettled?.reduce((acc, c) => {
        return acc + c.tod;
      }, 0);
      return (totalTod / casesSettled?.length).toFixed(1);
    },
    averageTodByAttorney() {
      if (this.filteredResults?.length === 0) return [];
      let casesSettled = this.filteredResults?.filter(
        (c) => c.reasonClosed === "CASE SETTLED"
      );
      const attorneyStats = {};

      casesSettled.forEach((c) => {
        const { attorney, tod } = c;
        if (!attorneyStats[attorney]) {
          attorneyStats[attorney] = { totalTod: 0, count: 0 };
        }
        attorneyStats[attorney].totalTod += tod;
        attorneyStats[attorney].count++;
      });

      const result = Object.keys(attorneyStats).map((attorney) => {
        const { totalTod, count } = attorneyStats[attorney];
        const averageTod = Number((totalTod / count).toFixed(1));
        return { attorney, averageTod };
      });

      return sortBy(result, [(s) => s.averageTod]).filter(
        (a) => !this.excludedAttorneys.includes(a.attorney)
      );
    },
    notSettledAfterDemand() {
      // return the number of cases that do not have reasonClosed === "CASE SETTLED" and c.demandDate is not null
      return filter(this.casesFiltered, (c) => {
        return c.reasonClosed !== "CASE SETTLED" && !!c.demandDate;
      }).length;
    },
    notSettledAfterDemandPercentage() {
      return (
        (this.notSettledAfterDemand / this.totalFilteredResults) *
        100
      ).toFixed(1);
    },
    orgClosedCasesOptions() {
      let groupedByReason = groupBy(this.casesFiltered, (c) => {
        return c.reasonClosed;
      });
      let series = Object.keys(groupedByReason).map((r) => {
        return { name: this.shortenLabel(r), y: groupedByReason[r].length };
      });
      series = sortBy(series, [(s) => s.name]);
      let parent = this;
      return {
        plotOptions: {
          column: {
            dataLabels: {
              enabled: true,
              formatter: function() {
                return `${this.y} (${(
                  (this.y / parent.casesFiltered.length) *
                  100
                ).toFixed(2)}%)`;
              },
            },
          },
        },
        xAxis: {
          type: "category",
        },
        series: [
          {
            name: "Closed in Period",
            data: series,
          },
        ],
      };
    },
    closedByAttorneyOptions() {
      let groupedByAttorney = groupBy(this.casesFiltered, (c) => {
        return c.attorney;
      });
      let attorneys = Object.keys(groupedByAttorney);

      let series = sortBy(
        attorneys.map((c) => {
          return {
            name: c,
            y: groupedByAttorney[c].length,
          };
        }),
        [(s) => s.name]
      );

      return {
        xAxis: {
          type: "category",
        },
        series: [
          {
            name: "By Attorney",
            data: series,
          },
        ],
      };
    },
    casesFiltered() {
      if (this.retainedOnly) {
        return filter(this.closedCases, function(c) {
          return !!c.retainedDate;
        });
      }
      return this.closedCases;
    },
  },
  apollo: {
    closedCases: {
      query: gql`
        query($startDate: String!, $endDate: String!) {
          closedCases: closedCasesInPeriod(
            startDate: $startDate
            endDate: $endDate
          ) {
            caseId
            type
            state
            clientName
            paralegal
            attorney
            dateOpened
            dateClosed
            retainedDate
            wantedDate
            reasonClosed
            intakeRank
            caseRank
            demandDate
            tod
          }
        }
      `,
      variables() {
        return {
          startDate: this.startDate,
          endDate: this.endDate,
        };
      },
    },
  },
};
</script>

<style scoped></style>
