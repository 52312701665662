<template>
  <v-card>
    <v-toolbar flat dense>
      <span class="text-subtitle-1">Retained By Week</span>
      <v-spacer></v-spacer>

      <div class="autocomplete">
        <v-autocomplete
          class="pt-5"
          :items="officeOptions"
          v-model="selectedOffice"
        ></v-autocomplete>
      </div>
    </v-toolbar>
    <highcharts ref="retainedByWeek" :options="chartOptions"></highcharts>
  </v-card>
</template>

<script>
import gql from "graphql-tag";
import filter from "lodash/filter";
// import { getFirstDayOfWeekFromWeekNumber } from "../util";

export default {
  data() {
    return {
      officeOptions: [
        { text: "All Markets", value: null },
        {
          text: "(2 - WA Main) Spokane Valley Office",
          value: "(2 - WA Main) Spokane Valley Office",
        },
        {
          text: "(1 - UT Main) Sandy Office",
          value: "(1 - UT Main) Sandy Office",
        },
        {
          text: "(3 - ID Main) Meridian Office",
          value: "(3 - ID Main) Meridian Office",
        },
        { text: "Sea-Tac", value: "Sea-Tac" },
        { text: "Other", value: "Other" },
        { text: "Wyoming", value: "Wyoming" },
        { text: "Montana", value: "Montana" },
        { text: "Colorado", value: "Colorado" },
        { text: "Oregon", value: "Oregon" },
      ],
      selectedOffice: null,
    };
  },
  mounted() {
    setTimeout(() => {
      if (!this.$refs.retainedByWeek) return;
      this.$refs.retainedByWeek.chart.reflow();
    }, 200);
  },
  computed: {
    // ...mapGetters([]),
    retainedForChartSeries() {
      if (!this.retainedByWeek) return [];

      let retained = this.retainedByWeek;
      let goal = 49;
      if (this.selectedOffice) {
        retained = filter(
          this.retainedByWeekByOffice,
          (f) => f.office === this.selectedOffice
        );
        switch (this.selectedOffice) {
          case "(2 - WA Main) Spokane Valley Office":
            goal = 14;
            break;
          case "(1 - UT Main) Sandy Office":
            goal = 22;
            break;
          case "(3 - ID Main) Meridian Office":
            goal = 13;
            break;
          case "Sea-Tac":
            goal = 0;
            break;
          case "Other":
            goal = 0;
            break;
          case "Wyoming":
            goal = 0;
            break;
          case "Montana":
            goal = 0;
            break;
          case "Colorado":
            goal = 0;
            break;
          case "Oregon":
            goal = 0;
            break;
          default:
            goal = 0;
            break;
        }
      }

      let weeklyRetained = {
        type: "column",
        name: "Weekly Retained",
        color: "#2196F3",
        data: retained.map((f) => {
          return parseInt(f.retained, 10);
        }),
      };
      let aggRetained = retained,
        aggregateRetainedSum;
      let aggregateRetained = {
        name: "Retained",
        color: "#2196F3",
        data: aggRetained.map(
          (f) =>
            (aggregateRetainedSum =
              (aggregateRetainedSum || 0) + parseInt(f.retained, 10))
        ),
      };

      let weeklyRetainedAfterTrueAttrition = {
        type: "column",
        name: "Weekly After Attrition",
        color: "#9C27B0",
        data: retained.map((f) => {
          return parseInt(f.retainedAfterTrueAttrition, 10);
        }),
      };
      let aggRetainedAfterTrueAttrition = retained,
        aggregateRetainedAfterTrueAttritionSum;
      let aggregateRetainedAfterTrueAttrition = {
        name: "After Attrition",
        color: "#9C27B0",
        data: aggRetainedAfterTrueAttrition.map(
          (f) =>
            (aggregateRetainedAfterTrueAttritionSum =
              (aggregateRetainedAfterTrueAttritionSum || 0) +
              parseInt(f.retainedAfterTrueAttrition, 10))
        ),
      };

      // let weeklyRetainedAfterEstimatedAttrition = {
      //   name: "Weekly Retained After Estimated Attrition",
      //   data: retained.map(f => {
      //     return parseInt(f.retainedAfterEstimatedAttrition, 10);
      //   })
      // };
      // let aggRetainedAfterEstimatedAttrition = retained,
      //   aggregateRetainedAfterEstimatedAttritionSum;
      // let aggregateRetainedAfterEstimatedAttrition = {
      //   name: "Estimated Attrition",
      //   color: "#F44336",
      //   data: aggRetainedAfterEstimatedAttrition
      //     .map(
      //       f =>
      //         (aggregateRetainedAfterEstimatedAttritionSum =
      //           (aggregateRetainedAfterEstimatedAttritionSum || 0) +
      //           parseFloat(f.retainedAfterEstimatedAttrition, 10))
      //     )
      //     .map(f => {
      //       return f.toFixed(2);
      //     })
      // };

      let goalSum;
      let goalRetained = {
        name: "Goal Retained",
        color: "#4CAF50",
        data: retained.map(() => (goalSum = (goalSum || 0) + goal)),
      };
      return [
        weeklyRetained,
        aggregateRetained,
        weeklyRetainedAfterTrueAttrition,
        aggregateRetainedAfterTrueAttrition,
        // aggregateRetainedAfterEstimatedAttrition,
        goalRetained,
      ];
    },
    chartOptions() {
      return {
        credits: {
          enabled: false,
        },
        title: {
          text: "",
        },
        yAxis: {
          title: {
            text: "Retained",
          },
        },
        tooltip: {
          split: true,
          // formatter: function() {
          //   let year = new Date().getFullYear();
          //   return (
          //     "<b>" +
          //     `Week ${this.x}` +
          //     " - " +
          //     `${getFirstDayOfWeekFromWeekNumber(year, this.x)}` +
          //     "</b><br/>" +
          //     this.series.name +
          //     ": " +
          //     this.y.toFixed(0)
          //   );
          // }
        },
        plotOptions: {
          series: {
            pointStart: 1,
          },
        },
        series: this.retainedForChartSeries,
      };
    },
  },
  apollo: {
    retainedByWeek: {
      query: gql`
        query OrgHomeRetainedChartsRetainedByWeek {
          retainedByWeek {
            week
            year
            retained
            retainedAfterEstimatedAttrition
            retainedAfterTrueAttrition
          }
        }
      `,
    },
    retainedByWeekByOffice: {
      query: gql`
        query OrgHomeRetainedChartsRetainedByWeekByOffice {
          retainedByWeekByOffice {
            week
            year
            retained
            retainedAfterEstimatedAttrition
            retainedAfterTrueAttrition
            office
          }
        }
      `,
    },
  },
};
</script>

<style scoped>
.autocomplete {
  width: 20%;
}
@media screen and (max-width: 767px) {
  .autocomplete {
    width: 35%;
  }
}
</style>
