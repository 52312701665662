<template>
  <div class="chart-container">
    <highcharts :options="chartOptions"></highcharts>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";

export default {
  name: "OrgToDByAttorney",
  components: {
    highcharts: Chart,
  },
  props: {
    attorneyData: {
      type: Array,
      required: true,
    },
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          type: "bar",
        },
        title: {
          text: "Time on Desk by Attorney",
        },
        xAxis: {
          categories: this.attorneyData.map((item) => item.attorney),
          title: {
            text: "Attorney",
          },
        },
        yAxis: {
          title: {
            text: "Time on Desk",
          },
          min: 0,
        },
        series: [
          {
            name: "Time on Desk",
            data: this.attorneyData.map((item) => item.averageTod),
            color: "#4285f4",
          },
        ],
        credits: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true,
            },
          },
        },
      };
    },
  },
};
</script>

<style scoped>
.chart-container {
  min-height: 400px;
  width: 100%;
}
</style>
