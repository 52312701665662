<template>
  <v-app id="inspire">
    <v-navigation-drawer
      :clipped="$vuetify.breakpoint.lgAndUp"
      v-model="drawer"
      :width="200"
      fixed
      app
      dark
      mini-variant
    >
      <v-list dense>
        <template v-for="item in items">
          <v-row v-if="item.heading" :key="item.heading" align="center">
            <v-col cols="6">
              <v-subheader v-if="item.heading">
                {{ item.heading }}
              </v-subheader>
            </v-col>
            <v-col cols="6" class="text-center">
              <a href="#!" class="body-2 black--text">EDIT</a>
            </v-col>
          </v-row>

          <v-list-group
            v-else-if="item.children"
            v-model="item.model"
            :key="item.text"
            :prepend-icon="item.model ? item.icon : item['icon-alt']"
            append-icon=""
          >
            <v-list-item slot="activator">
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-for="(child, i) in item.children"
              exact
              :key="i"
              :to="child.path"
            >
              <v-list-item-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ child.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item v-else exact :key="item.text" :to="item.path">
            <v-list-item-action>
              <v-tooltip right open-delay="1000">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">{{ item.icon }}</v-icon>
                </template>
                <span>{{ item.text }}</span>
              </v-tooltip>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      color="blue darken-3"
      dark
      app
      fixed
      dense
      max-height="48"
    >
      <v-toolbar-title style="width: 200px" class="ml-0 pl-3">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <span class="hidden-sm-and-down">RyBase</span>
      </v-toolbar-title>
      <v-menu
        ref="menu1"
        :close-on-content-click="false"
        v-model="menu1"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <div v-on="on" class="menu-date-picker blue darken-2">
            {{ startDate }}
          </div>
        </template>
        <v-date-picker
          v-model="startDate"
          no-title
          @input="menu1 = false"
        ></v-date-picker>
      </v-menu>
      <v-menu
        ref="menu2"
        :close-on-content-click="false"
        v-model="menu2"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <div v-on="on" class="menu-date-picker blue darken-2">
            {{ endDate }}
          </div>
        </template>
        <v-date-picker
          v-model="endDate"
          no-title
          @input="menu2 = false"
        ></v-date-picker>
      </v-menu>
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-width="100"
        offset-x
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on">
            <v-icon>mdi-calendar-range</v-icon>
          </v-btn>
        </template>

        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  ><v-btn depressed @click="setCurrentMonth"
                    >Current Month</v-btn
                  ></v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  ><v-btn depressed @click="setPast90Days"
                    >Past 90 Days</v-btn
                  ></v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  ><v-btn depressed @click="setCurrentQuarter"
                    >Current Quarter</v-btn
                  ></v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  ><v-btn depressed @click="setYearToDate"
                    >Year to Date</v-btn
                  ></v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  >Week
                  <v-btn depressed x-small>
                    <v-icon @click="setWeekInterval('prior')"
                      >mdi-chevron-left</v-icon
                    >
                  </v-btn>
                  <v-btn depressed x-small>
                    <v-icon @click="setWeekInterval('next')"
                      >mdi-chevron-right</v-icon
                    >
                  </v-btn>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  >Month
                  <v-btn depressed x-small>
                    <v-icon @click="setMonthInterval('prior')"
                      >mdi-chevron-left</v-icon
                    >
                  </v-btn>
                  <v-btn depressed x-small>
                    <v-icon @click="setMonthInterval('next')"
                      >mdi-chevron-right</v-icon
                    >
                  </v-btn>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  >Quarter
                  <v-btn depressed x-small>
                    <v-icon @click="setQuarterInterval('prior')"
                      >mdi-chevron-left</v-icon
                    >
                  </v-btn>
                  <v-btn depressed x-small>
                    <v-icon @click="setQuarterInterval('next')"
                      >mdi-chevron-right</v-icon
                    >
                  </v-btn>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  >Year
                  <v-btn depressed x-small>
                    <v-icon @click="setYearInterval('prior')"
                      >mdi-chevron-left</v-icon
                    >
                  </v-btn>
                  <v-btn depressed x-small>
                    <v-icon @click="setYearInterval('next')"
                      >mdi-chevron-right</v-icon
                    >
                  </v-btn>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
      <v-btn icon text :to="deadlinesPath">
        <v-avatar right color="red" size="30">
          {{ user.deadline_count }}
        </v-avatar>
      </v-btn>
      <v-spacer></v-spacer>
      <v-menu>
        <template v-slot:activator="{ on }">
          <v-btn icon large v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="logOut">
            <v-list-item-title>Log Out</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-main class="grey lighten-3">
      <slot></slot>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  format,
  addWeeks,
  subWeeks,
  startOfWeek,
  lastDayOfWeek,
  addDays,
  subDays,
  addMonths,
  subMonths,
  startOfMonth,
  lastDayOfMonth,
  addQuarters,
  subQuarters,
  startOfQuarter,
  lastDayOfQuarter,
  addYears,
  subYears,
  startOfYear,
  lastDayOfYear,
} from "date-fns";
import gql from "graphql-tag";
import { Socket } from "phoenix";

export default {
  data: () => ({
    dialog: false,
    drawer: null,
    menu: false,
    menu1: false,
    menu2: false,
    user: {
      deadline_count: 0,
    },
    socket: null,
    channel: null,
  }),
  props: ["source", "items"],
  computed: {
    ...mapGetters(["currentUserId", "imitatedUser"]),
    startDate: {
      get() {
        return this.$store.getters.startDate;
      },
      set(value) {
        this.$store.dispatch("setStartDate", value);
      },
    },
    endDate: {
      get() {
        return this.$store.getters.endDate;
      },
      set(value) {
        this.$store.dispatch("setEndDate", value);
      },
    },
    deadlinesPath() {
      return `/dash/${this.$store.getters.currentUserId}/inventory/deadlines`;
    },
  },
  apollo: {
    user: {
      query: gql`
        query($id: ID!, $fromDate: String!, $toDate: String!) {
          user(id: $id) {
            id
            first_name
            last_name
            deadline_count(fromDate: $fromDate, toDate: $toDate)
            watchedCases {
              caseId
            }
          }
        }
      `,
      variables() {
        let id = this.imitatedUser
          ? this.imitatedUser.toString()
          : this.currentUserId.toString();
        return {
          id,
          fromDate: "1900-01-01",
          toDate: this.calculateToDate(this.$store.getters.deadlineToDate),
        };
      },
      skip() {
        return !this.currentUserId || !this.imitatedUser;
      },
    },
  },
  mounted() {
    this.connectToPhoenixSocket();
  },
  beforeDestroy() {
    this.disconnectFromPhoenixSocket();
  },
  methods: {
    ...mapActions(["logOut"]),
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    calculateToDate(option) {
      switch (option) {
        case "One Week":
          return format(addDays(new Date(), 7), "yyyy-MM-dd");
        case "One Month":
          return format(addDays(new Date(), 30), "yyyy-MM-dd");
        case "Three Months":
          return format(addDays(new Date(), 90), "yyyy-MM-dd");
        case "Six Months":
          return format(addDays(new Date(), 180), "yyyy-MM-dd");
        default:
          console.log("Default");
      }
    },
    setWeekInterval(type) {
      let startDate = this.$store.getters.startDate;

      let workingDate;
      if (type === "prior") {
        workingDate = subWeeks(new Date(`${startDate}T01:00:00.000-07:00`), 1);
      } else {
        workingDate = addWeeks(new Date(`${startDate}T01:00:00.000-07:00`), 1);
      }
      let newStartDate = format(startOfWeek(workingDate), "yyyy-MM-dd");
      let endDate = format(lastDayOfWeek(workingDate), "yyyy-MM-dd");

      this.$store.dispatch("setPast90Days", false);
      this.$store.dispatch("setStartDate", newStartDate);
      this.$store.dispatch("setEndDate", endDate);
      return false;
    },
    setMonthInterval(type) {
      let startDate = this.$store.getters.startDate;

      let workingDate;
      if (type === "prior") {
        workingDate = subMonths(new Date(`${startDate}T01:00:00.000-07:00`), 1);
      } else {
        workingDate = addMonths(new Date(`${startDate}T01:00:00.000-07:00`), 1);
      }
      let newStartDate = format(startOfMonth(workingDate), "yyyy-MM-dd");
      let endDate = format(lastDayOfMonth(workingDate), "yyyy-MM-dd");

      this.$store.dispatch("setPast90Days", false);
      this.$store.dispatch("setStartDate", newStartDate);
      this.$store.dispatch("setEndDate", endDate);
      return false;
    },
    setQuarterInterval(type) {
      let startDate = this.$store.getters.startDate;

      let workingDate;
      if (type === "prior") {
        workingDate = subQuarters(
          new Date(`${startDate}T01:00:00.000-07:00`),
          1
        );
      } else {
        workingDate = addQuarters(
          new Date(`${startDate}T01:00:00.000-07:00`),
          1
        );
      }
      let newStartDate = format(startOfQuarter(workingDate), "yyyy-MM-dd");
      let endDate = format(lastDayOfQuarter(workingDate), "yyyy-MM-dd");

      this.$store.dispatch("setPast90Days", false);
      this.$store.dispatch("setStartDate", newStartDate);
      this.$store.dispatch("setEndDate", endDate);
      return false;
    },
    setPast90Days() {
      let startDate = format(subDays(new Date(), 90), "yyyy-MM-dd");
      let endDate = format(new Date(), "yyyy-MM-dd");

      this.$store.dispatch("setPast90Days", true);
      this.$store.dispatch("setStartDate", startDate);
      this.$store.dispatch("setEndDate", endDate);
      return false;
    },
    setYearInterval(type) {
      let startDate = this.$store.getters.startDate;

      let workingDate;
      if (type === "prior") {
        workingDate = subYears(new Date(`${startDate}T01:00:00.000-07:00`), 1);
      } else {
        workingDate = addYears(new Date(`${startDate}T01:00:00.000-07:00`), 1);
      }
      let newStartDate = format(startOfYear(workingDate), "yyyy-MM-dd");
      let endDate = format(lastDayOfYear(workingDate), "yyyy-MM-dd");

      this.$store.dispatch("setPast90Days", false);
      this.$store.dispatch("setStartDate", newStartDate);
      this.$store.dispatch("setEndDate", endDate);
      return false;
    },
    setCurrentMonth() {
      let workingDate = new Date();
      let newStartDate = format(startOfMonth(workingDate), "yyyy-MM-dd");
      let endDate = format(lastDayOfMonth(workingDate), "yyyy-MM-dd");

      this.$store.dispatch("setPast90Days", false);
      this.$store.dispatch("setStartDate", newStartDate);
      this.$store.dispatch("setEndDate", endDate);
      return false;
    },
    setCurrentQuarter() {
      let workingDate = new Date();
      let newStartDate = format(startOfQuarter(workingDate), "yyyy-MM-dd");
      let endDate = format(lastDayOfQuarter(workingDate), "yyyy-MM-dd");

      this.$store.dispatch("setPast90Days", false);
      this.$store.dispatch("setStartDate", newStartDate);
      this.$store.dispatch("setEndDate", endDate);
      return false;
    },
    setYearToDate() {
      let workingDate = new Date();
      let newStartDate = format(startOfYear(workingDate), "yyyy-MM-dd");
      let endDate = format(lastDayOfMonth(workingDate), "yyyy-MM-dd");

      this.$store.dispatch("setPast90Days", false);
      this.$store.dispatch("setStartDate", newStartDate);
      this.$store.dispatch("setEndDate", endDate);
      return false;
    },
    setNextYear() {
      console.log("Next Year");
      return false;
    },
    connectToPhoenixSocket() {
      const token = localStorage.getItem("RyBase.token");

      if (token) {
        // Initialize the Phoenix Socket
        const wsEndpoint =
          window.location && !/localhost/.test(window.location.href)
            ? "wss://rybase.io/socket"
            : "ws://localhost:4000/socket";
        this.socket = new Socket(wsEndpoint, {
          params: { token },
        });
        this.socket.connect();
        this.$store.commit("setSocket", this.socket);

        // Join the "presence:lobby" channel
        this.channel = this.socket.channel("presence:lobby");
        this.channel
          .join()
          .receive("ok", (resp) => {
            console.log("Joined presence:lobby successfully", resp);
          })
          .receive("error", (resp) => {
            console.error("Unable to join presence:lobby", resp);
          });

        this.channel.on("presence_state", (state) => {
          this.$store.commit("setOnlineUsers", Object.keys(state));
        });

        this.channel.on("presence_diff", (diff) => {
          console.log("presence_diff", diff);
          const joins = Object.keys(diff.joins);
          const leaves = Object.keys(diff.leaves);

          this.$store.commit("setOnlineUsers", [
            ...this.$store.getters.onlineUsers.filter(
              (id) => !leaves.includes(id)
            ),
            ...joins,
          ]);
        });
      }
    },
    disconnectFromPhoenixSocket() {
      if (this.channel) {
        this.channel.leave();
      }
      if (this.socket) {
        this.socket.disconnect();
      }
    },
  },
};
</script>

<style scoped>
.menu-date-picker {
  width: 110px;
  padding: 10px 15px;
  margin-right: 5px;
  font-size: 14px;
  max-height: 48px;
  white-space: nowrap;
}
</style>
