import Vue from "vue";
import Router from "vue-router";
import Login from "@/components/Login";
import ForgotPassword from "@/components/ForgotPassword";
import PasswordReset from "@/components/PasswordReset";
import CRM from "@/components/CRM";
import CRMHome from "@/components/CRMHome";
import CRMActivity from "@/components/CRMActivity";
import CRMContacts from "@/components/CRMContacts";
import Dash from "@/components/Dash";
import DashCase from "@/components/DashCase";
import DashHome from "@/components/DashHome";
import DashIntake from "@/components/DashIntake";
import DashIntakeAssignment from "@/components/DashIntakeAssignment";
import DashIntakeInvestigations from "@/components/DashIntakeInvestigations";
import DashIntakeCalendar from "@/components/DashIntakeCalendar";
import DashIntakeStats from "@/components/DashIntakeStats";
import DashIntakePPCReport from "@/components/DashIntakePPCReport";
import DashInventory from "@/components/DashInventory";
import DashInventoryList from "@/components/DashInventoryList";
import DashInventoryClientContact from "@/components/DashInventoryClientContact";
import DashInventoryDeadlines from "@/components/DashInventoryDeadlines";
import DashInventoryFileReview from "@/components/DashInventoryFileReview";
import DashInventoryDeadlineReview from "@/components/DashInventoryDeadlineReview";
import DashInventoryTasks from "@/components/DashInventoryTasks";
import DashInventoryTeam from "@/components/DashInventoryTeam";
import DashInventoryWatchedCases from "@/components/DashInventoryWatchedCases";
import DashTreating from "@/components/DashTreating";
import DashTreatingList from "@/components/DashTreatingList";
import DashDemands from "@/components/DashDemands";
import DashDemandPrepList from "@/components/DashDemandPrepList";
import DashDemandsList from "@/components/DashDemandsList";
import DashDemandReview from "@/components/DashDemandReview";
import DashDemandRequests from "@/components/DashDemandRequests";
import DashDemandBalances from "@/components/DashDemandBalances";
import DashNegotiation from "@/components/DashNegotiation";
import DashNegotiationList from "@/components/DashNegotiationList";
import DashNegotiationListFileReview from "@/components/DashNegotiationListFileReview";
import DashNegotiationSettled from "@/components/DashNegotiationSettled";
import DashLitigation from "@/components/DashLitigation";
import DashLitigationList from "@/components/DashLitigationList";
import DashFees from "@/components/DashFees";
import DashFeesList from "@/components/DashFeesList";
import DashFeesComparison from "@/components/DashFeesComparison";
import DashFeesSettlements from "@/components/DashFeesSettlements";
import DashFeesRate from "@/components/DashFeesRate";
// import DashFeesCurrentCollections from "@/components/DashFeesCurrentCollections";
import MR from "@/components/MR";
import MRHome from "@/components/MRHome";
import MRStats from "@/components/MRStats";
import Org from "@/components/Org";
import OrgHomeFees from "@/components/OrgHomeFees";
import OrgStaff from "@/components/OrgStaff";
import OrgStaffCMScorecard from "@/components/OrgStaffCMScorecard";
import OrgStaffClientContact from "@/components/OrgStaffClientContact";
import OrgStaffParalegalStats from "@/components/OrgStaffParalegalStats";
import OrgDemands from "@/components/OrgDemands";
import OrgDemandsList from "@/components/OrgDemandsList";
import OrgDemandsOverdue from "@/components/OrgDemandsOverdue";
import OrgGoals from "@/components/OrgGoals";
import OrgHome from "@/components/OrgHome";
import OrgHomeIndex from "@/components/OrgHomeIndex";
import OrgHomeMarketing from "@/components/OrgHomeMarketing";
import OrgHomeMarketingStats from "@/components/OrgHomeMarketingStats";
import OrgHomeIntake from "@/components/OrgHomeIntake";
import OrgHomeIntakeStats from "@/components/OrgHomeIntakeStats";
import OrgHomeIntakeLeads from "@/components/OrgHomeIntakeLeads";
import OrgHomeIntakeReferredOut from "@/components/OrgHomeIntakeReferredOut";
import IntakeFollowUp from "@/components/IntakeFollowUp";
import OrgHomeIntakeNewProviders from "@/components/OrgHomeIntakeNewProviders";
import OrgHomeInventory from "@/components/OrgHomeInventory";
import OrgHomeClosedCases from "@/components/OrgHomeClosedCases";
import OrgUsers from "@/components/OrgUsers";
import OrgUsersList from "@/components/OrgUsersList";
import OrgUsersDepartments from "@/components/OrgUsersDepartments";
import OrgUsersOffices from "@/components/OrgUsersOffices";
import OrgUsersTeams from "@/components/OrgUsersTeams";
import OrgReviews from "@/components/OrgReviews";
import DashIntakeNewCases from "@/components/DashIntakeNewCases";
import DashIntakeReferrals from "@/components/DashIntakeReferrals";
import DashIntakeReferralsMap from "@/components/DashIntakeReferralsMap";
import { checkIfSameOrgAndAdmin } from "../util";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      component: Login,
    },
    {
      path: "/forgot-password",
      component: ForgotPassword,
    },
    {
      path: "/password-reset/:token",
      component: PasswordReset,
    },
    {
      path: "/crm",
      component: CRM,
      children: [
        {
          path: "",
          component: CRMHome,
          children: [
            {
              path: "",
              name: "crm-activity",
              component: CRMActivity,
              meta: { requiresAuth: true },
            },
            {
              path: "contacts",
              name: "crm-contacts",
              component: CRMContacts,
              meta: { requiresAuth: true },
            },
          ],
        },
      ],
    },
    {
      path: "/mr",
      component: MR,
      children: [
        {
          path: "",
          component: MRHome,
          children: [
            {
              path: "",
              name: "mr-stats",
              component: MRStats,
              meta: { requiresAuth: true },
            },
            {
              path: "tasks",
              name: "mr-tasks",
              component: DashInventoryTasks,
              meta: { requiresAuth: true },
            },
          ],
        },
      ],
    },
    {
      path: "/dash/:id",
      component: Dash,
      children: [
        {
          path: "",
          name: "dash",
          component: DashHome,
          meta: { requiresAuth: true },
          redirect: { name: "inventory-list" },
        },
        {
          path: "intake",
          component: DashIntake,
          meta: { requiresAuth: true },
          children: [
            {
              path: "",
              name: "intake-assignment",
              component: DashIntakeAssignment,
              meta: { requiresAuth: true },
            },
            {
              path: "leads",
              name: "intake-leads",
              component: OrgHomeIntakeLeads,
              meta: { requiresAuth: true },
            },
            {
              path: "follow-up",
              name: "intake-follow_up",
              component: IntakeFollowUp,
              meta: { requiresAuth: true },
            },
            {
              path: "investigations",
              name: "intake-investigations",
              component: DashIntakeInvestigations,
              meta: { requiresAuth: true },
            },
            {
              path: "calendar",
              name: "intake-calendar",
              component: DashIntakeCalendar,
              meta: { requiresAuth: true },
            },
            {
              path: "new-cases",
              name: "intake-new-cases",
              component: DashIntakeNewCases,
              meta: { requiresAuth: true },
            },
            {
              path: "referrals",
              name: "intake-referrals",
              component: DashIntakeReferrals,
              meta: { requiresAuth: true },
            },
            {
              path: "referrals-map",
              name: "intake-referrals-map",
              component: DashIntakeReferralsMap,
              meta: { requiresAuth: true },
            },
            {
              path: "new-providers",
              name: "intake-new-providers",
              component: OrgHomeIntakeNewProviders,
              meta: { requiresAuth: true },
            },
            {
              path: "stats",
              name: "intake-stats",
              component: DashIntakeStats,
              meta: { requiresAuth: true },
            },
            {
              path: "ppc-report",
              name: "intake-ppc-report",
              component: DashIntakePPCReport,
              meta: { requiresAuth: true },
            },
            {
              path: "closed-cases",
              name: "intake-closed-cases",
              component: OrgHomeClosedCases,
              meta: { requiresAuth: true },
            },
          ],
        },
        {
          path: "inventory",
          component: DashInventory,
          children: [
            {
              path: "",
              name: "inventory-list",
              component: DashInventoryList,
              meta: { requiresAuth: true },
            },
            {
              path: "client-contact",
              name: "inventory-client-contact",
              component: DashInventoryClientContact,
              meta: { requiresAuth: true },
            },
            {
              path: "deadlines",
              name: "inventory-deadlines",
              component: DashInventoryDeadlines,
              meta: { requiresAuth: true },
            },
            {
              path: "deadline-review",
              name: "inventory-deadline-review",
              component: DashInventoryDeadlineReview,
              meta: { requiresAuth: true },
            },
            {
              path: "file-review",
              name: "inventory-file-review",
              component: DashInventoryFileReview,
              meta: { requiresAuth: true },
            },
            {
              path: "tasks",
              name: "inventory-tasks",
              component: DashInventoryTasks,
              meta: { requiresAuth: true },
            },
            {
              path: "team",
              name: "inventory-team",
              component: DashInventoryTeam,
              meta: { requiresAuth: true },
            },
            {
              path: "scorecard",
              name: "dash-cm-scorecard",
              component: OrgStaffCMScorecard,
              meta: { requiresAuth: true },
            },
            {
              path: "intake-rotation",
              name: "intake-rotation",
              component: DashIntakeAssignment,
              meta: { requiresAuth: true },
            },
            {
              path: "watched-cases",
              name: "watched-cases",
              component: DashInventoryWatchedCases,
              meta: { requiresAuth: true },
            },
          ],
        },
        {
          path: "treating",
          component: DashTreating,
          children: [
            {
              path: "",
              name: "treating-list",
              component: DashTreatingList,
              meta: { requiresAuth: true },
            },
          ],
        },
        {
          path: "demands",
          component: DashDemands,
          children: [
            {
              path: "",
              name: "demands-list",
              component: DashDemandsList,
              meta: { requiresAuth: true },
            },
            {
              path: "prep",
              name: "demand-prep",
              component: DashDemandPrepList,
              meta: { requiresAuth: true },
            },
            {
              path: "review",
              name: "demand-review",
              component: DashDemandReview,
              meta: { requiresAuth: true },
            },
            {
              path: "requests",
              name: "demand-requests",
              component: DashDemandRequests,
              meta: { requiresAuth: true },
            },
            {
              path: "balances",
              name: "demand-balances",
              component: DashDemandBalances,
              meta: { requiresAuth: true },
            },
          ],
        },
        {
          path: "negotiation",
          component: DashNegotiation,
          children: [
            {
              path: "",
              name: "negotiation-list",
              component: DashNegotiationList,
              meta: { requiresAuth: true },
            },
            {
              path: "file-review",
              name: "negotiation-file-review",
              component: DashNegotiationListFileReview,
              meta: { requiresAuth: true },
            },
            {
              path: "settled",
              name: "negotiation-settled",
              component: DashNegotiationSettled,
              meta: { requiresAuth: true },
            },
          ],
        },
        {
          path: "litigation",
          component: DashLitigation,
          children: [
            {
              path: "",
              name: "litigation-list",
              component: DashLitigationList,
              meta: { requiresAuth: true },
            },
          ],
        },
        {
          path: "fees",
          component: DashFees,
          children: [
            {
              path: "",
              name: "fees-list",
              component: DashFeesList,
              meta: { requiresAuth: true },
            },
            {
              path: "fee-comparison",
              name: "fees-current-collections",
              component: DashFeesComparison,
              meta: { requiresAuth: true },
            },
            {
              path: "fee-settlements",
              name: "fees-settlements",
              component: DashFeesSettlements,
              meta: { requiresAuth: true },
            },
            {
              path: "fee-rate",
              name: "fees-rate",
              component: DashFeesRate,
              meta: { requiresAuth: true },
            },
          ],
        },
        {
          path: "reviews",
          component: OrgReviews,
          meta: { requiresAuth: true },
        },
        {
          name: "case",
          path: "/case/:caseId",
          component: DashCase,
          meta: { requiresAuth: true },
        },
      ],
    },
    {
      path: "/org/:id",
      component: Org,
      children: [
        {
          path: "",
          component: OrgHome,
          children: [
            {
              path: "",
              name: "org-home-index",
              component: OrgHomeIndex,
              meta: {
                requiresAuth: true,
                auth: ["admin", "intake-management"],
              },
              beforeEnter: (to, from, next) => {
                checkIfSameOrgAndAdmin(to, from, next);
              },
            },
            {
              path: "demands",
              component: OrgDemands,
              meta: { requiresAuth: true },
              children: [
                {
                  path: "",
                  name: "demands-index",
                  component: OrgDemandsList,
                  meta: { requiresAuth: true },
                },
                {
                  path: "overdue",
                  name: "demands-overdue",
                  component: OrgDemandsOverdue,
                  meta: { requiresAuth: true },
                },
              ],
            },
            {
              path: "fees",
              component: OrgHomeFees,
              children: [
                {
                  path: "",
                  name: "org-fees-list",
                  component: DashFeesList,
                  meta: { requiresAuth: true },
                },
                {
                  path: "fee-comparison",
                  name: "org-fees-current-collections",
                  component: DashFeesComparison,
                  meta: { requiresAuth: true },
                },
                {
                  path: "fee-settlements",
                  name: "org-fees-settlements",
                  component: DashFeesSettlements,
                  meta: { requiresAuth: true },
                },
                {
                  path: "fee-rate",
                  name: "org-fees-rate",
                  component: DashFeesRate,
                  meta: { requiresAuth: true },
                },
              ],
            },
            {
              path: "staff",
              component: OrgStaff,
              meta: { requiresAuth: true },
              children: [
                {
                  path: "",
                  name: "cm-scorecard",
                  component: OrgStaffCMScorecard,
                  meta: { requiresAuth: true },
                },
                {
                  path: "client-contact",
                  name: "staff-client-contact",
                  component: OrgStaffClientContact,
                  meta: { requiresAuth: true },
                },
                {
                  path: "paralegal-stats",
                  name: "paralegal-stats",
                  component: OrgStaffParalegalStats,
                  meta: { requiresAuth: true },
                },
              ],
            },
            {
              path: "marketing",
              component: OrgHomeMarketing,
              meta: { requiresAuth: true, auth: ["admin", "marketing"] },
              children: [
                {
                  path: "",
                  name: "marketing-index",
                  component: OrgHomeMarketingStats,
                  meta: { requiresAuth: true },
                },
              ],
            },
            {
              path: "intake",
              component: OrgHomeIntake,
              meta: {
                requiresAuth: true,
                auth: ["admin", "intake-management"],
              },
              beforeEnter: (to, from, next) => {
                checkIfSameOrgAndAdmin(to, from, next);
              },
              children: [
                {
                  path: "",
                  name: "intake-index",
                  component: OrgHomeIntakeStats,
                  meta: {
                    requiresAuth: true,
                    auth: ["admin", "intake-management"],
                  },
                },
                {
                  path: "assignment",
                  name: "org-intake-assignment",
                  component: DashIntakeAssignment,
                  meta: {
                    requiresAuth: true,
                    auth: ["admin", "intake-management"],
                  },
                },
                {
                  path: "leads",
                  name: "org-intake-leads",
                  component: OrgHomeIntakeLeads,
                  meta: {
                    requiresAuth: true,
                    auth: ["admin", "intake-management"],
                  },
                },
                {
                  path: "follow-up",
                  name: "org-intake-follow-up",
                  component: IntakeFollowUp,
                  meta: {
                    requiresAuth: true,
                    auth: ["admin", "intake-management"],
                  },
                },
                {
                  path: "investigations",
                  name: "org-intake-investigations",
                  component: DashIntakeInvestigations,
                  meta: {
                    requiresAuth: true,
                    auth: ["admin", "intake-management"],
                  },
                },
                {
                  path: "calendar",
                  name: "org-intake-calendar",
                  component: DashIntakeCalendar,
                  meta: {
                    requiresAuth: true,
                    auth: ["admin", "intake-management"],
                  },
                },
                {
                  path: "new-cases",
                  name: "org-intake-new-cases",
                  component: DashIntakeNewCases,
                  meta: {
                    requiresAuth: true,
                    auth: ["admin", "intake-management"],
                  },
                },
                {
                  path: "referrals",
                  name: "org-intake-referrals",
                  component: DashIntakeReferrals,
                  meta: {
                    requiresAuth: true,
                    auth: ["admin", "intake-management"],
                  },
                },
                {
                  path: "referrals-map",
                  name: "org-intake-referrals-map",
                  component: DashIntakeReferralsMap,
                  meta: {
                    requiresAuth: true,
                    auth: ["admin", "intake-management"],
                  },
                },
                {
                  path: "new-providers",
                  name: "org-intake-new-providers",
                  component: OrgHomeIntakeNewProviders,
                  meta: {
                    requiresAuth: true,
                    auth: ["admin", "intake-management"],
                  },
                },
                {
                  path: "referred-out",
                  name: "org-intake-referred-out",
                  component: OrgHomeIntakeReferredOut,
                  meta: {
                    requiresAuth: true,
                    auth: ["admin", "intake-management"],
                  },
                },
                {
                  path: "ppc-report",
                  name: "org-intake-ppc-report",
                  component: DashIntakePPCReport,
                  meta: {
                    requiresAuth: true,
                    auth: ["admin", "intake-management"],
                  },
                },
              ],
            },
            {
              path: "inventory",
              name: "org-home-inventory",
              component: OrgHomeInventory,
              meta: { requiresAuth: true },
              beforeEnter: (to, from, next) => {
                checkIfSameOrgAndAdmin(to, from, next);
              },
            },
            {
              path: "closed-cases",
              name: "org-home-closed-cases",
              component: OrgHomeClosedCases,
              meta: { requiresAuth: true },
              beforeEnter: (to, from, next) => {
                checkIfSameOrgAndAdmin(to, from, next);
              },
            },
          ],
        },
        {
          path: "users",
          component: OrgUsers,
          children: [
            {
              path: "",
              name: "users-list",
              component: OrgUsersList,
              meta: { requiresAuth: true },
              beforeEnter: (to, from, next) => {
                checkIfSameOrgAndAdmin(to, from, next);
              },
            },
            {
              path: "departments",
              name: "users-departments",
              component: OrgUsersDepartments,
              meta: { requiresAuth: true },
              beforeEnter: (to, from, next) => {
                checkIfSameOrgAndAdmin(to, from, next);
              },
            },
            {
              path: "offices",
              name: "users-offices",
              component: OrgUsersOffices,
              meta: { requiresAuth: true },
              beforeEnter: (to, from, next) => {
                checkIfSameOrgAndAdmin(to, from, next);
              },
            },
            {
              path: "teams",
              name: "users-teams",
              component: OrgUsersTeams,
              meta: { requiresAuth: true },
              beforeEnter: (to, from, next) => {
                checkIfSameOrgAndAdmin(to, from, next);
              },
            },
          ],
        },
        {
          path: "reviews",
          component: OrgReviews,
          meta: { requiresAuth: true },
        },
      ],
    },
    {
      path: "/goals",
      component: OrgGoals,
      meta: { requiresAuth: true },
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    let token = localStorage.getItem("RyBase.token");
    if (token) {
      next();
    } else {
      next({ path: "/" });
    }
  } else {
    next();
  }
});

export default router;
