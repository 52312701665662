<template>
  <dash-menu :links="links">
    <router-view></router-view>
  </dash-menu>
</template>

<script>
import filter from "lodash/filter";
import DashMenu from "./DashMenu";
import { hasRole } from "../util";

export default {
  components: {
    DashMenu
  },
  computed: {
    links() {
      let id = this.$route.params.id;
      let currentUser = this.$store.state.user.currentUser;
      let routes = [
        { name: "Stats", path: `/org/${id}`, auth: ["admin"] },
        {
          name: "Marketing",
          path: `/org/${id}/marketing`,
          auth: ["admin", "marketing"]
        },
        {
          name: "Intake",
          path: `/org/${id}/intake`,
          auth: ["admin", "intake-management"]
        },
        {
          name: "Fees",
          path: `/org/${id}/fees`,
          auth: ["admin", "marketing"]
        },
        {
          name: "Staff",
          path: `/org/${id}/staff`,
          auth: ["admin"]
        },
        {
          name: "Inventory",
          path: `/org/${id}/inventory`,
          auth: ["admin"]
        },
        {
          name: "Closed Cases",
          path: `/org/${id}/closed-cases`,
          auth: ["admin"]
        }
      ];
      return filter(routes, route => {
        return route.auth.some(role => hasRole(currentUser, role));
      });
    }
  }
};
</script>

<style scoped lang="scss"></style>
