<template>
  <div>
    <v-row>
      <v-col sm="12" md="8">
        <v-card>
          <v-toolbar flat dense>
            <span class="text-subtitle-1">Retained Cases By Month</span>
          </v-toolbar>
          <div class="chart-vis">
            <org-retained-cases-by-month :options="retainedCasesByMonth" />
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12" md="8">
        <v-card>
          <v-toolbar flat dense>
            <span class="text-subtitle-1"
              >Net Retained Cases By Month (Not closed within 60 days of
              retainer)</span
            >
          </v-toolbar>
          <div class="chart-vis">
            <org-retained-cases-by-month :options="netRetainedCasesByMonth" />
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12" md="8">
        <v-card>
          <v-toolbar flat dense>
            <span class="text-subtitle-1">Retained Cases By Source</span>
            <v-spacer></v-spacer>
            <div class="mt-5">
              <v-autocomplete
                v-model="source"
                :items="referralSources"
              ></v-autocomplete>
            </div>
          </v-toolbar>
          <div class="chart-vis">
            <org-retained-cases-by-month :options="retainedCasesBySource" />
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12" md="8">
        <v-card>
          <v-toolbar flat dense>
            <span class="text-subtitle-1">Leads By Month</span>
          </v-toolbar>
          <div class="chart-vis">
            <org-leads-wanted-by-month />
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import gql from "graphql-tag";
import { mapGetters } from "vuex";
import OrgRetainedCasesByMonth from "../charts/OrgRetainedCasesByMonth";
import OrgLeadsWantedByMonth from "../charts/OrgLeadsWantedByMonth";
import { buildRetainedCasesByMonth } from "../util";

export default {
  data() {
    return {
      source: "Ref-PPC"
    };
  },
  components: {
    OrgRetainedCasesByMonth,
    OrgLeadsWantedByMonth
  },
  apollo: {
    organization: {
      query: gql`
        query OrgHome($id: ID!, $source: String!) {
          organization(id: $id) {
            id
            name
            retainedCasesByMonth {
              count
              office
              month
              year
              yearMonth
            }
            netRetainedCasesByMonth {
              count
              office
              month
              year
              yearMonth
            }
            retainedCasesByMonthByOfficeByReferralSource(source: $source) {
              count
              office
              month
              year
              yearMonth
              referralSource
            }
            referralSources {
              name
            }
          }
        }
      `,
      variables() {
        return {
          id: this.$route.params.id,
          source: this.source
        };
      }
    }
  },
  computed: {
    ...mapGetters(["currentUser", "startDate", "endDate"]),
    retainedCasesByMonth() {
      if (!this.organization) return {};
      let rcbm = this.organization.retainedCasesByMonth;
      let series = buildRetainedCasesByMonth(rcbm);
      return {
        series
      };
    },
    retainedCasesBySource() {
      if (!this.organization) return {};
      let data = this.organization.retainedCasesByMonthByOfficeByReferralSource;
      let series = buildRetainedCasesByMonth(data);
      return {
        series
      };
    },
    netRetainedCasesByMonth() {
      if (!this.organization) return {};
      let nrcbm = this.organization.netRetainedCasesByMonth;
      let series = buildRetainedCasesByMonth(nrcbm);
      return {
        series
      };
    },
    referralSources() {
      if (!this.organization) return [];
      return this.organization.referralSources.map(s => {
        return s.name;
      });
    }
  }
};
</script>

<style scoped lang="scss"></style>
