<template>
  <v-card>
    <v-alert :type="alertType" v-if="alert">{{ alertMessage }}</v-alert>
    <v-container class="py-0" fluid>
      <v-row align-content="space-around">
        <v-col cols="12" sm="2">
          <v-card-title class="pa-0">
            <div>
              <div class="headline">Referrals</div>
              <div class="text-subtitle-1 font-weight-thin" v-if="listView">
                {{ referrals.length }} Cases
              </div>
            </div>
          </v-card-title>
        </v-col>
        <v-col cols="12" sm="3">
          <v-radio-group v-model="listView" row>
            <v-radio label="List" :value="true"></v-radio>
            <v-radio label="Aggregate" :value="false"></v-radio>
          </v-radio-group>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="2" v-if="listView">
          <v-radio-group v-model="referredBy" row>
            <v-radio label="By" :value="true"></v-radio>
            <v-radio label="To" :value="false"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            hide-details
            single-line
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
    <v-data-table
      :footer-props="{
        'items-per-page-options': [-1],
      }"
      :headers="headers"
      hide-default-footer
      :items="referrals"
      :loading="$apollo.loading"
      :search="search"
      :sort-by="referredBy ? ['retainedDate'] : ['referredDate']"
      :sort-desc="[false]"
      v-if="listView"
    >
      <template v-slot:item.caseId="{ item }">
        <a
          class="go-to-case-link mr-2"
          target="_blank"
          :href="
            `https://cs-web.airdesksolutions.com/SA/CaseSummary.aspx?CaseID=${item.caseId}`
          "
        >
          <v-icon size="15">mdi-open-in-new</v-icon>
        </a>
      </template>
      <template v-slot:item.referralSourceOther="{ item }">
        {{ item.referralSourceOther }}
        <v-icon small color="green" v-if="item.contact.name"
          >mdi-alpha-c-circle</v-icon
        >
        <v-icon
          small
          v-if="!item.contact.name"
          @click="openNewContactDialog(item)"
          >mdi-plus</v-icon
        >
      </template>
      <template v-slot:item.medicalProvider="{ item }">
        {{ item.medicalProvider }}
        <v-icon small color="green" v-if="item.contact.name"
          >mdi-alpha-c-circle</v-icon
        >
        <v-icon
          small
          v-if="!item.contact.name"
          @click="openNewContactDialog(item)"
          >mdi-plus</v-icon
        >
      </template>
    </v-data-table>
    <v-card v-else v-for="market in referrals" :key="market.office">
      <v-card-title>
        {{ market.office }}
      </v-card-title>
      <v-data-table
        :footer-props="{
          'items-per-page-options': [-1],
        }"
        :headers="headers"
        hide-default-footer
        :items="market.medicalProviders"
        :loading="$apollo.loading"
        :search="search"
        :sort-by="['medicalProvider']"
        :sort-desc="[false]"
      >
        <template v-slot:item.referredTo="{ item }">
          <a @click="openAggDialog(item, 'to')">{{ item.referredTo }}</a>
        </template>
        <template v-slot:item.referredBy="{ item }">
          <a @click="openAggDialog(item, 'by')">{{ item.referredBy }}</a>
        </template>
        <template v-slot:item.difference="{ item }">
          <v-chip
            x-small
            :color="item.referredTo - item.referredBy < 0 ? 'red' : 'green'"
            >{{ item.referredTo - item.referredBy }}</v-chip
          >
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialog" width="80%">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          {{ dialogProvider }}
        </v-card-title>

        <v-data-table
          :footer-props="{
            'items-per-page-options': [-1],
          }"
          :headers="dialogHeaders"
          hide-default-footer
          :items="dialogList"
          :loading="$apollo.loading"
          :sort-by="
            dialogDirection === 'by' ? ['retainedDate'] : ['referredDate']
          "
          :sort-desc="[false]"
        >
          <template v-slot:item.caseId="{ item }">
            <a
              class="go-to-case-link mr-2"
              target="_blank"
              :href="
                `https://cs-web.airdesksolutions.com/SA/CaseSummary.aspx?CaseID=${item.caseId}`
              "
            >
              <v-icon size="15">mdi-open-in-new</v-icon>
            </a>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
    <v-dialog v-model="contactDialog" hide-overlay width="300">
      <v-card>
        <v-card-title>New Contact</v-card-title>
        <v-card-text>
          {{ selectedContact.referralSourceOther }}
        </v-card-text>
        <v-card-text>
          <v-select
            :items="contactTypeOptions"
            label="Contact Type"
            v-model="selectedContactType"
            dense
          ></v-select>
          <v-btn
            @click="addContact"
            color="primary"
            :disabled="!selectedContactType"
            >Add Contact</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import gql from "graphql-tag";
import filter from "lodash/filter";
import groupBy from "lodash/groupBy";
import findIndex from "lodash/findIndex";
import uniqBy from "lodash/uniqBy";
import { mapGetters } from "vuex";
import { DASH_INTAKE_REFERRALS } from "../graphql/intake.gql";

export default {
  data() {
    return {
      alert: false,
      alertType: false,
      alertMessage: false,
      search: "",
      organization: { newCasesInPeriod: [] },
      referredBy: true,
      listView: true,
      dialog: false,
      dialogProvider: "",
      dialogDirection: "",
      dialogList: [],
      contactDialog: false,
      selectedContactType: false,
      selectedContact: false,
    };
  },
  methods: {
    openAggDialog(item, direction) {
      this.dialogProvider = item.medicalProvider;
      this.dialogDirection = direction;
      if (direction === "to") {
        this.dialogList = filter(this.referredTo, (c) => {
          return c.medicalProvider === item.medicalProvider;
        });
      } else {
        this.dialogList = filter(this.refBy, (c) => {
          return c.referralSourceOther === item.medicalProvider;
        });
      }
      this.dialog = true;
    },
    openNewContactDialog(item) {
      this.contactDialog = true;
      this.selectedContact = item;
    },
    addContact() {
      let contact = {
        sa_id:
          this.selectedContact.referralSourceOtherContactId ||
          this.selectedContact.saContactId,
        sa_ctg:
          this.selectedContact.referralSourceOtherContactCtg ||
          this.selectedContact.saContactCtg,
        contact_type_id: parseInt(this.selectedContactType, 10),
      };
      this.$apollo
        .mutate({
          mutation: gql`
            mutation CreateContact($contact: ContactInput!) {
              createContact(contact: $contact) {
                id
                name
              }
            }
          `,
          variables: {
            contact,
          },
          update: () => {
            this.$apollo.queries.organization.refetch();
            this.$apollo.queries.referredTo.refetch();
          },
        })
        .then(() => {
          this.contactDialog = false;
          this.selectedContact = false;
          this.selectedContactType = false;
        })
        .catch((error) => {
          console.error(error);
          this.alert = true;
          this.alertType = "error";
          this.alertMessage = error.message;
          setTimeout(() => {
            this.alert = false;
          }, 3000);
        });
      return false;
    },
  },
  computed: {
    ...mapGetters(["currentUser", "startDate", "endDate"]),
    totalRows() {
      return this.organization.newCasesInPeriod.length;
    },
    dialogHeaders() {
      if (this.dialogDirection === "by") {
        return [
          { text: "Action", value: "caseId", width: "100px" },
          { text: "Client", value: "clientName" },
          { text: "Type", value: "referralSource" },
          { text: "Referred By", value: "referralSourceOther" },
          { text: "Attorney", value: "attorney" },
          { text: "CM", value: "paralegal" },
          { text: "Retained", value: "retainedDate" },
          { text: "State", value: "state" },
          { text: "Office", value: "office" },
        ];
      } else {
        return [
          { text: "Action", value: "caseId", width: "100px" },
          { text: "Client", value: "clientName" },
          { text: "Referred To", value: "medicalProvider" },
          { text: "Attorney", value: "attorney" },
          { text: "CM", value: "paralegal" },
          { text: "Referred", value: "referredDate" },
          { text: "Office", value: "office" },
        ];
      }
    },
    headers() {
      if (this.listView) {
        if (this.referredBy) {
          return [
            { text: "Action", value: "caseId", width: "100px" },
            { text: "Client", value: "clientName" },
            { text: "Type", value: "referralSource" },
            { text: "Referred By", value: "referralSourceOther" },
            { text: "Attorney", value: "attorney" },
            { text: "CM", value: "paralegal" },
            { text: "Retained", value: "retainedDate" },
            { text: "State", value: "state" },
            { text: "Office", value: "office" },
          ];
        } else {
          return [
            { text: "Action", value: "caseId", width: "100px" },
            { text: "Client", value: "clientName" },
            { text: "Referred To", value: "medicalProvider" },
            { text: "Attorney", value: "attorney" },
            { text: "CM", value: "paralegal" },
            { text: "Referred", value: "referredDate" },
            { text: "Office", value: "office" },
          ];
        }
      } else {
        return [
          {
            text: "Referral Partner",
            value: "medicalProvider",
            width: "300px",
          },
          { text: "To", value: "referredTo", width: "80px" },
          { text: "From", value: "referredBy", width: "80px" },
          { text: "Difference", value: "difference" },
        ];
      }
    },
    refBy() {
      if (!this.organization) return [];
      return filter(this.organization.newCasesInPeriod, (c) => {
        return (
          c.referralSource === "Ref-Doctors" ||
          c.referralSource === "Ref-Auto Body" ||
          c.referralSource === "Ref-Tow Truck" ||
          c.referralSource === "Ref-BNI" ||
          c.referralSource === "Ref-Partners"
        );
      });
    },
    referrals() {
      let refByGroupedByOffice = groupBy(this.refBy, "office");
      let refByGroupedByOfficeProvider = Object.keys(refByGroupedByOffice).map(
        (k) => {
          let groupedBySource = groupBy(
            refByGroupedByOffice[k],
            "referralSourceOther"
          );
          let providersAndTotalReferredBy = Object.keys(groupedBySource).map(
            (innerKey) => {
              return {
                medicalProvider: innerKey,
                referredBy: groupedBySource[innerKey].length,
              };
            }
          );
          return { office: k, medicalProviders: providersAndTotalReferredBy };
        }
      );
      let refToGroupedByOffice = groupBy(this.referredTo, "office");
      let refToGroupedByOfficeProvider = Object.keys(refToGroupedByOffice).map(
        (k) => {
          let groupedBySource = groupBy(
            refToGroupedByOffice[k],
            "medicalProvider"
          );
          let providersAndTotalReferredTo = Object.keys(groupedBySource).map(
            (innerKey) => {
              return {
                medicalProvider: innerKey,
                referredTo: groupedBySource[innerKey].length,
              };
            }
          );
          return { office: k, medicalProviders: providersAndTotalReferredTo };
        }
      );
      let refByOffices = Object.keys(
        groupBy(refByGroupedByOfficeProvider, "office")
      );
      let refToOffices = Object.keys(
        groupBy(refToGroupedByOfficeProvider, "office")
      );
      let combinedOffices = [...new Set([...refByOffices, ...refToOffices])];
      let aggregateData = combinedOffices.map((o) => {
        let byOfficeIndex = findIndex(refByGroupedByOfficeProvider, (x) => {
          return x.office === o;
        });
        let toOfficeIndex = findIndex(refToGroupedByOfficeProvider, (x) => {
          return x.office === o;
        });
        let byProviders = refByGroupedByOfficeProvider[byOfficeIndex]
          ? refByGroupedByOfficeProvider[byOfficeIndex].medicalProviders
          : [];
        let toProviders = refToGroupedByOfficeProvider[toOfficeIndex]
          ? refToGroupedByOfficeProvider[toOfficeIndex].medicalProviders
          : [];

        let byToProviders = byProviders.concat(toProviders);
        let medicalProviders = byToProviders.map((p) => {
          return filter(byToProviders, (pi) => {
            return pi.medicalProvider === p.medicalProvider;
          }).reduce(
            (prev, cur) => {
              return Object.assign(prev, cur, {});
            },
            { referredTo: 0, referredBy: 0 }
          );
        });

        let noDuplicates = uniqBy(medicalProviders, "medicalProvider");

        return { office: o, medicalProviders: noDuplicates };
      });

      if (this.listView) {
        if (this.referredBy) {
          return this.refBy;
        } else {
          return this.referredTo;
        }
      } else {
        return aggregateData;
      }
    },
    contactTypeOptions() {
      if (!this.contactTypes) return [];
      return this.contactTypes.map((c) => {
        return {
          text: c.name,
          value: c.id,
        };
      });
    },
  },
  apollo: {
    organization: {
      query: DASH_INTAKE_REFERRALS,
      variables() {
        return {
          id: this.currentUser.organization.id,
          startDate: this.startDate,
          endDate: this.endDate,
        };
      },
      skip() {
        return this.currentUser ? false : true;
      },
    },
    referredTo: {
      query: gql`
        query DashIntakeReferralsTo($startDate: String!, $endDate: String!) {
          referredTo: medicalReferredToInPeriod(
            startDate: $startDate
            endDate: $endDate
          ) {
            id
            caseId
            saContactId
            saContactCtg
            medicalProvider
            referredDate
            attorney
            paralegal
            clientName
            office
            contact {
              id
              name
            }
          }
        }
      `,
      variables() {
        return {
          startDate: this.startDate,
          endDate: this.endDate,
        };
      },
      skip() {
        return this.currentUser ? false : true;
      },
    },
    contactTypes: {
      query: gql`
        query ContactTypes {
          contactTypes {
            id
            name
          }
        }
      `,
    },
  },
};
</script>

<style scoped></style>
