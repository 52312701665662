<template>
  <v-card>
    <v-alert class="alert" :type="alertType" v-if="alert">{{
      alertMessage
    }}</v-alert>
    <v-toolbar flat>
      <v-toolbar-title class="headline">Contacts</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        :loading="syncingContacts"
        :disabled="syncingContacts"
        class="mr-2"
        color="primary"
        @click.native.stop="syncContacts"
        >Sync Contacts</v-btn
      >
      <v-btn color="primary" @click.native.stop="contactFormDialog = true"
        >New Contact</v-btn
      >
    </v-toolbar>
    <v-container class="py-0" fluid>
      <v-row align-content="space-around">
        <v-spacer></v-spacer>
        <v-col cols="8" sm="4">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            hide-details
            single-line
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
    <v-data-table
      :footer-props="{
        'items-per-page-options': [-1],
      }"
      :headers="headers"
      hide-default-footer
      :items="contacts"
      item-key="saId"
      :loading="$apollo.loading"
      :search="search"
      :sort-by="['name']"
      :sort-desc="[false]"
    >
      <template v-slot:item.saId="{ item }">
        <a
          class="go-to-case-link mr-2"
          target="_blank"
          :href="
            `https://cs-web.airdesksolutions.com/SA/Edit_Contact.aspx?ID=${item.saId}&CTG=${item.saCtg}`
          "
        >
          {{ item.saId }}
        </a>
        <a v-if="item.needsManualUpdate">
          <v-icon color="red" size="15">mdi-alert-outline</v-icon>
        </a>
      </template>
      <template v-slot:item.priority="{ item }">
        <span
          @click="updatePriority(item.id, false)"
          v-if="item.priority"
          class="green--text"
          >Yes</span
        >
        <span
          @click="updatePriority(item.id, true)"
          v-if="!item.priority"
          class="grey--text lighten-3"
          >No</span
        >
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small @click="editContact(item)">
          mdi-pencil
        </v-icon>
      </template>
    </v-data-table>
    <v-dialog v-model="contactFormDialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ contactFormType }} Contact</span>
        </v-card-title>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-autocomplete
                v-model="contactName"
                :search-input.sync="contactNameSearch"
                :items="contactNameOptions"
                @keyup="getContactsByName"
                label="Name"
                :loading="$apollo.loading"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="contactType"
                label="Type"
                :items="contactTypeOptions"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn
                @click="addContact"
                color="primary"
                :disabled="!contactType"
                >Add Contact</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editContactDialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Edit Contact</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="editedContact.name"
                  label="Name"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="editedContact.email"
                  label="Email"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="editedContact.phone"
                  label="Phone"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="editedContact.address"
                  label="Address"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="editedContact.city"
                  label="City"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="editedContact.state"
                  label="State"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="editedContact.zip"
                  label="ZIP"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeEdit">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="saveEdit">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import gql from "graphql-tag";
import { mapGetters } from "vuex";
import debounce from "lodash/debounce";
import findIndex from "lodash/findIndex";

export default {
  data() {
    return {
      alert: false,
      alertType: false,
      alertMessage: false,
      headers: [
        { text: "SA Link", value: "saId", width: "100px" },
        { text: "Name", value: "name" },
        { text: "Priority", value: "priority" },
        { text: "Inbound", value: "inboundReferrals" },
        { text: "Outbound", value: "outboundReferrals" },
        { text: "Type", value: "contactType.name" },
        { text: "Address", value: "address" },
        { text: "City", value: "city" },
        { text: "State", value: "state" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      contacts: [],
      search: "",
      contactFormDialog: false,
      contactFormType: "New",
      contactType: "",
      contactName: "",
      contactNameSearch: "",
      contactNameSearchResults: [],
      syncingContacts: false,
      editContactDialog: false,
      editedContact: {
        id: null,
        name: "",
        email: "",
        phone: "",
        address: "",
        city: "",
        state: "",
        zip: "",
      },
    };
  },
  apollo: {
    contacts: {
      query: gql`
        query CRMContacts {
          contacts {
            id
            saId
            saCtg
            contactType {
              name
            }
            name
            address
            city
            state
            zip
            phone
            email
            priority
            inboundReferrals
            outboundReferrals
            lat
            lng
            needsManualUpdate
          }
        }
      `,
      skip() {
        return this.currentUser ? false : true;
      },
    },
    contactTypes: {
      query: gql`
        query ContactTypes {
          contactTypes {
            id
            name
          }
        }
      `,
    },
  },
  computed: {
    ...mapGetters(["currentUser"]),
    contactTypeOptions() {
      if (!this.contactTypes) return [];
      return this.contactTypes.map((c) => {
        return {
          text: c.name,
          value: c.id,
        };
      });
    },
    contactNameOptions() {
      return this.contactNameSearchResults.map((c) => {
        return {
          text: `${c.name} - ${c.address} ${c.city} ${c.state} ${c.zip}`,
          value: c.saId,
        };
      });
    },
  },
  methods: {
    getContactsByName: debounce(function() {
      if (this.contactNameSearch.length < 5) return;
      this.$apollo
        .query({
          query: gql`
            query CRMContactsByName($name: String!) {
              contactsByName(name: $name) {
                saId
                saCtg
                name
                address
                city
                state
                zip
                phone
                email
              }
            }
          `,
          variables: {
            name: this.contactNameSearch,
          },
        })
        .then((response) => {
          console.log(response.data.contactsByName);
          this.contactNameSearchResults = response.data.contactsByName;
        });
    }, 500),
    updatePriority(contactId, priority) {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation updateContact($contactId: Int!, $contact: ContactInput!) {
              updateContact(contactId: $contactId, contact: $contact) {
                id
                name
              }
            }
          `,
          variables: {
            contactId: parseInt(contactId),
            contact: {
              priority,
            },
          },
          update: () => {
            this.$apollo.queries.contacts.refetch();
          },
        })
        .then(() => {})
        .catch((error) => {
          console.error(error);
          this.alert = true;
          this.alertType = "error";
          this.alertMessage = error.message;
          setTimeout(() => {
            this.alert = false;
          }, 3000);
        });
      return false;
    },
    addContact() {
      let index = findIndex(this.contactNameSearchResults, (c) => {
        return (c.saId = this.contactName);
      });

      let contact = {
        sa_id: this.contactNameSearchResults[index].saId,
        sa_ctg: this.contactNameSearchResults[index].saCtg,
        contact_type_id: parseInt(this.contactType, 10),
      };

      this.$apollo
        .mutate({
          mutation: gql`
            mutation CreateContact($contact: ContactInput!) {
              createContact(contact: $contact) {
                id
                name
              }
            }
          `,
          variables: {
            contact,
          },
          update: () => {
            this.$apollo.queries.contacts.refetch();
          },
        })
        .then(() => {
          this.contactFormDialog = false;
          this.contactName = "";
          this.contactType = "";
          this.contactNameSearchResults = [];
          this.contactNameSearch = "";
        })
        .catch((error) => {
          console.error(error);
          this.alert = true;
          this.alertType = "error";
          this.alertMessage = error.message;
          setTimeout(() => {
            this.alert = false;
          }, 3000);
        });
      return false;
    },
    syncContacts() {
      this.syncingContacts = true;
      this.$apollo
        .mutate({
          mutation: gql`
            mutation SyncContacts {
              syncContacts {
                result
              }
            }
          `,
          update: () => {
            this.$apollo.queries.contacts.refetch();
          },
        })
        .then(() => {
          this.syncingContacts = false;
          this.alert = true;
          this.alertType = "success";
          this.alertMessage = "Contacts synced successfully";
          setTimeout(() => {
            this.alert = false;
          }, 3000);
        })
        .catch((error) => {
          this.syncingContacts = false;
          console.error(error);
          this.alert = true;
          this.alertType = "error";
          this.alertMessage = error.message;
          setTimeout(() => {
            this.alert = false;
          }, 3000);
        });
      return false;
    },
    editContact(item) {
      this.editedContact = { ...item };
      this.editContactDialog = true;
    },
    closeEdit() {
      this.editContactDialog = false;
      this.$nextTick(() => {
        this.editedContact = {
          id: null,
          name: "",
          email: "",
          phone: "",
          address: "",
          city: "",
          state: "",
          zip: "",
        };
      });
    },
    saveEdit() {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation updateContact($contactId: Int!, $contact: ContactInput!) {
              updateContact(contactId: $contactId, contact: $contact) {
                id
                name
                email
                phone
                address
                city
                state
                zip
              }
            }
          `,
          variables: {
            contactId: parseInt(this.editedContact.id, 10),
            contact: {
              name: this.editedContact.name,
              email: this.editedContact.email,
              phone: this.editedContact.phone,
              address: this.editedContact.address,
              city: this.editedContact.city,
              state: this.editedContact.state,
              zip: this.editedContact.zip,
            },
          },
        })
        .then(() => {
          this.closeEdit();
          this.$apollo.queries.contacts.refetch();
        })
        .catch((error) => {
          console.error(error);
          this.alert = true;
          this.alertType = "error";
          this.alertMessage = error.message;
          setTimeout(() => {
            this.alert = false;
          }, 3000);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.alert {
  position: fixed;
  left: 50%;
  bottom: 50px;
  transform: translate(-50%, -50%);
  margin: 0 auto;
}
</style>
