<template>
  <v-app>
    <v-snackbar :timeout="0" top v-model="emailSent">
      Email sent.
      <router-link to="/">Login</router-link>
    </v-snackbar>
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="6">
          <v-card class="elevation-12">
            <v-toolbar dark color="primary">
              <v-toolbar-title>New Password</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <loader v-if="sendingEmail"></loader>
              <v-form v-if="!sendingEmail">
                <v-text-field
                  prepend-icon="mdi-account"
                  name="email"
                  label="Email"
                  type="text"
                  v-model="email"
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn text color="blue" to="/">Login</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="onSubmit"
                >Send Password Reset Email</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import Loader from "./Loader";
import gql from "graphql-tag";
import { routeToDashboard } from "../util";

export default {
  name: "ForgotPassword",
  data() {
    return {
      email: "",
      sendingEmail: false,
      emailSent: false
    };
  },
  components: {
    Loader
  },
  computed: {
    loggedIn() {
      return this.$store.state.user.loginStatus === "loggedIn";
    }
  },
  mounted: function() {
    if (this.loggedIn) {
      let user = this.$store.state.user.currentUser;
      routeToDashboard(user, this.$router);
    }
  },
  methods: {
    onSubmit(e) {
      e.preventDefault();
      this.sendingEmail = true;
      this.$apollo
        .mutate({
          mutation: gql`
            mutation($email: String) {
              sendPasswordResetEmail(email: $email) {
                email
              }
            }
          `,
          variables: {
            email: this.email
          }
        })
        .then(() => {
          this.sendingEmail = false;
          this.emailSent = true;
        })
        .catch(error => {
          this.sendingEmail = false;
          console.error(error);
        });
    }
  }
};
</script>
