<template>
  <v-container fluid>
    <!-- Team Selection -->
    <v-card class="mb-5">
      <v-card-title v-if="isAdmin">Filter Options</v-card-title>
      <v-card-text v-if="isAdmin">
        <v-select
          :value="selectedTeam"
          @input="updateSelectedTeam"
          :items="teamOptions"
          item-text="name"
          item-value="id"
          label="Select Team"
          clearable
          :disabled="!isAdmin"
        ></v-select>
        <div class="mt-2">
          <v-checkbox
            v-model="showAllParalegals"
            label="Show all paralegals"
            @change="onShowAllParalegalsChange"
          ></v-checkbox>
        </div>
      </v-card-text>
    </v-card>

    <!-- Demands Table -->
    <v-card class="mb-5">
      <v-card-title>Demands by Month</v-card-title>
      <!-- Horizontal scroll wrapper -->
      <div style="overflow-x: auto; width: 100%;">
        <v-data-table
          :headers="demandsHeaders"
          :items="demandsTableData"
          class="elevation-1"
        >
          <template v-slot:item="{ item, headers }">
            <tr>
              <td>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">{{ item.paralegal }}</span>
                  </template>
                  <span>{{ getParalegalFullName(item.paralegal) }}</span>
                </v-tooltip>
              </td>
              <td :class="getDemandsAverageClass(item.total, item.monthCount)">
                <a
                  @click="showDemandDetails(item.paralegal, null, item.total)"
                  class="clickable-cell"
                >
                  {{ item.total }}
                </a>
                ({{ formatAverage(item.total, item.monthCount) }}/mo)
              </td>
              <td
                v-for="header in headers.slice(2)"
                :key="header.value"
                :class="getDemandsClass(item[header.value])"
              >
                <a
                  v-if="item[header.value] > 0"
                  @click="
                    showDemandDetails(
                      item.paralegal,
                      header.value,
                      item[header.value]
                    )
                  "
                  class="clickable-cell"
                >
                  {{ item[header.value] }}
                </a>
                <span v-else>{{ item[header.value] }}</span>
              </td>
            </tr>
          </template>
        </v-data-table>
      </div>
    </v-card>

    <!-- Settlements Table -->
    <v-card class="mb-5">
      <v-card-title>Settlements by Month</v-card-title>
      <div style="overflow-x: auto; width: 100%;">
        <v-data-table
          :headers="settlementsHeaders"
          :items="settlementsTableData"
          class="elevation-1"
        >
          <template v-slot:item="{ item, headers }">
            <tr>
              <td>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">{{ item.paralegal }}</span>
                  </template>
                  <span>{{ getParalegalFullName(item.paralegal) }}</span>
                </v-tooltip>
              </td>
              <td :class="getDemandsAverageClass(item.total, item.monthCount)">
                <a
                  @click="
                    showSettlementDetails(item.paralegal, null, item.total)
                  "
                  class="clickable-cell"
                >
                  {{ item.total }}
                </a>
                ({{ formatAverage(item.total, item.monthCount) }}/mo)
              </td>
              <td
                v-for="header in headers.slice(2)"
                :key="header.value"
                :class="getDemandsClass(item[header.value])"
              >
                <a
                  v-if="item[header.value] > 0"
                  @click="
                    showSettlementDetails(
                      item.paralegal,
                      header.value,
                      item[header.value]
                    )
                  "
                  class="clickable-cell"
                >
                  {{ item[header.value] }}
                </a>
                <span v-else>{{ item[header.value] }}</span>
              </td>
            </tr>
          </template>
        </v-data-table>
      </div>
    </v-card>

    <!-- Fees Table -->
    <v-card>
      <v-card-title>Fees by Month</v-card-title>
      <div style="overflow-x: auto; width: 100%;">
        <v-data-table
          :headers="feesHeaders"
          :items="feesTableData"
          class="elevation-1"
        >
          <template v-slot:item="{ item, headers }">
            <tr>
              <td>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">{{ item.paralegal }}</span>
                  </template>
                  <span>{{ getParalegalFullName(item.paralegal) }}</span>
                </v-tooltip>
              </td>
              <td :class="getFeesAverageClass(item.total, item.monthCount)">
                <a
                  @click="showFeeDetails(item.paralegal, null, item.total)"
                  class="clickable-cell"
                >
                  {{ formatCurrency(item.total) }}
                </a>
                ({{ formatCurrency(item.total / (item.monthCount || 1)) }}/mo)
              </td>
              <td
                v-for="header in headers.slice(2)"
                :key="header.value"
                :class="getFeesClass(item[header.value])"
              >
                <a
                  v-if="item[header.value] > 0"
                  @click="
                    showFeeDetails(
                      item.paralegal,
                      header.value,
                      item[header.value]
                    )
                  "
                  class="clickable-cell"
                >
                  {{ formatCurrency(item[header.value]) }}
                </a>
                <span v-else>{{ formatCurrency(item[header.value]) }}</span>
              </td>
            </tr>
          </template>
        </v-data-table>
      </div>
    </v-card>

    <!-- Demand Details Dialog -->
    <v-dialog v-model="demandDetailsDialog" max-width="900px" scrollable>
      <v-card v-if="selectedDemandDetails">
        <v-card-title class="headline">
          {{ getDemandTitlePrefix() }}
          {{ getParalegalFullName(selectedDemandDetails.paralegal) }}
          {{ getDemandTitleSuffix() }}
          <v-spacer></v-spacer>
          <v-btn icon @click="demandDetailsDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-progress-circular
            v-if="selectedDemandDetails.loading"
            indeterminate
            color="primary"
            size="64"
            class="my-6 d-block mx-auto"
          ></v-progress-circular>

          <div
            v-else-if="selectedDemandDetails.error"
            class="text-center red--text"
          >
            {{ selectedDemandDetails.error }}
          </div>

          <div
            v-else-if="selectedDemandDetails.demands.length === 0"
            class="text-center"
          >
            No demand details found for this selection.
          </div>

          <template v-else>
            <v-card-subtitle class="pb-0">
              {{ selectedDemandDetails.demands.length }} demands found
            </v-card-subtitle>

            <v-text-field
              v-model="demandDetailsSearch"
              label="Search demands"
              prepend-icon="mdi-magnify"
              clearable
              hide-details
              class="mb-4"
            ></v-text-field>

            <v-data-table
              :headers="demandDetailsHeaders"
              :items="selectedDemandDetails.demands"
              :items-per-page="10"
              sort-by="demandDate"
              sort-desc
              :search="demandDetailsSearch"
              :footer-props="{
                'items-per-page-options': [5, 10, 15, 20, -1],
                'items-per-page-text': 'Rows per page:',
              }"
              class="elevation-1"
            >
              <template v-slot:item.demandDate="{ item }">
                {{ formatDate(item.demandDate) }}
              </template>
              <template v-slot:item.paralegal="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">{{ item.paralegal }}</span>
                  </template>
                  <span>{{ getParalegalFullName(item.paralegal) }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </template>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="success"
            text
            :disabled="
              !selectedDemandDetails ||
                !selectedDemandDetails.demands ||
                selectedDemandDetails.demands.length === 0
            "
            @click="exportToCSV"
          >
            <v-icon left>mdi-file-export</v-icon>
            Export to CSV
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="demandDetailsDialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Fee Details Dialog -->
    <v-dialog v-model="feeDetailsDialog" max-width="900px" scrollable>
      <v-card v-if="selectedFeeDetails">
        <v-card-title class="headline">
          {{ getFeeTitlePrefix() }}
          {{ getParalegalFullName(selectedFeeDetails.paralegal) }}
          {{ getFeeTitleSuffix() }}
          <v-spacer></v-spacer>
          <v-btn icon @click="feeDetailsDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-progress-circular
            v-if="selectedFeeDetails.loading"
            indeterminate
            color="primary"
            size="64"
            class="my-6 d-block mx-auto"
          ></v-progress-circular>

          <div
            v-else-if="selectedFeeDetails.error"
            class="text-center red--text"
          >
            {{ selectedFeeDetails.error }}
          </div>

          <div
            v-else-if="selectedFeeDetails.fees.length === 0"
            class="text-center"
          >
            No fee details found for this selection.
          </div>

          <template v-else>
            <v-card-subtitle class="pb-0 d-flex align-center">
              <div>{{ selectedFeeDetails.fees.length }} fees found</div>
              <v-spacer></v-spacer>
              <div class="subtitle-1 font-weight-bold">
                Total: {{ formatCurrency(calculateFeesTotal()) }}
              </div>
            </v-card-subtitle>

            <v-text-field
              v-model="feeDetailsSearch"
              label="Search fees"
              prepend-icon="mdi-magnify"
              clearable
              hide-details
              class="mb-4"
            ></v-text-field>

            <v-data-table
              :headers="feeDetailsHeaders"
              :items="selectedFeeDetails.fees"
              :items-per-page="10"
              sort-by="feeDate"
              sort-desc
              :search="feeDetailsSearch"
              :footer-props="{
                'items-per-page-options': [5, 10, 15, 20, -1],
                'items-per-page-text': 'Rows per page:',
              }"
              class="elevation-1"
            >
              <template v-slot:item.fee="{ item }">
                {{ formatCurrency(item.fee) }}
              </template>
              <template v-slot:item.feeDate="{ item }">
                {{ formatDate(item.feeDate) }}
              </template>
              <template v-slot:item.paralegal="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">{{ item.paralegal }}</span>
                  </template>
                  <span>{{ getParalegalFullName(item.paralegal) }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </template>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="success"
            text
            :disabled="
              !selectedFeeDetails ||
                !selectedFeeDetails.fees ||
                selectedFeeDetails.fees.length === 0
            "
            @click="exportFeesToCSV"
          >
            <v-icon left>mdi-file-export</v-icon>
            Export to CSV
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="feeDetailsDialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Settlement Details Dialog -->
    <v-dialog v-model="settlementDetailsDialog" max-width="800">
      <v-card>
        <v-card-title>
          <span v-if="selectedSettlementDetails">
            {{ getSettlementTitlePrefix() }}
            by {{ getParalegalFullName(selectedSettlementDetails.paralegal) }}
            {{ getSettlementTitleSuffix() }}
          </span>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="searchSettlementDetails"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-card-text>
          <v-data-table
            v-if="
              selectedSettlementDetails && !selectedSettlementDetails.loading
            "
            :headers="[
              { text: 'Case Number', value: 'caseNumber' },
              { text: 'Client Name', value: 'clientName' },
              { text: 'Settlement Date', value: 'settlementDate' },
              { text: 'Paralegal', value: 'paralegal' },
            ]"
            :items="selectedSettlementDetails.settlements"
            :search="searchSettlementDetails"
            :sort-by="['settlementDate']"
            :sort-desc="[true]"
            class="elevation-1"
          >
            <template v-slot:item.settlementDate="{ item }">
              {{ formatDate(item.settlementDate) }}
            </template>
            <template v-slot:item.paralegal="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">{{ item.paralegal }}</span>
                </template>
                <span>{{ getParalegalFullName(item.paralegal) }}</span>
              </v-tooltip>
            </template>
          </v-data-table>
          <div
            v-else-if="
              selectedSettlementDetails && selectedSettlementDetails.loading
            "
            class="text-center pa-5"
          >
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
            <div class="mt-2">Loading settlement details...</div>
          </div>
          <div
            v-else-if="
              selectedSettlementDetails && selectedSettlementDetails.error
            "
            class="error--text pa-5"
          >
            {{ selectedSettlementDetails.error }}
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            text
            @click="exportSettlementsToCSV"
            :disabled="
              !selectedSettlementDetails ||
                selectedSettlementDetails.loading ||
                selectedSettlementDetails.settlements.length === 0
            "
          >
            Export to CSV
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="settlementDetailsDialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import gql from "graphql-tag";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "MonthlyStatsTables",
  data() {
    return {
      // Raw data from GraphQL
      demandsRaw: [],
      settlementsRaw: [],
      feesRaw: [],

      // Processed table data/headers
      demandsHeaders: [],
      demandsTableData: [],
      settlementsHeaders: [],
      settlementsTableData: [],
      feesHeaders: [],
      feesTableData: [],

      // Simple loading indicator
      loadingQueries: 0,
      totalQueries: 3,
      isRefreshing: false,

      // Team selection
      teamOptions: [],
      paralegalIds: [], // IDs of paralegals to filter by
      showAllParalegals: false,

      // Store the imitated user's data
      imitatedUserData: null,

      // Store organization data
      organization: null,

      // New data for showing demand details
      selectedDemandDetails: null,
      demandDetailsDialog: false,
      demandDetailsHeaders: [
        { text: "Case Number", value: "caseNumber", width: "180px" },
        { text: "Client Name", value: "clientName", width: "180px" },
        { text: "Demand Date", value: "demandDate", width: "120px" },
        { text: "Paralegal", value: "paralegal", width: "150px" },
      ],
      demandDetailsSearch: "",

      // New data for showing fee details
      selectedFeeDetails: null,
      feeDetailsDialog: false,
      feeDetailsHeaders: [
        { text: "Case Number", value: "caseNumber", width: "180px" },
        { text: "Client Name", value: "clientName", width: "200px" },
        { text: "Fee", value: "fee", width: "120px", align: "right" },
        { text: "Fee Date", value: "feeDate", width: "120px" },
        { text: "Paralegal", value: "paralegal", width: "150px" },
      ],
      feeDetailsSearch: "",

      // New data for showing settlement details
      selectedSettlementDetails: null,
      settlementDetailsDialog: false,
      searchSettlementDetails: "",
    };
  },
  computed: {
    ...mapGetters([
      "startDate",
      "endDate",
      "selectedTeam",
      "currentUser",
      "imitatedUser",
    ]),

    // Determine if the current user is an admin
    isAdmin() {
      if (!this.currentUser || !this.currentUser.roles) return false;
      return this.currentUser.roles.some((role) => role.name === "admin");
    },

    // Get the current user's team ID - if imitated user is set, use their team instead
    currentUserTeamId() {
      // If we have an imitated user and the imitated user data is loaded
      if (
        this.imitatedUser &&
        this.imitatedUserData &&
        this.imitatedUserData.teams &&
        this.imitatedUserData.teams.length > 0
      ) {
        return this.imitatedUserData.teams[0].id;
      }

      // Otherwise fall back to current user's team
      if (
        !this.currentUser ||
        !this.currentUser.teams ||
        this.currentUser.teams.length === 0
      ) {
        return null;
      }
      return this.currentUser.teams[0].id;
    },
  },
  watch: {
    // Watch for changes to the selected team
    selectedTeam: {
      immediate: true,
      handler(newTeamId, oldTeamId) {
        // Only update if it's actually changed to prevent loops
        if (newTeamId !== oldTeamId) {
          if (newTeamId) {
            this.updateParalegalIds(newTeamId);
          } else if (this.showAllParalegals) {
            // If no team selected but showing all paralegals
            this.paralegalIds = [];
            this.refetchQueries();
          }
        }
      },
    },

    // Watch for changes to imitatedUser and load the user's data
    imitatedUser: {
      immediate: true,
      handler(newImitatedUser, oldImitatedUser) {
        // Only process if it actually changed
        if (newImitatedUser !== oldImitatedUser) {
          this.clearComponentData();

          if (newImitatedUser) {
            this.fetchImitatedUserData();
          } else {
            this.imitatedUserData = null;
            // If imitated user is cleared, reset to current user's team
            if (this.currentUserTeamId) {
              // Use nextTick to avoid immediate loop
              this.$nextTick(() => {
                this.setSelectedTeam(this.currentUserTeamId);
              });
            }
          }
        }
      },
    },

    // Watch for changes to the currentUser
    currentUser: {
      immediate: true,
      deep: true,
      handler(newUser, oldUser) {
        // Need to ensure we're not responding to the same user reference
        const newId = newUser ? newUser.id : null;
        const oldId = oldUser ? oldUser.id : null;

        if (newId !== oldId && newUser) {
          this.clearComponentData();

          if (newUser.teams && newUser.teams.length > 0) {
            // Using nextTick to avoid immediate reactions
            this.$nextTick(() => {
              this.setSelectedTeam(newUser.teams[0].id);
            });
          }
        }
      },
    },
  },
  apollo: {
    // 1) Demands Query
    demandsInPeriodByParalegalByMonth: {
      query: gql`
        query demandsInPeriodByParalegalByMonth(
          $startDate: String!
          $endDate: String!
          $paralegalIds: [Int]
        ) {
          demandsInPeriodByParalegalByMonth(
            startDate: $startDate
            endDate: $endDate
            paralegalIds: $paralegalIds
          ) {
            paralegal
            monthStart
            total
          }
        }
      `,
      variables() {
        return {
          startDate: this.startDate,
          endDate: this.endDate,
          paralegalIds: this.paralegalIds.length > 0 ? this.paralegalIds : null,
        };
      },
      fetchPolicy: "network-only", // Always fetch fresh data
      result({ data }) {
        if (data && data.demandsInPeriodByParalegalByMonth) {
          this.demandsRaw = data.demandsInPeriodByParalegalByMonth;
          this.buildDemandsTable();
        }
        this.markQueryComplete();
      },
    },
    // 2) Settlements Query
    settlementsInPeriodByParalegalByMonth: {
      query: gql`
        query settlementsInPeriodByParalegalByMonth(
          $startDate: String!
          $endDate: String!
          $paralegalIds: [Int]
        ) {
          settlementsInPeriodByParalegalByMonth(
            startDate: $startDate
            endDate: $endDate
            paralegalIds: $paralegalIds
          ) {
            paralegal
            monthStart
            total
          }
        }
      `,
      variables() {
        return {
          startDate: this.startDate,
          endDate: this.endDate,
          paralegalIds: this.paralegalIds.length > 0 ? this.paralegalIds : null,
        };
      },
      fetchPolicy: "network-only", // Always fetch fresh data
      result({ data }) {
        if (data && data.settlementsInPeriodByParalegalByMonth) {
          this.settlementsRaw = data.settlementsInPeriodByParalegalByMonth;
          this.buildSettlementsTable();
        }
        this.markQueryComplete();
      },
    },
    // 3) Fees Query
    feesInPeriodByParalegalByMonth: {
      query: gql`
        query feesInPeriodByParalegalByMonth(
          $startDate: String!
          $endDate: String!
          $paralegalIds: [Int]
        ) {
          feesInPeriodByParalegalByMonth(
            startDate: $startDate
            endDate: $endDate
            paralegalIds: $paralegalIds
          ) {
            paralegal
            monthStart
            total
          }
        }
      `,
      variables() {
        return {
          startDate: this.startDate,
          endDate: this.endDate,
          paralegalIds: this.paralegalIds.length > 0 ? this.paralegalIds : null,
        };
      },
      fetchPolicy: "network-only", // Always fetch fresh data
      result({ data }) {
        if (data && data.feesInPeriodByParalegalByMonth) {
          this.feesRaw = data.feesInPeriodByParalegalByMonth;
          this.buildFeesTable();
        }
        this.markQueryComplete();
      },
    },
    // Query to get organization teams and users for team selection
    organization: {
      query: gql`
        query($id: ID!) {
          organization(id: $id) {
            id
            name
            teams {
              id
              name
              active
              users {
                id
                firstName
                lastName
                saContactId
                staffCode
                roles {
                  name
                }
              }
            }
          }
        }
      `,
      variables() {
        return {
          id: this.currentUser ? this.currentUser.organization.id : null,
        };
      },
      fetchPolicy: "network-only", // Always fetch fresh data
      skip() {
        return !this.currentUser || !this.currentUser.organization;
      },
      result({ data }) {
        if (data && data.organization) {
          // Store the organization data for reference
          this.organization = data.organization;

          // Filter to only active teams
          this.teamOptions = data.organization.teams
            .filter((team) => team.active)
            .map((team) => ({
              id: team.id,
              name: team.name,
            }));

          // Only proceed if we have a valid selectedTeam or need to set one
          if (this.selectedTeam) {
            // We already have a selected team, just update paralegal IDs
            this.updateParalegalIds(this.selectedTeam);
          } else {
            // No team selected yet, determine which one to use

            if (this.currentUserTeamId) {
              this.setSelectedTeam(this.currentUserTeamId);
              // Don't call updateParalegalIds here, the selectedTeam watcher will handle it
            }
          }
        }
      },
    },
  },
  methods: {
    ...mapActions(["setSelectedTeam"]),

    // Handle click on demand number to show details
    showDemandDetails(paralegal, monthStart, total) {
      if (!total || total <= 0) return; // Don't do anything if there are no demands

      // Show loading dialog
      this.selectedDemandDetails = {
        loading: true,
        paralegal,
        monthStart,
        total,
        demands: [],
      };
      this.demandDetailsDialog = true;

      // Query the individual demands
      this.$apollo
        .query({
          query: gql`
            query individualDemandsInPeriodByParalegalByMonth(
              $startDate: String!
              $endDate: String!
              $paralegalIds: [Int]
            ) {
              individualDemandsInPeriodByParalegalByMonth(
                startDate: $startDate
                endDate: $endDate
                paralegalIds: $paralegalIds
              ) {
                caseNumber
                clientName
                paralegal
                demandDate
              }
            }
          `,
          variables: {
            startDate: this.createMonthStartDate(monthStart),
            endDate: this.createMonthEndDate(monthStart),
            paralegalIds: this.getParalegalId(paralegal),
          },
          fetchPolicy: "network-only",
        })
        .then(({ data }) => {
          if (data && data.individualDemandsInPeriodByParalegalByMonth) {
            this.selectedDemandDetails.demands =
              data.individualDemandsInPeriodByParalegalByMonth;
            this.selectedDemandDetails.loading = false;
          }
        })
        .catch(() => {
          this.selectedDemandDetails.loading = false;
          this.selectedDemandDetails.error = "Failed to load demand details";
        });
    },

    // Format the title for the demand details dialog
    formatDemandDetailsTitle() {
      if (!this.selectedDemandDetails) return "";

      const { paralegal, monthStart, total } = this.selectedDemandDetails;
      // Store the full name but continue using the staff code in the rendered title
      // The tooltip will show the full name when hovering

      if (monthStart) {
        // Format the month for display
        const [year, month] = monthStart.split("-").map(Number);
        const monthNames = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
        const formattedMonth = `${monthNames[month - 1]} ${year}`;

        return `${total} Demands by ${paralegal} in ${formattedMonth}`;
      } else {
        return `All Demands by ${paralegal}`;
      }
    },

    // Format a date for display
    formatDate(dateString) {
      if (!dateString) return "";

      const date = new Date(dateString);
      return new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      }).format(date);
    },

    // Helper to get paralegal ID from name
    getParalegalId(paralegalName) {
      if (!this.organization || !this.organization.teams) return [];

      // Look through all teams to find the paralegal
      const allUsers = [];
      this.organization.teams.forEach((team) => {
        if (team.users) {
          allUsers.push(...team.users);
        }
      });

      // Find the paralegal by name
      const paralegal = allUsers.find(
        (user) => user.staffCode == paralegalName
      );

      return paralegal && paralegal.saContactId
        ? [parseInt(paralegal.saContactId, 10)]
        : [];
    },

    // Create a date object for the start of a month (format: YYYY-MM-DD)
    createMonthStartDate(monthStart) {
      if (!monthStart) {
        // If no month specified, use the current date range
        return this.startDate;
      }
      return monthStart; // Already in YYYY-MM-DD format for the first day of the month
    },

    // Create a date object for the end of a month (format: YYYY-MM-DD)
    createMonthEndDate(monthStart) {
      if (!monthStart) {
        // If no month specified, use the current date range
        return this.endDate;
      }

      // Parse the month string
      const [year, month] = monthStart.split("-").map(Number);

      // Create a date for the last day of the month
      // Set to first day of next month, then subtract 1 day
      const lastDay = new Date(year, month, 0);

      // Format as YYYY-MM-DD
      return `${lastDay.getFullYear()}-${String(
        lastDay.getMonth() + 1
      ).padStart(2, "0")}-${String(lastDay.getDate()).padStart(2, "0")}`;
    },

    // Update the selected team in Vuex store
    updateSelectedTeam(teamId) {
      this.setSelectedTeam(teamId);
      this.updateParalegalIds(teamId);
    },

    // Clear all component data
    clearComponentData() {
      this.demandsRaw = [];
      this.settlementsRaw = [];
      this.feesRaw = [];
      this.demandsHeaders = [];
      this.demandsTableData = [];
      this.settlementsHeaders = [];
      this.settlementsTableData = [];
      this.feesHeaders = [];
      this.feesTableData = [];
      this.paralegalIds = [];
      this.loadingQueries = 0;
    },

    markQueryComplete() {
      this.loadingQueries++;
    },

    // Update paralegal IDs based on selected team
    updateParalegalIds(teamId) {
      if (!teamId || this.showAllParalegals) {
        // If no team selected or showing all paralegals, clear the filter
        // Only update and refetch if we're actually changing something
        if (this.paralegalIds.length > 0) {
          this.paralegalIds = [];
          this.refetchQueries();
        }
        return;
      }

      // Find the team and get paralegal IDs
      if (this.organization && this.organization.teams) {
        const selectedTeam = this.organization.teams.find(
          (team) => team.id === teamId
        );

        if (
          selectedTeam &&
          selectedTeam.users &&
          Array.isArray(selectedTeam.users)
        ) {
          // Filter users who have the paralegal role
          const newParalegalIds = selectedTeam.users
            .map((user) => {
              const id = parseInt(user.saContactId, 10);
              return id;
            })
            .filter((id) => !isNaN(id));

          // Check if we actually need to update (prevents infinite loops)
          const needsUpdate =
            newParalegalIds.length !== this.paralegalIds.length ||
            !newParalegalIds.every((id) => this.paralegalIds.includes(id));

          if (needsUpdate) {
            this.paralegalIds = newParalegalIds;
            // Use nextTick to avoid immediate reactions
            this.$nextTick(() => {
              this.refetchQueries();
            });
          }
        } else {
          if (this.paralegalIds.length > 0) {
            this.paralegalIds = [];
            this.refetchQueries();
          }
        }
      } else {
        if (this.paralegalIds.length > 0) {
          this.paralegalIds = [];
        }

        // Only refetch organization if we don't already have it
        if (!this.organization && this.$apollo.queries.organization) {
          this.$apollo.queries.organization.refetch();
        }
      }
    },

    // Handle "Show all paralegals" checkbox change
    onShowAllParalegalsChange() {
      if (this.showAllParalegals) {
        // If showing all paralegals, clear the paralegal IDs filter
        this.paralegalIds = [];
        this.refetchQueries();
      } else {
        // If not showing all paralegals, apply the team filter
        this.updateParalegalIds(this.selectedTeam);
      }
    },

    // Helper method to refetch all queries
    refetchQueries() {
      if (this.$apollo.queries.demandsInPeriodByParalegalByMonth) {
        this.$apollo.queries.demandsInPeriodByParalegalByMonth.refetch();
      }
      if (this.$apollo.queries.settlementsInPeriodByParalegalByMonth) {
        this.$apollo.queries.settlementsInPeriodByParalegalByMonth.refetch();
      }
      if (this.$apollo.queries.feesInPeriodByParalegalByMonth) {
        this.$apollo.queries.feesInPeriodByParalegalByMonth.refetch();
      }
    },

    // Format a number to one decimal place
    formatAverage(total, count) {
      if (!count) return "0.0";
      return (total / count).toFixed(1);
    },

    // Format currency values for the fees table
    formatCurrency(value) {
      if (value === undefined || value === null) return "";

      // Convert to number if it's a string
      const numValue = typeof value === "string" ? parseFloat(value) : value;

      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(numValue);
    },

    // Method for demands and settlements conditional formatting
    getDemandsClass(value) {
      if (value === undefined || value === null) return "";

      // Convert to number if it's a string
      const numValue = typeof value === "string" ? parseInt(value, 10) : value;

      if (numValue >= 5) return "high-value";
      if (numValue === 4) return "medium-value";
      return "low-value";
    },

    // Method for fees conditional formatting
    getFeesClass(value) {
      if (value === undefined || value === null) return "";

      // Convert to number if it's a string
      const numValue = typeof value === "string" ? parseFloat(value) : value;

      if (numValue >= 60000) return "high-value";
      if (numValue >= 50000 && numValue < 60000) return "medium-value";
      return "low-value";
    },

    // Method for demands/settlements average conditional formatting
    getDemandsAverageClass(total, count) {
      if (total === undefined || total === null || !count) return "";

      // Convert to number if it's a string
      const numValue = typeof total === "string" ? parseInt(total, 10) : total;
      const average = numValue / count;

      if (average >= 5) return "high-value";
      if (average >= 4 && average < 5) return "medium-value";
      return "low-value";
    },

    // Method for fees average conditional formatting
    getFeesAverageClass(total, count) {
      if (total === undefined || total === null || !count) return "";

      // Convert to number if it's a string
      const numValue = typeof total === "string" ? parseFloat(total) : total;
      const average = numValue / count;

      if (average >= 60000) return "high-value";
      if (average >= 50000 && average < 60000) return "medium-value";
      return "low-value";
    },

    // Helper to build a table with paralegal as the first column
    // and each unique month as subsequent columns
    buildMonthlyTable(rawData) {
      // 1) Collect all unique months
      const monthSet = new Set();
      rawData.forEach((item) => monthSet.add(item.monthStart));
      // Sort the months ascending; adjust if you prefer descending
      const months = Array.from(monthSet).sort();

      // 2) Build a map of paralegal -> { paralegal, [monthStart]: total }
      const paralegalMap = {};
      rawData.forEach(({ paralegal, monthStart, total }) => {
        if (!paralegalMap[paralegal]) {
          paralegalMap[paralegal] = { paralegal, total: 0, monthCount: 0 };
        }
        paralegalMap[paralegal][monthStart] = total;
        paralegalMap[paralegal].total += parseInt(total, 10);
        paralegalMap[paralegal].monthCount += 1;
      });

      // 3) Convert map to array of row objects
      const items = Object.values(paralegalMap);
      // Optional: sort paralegals alphabetically
      items.sort((a, b) => a.paralegal.localeCompare(b.paralegal));

      // 4) Build headers array
      const headers = [
        { text: "Paralegal", value: "paralegal", fixed: true, width: "100px" },
        { text: "Total", value: "total", fixed: true, width: "100px" },
      ];
      months.forEach((m) => {
        // Format date from "YYYY-MM-DD" to "MMM YY"
        // Parse the date parts manually to avoid timezone issues
        const [year, month] = m.split("-").map(Number);

        // Create date using the correct month (JS months are 0-based, so no adjustment needed when parsing directly)
        const monthNames = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        const monthName = monthNames[month - 1]; // Adjust for 1-based month in the string
        const yearSuffix = year.toString().substr(2, 2);
        const formattedDate = `${monthName} ${yearSuffix}`;

        headers.push({
          text: formattedDate,
          value: m, // keep the original date as the value for data binding
          // align: "center",
          width: "50px",
        });
      });

      return { headers, items };
    },

    buildDemandsTable() {
      const { headers, items } = this.buildMonthlyTable(this.demandsRaw);
      this.demandsHeaders = headers;
      this.demandsTableData = items;
    },
    buildSettlementsTable() {
      const { headers, items } = this.buildMonthlyTable(this.settlementsRaw);
      this.settlementsHeaders = headers;
      this.settlementsTableData = items;
    },
    buildFeesTable() {
      const { headers, items } = this.buildMonthlyTable(this.feesRaw);
      this.feesHeaders = headers;
      this.feesTableData = items;
    },

    // Fetch imitated user data to get their teams
    fetchImitatedUserData() {
      if (!this.imitatedUser) return;

      this.$apollo
        .query({
          query: gql`
            query($id: ID!) {
              user(id: $id) {
                id
                firstName
                lastName
                email
                teams {
                  id
                  name
                }
              }
            }
          `,
          variables: {
            id: this.imitatedUser,
          },
          fetchPolicy: "network-only", // Always fetch fresh data
        })
        .then(({ data }) => {
          if (data && data.user) {
            this.imitatedUserData = data.user;

            // If we now have imitated user data, update the team selection in Vuex
            if (
              this.imitatedUserData.teams &&
              this.imitatedUserData.teams.length > 0
            ) {
              const imitatedUserTeamId = this.imitatedUserData.teams[0].id;
              this.setSelectedTeam(imitatedUserTeamId);
            }
          }
        })
        .catch((error) => {
          console.error("Error fetching imitated user data:", error);
        });
    },

    // Reset Apollo cache
    resetApolloCache() {
      try {
        if (this.$apollo.provider.defaultClient) {
          this.$apollo.provider.defaultClient.clearStore();
          this.$apollo.provider.defaultClient.cache.reset();
        }
      } catch (e) {
        console.error("Error resetting Apollo cache:", e);
      }
    },

    // Export to CSV
    exportToCSV() {
      if (
        !this.selectedDemandDetails ||
        !this.selectedDemandDetails.demands ||
        this.selectedDemandDetails.demands.length === 0
      ) {
        return;
      }

      const demands = this.selectedDemandDetails.demands;
      const fileName = `demands_${
        this.selectedDemandDetails.paralegal
      }_${new Date().toISOString().slice(0, 10)}.csv`;

      // Define the fields to include in the CSV
      const fields = [
        { key: "clientName", label: "Client Name" },
        { key: "paralegal", label: "Paralegal" },
        { key: "demandDate", label: "Demand Date", formatter: this.formatDate },
      ];

      // Create CSV header row
      let csvContent =
        fields.map((field) => `"${field.label}"`).join(",") + "\n";

      // Add data rows
      demands.forEach((demand) => {
        const row = fields.map((field) => {
          // Get the value, apply formatter if specified
          let value = field.formatter
            ? field.formatter(demand[field.key])
            : demand[field.key];
          // Escape quotes and wrap in quotes
          return `"${String(value || "").replace(/"/g, '""')}"`;
        });
        csvContent += row.join(",") + "\n";
      });

      // Create a download link and trigger it
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");

      // Create a URL for the blob
      const url = URL.createObjectURL(blob);

      // Set link properties
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      link.style.visibility = "hidden";

      // Add to document, click it, then remove it
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    // Show fee details
    showFeeDetails(paralegal, monthStart, total) {
      if (!total || total <= 0) return; // Don't do anything if there are no fees

      // Show loading dialog
      this.selectedFeeDetails = {
        loading: true,
        paralegal,
        monthStart,
        total,
        fees: [],
      };
      this.feeDetailsDialog = true;

      // Query the individual fees
      this.$apollo
        .query({
          query: gql`
            query individualFeesInPeriodByParalegalByMonth(
              $startDate: String!
              $endDate: String!
              $paralegalIds: [Int]
            ) {
              individualFeesInPeriodByParalegalByMonth(
                startDate: $startDate
                endDate: $endDate
                paralegalIds: $paralegalIds
              ) {
                caseNumber
                clientName
                fee
                feeDate
                paralegal
              }
            }
          `,
          variables: {
            startDate: this.createMonthStartDate(monthStart),
            endDate: this.createMonthEndDate(monthStart),
            paralegalIds: this.getParalegalId(paralegal),
          },
          fetchPolicy: "network-only",
        })
        .then(({ data }) => {
          if (data && data.individualFeesInPeriodByParalegalByMonth) {
            this.selectedFeeDetails.fees =
              data.individualFeesInPeriodByParalegalByMonth;
            this.selectedFeeDetails.loading = false;
          }
        })
        .catch(() => {
          this.selectedFeeDetails.loading = false;
          this.selectedFeeDetails.error = "Failed to load fee details";
        });
    },

    // Format the title for the fee details dialog
    formatFeeDetailsTitle() {
      if (!this.selectedFeeDetails) return "";

      const { paralegal, monthStart, total } = this.selectedFeeDetails;
      // The full name will be displayed in a tooltip when hovering over the paralegal code

      if (monthStart) {
        // Format the month for display
        const [year, month] = monthStart.split("-").map(Number);
        const monthNames = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
        const formattedMonth = `${monthNames[month - 1]} ${year}`;

        return `${this.formatCurrency(
          total
        )} in Fees by ${paralegal} in ${formattedMonth}`;
      } else {
        return `${this.formatCurrency(total)} in Fees by ${paralegal}`;
      }
    },

    // Calculate the total fees
    calculateFeesTotal() {
      if (!this.selectedFeeDetails || !this.selectedFeeDetails.fees) return 0;
      return this.selectedFeeDetails.fees.reduce(
        (total, fee) => total + fee.fee,
        0
      );
    },

    // Export fees to CSV
    exportFeesToCSV() {
      if (
        !this.selectedFeeDetails ||
        !this.selectedFeeDetails.fees ||
        this.selectedFeeDetails.fees.length === 0
      ) {
        return;
      }

      const fees = this.selectedFeeDetails.fees;
      const fileName = `fees_${
        this.selectedFeeDetails.paralegal
      }_${new Date().toISOString().slice(0, 10)}.csv`;

      // Define the fields to include in the CSV
      const fields = [
        { key: "clientName", label: "Client Name" },
        { key: "fee", label: "Fee", formatter: this.formatCurrency },
        { key: "feeDate", label: "Fee Date", formatter: this.formatDate },
        { key: "paralegal", label: "Paralegal" },
      ];

      // Create CSV header row
      let csvContent =
        fields.map((field) => `"${field.label}"`).join(",") + "\n";

      // Add data rows
      fees.forEach((fee) => {
        const row = fields.map((field) => {
          // Get the value, apply formatter if specified
          let value = field.formatter
            ? field.formatter(fee[field.key])
            : fee[field.key];
          // Escape quotes and wrap in quotes
          return `"${String(value || "").replace(/"/g, '""')}"`;
        });
        csvContent += row.join(",") + "\n";
      });

      // Create a download link and trigger it
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");

      // Create a URL for the blob
      const url = URL.createObjectURL(blob);

      // Set link properties
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      link.style.visibility = "hidden";

      // Add to document, click it, then remove it
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    // Show settlement details
    showSettlementDetails(paralegal, monthStart, total) {
      if (!total || total <= 0) return; // Don't do anything if there are no settlements

      // Show loading dialog
      this.selectedSettlementDetails = {
        loading: true,
        paralegal,
        monthStart,
        total,
        settlements: [],
      };
      this.settlementDetailsDialog = true;

      // Query the individual settlements
      this.$apollo
        .query({
          query: gql`
            query individualSettlementsInPeriodByParalegalByMonth(
              $startDate: String!
              $endDate: String!
              $paralegalIds: [Int]
            ) {
              individualSettlementsInPeriodByParalegalByMonth(
                startDate: $startDate
                endDate: $endDate
                paralegalIds: $paralegalIds
              ) {
                caseNumber
                clientName
                settlementDate
                paralegal
              }
            }
          `,
          variables: {
            startDate: this.createMonthStartDate(monthStart),
            endDate: this.createMonthEndDate(monthStart),
            paralegalIds: this.getParalegalId(paralegal),
          },
          fetchPolicy: "network-only",
        })
        .then(({ data }) => {
          if (data && data.individualSettlementsInPeriodByParalegalByMonth) {
            this.selectedSettlementDetails.settlements =
              data.individualSettlementsInPeriodByParalegalByMonth;
            this.selectedSettlementDetails.loading = false;
          }
        })
        .catch((error) => {
          console.error("Settlement details error:", error);
          this.selectedSettlementDetails.loading = false;
          this.selectedSettlementDetails.error =
            "Failed to load settlement details";
        });
    },

    // Format settlement details title
    formatSettlementDetailsTitle() {
      if (!this.selectedSettlementDetails) return "";
      const { paralegal, monthStart, total } = this.selectedSettlementDetails;
      // We'll display the staff code in the title with a tooltip for the full name

      // If a month is specified, format it
      let formattedMonth = "";
      if (monthStart) {
        const monthNames = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];

        // Parse the date parts manually to avoid timezone issues
        const [year, month] = monthStart.split("-").map(Number);

        // Properly adjust the month index (months in the array are 0-indexed, but monthStart is 1-indexed)
        formattedMonth = ` for ${monthNames[month - 1]} ${year}`;
      }

      return `${total} Settlements${formattedMonth} by ${paralegal}`;
    },

    // Export settlements to CSV
    exportSettlementsToCSV() {
      if (
        !this.selectedSettlementDetails ||
        !this.selectedSettlementDetails.settlements ||
        this.selectedSettlementDetails.settlements.length === 0
      ) {
        return;
      }

      const settlements = this.selectedSettlementDetails.settlements;
      const fileName = `settlements_${
        this.selectedSettlementDetails.paralegal
      }_${new Date().toISOString().slice(0, 10)}.csv`;

      // Define the fields to include in the CSV
      const fields = [
        { key: "caseNumber", label: "Case Number" },
        { key: "clientName", label: "Client Name" },
        {
          key: "settlementDate",
          label: "Settlement Date",
          formatter: this.formatDate,
        },
        { key: "paralegal", label: "Paralegal" },
      ];

      // Create CSV header row
      let csvContent =
        fields.map((field) => `"${field.label}"`).join(",") + "\n";

      // Add data rows
      settlements.forEach((settlement) => {
        const row = fields
          .map((field) => {
            let value = settlement[field.key];
            if (field.formatter) {
              value = field.formatter(value);
            }
            return `"${value || ""}"`;
          })
          .join(",");
        csvContent += row + "\n";
      });

      // Create a Blob and download it
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);

      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      link.style.visibility = "hidden";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    // Get paralegal's full name by staff code
    getParalegalFullName(staffCode) {
      if (!this.organization || !this.organization.teams) return staffCode;

      // Look through all teams to find the paralegal
      let fullName = staffCode; // Default to staff code if not found

      this.organization.teams.forEach((team) => {
        if (team.users) {
          const user = team.users.find((user) => user.staffCode === staffCode);
          if (user) {
            fullName = `${user.firstName} ${user.lastName}`;
          }
        }
      });

      return fullName;
    },

    // Helper methods to generate title parts for dialogs
    getDemandTitlePrefix() {
      if (!this.selectedDemandDetails) return "";
      const { total, monthStart } = this.selectedDemandDetails;

      if (monthStart) {
        return `${total} Demands by `;
      } else {
        return "All Demands by ";
      }
    },

    getDemandTitleSuffix() {
      if (!this.selectedDemandDetails) return "";
      const { monthStart } = this.selectedDemandDetails;

      if (monthStart) {
        // Format the month for display
        const [year, month] = monthStart.split("-").map(Number);
        const monthNames = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
        const formattedMonth = `${monthNames[month - 1]} ${year}`;
        return ` in ${formattedMonth}`;
      } else {
        return "";
      }
    },

    getFeeTitlePrefix() {
      if (!this.selectedFeeDetails) return "";
      const { total } = this.selectedFeeDetails;
      return `${this.formatCurrency(total)} in Fees by `;
    },

    getFeeTitleSuffix() {
      if (!this.selectedFeeDetails) return "";
      const { monthStart } = this.selectedFeeDetails;

      if (monthStart) {
        // Format the month for display
        const [year, month] = monthStart.split("-").map(Number);
        const monthNames = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
        const formattedMonth = `${monthNames[month - 1]} ${year}`;
        return ` in ${formattedMonth}`;
      } else {
        return "";
      }
    },

    getSettlementTitlePrefix() {
      if (!this.selectedSettlementDetails) return "";
      const { total } = this.selectedSettlementDetails;
      return `${total} Settlements`;
    },

    getSettlementTitleSuffix() {
      if (!this.selectedSettlementDetails) return "";
      const { monthStart } = this.selectedSettlementDetails;

      // If a month is specified, format it
      if (monthStart) {
        const monthNames = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];

        // Parse the date parts manually to avoid timezone issues
        const [year, month] = monthStart.split("-").map(Number);

        // Properly adjust the month index (months in the array are 0-indexed, but monthStart is 1-indexed)
        return ` for ${monthNames[month - 1]} ${year}`;
      } else {
        return "";
      }
    },
  },
};
</script>

<style scoped>
/* Adjust spacing or widths as needed */
.mb-5 {
  margin-bottom: 20px;
}

/* Conditional formatting styles for tables */
.high-value {
  background-color: #e8f5e9; /* light green */
  color: #2e7d32; /* darker green */
}

.medium-value {
  background-color: #fffde7; /* light yellow */
  color: #f9a825; /* darker yellow */
}

.low-value {
  background-color: #ffebee; /* light red */
  color: #c62828; /* darker red */
}

/* Clickable cell styling */
.clickable-cell {
  cursor: pointer;
  color: inherit;
  text-decoration: none;
  font-weight: 500;
  border-bottom: 1px dashed;
  padding-bottom: 1px;
}

.clickable-cell:hover {
  opacity: 0.8;
  border-bottom: 1px solid;
}

/* Allow text to wrap in detail table cells */
::v-deep .v-data-table td {
  white-space: normal;
  max-width: 300px;
}
</style>
