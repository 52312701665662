<template>
  <v-card>
    <v-container class="py-0" fluid>
      <v-row align-content="space-around">
        <v-col cols="12" sm="3">
          <v-card-title class="pa-0">
            <div>
              <div class="headline">Intake Statistics</div>
            </div>
          </v-card-title>
        </v-col>
      </v-row>
      <org-home-intake-stats-pacing />
      <v-row align-content="space-around">
        <v-col cols="12" sm="12">
          <org-retained-by-day />
        </v-col>
      </v-row>
      <v-row align-content="space-around">
        <v-col cols="12" sm="12">
          <org-leads-stats-by-day />
        </v-col>
      </v-row>
      <v-row align-content="space-around">
        <v-col cols="12" sm="12">
          <org-leads-stats-by-day-by-source />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <intake-referrals />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-card>
            <v-toolbar flat dense>
              <span class="text-subtitle-1">Leads by Type</span>
            </v-toolbar>
            <org-leads-stats-by-type />
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card>
            <v-toolbar flat dense>
              <span class="text-subtitle-1">Wanted by Type</span>
            </v-toolbar>
            <org-wanted-stats-by-type />
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card>
            <v-toolbar flat dense>
              <span class="text-subtitle-1">Signed by Type</span>
            </v-toolbar>
            <org-signed-stats-by-type />
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <org-intake-agent-stats />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import gql from "graphql-tag";
import { mapGetters } from "vuex";
import differenceInBusinessDays from "date-fns/differenceInBusinessDays";
import addDays from "date-fns/addDays";
import OrgRetainedByDay from "../charts/OrgRetainedByDay";
import OrgLeadsStatsByDay from "../charts/OrgLeadsStatsByDay";
import OrgLeadsStatsByDayBySource from "../charts/OrgLeadsStatsByDayBySource";
import OrgLeadsStatsByType from "../charts/OrgLeadsStatsByType";
import OrgWantedStatsByType from "../charts/OrgWantedStatsByType";
import OrgSignedStatsByType from "../charts/OrgSignedStatsByType";
import OrgIntakeAgentStats from "../charts/OrgIntakeAgentStats";
import OrgHomeIntakeStatsPacing from "./OrgHomeIntakeStatsPacing";
import IntakeReferrals from "../charts/IntakeReferrals";

export default {
  data() {
    return {
      search: "",
      headers: [
        { text: "Action", value: "caseId", width: "100px" },
        { text: "Type", value: "type" },
        { text: "State", value: "state" },
        { text: "Client", value: "clientName" },
        { text: "Attorney", value: "attorney" },
        { text: "Paralegal", value: "paralegal" },
        { text: "Opened", value: "dateOpened" },
        { text: "Closed", value: "dateClosed" },
        { text: "Reason", value: "reasonClosed" },
      ],
      closedCases: [],
      retainedOnly: false,
    };
  },
  components: {
    IntakeReferrals,
    OrgRetainedByDay,
    OrgLeadsStatsByDay,
    OrgLeadsStatsByDayBySource,
    OrgLeadsStatsByType,
    OrgWantedStatsByType,
    OrgSignedStatsByType,
    OrgHomeIntakeStatsPacing,
    OrgIntakeAgentStats,
  },
  computed: {
    ...mapGetters(["startDate", "endDate"]),
    retainedCases() {
      if (!this.organization) return 0;
      return this.organization.retainedCasesInPeriod[0];
    },
    businessDays() {
      let start = this.startDate.split("-").map((x) => parseInt(x));
      let startDate = new Date(start[0], start[1] - 1, start[2]);
      let end = this.endDate.split("-").map((x) => parseInt(x));
      let endDate = new Date(end[0], end[1] - 1, end[2]);
      // We only want to count business days up to today if range is current month
      // If historical range we will count business days through range
      if (endDate > new Date()) {
        endDate = new Date();
      }
      // Add a day to endDate so we can include the last day
      endDate = addDays(endDate, 1);

      return differenceInBusinessDays(endDate, startDate);
    },
    retainedCasesInPeriodByOffice() {
      if (!this.organization) return [];
      return this.organization.retainedCasesInPeriodByOffice;
    },
  },
  methods: {
    retainedCasesGoal(multiplier) {
      return Math.round(this.businessDays * multiplier * 10) / 10;
    },
    goalPercentage(retainedCases, multiplier) {
      return (
        Math.round(
          (retainedCases / this.retainedCasesGoal(multiplier)) * 100 * 10
        ) / 10
      );
    },
    formatOffice(office) {
      switch (office) {
        case "(3 - ID Main) Meridian Office":
          return "BOI";
        case "Sea-Tac":
          return "SEA";
        case "(2 - WA Main) Spokane Valley Office":
          return "SPO";
        case "(1 - UT Main) Sandy Office":
          return "UT";
        case "Wyoming":
          return "WY";
        case "Montana":
          return "MT";
        default:
          return "?";
      }
    },
    officeGoal(office) {
      switch (office) {
        case "(3 - ID Main) Meridian Office":
          // 40/21
          return this.retainedCasesGoal(1.9);
        case "Sea-Tac":
          // 5/21
          return this.retainedCasesGoal(0.25);
        case "(2 - WA Main) Spokane Valley Office":
          // 80/21
          return this.retainedCasesGoal(3.8);
        case "(1 - UT Main) Sandy Office":
          // 80/21
          return this.retainedCasesGoal(3.8);
        case "Wyoming":
          // 5/21
          return this.retainedCasesGoal(0.25);
        case "Montana":
          // 5/21
          return this.retainedCasesGoal(0.25);
        default:
          return this.retainedCasesGoal(0);
      }
    },
    officeGoalPercentage(count, office) {
      switch (office) {
        case "(3 - ID Main) Meridian Office":
          // 40/21
          return `${this.goalPercentage(count, 1.9)}%`;
        case "Sea-Tac":
          // 5/21
          return `${this.goalPercentage(count, 0.25)}%`;
        case "(2 - WA Main) Spokane Valley Office":
          // 80/21
          return `${this.goalPercentage(count, 3.8)}%`;
        case "(1 - UT Main) Sandy Office":
          // 80/21
          return `${this.goalPercentage(count, 3.8)}%`;
        case "Wyoming":
          // 5/21
          return `${this.goalPercentage(count, 0.25)}%`;
        case "Montana":
          // 5/21
          return `${this.goalPercentage(count, 0.25)}%`;
        default:
          return `${this.goalPercentage(count, 0)}%`;
      }
    },
  },
  apollo: {
    organization: {
      query: gql`
        query OrgHomeStatsNewCases(
          $id: ID!
          $startDate: String!
          $endDate: String!
        ) {
          organization(id: $id) {
            id
            retainedCasesInPeriod(startDate: $startDate, endDate: $endDate)
            retainedCasesInPeriodByOffice(
              startDate: $startDate
              endDate: $endDate
            ) {
              office
              count
            }
            newCasesInPeriod(startDate: $startDate, endDate: $endDate) {
              caseId
              retainedDate
              clientName
              state
              office
              referralSource
              attorney
              paralegal
            }
          }
        }
      `,
      variables() {
        return {
          id: this.$route.params.id,
          startDate: this.startDate,
          endDate: this.endDate,
        };
      },
      skip() {
        return this.startDate === null || this.endDate === null;
      },
    },
  },
};
</script>

<style scoped></style>
