<template>
  <v-card>
    <v-container class="py-0" fluid>
      <v-row align-content="space-around">
        <v-col cols="12" sm="1">
          <v-card-title class="pa-0">
            <div>
              <div class="headline">All Cases</div>
              <div class="text-subtitle-1 font-weight-thin">
                {{ filteredCases.length }} Cases
              </div>
            </div>
          </v-card-title>
        </v-col>
        <v-col cols="12" sm="3">
          <div class="total-settlement-value font-weight-thin pt-5">
            {{ inventoryValue.totalLowValue }} -
            {{ inventoryValue.totalHighValue }}
          </div>
          <div class="total-fee-value">
            {{ inventoryValue.totalLowFees }} -
            {{ inventoryValue.totalHighFees }} (33%)
          </div>
        </v-col>
        <v-col cols="12" sm="4">
          <div>
            <highcharts ref="chart" :options="rankChartOptions" />
          </div>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="1">
          <v-switch
            class=""
            label="Exclude Ref"
            v-model="filterRefAndInactive"
          ></v-switch>
        </v-col>
        <v-col cols="12" sm="3">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            hide-details
            single-line
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
    <v-data-table
      :footer-props="{
        'items-per-page-options': [50, 100, -1],
      }"
      :headers="headers"
      :items="filteredCases"
      :item-key="Math.random(10).toString()"
      :loading="$apollo.loading"
      :search="search"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      ref="inventory-table"
    >
      <template v-slot:item.caseId="props">
        <a
          class="go-to-case-link mr-2"
          target="_blank"
          :href="
            `https://cs-web.airdesksolutions.com/SA/CaseSummary.aspx?CaseID=${props.item.caseId}`
          "
        >
          <v-icon size="15">mdi-open-in-new</v-icon>
        </a>
        <span v-if="isAdmin">
          <a
            v-if="isWatching(currentUser, props.item.caseId)"
            class="mr-2"
            @click="stopWatching($apollo, props.item.caseId)"
          >
            <v-icon size="15">mdi-star-off</v-icon>
          </a>
          <a
            v-else
            class="mr-2"
            @click="startWatching($apollo, props.item.caseId)"
          >
            <v-icon size="15">mdi-star</v-icon>
          </a>
        </span>
        <a
          class="go-to-case-link"
          title="Open Investigation"
          @click="
            investigationDialog = true;
            selectedCaseId = props.item.caseId;
          "
        >
          <v-icon size="15" color="primary">mdi-folder-account</v-icon>
        </a>
      </template>
      <template v-slot:item.status="props">
        <v-edit-dialog
          @open="openEditDialog(props.item.caseId)"
          @close="closeEditDialog"
        >
          {{ props.item.status && props.item.status.substr(0, 20) }}
          <template v-slot:input>
            <v-select
              :items="statusOptions"
              :value="getValue(props.item.status, statusOptions)"
              @change="saveStatus($event, 'status', statusOptions, 1)"
              dense
            ></v-select>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:item.lastAccessedDateTime="props">
        {{ formatLastAccessDate(props.item.lastAccessedDateTime) }}
      </template>
      <template v-slot:item.dateOfSolConf="props">
        <v-icon v-if="props.item.dateOfSolConf" size="15"
          >mdi-check-circle</v-icon
        >
      </template>
      <template v-slot:item.valueFrom="props">
        {{ formatValue(props.item) }}
      </template>
      <template v-slot:item.rank="props">
        <v-edit-dialog @open="openRank(props.item.caseId)" @close="closeRank">
          {{ props.item.rank }}
          <template v-slot:input>
            <v-select
              :items="rankOptions"
              :value="rankValue(props.item.rank)"
              @change="saveRank"
              dense
            ></v-select>
          </template>
        </v-edit-dialog>
      </template>
    </v-data-table>
    <v-dialog
      v-model="investigationDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <investigation
        :closeInvestigationDialog="closeInvestigationDialog"
        :caseId="selectedCaseId"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import gql from "graphql-tag";
import { mapGetters } from "vuex";
import {
  formatValue,
  startWatching,
  stopWatching,
  isWatching,
  hasRole,
  getDaysSinceToday,
} from "../util";
import groupBy from "lodash/groupBy";
import findIndex from "lodash/findIndex";
import filter from "lodash/filter";
import { DASH_INVENTORY_LIST } from "../graphql/user.gql";
import accounting from "accounting";
import Investigation from "./Investigation.vue";

export default {
  data() {
    return {
      search: "",
      filterRefAndInactive: true,
      headers: [
        { text: "Action", value: "caseId", width: "100px" },
        { text: "Type", value: "type" },
        { text: "Stage", value: "stage" },
        { text: "Status", value: "status" },
        { text: "Client", value: "clientName" },
        { text: "Last Access", value: "lastAccessedDateTime" },
        { text: "SOL Conf", value: "dateOfSolConf" },
        // { text: "Value", value: "valueFrom" },
        { text: "Rank", value: "rank" },
        { text: "Paralegal", value: "paralegal" },
        { text: "Opened", value: "dateOpened" },
        { text: "DOI", value: "dateOfIncident" },
        { text: "State", value: "state" },
      ],
      user: { cases: [] },
      rankOptions: [
        { text: "A++ (> $1M)", value: 805 },
        { text: "A+ ($251k - $1M)", value: 806 },
        { text: "A ($101k - $250k)", value: 807 },
        { text: "B ($51k - $100k)", value: 1161 },
        { text: "C ($15k - $50k)", value: 1089 },
        { text: "D (<$15k)", value: 1090 },
        { text: "Unrated - Default", value: 1088 },
        { text: "", value: "" },
      ],
      statusOptions: [
        { stage: "Treating", text: "PL 00 - File Setup", value: 1120 },
        { stage: "Treating", text: "PL 01 - Medical Management", value: 1121 },
        {
          stage: "Demand Prep",
          text: "PL 02 - Evidence Collection",
          value: 1122,
        },
        {
          stage: "Demand Prep",
          text: "PL 03 - Demand Preparation",
          value: 1123,
        },
        { stage: "Demand Prep", text: "PL 04 - Demand Review", value: 1124 },
        { stage: "Demand Prep", text: "PL 05 - Demand Sent", value: 1125 },
        { stage: "Negotiations", text: "PL 06 - Negotiations", value: 1126 },
        {
          stage: "Negotiations",
          text: "PL 07 - UIM Demand Preparation",
          value: 1127,
        },
        { stage: "Negotiations", text: "PL 08 - UIM Demand Sent", value: 1128 },
        {
          stage: "Negotiations",
          text: "PL 09 - UIM Negotiations",
          value: 1129,
        },
        { stage: "Litigation", text: "PL 10 - UIM Arbitration", value: 1165 },
        { stage: "Settled", text: "PL 11 - Incoming Fees", value: 1130 },
        {
          stage: "Settled",
          text: "PL 12 - Fees Disbursed or Lien and Balance Resolution",
          value: 1131,
        },
        { stage: "Settled", text: "PL 13 - Long-term Trust", value: 1132 },
        { stage: "", text: "", value: "" },
      ],
      selectedCaseId: "",
      investigationDialog: false,
    };
  },
  components: {
    Investigation,
  },
  mounted() {
    setTimeout(() => {
      if (!this.$refs.chart) return;
      this.$refs.chart.chart.reflow();
    }, 200);
  },
  computed: {
    ...mapGetters(["imitatedUser", "currentUser"]),
    filteredCases() {
      if (!this.user) return [];
      return filter(this.user.cases, (c) => {
        if (this.filterRefAndInactive) {
          return !["Referred Out Stage", "Inactive"].includes(c.stage);
        } else {
          return true;
        }
      });
    },
    isAdmin() {
      return hasRole(this.currentUser, "admin");
    },
    sortBy() {
      let saved = JSON.parse(localStorage.getItem("RyBase.inventorySortBy"));
      if (!saved) return ["dateOpened"];
      return saved;
    },
    sortDesc() {
      let saved = JSON.parse(localStorage.getItem("RyBase.inventorySortDesc"));
      if (!saved) return [true];
      return saved;
    },
    inventoryValue() {
      if (!this.user) return "";
      let settledStatuses = [
        "SET - Disbursed or Lien and Balance Resolution",
        "PL 11 = Fees Disbursed or Lien and Balance Resolution",
        "PL 12 = Long-term Trust",
      ];
      let cases = this.filteredCases;
      let nonSettled = filter(cases, (c) => {
        return settledStatuses.indexOf(c.status) === -1;
      });
      let casesGrouped = groupBy(nonSettled, "rank");
      delete casesGrouped["Unrated - Default"];
      delete casesGrouped["null"];
      let casesGroupedTotals = [];
      for (const [key, val] of Object.entries(casesGrouped)) {
        switch (key) {
          case "A++ (> $1M)":
            casesGroupedTotals.push({
              name: key,
              lowValue: val.length * 1000000,
              highValue: val.length * 1000000,
            });
            break;
          case "A+ ($251k - $1M)":
            casesGroupedTotals.push({
              name: key,
              lowValue: val.length * 250000,
              highValue: val.length * 1000000,
            });
            break;
          case "A ($101k - $250k)":
            casesGroupedTotals.push({
              name: key,
              lowValue: val.length * 101000,
              highValue: val.length * 250000,
            });
            break;
          case "B ($51k - $100k)":
            casesGroupedTotals.push({
              name: key,
              lowValue: val.length * 51000,
              highValue: val.length * 100000,
            });
            break;
          case "C ($15k - $50k)":
            casesGroupedTotals.push({
              name: key,
              lowValue: val.length * 15000,
              highValue: val.length * 50000,
            });
            break;
          case "D (<$15k)":
            casesGroupedTotals.push({
              name: key,
              lowValue: val.length * 5000,
              highValue: val.length * 14000,
            });
            break;
          default:
            console.log("Bad Value");
            console.log(key, val);
        }
      }
      let totalLowValue = casesGroupedTotals.reduce((prev, cur) => {
        return prev + cur.lowValue;
      }, 0);
      let totalHighValue = casesGroupedTotals.reduce((prev, cur) => {
        return prev + cur.highValue;
      }, 0);
      return {
        totalLowValue: accounting.formatMoney(totalLowValue),
        totalLowFees: accounting.formatMoney(totalLowValue * (1 / 3)),
        totalHighValue: accounting.formatMoney(totalHighValue),
        totalHighFees: accounting.formatMoney(totalHighValue * (1 / 3)),
      };
    },
    rankChartOptions() {
      if (!this.user) return {};
      let cases = this.filteredCases;
      let casesGrouped = groupBy(cases, "rank");
      return {
        chart: {
          type: "bar",
          height: 100,
        },
        title: {
          text: "",
        },
        credits: {
          enabled: false,
        },
        xAxis: {
          visible: false,
          labels: {
            enabled: false,
          },
        },
        yAxis: {
          min: 0,
          visible: false,
          title: {
            text: "",
          },
          labels: {
            enabled: false,
          },
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          series: {
            stacking: "normal",
          },
        },
        tooltip: {
          headerFormat: "",
        },
        series: [
          {
            name: "A++ (> $1M)",
            color: "#03A9F4",
            data: [
              casesGrouped["A++ (> $1M)"]
                ? casesGrouped["A++ (> $1M)"].length
                : 0,
            ],
          },
          {
            name: "A+ ($251k - $1M)",
            color: "#2196F3",
            data: [
              casesGrouped["A+ ($251k - $1M)"]
                ? casesGrouped["A+ ($251k - $1M)"].length
                : 0,
            ],
          },
          {
            name: "A ($101k - $250k)",
            color: "#3F51B5",
            data: [
              casesGrouped["A ($101k - $250k)"]
                ? casesGrouped["A ($101k - $250k)"].length
                : 0,
            ],
          },
          {
            name: "B ($51k - $100k)",
            color: "#673AB7",
            data: [
              casesGrouped["B ($51k - $100k)"]
                ? casesGrouped["B ($51k - $100k)"].length
                : 0,
            ],
          },
          {
            name: "C ($15k - $50k)",
            color: "#9C27B0",
            data: [
              casesGrouped["C ($15k - $50k)"]
                ? casesGrouped["C ($15k - $50k)"].length
                : 0,
            ],
          },
          {
            name: "D (<$15k)",
            color: "#E91E63",
            data: [
              casesGrouped["D (<$15k)"] ? casesGrouped["D (<$15k)"].length : 0,
            ],
          },
          {
            name: "Unrated - Default",
            color: "#F44336",
            data: [
              casesGrouped["Unrated - Default"]
                ? casesGrouped["Unrated - Default"].length
                : 0,
            ],
          },
          {
            name: "No Rank",
            color: "#FF9800",
            data: [casesGrouped["null"] ? casesGrouped["null"].length : 0],
          },
        ],
      };
    },
  },
  methods: {
    formatValue,
    startWatching,
    stopWatching,
    isWatching,
    formatLastAccessDate(date) {
      if (!date) return "";
      let days = getDaysSinceToday(date);
      return `${date} (${days} days)`;
    },
    updateSort(type, event) {
      if (type === "by") {
        localStorage.setItem("RyBase.inventorySortBy", JSON.stringify(event));
      } else if (type === "order") {
        localStorage.setItem("RyBase.inventorySortDesc", JSON.stringify(event));
      }
    },
    saveRank(statusId) {
      if (!statusId) return false;
      this.$apollo
        .mutate({
          mutation: gql`
            mutation InsertCaseRankStatus($status: CaseStatus!) {
              insertSaRankStatus(status: $status) {
                statusId
              }
            }
          `,
          variables: {
            status: {
              case_id: this.selectedCaseId,
              status_id: statusId,
            },
          },
          update: (store, { data: { insertSaRankStatus } }) => {
            this.updateCache(store, insertSaRankStatus);
          },
        })
        .then(() => {})
        .catch((error) => {
          console.error(error);
        });
      return false;
    },
    saveStatus(val, type, options, statusType) {
      if (!val) return false;
      this.$apollo
        .mutate({
          mutation: gql`
            mutation UpdateCaseStatus($status: CaseStatus!) {
              upsertSaStatus(status: $status) {
                statusId
              }
            }
          `,
          variables: {
            status: {
              case_id: this.selectedCaseId,
              status_id: val,
              type: statusType,
            },
          },
          update: (store, { data: { upsertSaStatus } }) => {
            this.updateStatusCache(store, upsertSaStatus, "status");
          },
        })
        .then(() => {
          this.$refs["inventory-table"].$el.click();
        })
        .catch((error) => {
          console.error(error);
        });
      return false;
    },
    updateCache(store, insertData) {
      let id = this.imitatedUser
        ? this.imitatedUser.toString()
        : this.$route.params.id;
      let caseId = this.selectedCaseId;
      const data = store.readQuery({
        query: DASH_INVENTORY_LIST,
        variables: {
          id,
        },
      });
      let index = findIndex(data.user.cases, (c) => {
        return c.caseId === caseId;
      });
      let newRank = this.rankText(insertData.statusId);
      data.user.cases[index].rank = newRank;
      store.writeQuery({
        query: DASH_INVENTORY_LIST,
        variables: {
          id,
        },
        data,
      });
    },
    updateStatusCache(store, insertData, field) {
      let id = this.imitatedUser
        ? this.imitatedUser.toString()
        : this.$route.params.id;
      let caseId = this.selectedCaseId;
      const data = store.readQuery({
        query: DASH_INVENTORY_LIST,
        variables: {
          id,
        },
      });
      let index = findIndex(data.user.cases, (c) => {
        return c.caseId === caseId;
      });
      data.user.cases[index][field] = this.getText(
        insertData.statusId,
        this.statusOptions
      );
      data.user.cases[index].stage = this.getStage(
        insertData.statusId,
        this.statusOptions
      );
      store.writeQuery({
        query: DASH_INVENTORY_LIST,
        variables: {
          id,
        },
        data,
      });
    },
    rankValue(rank) {
      let ranks = groupBy(this.rankOptions, "text");
      if (!ranks[rank]) return null;
      return ranks[rank][0].value;
    },
    rankText(rank) {
      let ranks = groupBy(this.rankOptions, "value");
      return ranks[rank][0].text;
    },
    openRank(caseId) {
      this.selectedCaseId = caseId;
      return false;
    },
    closeRank() {
      this.selectedCaseId = "";
      return false;
    },
    getValue(val, options) {
      let values = groupBy(options, "text");
      if (!values[val]) return null;
      return values[val][0].value;
    },
    getText(val, options) {
      let values = groupBy(options, "value");
      return values[val][0].text;
    },
    getStage(val, options) {
      let values = groupBy(options, "value");
      return values[val][0].stage;
    },
    openEditDialog(caseId) {
      this.selectedCaseId = caseId;
      return false;
    },
    closeEditDialog() {
      this.selectedCaseId = "";
      return false;
    },
    closeInvestigationDialog() {
      this.selectedCaseId = "";
      this.investigationDialog = false;
    },
  },
  apollo: {
    user: {
      query: DASH_INVENTORY_LIST,
      variables() {
        let id = this.imitatedUser
          ? this.imitatedUser.toString()
          : this.$route.params.id;
        return {
          id,
        };
      },
    },
  },
};
</script>

<style scoped>
.total-settlement-value {
  width: 90%;
  text-align: center;
}
.total-fee-value {
  width: 90%;
  text-align: center;
}
</style>
