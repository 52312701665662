<template>
  <v-card>
    <v-alert
      class="alert"
      transition="scale-transition"
      :type="alertType"
      :value="alert"
      >{{ alertMessage }}</v-alert
    >
    <v-container class="py-0" fluid>
      <v-row align-content="space-around">
        <v-col cols="12" sm="4">
          <v-card-title class="pa-0">
            <div>
              <div class="headline">Deadlines</div>
              <div class="text-subtitle-1 font-weight-thin">
                {{ deadlines.length }} Deadlines
              </div>
            </div>
          </v-card-title>
        </v-col>
        <v-spacer></v-spacer>
        <v-col v-if="isAdmin" cols="12" sm="1">
          <v-switch v-model="showAll" label="Show All" />
        </v-col>
        <v-col cols="6" sm="2" class="date-range-select">
          <v-select :items="dateOptions" label="Date Range" v-model="toDate">
          </v-select>
        </v-col>
        <v-col cols="6" sm="4">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            hide-details
            single-line
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
    <v-data-table
      :footer-props="{
        'items-per-page-options': [50, 100, -1]
      }"
      :headers="headers"
      :items="deadlines"
      :item-key="Math.round(Math.random() * 10000).toString()"
      :loading="$apollo.loading"
      :search="search"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      @update:sort-by="updateSort('by', $event)"
      @update:sort-desc="updateSort('order', $event)"
    >
      <template v-slot:item.caseId="props">
        <a
          class="go-to-case-link mr-2"
          target="_blank"
          :href="
            `https://cs-web.airdesksolutions.com/SA/Case_SOL.aspx?CaseID=${props.item.caseId}`
          "
        >
          <v-icon size="15">mdi-open-in-new</v-icon>
        </a>
        <a v-if="isAdmin">
          <v-icon size="15" @click="sendOverdueDeadlinesEmail(props)"
            >mdi-email-arrow-right-outline</v-icon
          >
        </a>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import gql from "graphql-tag";
import { format, addDays } from "date-fns/esm";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      alert: false,
      alertType: "success",
      alertMessage: "",
      search: "",
      user: { deadlines: [] },
      dateOptions: ["One Week", "One Month", "Three Months", "Six Months"],
      showAll: false
    };
  },
  computed: {
    ...mapGetters(["imitatedUser", "isAdmin"]),
    deadlines() {
      if (this.showAll) {
        if (!this.organization) return [];
        return this.organization.deadlines;
      } else {
        if (!this.user) return [];
        return this.user.deadlines;
      }
    },
    headers() {
      let headers = [
        { text: "Action", value: "caseId", width: "100px" },
        { text: "Client", value: "clientName" },
        { text: "Due Date", value: "dueDate" },
        { text: "Responsible", value: "respondent" },
        { text: "Type", value: "type" },
        { text: "Comments", value: "comments" }
      ];
      if (this.showAll) {
        headers = [
          { text: "Action", value: "caseId", width: "100px" },
          { text: "Client", value: "clientName" },
          { text: "Attorney", value: "attorney" },
          { text: "Paralegal", value: "paralegal" },
          { text: "Due Date", value: "dueDate" },
          { text: "Responsible", value: "respondent" },
          { text: "Type", value: "type" },
          { text: "Comments", value: "comments" }
        ];
      }
      return headers;
    },
    totalRows() {
      return this.deadlines.length;
    },
    toDate: {
      get() {
        return this.$store.getters.deadlineToDate;
      },
      set(value) {
        this.calculateToDate(value);
        this.$store.dispatch("setDeadlineToDate", value);
      }
    },
    sortBy() {
      let saved = JSON.parse(localStorage.getItem("RyBase.deadlinesSortBy"));
      if (!saved) return ["dueDate"];
      return saved;
    },
    sortDesc() {
      let saved = JSON.parse(localStorage.getItem("RyBase.deadlinesSortDesc"));
      if (!saved) return [true];
      return saved;
    }
  },
  apollo: {
    user: {
      query: gql`
        query($id: ID!, $fromDate: String!, $toDate: String!) {
          user(id: $id) {
            id
            first_name
            last_name
            watchedCases {
              caseId
            }
            deadlines(fromDate: $fromDate, toDate: $toDate) {
              caseId
              clientName
              dueDate
              comments
              type
              party
              respondent
            }
          }
        }
      `,
      variables() {
        let id = this.imitatedUser
          ? this.imitatedUser.toString()
          : this.$route.params.id;
        return {
          id,
          fromDate: "1900-01-01",
          toDate: this.calculateToDate(this.toDate)
        };
      }
    },
    organization: {
      query: gql`
        query($id: ID!, $fromDate: String!, $toDate: String!) {
          organization(id: $id) {
            id
            name
            deadlines(fromDate: $fromDate, toDate: $toDate) {
              caseId
              clientName
              dueDate
              comments
              type
              party
              respondent
              attorney
              paralegal
            }
          }
        }
      `,
      variables() {
        return {
          id: 2,
          fromDate: "1900-01-01",
          toDate: this.calculateToDate(this.toDate)
        };
      },
      skip() {
        return !this.showAll;
      }
    }
  },
  methods: {
    updateSort(type, event) {
      if (type === "by") {
        localStorage.setItem("RyBase.deadlinesSortBy", JSON.stringify(event));
      } else if (type === "order") {
        localStorage.setItem("RyBase.deadlinesSortDesc", JSON.stringify(event));
      }
    },
    calculateToDate(option) {
      switch (option) {
        case "One Week":
          return format(addDays(new Date(), 7), "yyyy-MM-dd");
        case "One Month":
          return format(addDays(new Date(), 30), "yyyy-MM-dd");
        case "Three Months":
          return format(addDays(new Date(), 90), "yyyy-MM-dd");
        case "Six Months":
          return format(addDays(new Date(), 180), "yyyy-MM-dd");
        default:
          console.log("Default");
      }
    },
    sendOverdueDeadlinesEmail(c) {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation($caseId: Int!, $client: String!) {
              sendOverdueDeadlinesEmail(caseId: $caseId, client: $client) {
                caseId
              }
            }
          `,
          variables: {
            caseId: c.item.caseId,
            client: c.item.clientName
          }
        })
        .then(({ data }) => {
          console.log(data);
          this.alert = true;
          this.alertType = "success";
          this.alertMessage = "Email sent successfully";
          setTimeout(() => {
            this.alert = false;
          }, 3000);
        });
    }
  }
};
</script>

<style scoped>
.date-range-select {
  padding: 13px 50px;
}
.alert {
  position: fixed;
  left: 50%;
  bottom: 50px;
  transform: translate(-50%, -50%);
  margin: 0 auto;
}
</style>
