<template>
  <div>
    <highcharts ref="chart" :options="chartOptions" />
  </div>
</template>

<script>
import gql from "graphql-tag";
import { mapGetters } from "vuex";

export default {
  mounted() {
    setTimeout(() => {
      if (!this.$refs.chart) return;
      this.$refs.chart.chart.reflow();
    }, 200);
  },
  computed: {
    ...mapGetters(["startDate", "endDate"]),
    chartOptions() {
      if (!this.signedStatsByType) return {};
      return {
        credits: {
          enabled: false
        },
        chart: {
          type: "pie",
          height: 200
        },
        title: {
          text: ""
        },
        tooltip: {
          pointFormat:
            "{point.name}: <b>{point.percentage:.1f}% ({point.y})</b>"
        },
        accessibility: {
          point: {
            valueSuffix: "%"
          }
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              enabled: true,
              format: "<b>{point.name}</b>: {point.percentage:.1f}% ({point.y})"
            }
          }
        },
        series: [
          {
            name: "Signed by Type",
            data: this.signedStatsByType
          }
        ]
      };
    }
  },
  apollo: {
    signedStatsByType: {
      query: gql`
        query OrgSignedStatsByType($startDate: String!, $endDate: String!) {
          signedStatsByType(startDate: $startDate, endDate: $endDate) {
            y: total
            name: type
          }
        }
      `,
      variables() {
        return {
          startDate: this.startDate,
          endDate: this.endDate
        };
      },
      skip() {
        return this.startDate === null || this.endDate === null;
      }
    }
  }
};
</script>

<style scoped></style>
