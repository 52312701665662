<template>
  <v-card>
    <v-toolbar flat dense>
      <span class="text-subtitle-1">Agent Statistics</span>
      <v-spacer></v-spacer>
      <v-switch class="pt-5" label="Show All" v-model="showAll"></v-switch>
    </v-toolbar>
    <div>
      <v-data-table
        :footer-props="{
          'items-per-page-options': [-1]
        }"
        :headers="headers"
        hide-default-footer
        :items="agents"
        :item-key="Math.random(10).toString()"
        :loading="$apollo.loading"
        :sort-by="['conversionRate']"
        :sort-desc="[true]"
      >
        <template v-slot:item.conversionRate="props">
          {{ props.item.conversionRate }}%
        </template>
      </v-data-table>
    </div>
  </v-card>
</template>

<script>
import gql from "graphql-tag";
import { mapGetters } from "vuex";
import filter from "lodash/filter";

export default {
  data() {
    return {
      headers: [
        { text: "Agent", value: "agent" },
        { text: "Leads", value: "totalLeads" },
        { text: "Wanted", value: "totalWanted" },
        { text: "Signed", value: "totalWantedSigned" },
        { text: "Conversion", value: "conversionRate" }
      ],
      showAll: false
    };
  },
  computed: {
    ...mapGetters(["startDate", "endDate"]),
    agents() {
      if (!this.agentStats || !this.department) return [];
      if (this.showAll) return this.agentStats;
      let users = this.department.users.map(u => {
        return u.staffCode;
      });
      return filter(this.agentStats, a => {
        return users.indexOf(a.agent) !== -1;
      });
    }
  },
  apollo: {
    agentStats: {
      query: gql`
        query OrgIntakeAgentStats($startDate: String!, $endDate: String!) {
          agentStats(startDate: $startDate, endDate: $endDate) {
            agent
            totalLeads
            totalWanted
            totalWantedSigned
            conversionRate
          }
        }
      `,
      variables() {
        return {
          startDate: this.startDate,
          endDate: this.endDate
        };
      },
      skip() {
        return this.startDate === null || this.endDate === null;
      }
    },
    department: {
      query: gql`
        query OrgIntakeAgentStatsDepartment($name: String!) {
          department: departmentByName(name: $name) {
            name
            users {
              staffCode
            }
          }
        }
      `,
      variables() {
        return {
          name: "Intake"
        };
      }
    }
  }
};
</script>

<style scoped></style>
