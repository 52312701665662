<template>
  <div>
    <v-row>
      <v-col sm="12" md="8">
        <v-card>
          <v-progress-linear
            color="primary"
            indeterminate
            v-if="$apollo.loading"
          ></v-progress-linear>
          <v-toolbar flat dense>
            <span class="text-subtitle-1">Pre-lit Fees in Period</span>
          </v-toolbar>
          <div class="chart-vis">
            <org-fees-in-period-by-attorney
              :options="feesInPeriodByPreLitAttorney"
            />
          </div>
        </v-card>
      </v-col>
      <v-col sm="12" md="4">
        <v-card>
          <v-progress-linear
            color="primary"
            indeterminate
            v-if="$apollo.loading"
          ></v-progress-linear>
          <v-toolbar flat dense>
            <span class="text-subtitle-1">Lit Fees in Period</span>
          </v-toolbar>
          <div class="chart-vis">
            <org-fees-in-period-by-attorney
              :options="feesInPeriodByLitAttorney"
            />
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <fees-by-week></fees-by-week>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import gql from "graphql-tag";
import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";
import { mapGetters } from "vuex";
import OrgFeesInPeriodByAttorney from "../charts/OrgFeesInPeriodByAttorney";
import FeesByWeek from "../charts/FeesByWeek.vue";
import { getPreLitAttorneysDb, getLitAttorneysDb } from "../util";

export default {
  components: {
    OrgFeesInPeriodByAttorney,
    FeesByWeek,
  },
  apollo: {
    organization: {
      query: gql`
        query OrgHomeFeeCharts(
          $id: ID!
          $startDate: String!
          $endDate: String!
        ) {
          organization(id: $id) {
            id
            name
            departments {
              id
              name
              users {
                staffCode
              }
            }
            saFeesInPeriodByAttorney(startDate: $startDate, endDate: $endDate) {
              attorney
              amount
            }
          }
        }
      `,
      variables() {
        return {
          id: this.$route.params.id,
          startDate: this.startDate,
          endDate: this.endDate,
        };
      },
      skip() {
        return this.startDate === null || this.endDate === null;
      },
    },
  },
  computed: {
    ...mapGetters(["currentUser", "startDate", "endDate"]),
    feesInPeriodByPreLitAttorney() {
      if (!this.organization) return {};
      let attorneys = getPreLitAttorneysDb(this.organization);
      let cleanData = this.organization.saFeesInPeriodByAttorney.map((d) => {
        if (d.attorney === "RLG-P") {
          return { attorney: "RG", amount: d.amount };
        } else {
          if (!d.attorney) return { attorney: "", amount: 0 };
          return { attorney: d.attorney.replace(/-P/i, ""), amount: d.amount };
        }
      });
      let attorneysWithFees = cleanData.map((d) => d.attorney);
      // compare the attorneys array with the attorneysWithFees array and return the names of attorneys in attorneysWithFees that are not in attorneys
      let unlistedAttorneys = attorneysWithFees.filter(
        (a) => !attorneys.includes(a)
      );
      let groupedByAttorney = groupBy(cleanData, (f) => {
        return f.attorney;
      });
      let series = attorneys.concat(unlistedAttorneys).map((a) => {
        return {
          name: a,
          y: groupedByAttorney[a]
            ? parseFloat(groupedByAttorney[a][0].amount)
            : 0,
        };
      });
      // create a new array from the series array, combine the objects with the name of "LS" and "KNP" to create a new object with the sum of the y property, also combine the objects with the name of "CLP" and "PPR"
      series = series.reduce((acc, obj) => {
        if (obj.name === "LS" || obj.name === "KNP" || obj.name === "EMR") {
          let newObj = acc.find((o) => o.name === "LS");
          if (newObj) {
            newObj.y += obj.y;
          } else {
            acc.push({ name: "LS", y: obj.y });
          }
        } else if (obj.name === "CLP" || obj.name === "PPR") {
          let newObj = acc.find((o) => o.name === "CLP");
          if (newObj) {
            newObj.y += obj.y;
          } else {
            acc.push({ name: "CLP", y: obj.y });
          }
        } else if (
          obj.name === "ACS" ||
          obj.name === "TRS" ||
          obj.name === "RAS"
        ) {
          let newObj = acc.find((o) => o.name === "ACS");
          if (newObj) {
            newObj.y += obj.y;
          } else {
            acc.push({ name: "ACS", y: obj.y });
          }
        } else {
          acc.push(obj);
        }
        return acc;
      }, []);
      series = sortBy(series, [(s) => s.name]);
      return {
        xAxis: {
          type: "category",
        },
        series: [
          {
            name: "Attorneys",
            data: series,
          },
        ],
      };
    },
    feesInPeriodByLitAttorney() {
      if (!this.organization) return {};
      let attorneys = getLitAttorneysDb(this.organization);
      let cleanData = this.organization.saFeesInPeriodByAttorney.map((d) => {
        if (d.attorney === "RLG-P") {
          return { attorney: "RG", amount: d.amount };
        } else {
          if (!d.attorney) return { attorney: "", amount: 0 };
          return { attorney: d.attorney.replace(/-P/i, ""), amount: d.amount };
        }
      });
      let groupedByAttorney = groupBy(cleanData, (f) => {
        return f.attorney;
      });
      let series = attorneys.map((a) => {
        return {
          name: a,
          y: groupedByAttorney[a]
            ? parseFloat(groupedByAttorney[a][0].amount)
            : 0,
        };
      });
      series = sortBy(series, [(s) => s.name]);
      return {
        xAxis: {
          type: "category",
        },
        series: [
          {
            name: "Attorneys",
            data: series,
          },
        ],
      };
    },
  },
};
</script>

<style scoped lang="scss"></style>
