<template>
  <v-card>
    <v-container class="py-0" fluid>
      <v-row align-content="space-around">
        <v-col cols="12" sm="2">
          <v-card-title class="pa-0">
            <div>
              <div class="headline">Settlements</div>
              <div class="text-subtitle-1 font-weight-thin">
                {{ totalCases }} Settled Cases
              </div>
            </div>
          </v-card-title>
        </v-col>
        <v-col cols="12" sm="2">
          <div class="headline">{{ feesNotBanked }}</div>
          <div class="text-subtitle-1 font-weight-thin">Fees Not Banked</div>
        </v-col>
        <v-col cols="12" sm="2">
          <div class="mr-5">
            <v-switch
              class=""
              label="Only Not Banked"
              v-model="showNotBanked"
            ></v-switch>
          </div>
        </v-col>
        <v-col cols="12" sm="2" v-if="isAdmin">
          <div class="mr-5">
            <v-switch class="" label="Show All" v-model="showAll"></v-switch>
          </div>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            hide-details
            single-line
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
    <v-data-table
      :footer-props="{
        'items-per-page-options': [50, 100, -1],
      }"
      :headers="headers"
      :items="settlements"
      :item-key="(Math.random() * 1000).toString()"
      :loading="$apollo.loading"
      :search="search"
      :sort-by="['dateCreated']"
      :sort-desc="[false]"
      ref="orgSettlementsTable"
    >
      <template v-slot:item.caseId="props">
        <a
          class="go-to-case-link mr-2"
          target="_blank"
          :href="
            `https://cs-web.airdesksolutions.com/SA/Negotiation.aspx?CaseID=${props.item.caseId}`
          "
        >
          <v-icon size="15">mdi-open-in-new</v-icon>
        </a>
      </template>
      <template v-slot:item.settlement="props">
        {{ formatValue(props.item.settlement) }}
      </template>
      <template v-slot:item.attorneyFee="props">
        {{ formatValue(props.item.attorneyFee) }}
      </template>
      <template v-slot:item.feesTaken="props">
        {{ formatValue(props.item.feesTaken) }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import gql from "graphql-tag";
import { mapGetters } from "vuex";
import accounting from "accounting";
import filter from "lodash/filter";
import { hasRole } from "../util";
import groupBy from "lodash/groupBy";
import get from "lodash/get";

export default {
  data() {
    return {
      search: "",
      headers: [
        { text: "Action", value: "caseId", width: "100px" },
        { text: "Attorney", value: "attorney" },
        { text: "Client", value: "clientName" },
        { text: "Type", value: "type" },
        { text: "Ins Type", value: "insType" },
        { text: "Date", value: "dateCreated" },
        { text: "Settlement", value: "settlement" },
        { text: "Fee", value: "attorneyFee" },
        { text: "Banked", value: "feesTaken" },
        { text: "Market", value: "market" },
        { text: "Closed", value: "closingDate" },
      ],
      totalSettlements: 0,
      showNotBanked: false,
      showAll: false,
    };
  },

  computed: {
    ...mapGetters(["currentUser", "startDate", "endDate", "selectedTeam"]),
    isAdmin() {
      if (!this.currentUser) return false;
      return hasRole(this.currentUser, "admin");
    },
    settlements() {
      if (!this.openSettlements || !this.openSettlementsByStaff) return [];
      let settlements = this.openSettlementsByStaff;
      if (this.showAll) {
        settlements = this.openSettlements;
      }
      if (this.showNotBanked) {
        settlements = filter(settlements, (s) => {
          let referralFees = parseFloat(s.referralFee) || 0;
          let fees = parseFloat(s.attorneyFee) || 0;
          let taken = parseFloat(s.feesTaken) || 0;
          return taken + referralFees < fees && s.closingDate === null;
        });
      }
      return settlements;
    },
    totalCases() {
      if (!this.settlements) return 0;
      let settlements = this.settlements;
      if (this.search) {
        settlements = get(
          this.$refs,
          "orgSettlementsTable.$children[0].filteredItems"
        );
      }
      let groupedByCases = groupBy(settlements, "caseId");
      return Object.keys(groupedByCases).length;
    },
    feesNotBanked() {
      if (!this.openSettlements) return 0;
      let settlements = this.showAll
        ? this.openSettlements
        : this.openSettlementsByStaff;
      if (this.search) {
        settlements = get(
          this.$refs,
          "orgSettlementsTable.$children[0].filteredItems"
        );
      }
      let feesToCount = filter(settlements, (s) => {
        let referralFees = parseFloat(s.referralFee) || 0;
        let fees = parseFloat(s.attorneyFee) || 0;
        let taken = parseFloat(s.feesTaken) || 0;
        return taken + referralFees < fees && s.closingDate === null;
      });
      let total = feesToCount.reduce((prev, cur) => {
        let fees = parseFloat(cur.attorneyFee) || 0;
        let taken = parseFloat(cur.feesTaken) || 0;
        return prev + fees - taken;
      }, 0);
      return accounting.formatMoney(total);
    },
  },
  apollo: {
    openSettlements: {
      query: gql`
        query DashFeeSettlements {
          openSettlements {
            attorney
            caseId
            caseNumber
            clientName
            type
            settlement
            insType
            attorneyFee
            referralFee
            feesTaken
            dateCreated
            incidentDate
            openingDate
            retainedDate
            market
            referralSource
            closingDate
          }
        }
      `,
      skip() {
        if (!this.currentUser) return true;
        return false;
      },
    },
    openSettlementsByStaff: {
      query: gql`
        query DashFeeOpenSettlements {
          openSettlementsByStaff {
            attorney
            caseId
            caseNumber
            clientName
            type
            settlement
            insType
            attorneyFee
            referralFee
            feesTaken
            dateCreated
            incidentDate
            openingDate
            retainedDate
            market
            referralSource
            closingDate
          }
        }
      `,
      skip() {
        if (!this.currentUser) return true;
        return false;
      },
    },
  },
  methods: {
    formatValue(val) {
      return accounting.formatMoney(parseFloat(val));
    },
    formatPercentage(val) {
      return parseFloat(val).toFixed(2) + "%";
    },
  },
};
</script>

<style scoped></style>
