<template>
  <v-card>
    <v-container class="py-0" fluid>
      <v-row align-content="space-around">
        <v-col cols="12" sm="6">
          <v-card-title class="pa-0">
            <div>
              <div class="headline">Balances</div>
              <div class="text-subtitle-1 font-weight-thin">
                {{ balances.length }} Balances
              </div>
            </div>
          </v-card-title>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            hide-details
            single-line
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
    <v-data-table
      :footer-props="{
        'items-per-page-options': [-1]
      }"
      :headers="headers"
      hide-default-footer
      :items="balances"
      :item-key="(Math.random() * 1000).toString()"
      :loading="$apollo.loading"
      :search="search"
      :sort-by="['medicalProvider']"
      :sort-desc="[false]"
      group-by="medicalProvider"
      show-group-by
    >
      <template v-slot:item.caseId="{ item }">
        <a
          class="go-to-case-link mr-2"
          target="_blank"
          :href="
            `https://cs-web.airdesksolutions.com/SA/Plaintiff_Medical_Provider.aspx?current=true&CaseID=${item.caseId}`
          "
        >
          <v-icon size="15">mdi-open-in-new</v-icon>
        </a>
      </template>
      <template v-slot:item.clientName="{ item }">
        {{ truncate(item.clientName, 20) }}
      </template>
      <template v-slot:item.medicalProvider="{ item }">
        {{ truncate(item.medicalProvider, 20) }}
      </template>
      <template v-slot:item.comments="{ item }">
        <a
          class="go-to-case-link mr-2"
          @click="
            dialog = true;
            comments = item.comments;
          "
        >
          Comments
        </a>
      </template>
      <template v-slot:item.total="{ item }">
        <span style="display: block; text-align: right;">
          {{ formatMoney(item.total) }}
        </span>
      </template>
      <template v-slot:item.paid="{ item }">
        <span style="display: block; text-align: right;">
          {{ formatMoney(item.paid) }}
        </span>
      </template>
      <template v-slot:item.adjusted="{ item }">
        <span style="display: block; text-align: right;">
          {{ formatMoney(item.adjusted) }}
        </span>
      </template>
      <template v-slot:item.balance="{ item }">
        <span style="display: block; text-align: right;">
          {{ formatMoney(item.balance) }}
        </span>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog" width="500">
      <v-card class="pt-5">
        <v-card-text style="white-space: pre-wrap">{{ comments }}</v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import gql from "graphql-tag";
import { mapGetters } from "vuex";
import accounting from "accounting";

export default {
  data() {
    return {
      search: "",
      headers: [
        { text: "Action", value: "caseId", width: "80px" },
        { text: "Client", value: "clientName", width: "150px" },
        { text: "Requested", value: "startDate", width: "150px" },
        { text: "Provider", value: "medicalProvider", width: "150px" },
        { text: "Total", value: "total", width: "150px" },
        { text: "Paid", value: "paid", width: "150px" },
        { text: "Adjusted", value: "adjusted", width: "150px" },
        { text: "Balance", value: "balance", width: "150px" },
        { text: "Comments", value: "comments" }
      ],
      dialog: false,
      comments: "",
      showRequested: false
    };
  },
  computed: {
    ...mapGetters(["imitatedUser"]),
    balances() {
      if (!this.user) return [];
      return this.user.uncompletedBalanceVerifications;
    }
  },
  methods: {
    truncate(string, length) {
      if (!string) return "";
      return string.slice(0, length);
    },
    formatMoney(x) {
      return accounting.formatMoney(x);
    }
  },
  apollo: {
    user: {
      query: gql`
        query($id: ID!) {
          user(id: $id) {
            id
            first_name
            last_name
            watchedCases {
              caseId
            }
            uncompletedBalanceVerifications {
              caseId
              clientName
              category
              summary
              description
              startDate
              dueDate
              requestorUser
              medicalProviderId
              medicalProvider
              total
              paid
              adjusted
              balance
              comments
            }
          }
        }
      `,
      variables() {
        let id = this.imitatedUser
          ? this.imitatedUser.toString()
          : this.$route.params.id;
        return {
          id
        };
      }
    }
  }
};
</script>

<style scoped></style>
