<template>
  <div>
    <v-tabs>
      <v-tab v-for="(link, index) in links" :key="index">
        <v-btn text exact :to="link.path">{{ link.name }}</v-btn>
      </v-tab>
    </v-tabs>
    <router-view></router-view>
  </div>
</template>

<script>
import { hasRole } from "../util";

export default {
  computed: {
    links() {
      let id = this.$route.params.id;
      let links = [{ name: "Fees", path: `/org/${id}/fees/` }];
      let currentUser = this.$store.state.user.currentUser;
      if (!currentUser) return links;
      if (
        hasRole(currentUser, "attorney") ||
        hasRole(currentUser, "admin") ||
        hasRole(currentUser, "marketing")
      ) {
        links = links.concat([
          { name: "Fee Comparison", path: `/org/${id}/fees/fee-comparison` },
          { name: "Settlements", path: `/org/${id}/fees/fee-settlements` },
          { name: "Rate", path: `/org/${id}/fees/fee-rate` },
        ]);
      }
      return links;
    },
  },
};
</script>

<style scoped></style>
