<template>
  <div>
    <v-tabs>
      <v-tab v-for="(link, index) in links" :key="index">
        <v-btn text exact :to="link.path">{{ link.name }}</v-btn>
      </v-tab>
    </v-tabs>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  computed: {
    links() {
      let id = this.$route.params.id;
      return [
        { name: "CM Scorecard", path: `/org/${id}/staff` },
        { name: "Client Contact", path: `/org/${id}/staff/client-contact` },
        {
          name: "Paralegal Stats",
          path: `/org/${id}/staff/paralegal-stats`,
        },
      ];
    },
  },
};
</script>

<style scoped></style>
