<template>
  <v-card>
    <v-toolbar flat>
      <div>
        <div class="headline">Users</div>
        <div class="text-subtitle-1 font-weight-thin">
          {{ users.length }} Users
        </div>
      </div>
      <v-spacer></v-spacer>
      <div class="mr-5 pt-5">
        <v-switch class="" label="Show Online" v-model="showOnline"></v-switch>
      </div>
      <div class="mr-5 pt-5">
        <v-switch class="" label="Show All" v-model="showAll"></v-switch>
      </div>
      <div class="mr-5 pt-5" style="width: 300px">
        <v-text-field v-model="search" placeholder="Search..."></v-text-field>
      </div>
      <v-btn
        @click.native.stop="dialog = true"
        color="primary"
        dark
        class="mb-2"
        >New User</v-btn
      >
    </v-toolbar>
    <v-row>
      <v-col cols="12">
        <v-dialog v-model="dialog" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="4" md="4">
                    <v-text-field
                      v-model="editedItem.firstName"
                      label="First Name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-text-field
                      v-model="editedItem.lastName"
                      label="Last Name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-text-field
                      v-model="editedItem.staffCode"
                      label="Staff Code"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.email"
                      label="Email"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem.password"
                      label="Password"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-text-field
                      type="number"
                      v-model="editedItem.saContactId"
                      label="SA Contact ID"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-text-field
                      type="number"
                      v-model="editedItem.capacity"
                      label="Capacity"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-switch
                      v-model="editedItem.active"
                      label="Active"
                    ></v-switch>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-select
                      v-model="editedItem.teamIds"
                      label="Teams"
                      :items="teamOptions"
                      multiple
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-select
                      v-model="editedItem.roleIds"
                      label="Roles"
                      :items="roleOptions"
                      multiple
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-select
                      v-model="editedItem.officeIds"
                      label="Offices"
                      :items="officeOptions"
                      multiple
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-select
                      v-model="editedItem.departmentIds"
                      label="Departments"
                      :items="departmentOptions"
                      multiple
                    ></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click.native="close"
                >Cancel</v-btn
              >
              <v-btn color="blue darken-1" text @click.native="save"
                >Save</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-data-table
          :footer-props="{
            'items-per-page-options': [-1],
          }"
          :headers="headers"
          :search="search"
          hide-default-footer
          :items="users"
          :loading="$apollo.loading"
          :sort-by="['name']"
          :sort-desc="[false]"
        >
          <template v-slot:body="{ items }">
            <tbody>
              <tr v-for="item in items" :key="item.id">
                <td>
                  <span>
                    {{ item.name }}
                    <v-icon
                      v-if="onlineUsers.includes(item.id.toString())"
                      x-small
                      color="green"
                      >mdi-circle</v-icon
                    >
                  </span>
                </td>
                <td>{{ item.email }}</td>
                <td>{{ item.capacity }}</td>
                <td>{{ item.roles }}</td>
                <td>{{ item.offices }}</td>
                <td>{{ item.departments }}</td>
                <td>
                  <v-simple-checkbox
                    color="primary"
                    :value="item.active"
                    :ripple="false"
                  />
                </td>
                <td class="justify-center layout px-0">
                  <v-icon
                    small
                    class="mr-2"
                    color="teal"
                    @click="editItem(item)"
                    >mdi-account-edit</v-icon
                  >
                  <v-icon small color="pink" @click="deleteItem(item)"
                    >mdi-delete</v-icon
                  >
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import gql from "graphql-tag";
import get from "lodash/get";
import sortBy from "lodash/sortBy";
import filter from "lodash/filter";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      dialog: false,
      search: "",
      showAll: false,
      headers: [
        { text: "Name", value: "name" },
        { text: "Email", value: "email" },
        { text: "Capacity", value: "capacity" },
        { text: "Roles", value: "roles" },
        { text: "Offices", value: "offices" },
        { text: "Departments", value: "departments" },
        { text: "Active", value: "active" },
        { text: "Actions", align: "center", value: "", sortable: false },
      ],
      editedIndex: -1,
      editedItem: {
        firstName: "",
        lastName: "",
        staffCode: "",
        email: "",
        password: "",
        saContactId: "",
        capacity: 0,
        active: true,
        teamIds: [],
        roleIds: [],
        officeIds: [],
        departmentIds: [],
      },
      defaultItem: {
        firstName: "",
        lastName: "",
        staffCode: "",
        email: "",
        password: "",
        saContactId: "",
        capacity: 0,
        active: true,
        teamIds: [],
        roleIds: [],
        officeIds: [],
        departmentIds: [],
      },
      roles: [],
      roleOptions: [],
      teamOptions: [],
      officeOptions: [],
      departmentOptions: [],
      organization: {
        teams: [],
        offices: [],
        departments: [],
      },
      showOnline: false,
    };
  },
  computed: {
    ...mapGetters(["socket", "onlineUsers"]),
    users() {
      let users = get(this.organization, "users");
      if (users && users.length > 0) {
        let filteredUsers = users;
        if (!this.showAll) {
          filteredUsers = filter(users, (u) => {
            return u.active === true;
          });
          if (this.showOnline) {
            filteredUsers = filter(filteredUsers, (u) => {
              return this.onlineUsers.includes(u.id.toString());
            });
          }
        }
        let mappedUsers = filteredUsers.map((u) => {
          let roles = u.roles
            .map((role) => {
              return role.name;
            })
            .join(", ");
          let roleIds = u.roles.map((role) => {
            return role.id;
          });
          let teamIds = u.teams.map((team) => {
            return team.id;
          });
          let offices = u.offices
            .map((office) => {
              return office.name;
            })
            .join(", ");
          let officeIds = u.offices.map((office) => {
            return office.id;
          });
          let departments = u.departments
            .map((department) => {
              return department.name;
            })
            .join(", ");
          let departmentIds = u.departments.map((department) => {
            return department.id;
          });
          return {
            id: u.id,
            firstName: u.first_name,
            lastName: u.last_name,
            name: `${u.first_name} ${u.last_name}`,
            email: u.email,
            saContactId: u.saContactId,
            staffCode: u.staffCode,
            capacity: u.capacity,
            active: u.active,
            roles,
            roleIds,
            teamIds,
            offices,
            officeIds,
            departments,
            departmentIds,
          };
        });
        return mappedUsers;
      } else {
        return [];
      }
    },
    formTitle() {
      return this.editedIndex === -1 ? "New User" : "Edit User";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    "organization.teams"(val) {
      this.teamOptions = sortBy(
        val.map((t) => {
          return { text: t.name, value: t.id };
        }),
        [(item) => item.text]
      );
    },
    "organization.offices"(val) {
      this.officeOptions = sortBy(
        val.map((o) => {
          return { text: o.name, value: o.id };
        }),
        [(item) => item.text]
      );
    },
    "organization.departments"(val) {
      this.departmentOptions = sortBy(
        val.map((d) => {
          return { text: d.name, value: d.id };
        }),
        [(item) => item.text]
      );
    },
    roles(val) {
      this.roleOptions = sortBy(
        val.map((r) => {
          return { text: r.name, value: r.id };
        }),
        [(item) => item.text]
      );
    },
  },
  apollo: {
    roles: {
      query: gql`
        query {
          roles {
            id
            name
          }
        }
      `,
    },
    organization: {
      query: gql`
        query($id: ID!) {
          organization(id: $id) {
            id
            name
            teams {
              id
              name
            }
            offices {
              id
              name
            }
            departments {
              id
              name
            }
            users {
              id
              first_name
              last_name
              email
              saContactId
              staffCode
              capacity
              active
              roles {
                id
                name
              }
              teams {
                id
                name
              }
              offices {
                id
                name
              }
              departments {
                id
                name
              }
            }
          }
        }
      `,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
    },
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem() {
      confirm("Are you sure you want to delete this user?");
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      let contactId = parseInt(this.editedItem.saContactId);
      let capacity = parseInt(this.editedItem.capacity);
      this.editedItem.saContactId = contactId;
      this.editedItem.capacity = capacity;
      delete this.editedItem.roles;
      delete this.editedItem.offices;
      delete this.editedItem.departments;
      delete this.editedItem.name;
      if (!this.editedItem.password) {
        delete this.editedItem.password;
      }
      this.$apollo
        .mutate({
          mutation: gql`
            mutation($user: UpsertUserInput) {
              upsertUser(user: $user) {
                id
              }
            }
          `,
          variables: {
            user: this.editedItem,
          },
        })
        .then(({ data }) => {
          console.log(data);
          this.dialog = false;
          setTimeout(() => {
            this.editedItem = Object.assign({}, this.defaultItem);
            this.editedIndex = -1;
          });
          this.$apollo.queries.organization.refetch();
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style scoped></style>
