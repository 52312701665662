<template>
  <v-card>
    <v-alert :type="alertType" v-if="alert">{{ alertMessage }}</v-alert>
    <v-container class="py-0" fluid>
      <v-row align-content="space-around">
        <v-col cols="12" sm="2">
          <v-card-title class="pa-0">
            <div>
              <div class="headline">Intake Calendar</div>
            </div>
          </v-card-title>
        </v-col>
      </v-row>
    </v-container>
    <v-toolbar flat>
      <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
        Today
      </v-btn>
      <v-btn fab text small color="grey darken-2" @click="prev">
        <v-icon small>
          mdi-chevron-left
        </v-icon>
      </v-btn>
      <v-btn fab text small color="grey darken-2" @click="next">
        <v-icon small>
          mdi-chevron-right
        </v-icon>
      </v-btn>
      <v-toolbar-title v-if="$refs.calendar">
        {{ $refs.calendar.title }}
      </v-toolbar-title>
    </v-toolbar>
    <v-calendar
      ref="calendar"
      v-model="focus"
      color="primary"
      type="week"
      category-show-all
      first-time="07:00"
      interval-count="14"
      :categories="categories"
      :events="events"
      :event-color="getEventColor"
      @click:event="showEvent"
    />
    <v-dialog v-model="eventDialog" hide-overlay width="300">
      <v-card v-if="selectedEvent">
        <v-card-title>{{ selectedEvent.name }}</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-menu
                ref="dateMenu"
                :close-on-content-click="false"
                v-model="dateMenu"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <div v-on="on" class="menu-date-picker blue darken-2">
                    {{ workingEvent.date }}
                  </div>
                </template>
                <v-date-picker
                  v-model="workingEvent.date"
                  no-title
                  @input="dateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
          <v-btn @click="addEvent" color="primary">Add Event</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import gql from "graphql-tag";
import { mapGetters } from "vuex";
import format from "date-fns/format";
import addHours from "date-fns/addHours";
import { getInvestigatorsDb } from "../util";
// import { zonedTimeToUtc, utcToZonedTime } from "date-fns-tz";

export default {
  data() {
    let defaultEvent = {
      id: null,
      type: "intake",
      name: "",
      color: "",
      office_id: "",
      creator_id: "",
      investigator_id: "",
      date: "",
      start: "",
      end: "",
      caseId: "",
      details: "",
      location: "",
      timezone: ""
    };
    return {
      alert: false,
      alertType: false,
      alertMessage: false,
      eventDialog: false,
      focus: "",
      defaultEvent,
      workingEvent: Object.assign({}, defaultEvent),
      dateMenu: false,
      events: [
        {
          id: 1,
          color: "blue",
          name: "Doug - Somebody",
          start: format(new Date(), "yyyy-MM-dd HH:MM"),
          end: format(addHours(new Date(), 1), "yyyy-MM-dd HH:MM"),
          caseId: 123456
        }
      ],
      colors: [
        "blue",
        "indigo",
        "deep-purple",
        "cyan",
        "green",
        "orange",
        "grey darken-1"
      ],
      categories: ["intake"],
      selectedEventId: null
    };
  },
  mounted() {
    this.$refs.calendar.checkChange();
  },
  methods: {
    addEvent(e) {
      return e;
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent(e) {
      console.log(this.$refs.calendar.getNow());
      console.log(this.$refs.calendar);
      console.log(e);
      this.selectedEventId = e.event.id;
      this.eventDialog = true;
    }
  },
  computed: {
    ...mapGetters(["currentUser", "startDate", "endDate"]),
    selectedEvent() {
      if (this.selectedEventId) {
        return this.events.find(e => e.id === this.selectedEventId);
      }
      return null;
    },
    investigators() {
      if (!this.organization) return [];
      return getInvestigatorsDb(this.organization);
    }
  },
  apollo: {
    organization: {
      query: gql`
        query($id: ID!) {
          organization(id: $id) {
            id
            name
            teams {
              id
              name
            }
            departments {
              id
              name
              users {
                staffCode
              }
            }
          }
        }
      `,
      variables() {
        return {
          id: 2
        };
      }
    }
  }
};
</script>

<style scoped>
.menu-date-picker {
  width: 110px;
  padding: 10px 15px;
  margin-right: 5px;
  font-size: 14px;
  max-height: 48px;
  white-space: nowrap;
}
</style>
