<template>
  <div>
    <v-tabs>
      <v-tab v-for="(link, index) in links" :key="index">
        <v-btn text exact :to="link.path">{{ link.name }}</v-btn>
      </v-tab>
    </v-tabs>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  computed: {
    links() {
      let id = this.$route.params.id;
      return [
        { name: "Report", path: `/org/${id}/intake` },
        { name: "Assignment", path: `/org/${id}/intake/assignment` },
        { name: "Leads", path: `/org/${id}/intake/leads` },
        { name: "Follow Up", path: `/org/${id}/intake/follow-up` },
        {
          name: "Investigations",
          path: `/org/${id}/intake/investigations`
        },
        // {
        //   name: "Calendar",
        //   path: `/org/${id}/intake/calendar`
        // },
        {
          name: "New Cases",
          path: `/org/${id}/intake/new-cases`
        },
        {
          name: "Referrals",
          path: `/org/${id}/intake/referrals`
        },
        {
          name: "Referrals Map",
          path: `/org/${id}/intake/referrals-map`
        },
        {
          name: "Referred Out",
          path: `/org/${id}/intake/referred-out`
        },
        {
          name: "PPC Report",
          path: `/org/${id}/intake/ppc-report`
        }
      ];
    }
  }
};
</script>

<style scoped></style>
