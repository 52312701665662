<template>
  <v-card>
    <v-container class="py-0" fluid>
      <v-row align-content="space-around">
        <v-col cols="12" sm="4">
          <v-card-title class="pa-0">
            <div>
              <div class="headline">Deadline Review</div>
              <div class="text-subtitle-1 font-weight-thin">
                {{ filteredCases.length }} Cases
              </div>
            </div>
          </v-card-title>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            hide-details
            single-line
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
    <v-data-table
      :footer-props="{
        'items-per-page-options': [-1]
      }"
      :headers="headers"
      hide-default-footer
      :items="filteredCases"
      :loading="$apollo.loading"
      :search="search"
      :sort-by="['dateOfLastFileReview']"
      :sort-desc="[false]"
    >
      <template v-slot:body="{ items }">
        <tbody>
          <tr
            :style="{ background: getDateColor(item.dateOfLastFileReview) }"
            v-for="item in items"
            :key="item + Math.random(10).toString()"
          >
            <td>
              <a
                class="go-to-case-link mr-2"
                target="_blank"
                :href="
                  `https://cs-web.airdesksolutions.com/SA/CaseSummary.aspx?CaseID=${item.caseId}`
                "
              >
                <v-icon size="15">mdi-open-in-new</v-icon>
              </a>
            </td>
            <td>{{ item.stage }}</td>
            <td>{{ item.clientName }}</td>
            <td>{{ formatLastContactDate(item.dateOfLastFileReview) }}</td>
            <td>{{ item.paralegal }}</td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import gql from "graphql-tag";
import filter from "lodash/filter";
import { getDaysSinceToday } from "../util";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      search: "",
      pagination: { sortBy: "dateOfLastFileReview", descending: false },
      headers: [
        { text: "Action", value: "", width: "100px" },
        { text: "Stage", value: "stage" },
        { text: "Client", value: "clientName" },
        { text: "Last Review", value: "dateOfLastFileReview" },
        { text: "Paralegal", value: "paralegal" }
      ],
      user: { cases: [] }
    };
  },
  computed: {
    ...mapGetters(["imitatedUser"]),
    hideFileReviewStages: {
      get() {
        return this.$store.getters.hideFileReviewStages;
      },
      set(value) {
        this.$store.dispatch("setHideFileReviewStages", value);
      }
    },
    filteredCases() {
      let stages = this.$store.getters.hideFileReviewStages;
      return filter(this.user.cases, c => {
        return stages.indexOf(c.stage) === -1;
      });
    }
  },
  apollo: {
    user: {
      query: gql`
        query($id: ID!) {
          user(id: $id) {
            id
            first_name
            last_name
            watchedCases {
              caseId
            }
            cases: casesWithLastDeadlineReview {
              caseId
              stage
              clientName
              paralegal
              dateOfLastFileReview
            }
          }
        }
      `,
      variables() {
        let id = this.imitatedUser
          ? this.imitatedUser.toString()
          : this.$route.params.id;
        return {
          id
        };
      }
    }
  },
  methods: {
    trimStatus(status) {
      if (status) return status.substr(0, 3);
      return "";
    },
    formatLastContactDate(date) {
      if (!date) return "";
      let days = getDaysSinceToday(date);
      return `${date} (${days} days)`;
    },
    getDateColor(date) {
      if (!date) return "#EF9A9A";
      let days = getDaysSinceToday(date);
      if (days <= 30) return "#ffffff";
      else if (days > 30 && days <= 45) return "#FFF59D";
      else return "#EF9A9A";
    }
  }
};
</script>

<style scoped></style>
