<template>
  <v-card class="pl-5 pr-5">
    <v-row>
      <v-toolbar flat>
        <div>
          <div class="headline pl-1">Marketing Stats</div>
        </div>
      </v-toolbar>
    </v-row>
    <v-row>
      <v-col cols="12" sm="3">
        <v-card>
          <v-card-title>
            <span class="text-h5">Page Speed</span>
            <span class="ml-5 subtitle-1">({{ pageSpeed.url }})</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6">
                <div
                  :style="scoreColor(pageSpeed.mobileScore)"
                  class="text-center text-subtitle-2"
                >
                  Mobile
                </div>
                <div
                  :style="scoreColor(pageSpeed.mobileScore)"
                  class="text-center text-h4"
                >
                  {{ precision(pageSpeed.mobileScore) }}
                </div>
              </v-col>
              <v-col cols="12" sm="6">
                <div
                  :style="scoreColor(pageSpeed.desktopScore)"
                  class="text-center text-subtitle-2"
                >
                  Desktop
                </div>
                <div
                  :style="scoreColor(pageSpeed.desktopScore)"
                  class="text-center text-h4"
                >
                  {{ precision(pageSpeed.desktopScore) }}
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6">
        <v-card>
          <OrgMarketingCallsByDay />
        </v-card>
      </v-col>

      <v-col cols="12" sm="6">
        <v-card>
          <OrgMarketingCallsBySource />
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import gql from "graphql-tag";
// import { mapGetters } from "vuex";
import OrgMarketingCallsByDay from "@/charts/OrgMarketingCallsByDay.vue";
import OrgMarketingCallsBySource from "@/charts/OrgMarketingCallsBySource.vue";

export default {
  data() {
    return {};
  },
  components: {
    OrgMarketingCallsByDay,
    OrgMarketingCallsBySource
  },
  computed: {
    pageSpeed() {
      if (!this.mostRecentPageSpeed)
        return { url: "", desktopScore: 0, mobileScore: 0 };
      return this.mostRecentPageSpeed[0];
    }
  },
  methods: {
    scoreColor(score) {
      if (score >= 90) return "color: green";
      if (score >= 80) return "color: orange";
      return "color: red";
    },
    precision(score) {
      return parseFloat(score).toFixed(0);
    }
  },
  apollo: {
    mostRecentPageSpeed: {
      query: gql`
        query OrgMostRecentPageSpeed {
          mostRecentPageSpeed {
            url
            mobileScore
            desktopScore
          }
        }
      `
    }
  }
};
</script>

<style scoped>
.chart-container {
  height: 220px;
}
</style>
