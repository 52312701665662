<template>
  <v-card>
    <v-container class="py-0" fluid>
      <v-row align-content="space-around">
        <v-col cols="12" sm="2">
          <v-card-title class="pa-0">
            <div>
              <div class="headline">Fee Comparison</div>
              <div class="text-subtitle-1 font-weight-thin" v-if="showList">
                {{ totalSettlements }} Settled Cases
              </div>
            </div>
          </v-card-title>
        </v-col>
        <v-col cols="12" sm="3">
          <v-radio-group v-model="showList" row>
            <v-radio label="Aggregate" :value="false"></v-radio>
            <v-radio label="List" :value="true"></v-radio>
          </v-radio-group>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="4" v-if="showList">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            hide-details
            single-line
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
    <v-data-table
      :footer-props="{
        'items-per-page-options': [-1]
      }"
      :headers="listHeaders"
      hide-default-footer
      :items="feeComparisonInPeriod"
      item-key="caseId"
      :loading="$apollo.loading"
      :search="search"
      :sort-by="['attorney']"
      :sort-desc="[false]"
      @current-items="getFiltered"
      v-if="showList"
    >
      <template v-slot:item.caseId="props">
        <a
          class="go-to-case-link mr-2"
          target="_blank"
          :href="
            `https://cs-web.airdesksolutions.com/SA/CaseSummary.aspx?CaseID=${props.item.caseId}`
          "
        >
          <v-icon size="15">mdi-open-in-new</v-icon>
        </a>
      </template>
      <template v-slot:item.settlements="props">
        {{ formatValue(props.item.settlements) }}
      </template>
      <template v-slot:item.fees="props">
        {{ formatValue(props.item.fees) }}
      </template>
      <template v-slot:item.feePercentage="props">
        {{ formatPercentage(props.item.feePercentage) }}
      </template>
      <template v-slot:item.totalMedicalBills="props">
        {{ formatValue(props.item.totalMedicalBills) }}
      </template>
    </v-data-table>
    <v-data-table
      :footer-props="{
        'items-per-page-options': [-1]
      }"
      :headers="aggregateHeaders"
      hide-default-footer
      :items="averages"
      item-key="caseId"
      :loading="$apollo.loading"
      :sort-by="['attorney']"
      :sort-desc="[false]"
      v-if="!showList"
    >
      <template v-slot:item.averageSettlement="props">
        {{ formatValue(props.item.averageSettlement) }}
      </template>
      <template v-slot:item.averageFee="props">
        {{ formatValue(props.item.averageFee) }}
      </template>
      <template v-slot:item.averageFeePercentage="props">
        {{ formatPercentage(props.item.averageFeePercentage) }}
      </template>
      <template v-slot:item.averageTotalMedicalBills="props">
        {{ formatValue(props.item.averageTotalMedicalBills) }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import gql from "graphql-tag";
import { mapGetters } from "vuex";
import accounting from "accounting";
import filter from "lodash/filter";

export default {
  data() {
    return {
      search: "",
      aggregateHeaders: [
        { text: "Attorney", value: "attorney" },
        { text: "Settlements", value: "totalSettlements" },
        { text: "Avg Settlement", value: "averageSettlement" },
        { text: "Avg Fee", value: "averageFee" },
        { text: "Avg Fee %", value: "averageFeePercentage" },
        { text: "Avg Medical", value: "averageTotalMedicalBills" },
        { text: "Avg Medical Multiple", value: "averageMedicalMultiple" }
      ],
      listHeaders: [
        { text: "Action", value: "caseId", width: "100px" },
        { text: "Client", value: "clientName" },
        { text: "Settlement", value: "settlements" },
        { text: "Fee", value: "fees" },
        { text: "Fee %", value: "feePercentage" },
        { text: "Medical", value: "totalMedicalBills" },
        { text: "Medical Multiple", value: "medicalMultiple" },
        { text: "Attorney", value: "attorney" }
      ],
      feeComparisonInPeriod: [],
      feeComparisonAverageInPeriod: [],
      totalSettlements: 0,
      showList: false
    };
  },

  computed: {
    ...mapGetters(["currentUser", "startDate", "endDate"]),
    averages() {
      if (!this.feeComparisonAverageInPeriod) return [];
      return filter(this.feeComparisonAverageInPeriod, f => {
        return !!f.attorney;
      });
    }
  },
  apollo: {
    feeComparisonInPeriod: {
      query: gql`
        query DashFeeComparison($startDate: String!, $endDate: String!) {
          feeComparisonInPeriod(startDate: $startDate, endDate: $endDate) {
            fees
            settlements
            feePercentage
            totalMedicalBills
            medicalMultiple
            caseId
            clientName
            attorney
          }
        }
      `,
      variables() {
        return {
          startDate: this.startDate,
          endDate: this.endDate
        };
      },
      skip() {
        if (!this.currentUser) return true;
        if (!this.showList) return true;
        return false;
      }
    },
    feeComparisonAverageInPeriod: {
      query: gql`
        query DashFeeComparisonAverage($startDate: String!, $endDate: String!) {
          feeComparisonAverageInPeriod(
            startDate: $startDate
            endDate: $endDate
          ) {
            attorney
            averageFee
            averageSettlement
            totalSettlements
            averageFeePercentage
            averageTotalMedicalBills
            averageMedicalMultiple
          }
        }
      `,
      variables() {
        return {
          startDate: this.startDate,
          endDate: this.endDate
        };
      },
      skip() {
        if (!this.currentUser) return true;
        return false;
      }
    }
  },
  methods: {
    formatValue(val) {
      return accounting.formatMoney(val);
    },
    formatPercentage(val) {
      return val.toFixed(2) + "%";
    },
    getFiltered(val) {
      this.totalSettlements = val.length;
    }
  }
};
</script>

<style scoped></style>
