<template>
  <v-card>
    <v-container class="py-0" fluid>
      <v-row align-content="space-around">
        <v-col cols="12" sm="8">
          <v-card-title class="pa-0">
            <div>
              <div class="headline">Demands</div>
              <div class="text-subtitle-1 font-weight-thin">
                {{ user.demands.length }} Demands
              </div>
              <v-switch
                v-if="isParalegal"
                label="Team Demands"
                v-model="showTeamDemands"
              ></v-switch>
            </div>
          </v-card-title>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            hide-details
            single-line
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
    <v-data-table
      :footer-props="{
        'items-per-page-options': [-1]
      }"
      :headers="headers"
      hide-default-footer
      :items="user.demands"
      item-key="caseId"
      :loading="$apollo.loading"
      :search="search"
      :sort-by="['dateOfDemand']"
      :sort-desc="[false]"
    >
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="(item, index) in items" :key="index">
            <td>
              <a
                class="go-to-case-link mr-2"
                target="_blank"
                :href="
                  `https://cs-web.airdesksolutions.com/SA/case_documents.aspx?CaseID=${item.caseId}`
                "
              >
                <v-icon size="15">mdi-open-in-new</v-icon>
              </a>
            </td>
            <td>{{ item.clientName }}</td>
            <td>{{ formatValue(item) }}</td>
            <td>{{ item.rank }}</td>
            <td>{{ item.dateOfDemand }}</td>
            <td>{{ item.paralegal }}</td>
            <td>{{ item.status }}</td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { USER_DEMANDS_IN_PERIOD_QUERY } from "../graphql/user.gql";
import { formatValue, hasRole } from "../util";

export default {
  data() {
    return {
      search: "",
      pagination: { sortBy: "dateOfDemand", descending: true },
      headers: [
        { text: "Actions", value: "", width: "100px" },
        { text: "Client", value: "clientName" },
        { text: "Value", value: "valueFrom" },
        { text: "Rank", value: "rank" },
        { text: "Date", value: "dateOfDemand" },
        { text: "Paralegal", value: "paralegal" },
        { text: "Status", value: "status" }
      ],
      user: { demands: [] },
      statusPieChart: false,
      statusPieSeries: false,
      cmPieChart: false,
      cmPieSeries: false
    };
  },
  computed: {
    ...mapGetters([
      "imitatedUser",
      "selectedTeam",
      "startDate",
      "endDate",
      "showTeamDemands"
    ]),
    totalRows() {
      return this.user.demands.length;
    },
    showTeamDemands: {
      get() {
        return this.$store.getters.showTeamDemands;
      },
      set(value) {
        this.$store.dispatch("setShowTeamDemands", value);
      }
    },
    isParalegal() {
      if (this.user) {
        return hasRole(this.user, "paralegal");
      } else {
        return true;
      }
    }
  },
  methods: {
    formatValue
  },
  apollo: {
    user: {
      query: USER_DEMANDS_IN_PERIOD_QUERY,
      variables() {
        let id = this.imitatedUser
          ? this.imitatedUser.toString()
          : this.$route.params.id;
        let vars = {
          id,
          startDate: this.startDate,
          endDate: this.endDate
        };
        if (this.showTeamDemands) {
          vars.teamId = this.selectedTeam;
        }
        return vars;
      }
    }
  }
};
</script>

<style scoped>
td.status {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  width: 150px;
}
table.table > tbody {
  max-height: 10vh !important;
  font-size: 10px !important;
}
table.table > tbody > td {
  white-space: nowrap !important;
  font-size: 10px !important;
}
.inventory-title {
  font-size: 36px;
}

.inventory-number {
  font-size: 24px;
}
</style>
