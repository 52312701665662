<template>
  <v-card>
    <v-container class="py-0" fluid>
      <v-row align-content="space-around">
        <v-col cols="12" sm="2">
          <v-card-title class="pa-0">
            <div>
              <div class="headline">Client Contact</div>
              <div class="text-subtitle-1 font-weight-thin">
                {{ filteredCases.length }} Cases
              </div>
            </div>
          </v-card-title>
        </v-col>
        <v-col cols="12" sm="2">
          <v-switch
            class="pa-4"
            label="Attorney Only"
            v-model="primaryAttorneyOnly"
          ></v-switch>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="4">
          <v-select
            small-chips
            label="Hide Stages"
            v-model="hideClientContactStages"
            :items="[
              'Intake',
              'Treating',
              'Demand Prep',
              'Negotiations',
              'Litigation',
              'Settled',
              'Closed',
              'Referred Out Stage',
              'Inactive',
            ]"
            multiple
            single-line
          ></v-select>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            hide-details
            single-line
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
    <v-data-table
      :footer-props="{
        'items-per-page-options': [-1],
      }"
      :headers="headers"
      hide-default-footer
      :items="filteredCases"
      :loading="$apollo.loading"
      :search="search"
      :sort-by="['dateOfLastClientContact']"
      :sort-desc="[false]"
    >
      <template v-slot:body="{ items }">
        <tbody>
          <tr
            :style="{
              background: getDateColor(item),
            }"
            v-for="item in items"
            :key="`${item.caseId}-${Math.random().toString()}`"
          >
            <td>
              <a
                class="go-to-case-link mr-2"
                target="_blank"
                :href="
                  `https://cs-web.airdesksolutions.com/SA/CaseSummary.aspx?CaseID=${item.caseId}`
                "
              >
                <v-icon size="15">mdi-open-in-new</v-icon>
              </a>
            </td>
            <td>{{ item.stage }}</td>
            <td>{{ item.clientName }}</td>
            <td>
              {{ formatLastContactDate(item.dateOfLastClientContact) }}
            </td>
            <td>
              {{ formatLastContactDateAttempt(item) }}
            </td>
            <td>{{ item.paralegal }}</td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import gql from "graphql-tag";
import { getDaysSinceToday } from "../util";
import { mapGetters } from "vuex";
import filter from "lodash/filter";

export default {
  data() {
    return {
      search: "",
      pagination: { sortBy: "dateOfLastClientContact", descending: false },
      headers: [
        { text: "Action", value: "", width: "100px" },
        { text: "Stage", value: "stage" },
        { text: "Client", value: "clientName" },
        { text: "Last Contact", value: "dateOfLastClientContact" },
        { text: "Last Attempt", value: "dateOfLastClientContactAttempt" },
        { text: "Paralegal", value: "paralegal" },
      ],
      user: { cases: [] },
      primaryAttorneyOnly: false,
    };
  },
  computed: {
    ...mapGetters(["imitatedUser"]),
    hideClientContactStages: {
      get() {
        return this.$store.getters.hideClientContactStages;
      },
      set(value) {
        this.$store.dispatch("setHideClientContactStages", value);
      },
    },
    filteredCases() {
      let stages = this.$store.getters.hideClientContactStages;
      let cases = this.user.cases;
      if (this.primaryAttorneyOnly) {
        cases = this.user.casesByAttorney;
      }
      return filter(cases, (c) => {
        return stages.indexOf(c.stage) === -1;
      });
    },
  },
  apollo: {
    user: {
      query: gql`
        query($id: ID!) {
          user(id: $id) {
            id
            first_name
            last_name
            watchedCases {
              caseId
            }
            cases: casesWithLastClientContact {
              caseId
              stage
              clientName
              paralegal
              dateOfLastClientContact
              dateOfLastClientContactAttempt
            }
            casesByAttorney: casesWithLastClientContactByPrimaryAttorney {
              caseId
              stage
              clientName
              paralegal
              dateOfLastClientContact
              dateOfLastClientContactAttempt
            }
          }
        }
      `,
      variables() {
        let id = this.imitatedUser
          ? this.imitatedUser.toString()
          : this.$route.params.id;
        return {
          id,
        };
      },
    },
  },
  methods: {
    trimStatus(status) {
      if (status) return status.substr(0, 3);
      return "";
    },
    formatLastContactDate(date) {
      if (!date) return "";
      let days = getDaysSinceToday(date);
      return `${date} (${days} days)`;
    },
    formatLastContactDateAttempt(item) {
      if (!item.dateOfLastClientContactAttempt) return "";
      let attemptDays = parseInt(
        getDaysSinceToday(item.dateOfLastClientContactAttempt)
      );
      let contactDays = parseInt(
        getDaysSinceToday(item.dateOfLastClientContact)
      );
      if (contactDays > attemptDays) {
        return this.formatLastContactDate(item.dateOfLastClientContactAttempt);
      } else {
        return "";
      }
    },
    getDateColor(item) {
      if (!item.dateOfLastClientContact && !item.dateOfLastClientContactAttempt)
        return "#EF9A9A";
      let contactDays = item.dateOfLastClientContact
        ? getDaysSinceToday(item.dateOfLastClientContact)
        : "";
      let attemptDays = item.dateOfLastClientContactAttempt
        ? getDaysSinceToday(item.dateOfLastClientContactAttempt)
        : "";
      if (
        parseInt(contactDays) <= (this.primaryAttorneyOnly ? 90 : 30) ||
        parseInt(attemptDays) <= 5
      )
        return "#ffffff";
      else if (
        parseInt(contactDays) > (this.primaryAttorneyOnly ? 90 : 30) &&
        parseInt(contactDays) <= (this.primaryAttorneyOnly ? 105 : 45)
      )
        return "#FFF59D";
      else return "#EF9A9A";
    },
  },
};
</script>

<style scoped></style>
