<template>
  <main-layout :items="items">
    <router-view />
  </main-layout>
</template>

<script>
import MainLayout from "./MainLayout";
import { hasRole } from "../util";

export default {
  computed: {
    items() {
      let currentUser = this.$store.state.user.currentUser;
      if (!currentUser) return [];
      let items = [];
      if (currentUser && hasRole(currentUser, "admin")) {
        items = items.concat([
          {
            icon: "mdi-office-building",
            text: "Organization",
            path: `/org/${currentUser.organization.id}`
          }
        ]);
      }
      if (currentUser && hasRole(currentUser, "medical-requester")) {
        items = items.concat([
          // { icon: 'home', text: 'Home', path: `/dash/${currentUser.id}` },
          {
            icon: "mdi-file-document",
            text: "Medical Requests",
            path: `/mr`
          }
        ]);
      } else {
        items = items.concat([
          // { icon: 'home', text: 'Home', path: `/dash/${currentUser.id}` },
          {
            icon: "mdi-format-list-bulleted-square",
            text: "Inventory",
            path: `/dash/${currentUser.id}/inventory`
          }
        ]);
      }
      // if (currentUser && (hasRole(currentUser, 'attorney') || hasRole(currentUser, 'admin'))) {
      //   items = items.concat([
      //   ])
      // }
      return items;
    }
  },
  components: {
    MainLayout
  }
};
</script>

<style scoped></style>
