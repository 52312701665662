<template>
  <v-col sm="6" md="3">
    <v-card>
      <v-progress-linear
        color="primary"
        indeterminate
        v-if="$apollo.loading"
      ></v-progress-linear>
      <v-toolbar flat dense>
        <span class="text-subtitle-1"
          ><router-link
            class="unstyled-link"
            :to="`/dash/${currentUser.id}/intake/new-cases`"
            >New Cases in Period</router-link
          ></span
        >
      </v-toolbar>
      <div class="stat-data">
        {{ retainedCasesInPeriod }}
        <span
          class="stat-data-desc"
          v-for="(item, index) in retainedCasesInPeriodByOffice"
          :key="index"
        >
          {{ formatOffice(item.office) }} ({{ item.count }})
        </span>
      </div>
    </v-card>
  </v-col>
</template>

<script>
import gql from "graphql-tag";
import { mapGetters } from "vuex";
import { formatOffice } from "../util";

export default {
  apollo: {
    organization: {
      query: gql`
        query OrgHomeStatsNewCases(
          $id: ID!
          $startDate: String!
          $endDate: String!
        ) {
          organization(id: $id) {
            id
            retainedCasesInPeriod(startDate: $startDate, endDate: $endDate)
            retainedCasesInPeriodByOffice(
              startDate: $startDate
              endDate: $endDate
            ) {
              office
              count
            }
          }
        }
      `,
      variables() {
        return {
          id: this.$route.params.id,
          startDate: this.startDate,
          endDate: this.endDate
        };
      },
      skip() {
        return this.startDate === null || this.endDate === null;
      }
    }
  },
  computed: {
    ...mapGetters(["currentUser", "startDate", "endDate"]),
    retainedCasesInPeriod() {
      if (!this.organization) return 0;
      return this.organization.retainedCasesInPeriod;
    },
    retainedCasesInPeriodByOffice() {
      if (!this.organization) return [];
      return this.organization.retainedCasesInPeriodByOffice;
    }
  },
  methods: {
    formatOffice(office) {
      return formatOffice(office);
    }
  }
};
</script>

<style scoped lang="scss">
.unstyled-link {
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
</style>
