<template>
  <v-card>
    <v-container class="py-0" fluid>
      <v-row align-content="space-around">
        <v-col cols="12" sm="7">
          <v-card-title class="pa-0">
            <div>
              <div class="headline">Unique Reviews</div>
              <div class="text-subtitle-1 font-weight-thin">
                {{ tableResults.length }} Reviews
              </div>
            </div>
          </v-card-title>
        </v-col>
        <v-spacer></v-spacer>
        <v-switch
          class="pt-5 mr-5"
          label="Exclude Intake"
          v-model="excludeIntake"
        ></v-switch>
        <v-col cols="12" sm="3">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            hide-details
            single-line
            @keydown="searchName"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="2" v-if="isAdmin">
          <v-btn class="primary" @click="openNewDialog"
            >Non-Client Review</v-btn
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <org-reviews-in-period :options="reviewsByTeam" />
        </v-col>
      </v-row>
    </v-container>
    <v-data-table
      :footer-props="{
        'items-per-page-options': [100, -1],
      }"
      :headers="headers"
      :items="tableResults"
      :loading="$apollo.loading"
      :sort-by="['date']"
      :sort-desc="[true]"
    >
      <template v-slot:item.caseId="props">
        <a
          class="go-to-case-link mr-2"
          target="_blank"
          :href="
            `https://cs-web.airdesksolutions.com/SA/CaseSummary.aspx?CaseID=${props.item.caseId}`
          "
          v-if="props.item.caseId"
        >
          <v-icon size="15">mdi-open-in-new</v-icon>
        </a>
        <a v-if="!props.item.caseId && isAdmin">
          <v-icon small class="mr-2" @click="openEditDialog(props.item)"
            >mdi-pencil</v-icon
          >
        </a>
      </template>
      <template v-slot:item.review="props">
        <v-edit-dialog
          v-if="props.item.caseId"
          @open="openReview(props.item.caseId, props.item.statusId)"
          @close="closeReview"
        >
          {{ props.item.review }}
          {{ props.item.statusType === 75 ? "- Intake" : "" }}
          <template v-slot:input>
            <v-select
              :items="reviewOptions"
              :value="reviewValue(props.item.review)"
              @change="saveReview"
              dense
            ></v-select>
          </template>
        </v-edit-dialog>
        <span v-if="!props.item.caseId">{{ props.item.review }}</span>
      </template>
    </v-data-table>
    <v-dialog
      v-model="reviewDialog"
      max-width="600px"
      click:outside="closeDialog"
    >
      <v-card>
        <v-toolbar flat>
          <span class="headline" v-if="reviewAction === 'new'">New Review</span>
          <span class="headline" v-if="reviewAction === 'edit'"
            >Edit Review</span
          >
        </v-toolbar>
        <v-container>
          <v-form>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="newReview.firstName"
                  autofocus
                  label="First Name"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="newReview.lastName"
                  label="Last Name"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-autocomplete
                  v-model="newReview.rating"
                  :items="newReviewOptions"
                  label="Rating"
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6">
                <v-menu>
                  <template v-slot:activator="{ on, attr }">
                    <v-text-field
                      class="mt-0 pt-0"
                      label="Review Date"
                      :value="newReview.date"
                      readonly
                      v-bind="attr"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker v-model="newReview.date" no-title scrollable />
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-autocomplete
                  v-model="newReview.teamId"
                  :items="teamOptions"
                  label="Team"
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6">
                <v-autocomplete
                  v-model="newReview.userId"
                  :items="userOptions"
                  label="Case Manager"
                  dense
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-btn
                  @click="saveNewReview"
                  class="primary"
                  :loading="$apollo.loading"
                  v-if="reviewAction == 'new'"
                  >Save Review</v-btn
                >
                <v-btn
                  @click="updateReview"
                  class="primary"
                  :loading="$apollo.loading"
                  v-if="reviewAction == 'edit'"
                  >Edit Review</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import gql from "graphql-tag";
import sortBy from "lodash/sortBy";
import debounce from "lodash/debounce";
import get from "lodash/get";
import groupBy from "lodash/groupBy";
import format from "date-fns/format";
import filter from "lodash/filter";
import { hasRole } from "../util";
import { mapGetters } from "vuex";
// import format from "date-fns/format";
import { ORG_REVIEWS } from "../graphql/OrgReviews.gql";
import OrgReviewsInPeriod from "../charts/OrgReviewsInPeriod";

export default {
  data() {
    return {
      // headers: [
      //   { text: "User/Team", value: "", width: "120px" },
      //   { text: "Source", value: "sourceType", width: "70px" },
      //   { text: "Reviewer", value: "nickName", width: "150px" },
      //   { text: "Rating", value: "rating", width: "90px" },
      //   { text: "Date", value: "reviewDate", width: "100px" },
      //   { text: "Review", value: "comments" }
      // ],
      headers: [
        { text: "SA Link", value: "caseId", width: "120px" },
        { text: "Case Number", value: "caseNumber" },
        { text: "Client", value: "clientName" },
        { text: "Creator", value: "creator" },
        { text: "Review", value: "review" },
        { text: "Date", value: "date", width: "100px" },
        { text: "Attorney", value: "attorney" },
        { text: "Paralegal", value: "paralegal" },
      ],
      reviewOptions: [
        { text: "5 Star", value: 1150 },
        { text: "4 Star", value: 1151 },
        { text: "3 Star", value: 1152 },
        { text: "2 Star", value: 1153 },
        { text: "1 Star", value: 1154 },
        // { text: "Intake - 5 Star", value: 1166 },
        // { text: "Intake - 4 Star", value: 1167 },
        // { text: "Intake - 3 Star", value: 1168 },
        // { text: "Intake - 2 Star", value: 1169 },
        // { text: "Intake - 1 Star", value: 1170 },
        { text: "Delete Status", value: "delete" },
      ],
      newReviewOptions: [
        { text: "5 Star", value: 1150 },
        { text: "4 Star", value: 1151 },
        { text: "3 Star", value: 1152 },
        { text: "2 Star", value: 1153 },
        { text: "1 Star", value: 1154 },
      ],
      userOptions: [],
      // teamOptions: [],
      organization: {
        reviewsInPeriod: [],
      },
      name: "",
      search: "",
      commentDialog: false,
      attrDialog: false,
      reviewId: null,
      userId: null,
      teamId: null,
      selectedReview: null,
      reviewDialog: false,
      reviewAction: "new",
      defaultReview: {
        id: null,
        firstName: "",
        lastName: "",
        rating: null,
        date: format(new Date(), "yyyy-MM-dd"),
        teamId: null,
        userId: null,
      },
      newReview: {
        firstName: "",
        lastName: "",
        rating: null,
        date: format(new Date(), "yyyy-MM-dd"),
        teamId: null,
        userId: null,
      },
      editReview: {
        id: null,
        firstName: "",
        lastName: "",
        rating: null,
        date: format(new Date(), "yyyy-MM-dd"),
        teamId: null,
        userId: null,
      },
      excludeIntake: true,
      // nonClientReviews: []
    };
  },
  components: {
    OrgReviewsInPeriod,
  },
  computed: {
    ...mapGetters(["startDate", "endDate", "currentUser"]),
    tableResults() {
      if (!this.reviews) return [];
      if (this.name) return this.cases;
      let reviews = this.reviews.concat(this.nonClientReviews);
      if (this.excludeIntake) {
        reviews = filter(reviews, (review) => {
          return review.statusType !== 75;
        });
      }
      return reviews;
    },
    reviewsByTeam() {
      if (!this.reviews) return {};
      if (this.name) return this.cases;
      let reviews = this.reviews.concat(this.nonClientReviews);
      if (this.excludeIntake) {
        reviews = filter(reviews, (review) => {
          return review.statusType !== 75;
        });
      }
      let filteredResults = filter(
        reviews,
        (t) => t.review === "4 Star" || t.review === "5 Star"
      );
      let groupedByTeam = groupBy(filteredResults, (t) => t.attorney);
      let teams = [...this.teamOptions];
      teams.shift();
      let series = teams.map((t) => {
        return {
          name: t.text,
          y: groupedByTeam[t.text] ? groupedByTeam[t.text].length : 0,
        };
      });
      return {
        xAxis: {
          type: "category",
        },
        series: [
          {
            name: "Unique Reviews in Period",
            data: series,
          },
        ],
      };
    },
    teamOptions() {
      if (!this.organization || !this.organization.teams) return [];
      let options = sortBy(
        filter(this.organization.teams, (t) => t.active).map((t) => {
          return { text: t.name, value: t.id };
        }),
        [(item) => item.text]
      );
      options.unshift({ text: "", value: null });
      return options;
    },
    isAdmin() {
      if (!this.currentUser) return false;
      return hasRole(this.currentUser, "admin");
    },
    nonClientReviews() {
      if (!this.organization || !this.organization.reviewsInPeriod) return [];
      return this.organization.reviewsInPeriod.map((r) => {
        let year = parseInt(r.reviewDate.substr(0, 4), 10);
        let month = parseInt(r.reviewDate.substr(5, 2), 10) - 1;
        let day = parseInt(r.reviewDate.substr(8, 2), 10);
        return {
          id: r.id,
          caseNumber: "Non-Client",
          rating: parseInt(r.rating, 10),
          review: this.reviewText(parseInt(r.rating)),
          firstName: r.firstName,
          lastName: r.lastName,
          clientName: `${r.firstName} ${r.lastName}`,
          date: format(new Date(year, month, day), "yyyy-MM-dd"),
          teamId: r.team.id,
          attorney: r.team.name,
          userId: r.user.id,
          paralegal: r.user.staffCode,
        };
      });
    },
  },
  watch: {
    "organization.users"(val) {
      this.userOptions = sortBy(
        val.map((u) => {
          return { text: `${u.firstName} ${u.lastName}`, value: u.id };
        }),
        [(item) => item.text]
      );
      this.userOptions.unshift({ text: "", value: null });
    },
  },
  apollo: {
    reviews: {
      query: gql`
        query OrgSAReviews(
          $startDate: String!
          $endDate: String!
          $includeIntakeReviews: Boolean!
        ) {
          reviews: casesWithReviewsInPeriod(
            startDate: $startDate
            endDate: $endDate
            includeIntakeReviews: $includeIntakeReviews
          ) {
            caseId
            caseNumber
            statusId
            statusType
            clientName
            review
            date
            creator
            modifier
            attorney
            paralegal
          }
        }
      `,
      variables() {
        return {
          startDate: this.startDate,
          endDate: this.endDate,
          includeIntakeReviews: true,
        };
      },
    },
    cases: {
      query: gql`
        query OrgSAReviewsCases($name: String!) {
          cases: casesByClientName(name: $name) {
            caseId
            caseNumber
            statusId
            clientName
            review
            date
            attorney
            paralegal
          }
        }
      `,
      variables() {
        return {
          name: this.name,
        };
      },
      skip() {
        if (!this.name) return true;
      },
    },
    organization: {
      query: ORG_REVIEWS,
      variables() {
        return {
          id: 2,
          startDate: this.startDate,
          endDate: this.endDate,
        };
      },
      // pollInterval: 1000 * 60 * 5
    },
  },
  methods: {
    closeDialog() {
      this.reviewDialog = false;
      this.reviewAction = "new";
      this.newReview = this.defaultReview;
    },
    openEditDialog(item) {
      this.reviewAction = "edit";
      this.newReview = Object.assign({}, item);
      this.reviewDialog = true;
    },
    openNewDialog() {
      this.reviewAction = "new";
      this.newReview = this.defaultReview;
      this.reviewDialog = true;
    },
    deleteItem() {
      confirm("Are you sure you want to delete this user?");
    },
    displayTeamAndUser(item) {
      let team = item.team ? get(item, "team.name") : "";
      let user = item.user ? get(item, "user.firstName") : "";
      return `${user} ${team ? `(${team})` : ""}`;
    },
    truncate(comment) {
      if (!comment) return "";
      return comment.slice(0, 150);
    },
    searchName: debounce(function(e) {
      let search = e.target.value;
      if (search.split(",").length < 2) {
        this.name = "";
        return false;
      }
      this.name = search;
    }, 500),
    updateReview() {
      let reviewToBeUpdated = this.newReview;

      // remove from reviewToBeUpdated object the fields that are not needed including attorney, caseNumber, clientName, review and paralegal
      reviewToBeUpdated = Object.keys(reviewToBeUpdated).reduce(
        (object, key) => {
          if (
            key !== "attorney" &&
            key !== "caseNumber" &&
            key !== "clientName" &&
            key !== "paralegal" &&
            key !== "review"
          ) {
            object[key] = reviewToBeUpdated[key];
          }
          return object;
        },
        {}
      );

      this.$apollo
        .mutate({
          mutation: gql`
            mutation UpdateReview($review: ReviewInput) {
              updateReview(review: $review) {
                id
              }
            }
          `,
          variables: {
            review: reviewToBeUpdated,
          },
        })
        .then(() => {
          this.reviewDialog = false;
          this.newReview = this.defaultReview;
          this.$apollo.queries.organization.refetch();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    saveNewReview() {
      let review = { ...this.newReview };
      // Check to see if review has all fields filled out
      if (
        !review.firstName ||
        !review.lastName ||
        !review.rating ||
        !review.date ||
        !review.teamId ||
        !review.userId
      ) {
        alert("Please fill out all fields");
        return false;
      } else {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation CreateReview($review: ReviewInput) {
                createReview(review: $review) {
                  id
                  user {
                    id
                    firstName
                    lastName
                  }
                  team {
                    id
                    name
                  }
                }
              }
            `,
            variables: {
              review: this.newReview,
            },
          })
          .then(() => {
            // this.reviewDialog = false;
            this.newReview = this.defaultReview;
            this.$apollo.queries.organization.refetch();
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
    saveReview(statusId) {
      console.log(statusId);
      if (statusId === "delete") {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation DeleteCaseReviewStatus($status: CaseStatus!) {
                deleteSaReviewStatus(status: $status) {
                  statusId
                }
              }
            `,
            variables: {
              status: {
                case_id: this.selectedCaseId,
                status_id: this.selectedStatusId,
              },
            },
            update: () => {
              this.$apollo.queries.reviews.refetch();
              this.$apollo.queries.cases.refetch();
            },
          })
          .then(() => {})
          .catch((error) => {
            console.error(error);
          });
      } else {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation InsertCaseReviewStatus($status: CaseStatus!) {
                insertSaReviewStatus(status: $status) {
                  statusId
                }
              }
            `,
            variables: {
              status: {
                case_id: this.selectedCaseId,
                status_id: statusId,
              },
            },
            update: () => {
              this.$apollo.queries.reviews.refetch();
              this.$apollo.queries.cases.refetch();
            },
          })
          .then(() => {})
          .catch((error) => {
            console.error(error);
          });
      }
      return false;
    },
    reviewValue(review) {
      let reviews = groupBy(this.reviewOptions, "text");
      if (!reviews[review]) return null;
      return reviews[review][0].value;
    },
    reviewText(review) {
      let reviews = groupBy(this.reviewOptions, "value");
      return reviews[review][0].text;
    },
    openReview(caseId, statusId) {
      this.selectedCaseId = caseId;
      this.selectedStatusId = statusId;
      return false;
    },
    closeReview() {
      this.selectedCaseId = "";
      this.selectedStatusId = "";
      return false;
    },
  },
};
</script>

<style scoped>
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
