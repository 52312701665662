<template>
  <v-card>
    <v-container class="py-0" fluid>
      <v-row align-content="space-around">
        <v-col cols="12" sm="6">
          <v-card-title class="pa-0">
            <div>
              <div class="headline">{{ stage }}</div>
              <div class="text-subtitle-1 font-weight-thin">
                {{ cases.length }} Cases
              </div>
            </div>
          </v-card-title>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="2">
          <v-switch
            v-if="stage === 'Negotiation'"
            v-model="useNegotiationNotes"
            label="Use SA Notes"
          ></v-switch>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            hide-details
            single-line
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="swatchDialog" max-width="300px">
      <v-card>
        <v-card-title>
          Highlight Row
        </v-card-title>
        <v-card-text>
          <swatches colors="text-advanced" inline @input="saveColor($event)" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-data-table
      :footer-props="{
        'items-per-page-options': [-1],
      }"
      :headers="headers"
      hide-default-footer
      :items="cases"
      item-key="caseId"
      :loading="$apollo.loading"
      :search="search"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      @update:sort-by="updateSort('by', $event)"
      @update:sort-desc="updateSort('order', $event)"
    >
      <template v-slot:body="{ items }">
        <tbody v-if="stage !== 'Negotiation' || !useNegotiationNotes">
          <tr
            :style="{ background: item.data[`${stageCc}Color`] }"
            v-for="item in items"
            :key="item.caseId"
          >
            <td>
              <a
                class="go-to-case-link mr-2"
                target="_blank"
                :href="
                  `https://cs-web.airdesksolutions.com/SA/CaseSummary.aspx?CaseID=${item.caseId}`
                "
              >
                <v-icon size="15">mdi-open-in-new</v-icon>
              </a>
              <v-icon
                small
                @click="
                  swatchDialog = true;
                  selectedRow = item;
                "
                >mdi-palette</v-icon
              >
            </td>
            <td>{{ formatName(item.clientName) }}</td>
            <td>{{ item.paralegal }}</td>
            <td>{{ shortenStatus(item.status) }}</td>
            <td>{{ item.daysInStatus }}</td>
            <td>{{ item.rank }}</td>
            <td>
              {{ formatNoteDate(item.data[`${stageCc}NoteUpdatedAt`]) }}
            </td>
            <td>
              <v-edit-dialog lazy save="saveTest">
                {{ item.data[`${stageCc}Note`] }}
                <template v-slot:input>
                  <v-text-field
                    style="width: 500px"
                    @keyup.enter="saveNote(item, $event)"
                    label="Edit"
                    single-line
                    :value="item.data[`${stageCc}Note`]"
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </td>
          </tr>
        </tbody>
        <tbody v-if="stage === 'Negotiation' && useNegotiationNotes">
          <tr
            :style="{ background: item.data[`${stageCc}Color`] }"
            v-for="item in items"
            :key="item.caseId"
          >
            <td>
              <a
                class="go-to-case-link mr-2"
                target="_blank"
                :href="
                  `https://cs-web.airdesksolutions.com/SA/CaseSummary.aspx?CaseID=${item.caseId}`
                "
              >
                <v-icon size="15">mdi-open-in-new</v-icon>
              </a>
              <v-icon
                small
                @click="
                  swatchDialog = true;
                  selectedRow = item;
                "
                >mdi-palette</v-icon
              >
            </td>
            <td>{{ formatName(item.clientName) }}</td>
            <td>{{ item.paralegal }}</td>
            <td>{{ shortenStatus(item.status) }}</td>
            <td>{{ item.daysInStatus }}</td>
            <td>{{ item.rank }}</td>
            <td>{{ item.lastNegotiationDemandAmount }}</td>
            <td>{{ item.lastNegotiationOfferAmount }}</td>
            <td>
              {{ item.lastNegotiationDateFormatted }}
            </td>
            <td>
              <span v-html="item.lastNegotiationComments"></span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { USER_CASES_BY_STATUS_QUERY } from "../graphql/user.gql";
import gql from "graphql-tag";
import findIndex from "lodash/findIndex";
import camelCase from "lodash/camelCase";
import format from "date-fns/format";
import Swatches from "vue-swatches";
import { mapGetters } from "vuex";
import { formatValue } from "../util";
import parse from "date-fns/parse";

export default {
  data() {
    let savedUseNegNotes = JSON.parse(
      localStorage.getItem("RyBase.useNegotiationNotes")
    );
    let useNegotiationNotes = savedUseNegNotes ? savedUseNegNotes : false;
    return {
      useNegotiationNotes,
      search: "",
      selectedRow: null,
      swatchDialog: false,
      user: { cases: [] },
    };
  },
  props: ["stage"],
  components: {
    Swatches,
  },
  apollo: {
    user: {
      query: USER_CASES_BY_STATUS_QUERY,
      variables() {
        let id = this.imitatedUser
          ? this.imitatedUser.toString()
          : this.$route.params.id;
        return {
          id,
          status: this.stage,
        };
      },
    },
  },
  watch: {
    useNegotiationNotes(val) {
      localStorage.setItem("RyBase.useNegotiationNotes", val);
    },
  },
  computed: {
    ...mapGetters(["imitatedUser"]),
    stageCc() {
      if (this.stage === "Negotiation") {
        return "neg";
      } else {
        return camelCase(this.stage);
      }
    },
    sortBy() {
      let saved = JSON.parse(
        localStorage.getItem(`RyBase.cnl${this.stage}SortBy`)
      );
      if (!saved) return ["clientName"];
      return saved;
    },
    sortDesc() {
      let saved = JSON.parse(
        localStorage.getItem(`RyBase.cnl${this.stage}SortDesc`)
      );
      if (!saved) return [false];
      return saved;
    },
    cases() {
      if (!this.user) return [];
      return this.user.cases.map((c) => {
        if (c.lastNegotiationDate) {
          let day = c.lastNegotiationDate.split("-")[2];
          let month = c.lastNegotiationDate.split("-")[1];
          let year = c.lastNegotiationDate.split("-")[0];
          return Object.assign({}, c, {
            lastNegotiationDate: Date.UTC(year, month - 1, day),
            lastNegotiationDateFormatted: format(
              parse(c.lastNegotiationDate, "yyyy-MM-dd", new Date()),
              "yyyy/MM/dd"
            ),
          });
        } else {
          return c;
        }
      });
    },
    headers() {
      let headers = [
        { text: "Action", value: `data[${this.stageCc}Color]`, width: "100px" },
        { text: "Client", value: "clientName", width: "150px" },
        { text: "CM", value: "paralegal", width: "60px" },
        { text: "Status", value: "status", width: "80px" },
        { text: "Days", value: "daysInStatus", width: "65px" },
        { text: "Rank", value: "rank", width: "130px" },
      ];
      if (this.stage === "Negotiation" && this.useNegotiationNotes) {
        headers = headers.concat([
          {
            text: "Demand",
            value: "lastNegotiationDemandAmount",
            width: "70px",
          },
          {
            text: "Ins. Offer",
            value: "lastNegotiationOfferAmount",
            width: "90px",
          },
          { text: "Date", value: "lastNegotiationDate", width: "65px" },
          { text: "Note", value: "lastNegotiationComments" },
        ]);
      } else {
        headers = headers.concat([
          {
            text: "Date",
            value: `data[${this.stageCc}NoteUpdatedAt]`,
            width: "65px",
          },
          { text: "Note", value: `data[${this.stageCc}Note]` },
        ]);
      }
      return headers;
    },
  },
  methods: {
    updateSort(type, event) {
      if (type === "by") {
        localStorage.setItem(
          `RyBase.cnl${this.stage}SortBy`,
          JSON.stringify(event)
        );
      } else if (type === "order") {
        localStorage.setItem(
          `RyBase.cnl${this.stage}SortDesc`,
          JSON.stringify(event)
        );
      }
    },
    comments(item) {
      return `${item.lastNegotiationInsuranceCo} Offer: ${
        item.lastNegotiationOffer ? item.lastNegotiationOffer : ""
      }, Demand: ${
        item.lastNegotiationDemand ? item.lastNegotiationDemand : ""
      }<p>${item.lastNegotiationComments}</p>`;
    },
    updateUseNegotiationNotes(value) {
      console.log(value);
      this.useNegotiationNotes = !this.useNegotiationNotes;
      localStorage.setItem(
        "RyBase.useNegotiationNotes",
        JSON.stringify(!this.useNegotiationNotes)
      );
    },
    shortenStatus(status) {
      if (status.substr(0, 3) === "LIT") return status;
      let firstLetters = status
        .split(" - ")[1]
        .split(" ")
        .map((word) => {
          return word.charAt(0);
        })
        .join("");
      return `${status.substr(0, 5)} ${firstLetters}`;
    },
    formatValue,
    formatName(name) {
      if (!name) return "";
      if (name.length > 20) return `${name.slice(0, 20)}...`;
      else return name;
    },
    saveNote(item, e) {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation UpsertCaseData($caseData: CaseDataInput!) {
              upsertCaseData(caseData: $caseData) {
                id
                caseId
                ${this.stageCc}Note
                ${this.stageCc}NoteUpdatedAt
                updatedAt
              }
            }
          `,
          variables: {
            caseData: {
              case_id: item.caseId,
              [`${this.stageCc}Note`]: e.target.value,
              user_id: parseInt(
                this.imitatedUser ? this.imitatedUser : this.$route.params.id
              ),
            },
          },
          update: (store, { data: { upsertCaseData } }) => {
            this.updateCache(store, upsertCaseData);
          },
        })
        .then(() => {})
        .catch((error) => {
          console.error(error);
        });
    },
    saveTest(val) {
      console.log(val);
    },
    saveColor(color) {
      this.$apollo
        .mutate({
          mutation: gql`
            mutation UpsertCaseData($caseData: CaseDataInput!) {
              upsertCaseData(caseData: $caseData) {
                id
                caseId
                ${this.stageCc}Note
                ${this.stageCc}Color
                ${this.stageCc}NoteUpdatedAt
                updatedAt
              }
            }
          `,
          variables: {
            caseData: {
              case_id: this.selectedRow.caseId,
              [`${this.stageCc}Color`]: color,
              user_id: parseInt(
                this.imitatedUser ? this.imitatedUser : this.$route.params.id
              ),
            },
          },
          update: (store, { data: { upsertCaseData } }) => {
            this.updateCache(store, upsertCaseData);
          },
        })
        .then(() => {})
        .catch((error) => {
          console.error(error);
        });
    },
    updateCache(store, upsertCaseData) {
      let id = this.imitatedUser
        ? this.imitatedUser.toString()
        : this.$route.params.id;
      const data = store.readQuery({
        query: USER_CASES_BY_STATUS_QUERY,
        variables: {
          id,
          status: this.stage,
        },
      });
      let index = findIndex(data.user.cases, (c) => {
        return c.caseId === upsertCaseData.caseId;
      });
      let cd = data.user.cases[index].data;
      data.user.cases[index].data = Object.assign({}, cd, upsertCaseData);
      store.writeQuery({
        query: USER_CASES_BY_STATUS_QUERY,
        variables: {
          id,
          status: this.stage,
        },
        data,
      });
    },
    formatNoteDate(date) {
      if (!date) return "";
      let d = new Date(`${date} UTC`);
      return format(d, "MM/dd");
    },
  },
};
</script>

<style scoped></style>
