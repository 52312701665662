import get from "lodash/get";
import store from "../store";
import { hasRole } from "./index";

function getUserState() {
  return new Promise(resolve => {
    if (get(store, "state.user.currentUser") === null) {
      const unwatch = store.watch(
        () => get(store, "state.user.currentUser"),
        value => {
          unwatch();
          resolve(value);
        }
      );
    } else {
      resolve(get(store, "state.user.currentUser"));
    }
  });
}

export async function checkIfSameOrgAndAdmin(to, from, next) {
  let user = await getUserState();
  let orgId = parseInt(get(to, "params.id"), 10);
  if (!user) {
    next({ path: "/" });
  } else {
    if (hasRole(user, "overlord")) {
      next();
    } else if (user.organization.id === orgId && hasRole(user, "admin")) {
      next();
    } else if (to.meta.auth && to.meta.auth.some(role => hasRole(user, role))) {
      // Without checking for name == intake-index this would be a forever loop
      if (to.name === "intake-index") {
        next();
      } else {
        next({ path: `/org/${user.organization.id}/intake` });
      }
    } else {
      next({ path: from.path });
    }
  }
}
export function routeToDashboard(user, router) {
  if (user && hasRole(user, "admin")) {
    router.push(`/org/${user.organization.id}`);
  } else if (user && hasRole(user, "intake-management")) {
    router.push(`/org/${user.organization.id}/intake`);
  } else if (user && hasRole(user, "intake")) {
    router.push({
      path: `/dash/${user.id}/intake`
    });
  } else if (user && hasRole(user, "medical-requester")) {
    router.push({
      path: `/mr`
    });
  } else {
    router.push({
      path: `/dash/${user.id}`
    });
  }
}
