<template>
  <dash-menu :links="links">
    <router-view></router-view>
  </dash-menu>
</template>

<script>
import DashMenu from "./DashMenu";

export default {
  components: {
    DashMenu
  },
  computed: {
    links() {
      let id = this.$route.params.id;
      return [
        {
          name: "Negotiation",
          path: `/dash/${id}/negotiation`
        },
        {
          name: "File Review",
          path: `/dash/${id}/negotiation/file-review`
        },
        {
          name: "Settled",
          path: `/dash/${id}/negotiation/settled`
        }
      ];
    }
  }
};
</script>

<style scoped></style>
