<template>
  <v-app>
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="6">
          <v-card class="elevation-12">
            <v-toolbar dark color="primary">
              <v-toolbar-title>RyBase Login</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-form @submit.prevent="onSubmit">
                <v-text-field
                  prepend-icon="mdi-account"
                  name="email"
                  label="Email"
                  type="text"
                  v-model="email"
                ></v-text-field>
                <v-text-field
                  prepend-icon="mdi-lock"
                  name="password"
                  label="Password"
                  type="password"
                  v-model="password"
                ></v-text-field>
                <!-- Hidden submit button to ensure form submits on Enter key press -->
                <button type="submit" style="display: none;"></button>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn text color="blue" to="/forgot-password"
                >Forgot Password</v-btn
              >
              <v-spacer></v-spacer>
              <v-btn
                type="submit"
                color="primary"
                id="ry-login-btn"
                @click="onSubmit"
                >Login</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { LOGIN, USER_BY_TOKEN } from "../graphql/user.gql";
import { routeToDashboard } from "../util";

export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
    };
  },
  computed: {
    loggingIn() {
      return this.$store.state.user.loginStatus === "loggingIn";
    },
    loggedIn() {
      return this.$store.state.user.loginStatus === "loggedIn";
    },
  },
  mounted: function() {
    if (this.loggedIn) {
      let user = this.$store.state.user.currentUser;
      routeToDashboard(user, this.$router);
    }
  },
  methods: {
    onSubmit(e) {
      if (e) e.preventDefault();

      this.$store.commit("setLoginStatus", "loggingIn");
      this.$apollo
        .mutate({
          mutation: LOGIN,
          variables: {
            email: this.email,
            password: this.password,
          },
        })
        .then(
          ({
            data: {
              login: { token },
            },
          }) => {
            localStorage.setItem("RyBase.token", token);
            this.$apollo
              .query({
                query: USER_BY_TOKEN,
                variables: {
                  token,
                },
              })
              .then(({ data: { userByToken } }) => {
                this.$store.commit("setCurrentUser", userByToken);
                this.$store.commit("setLoginStatus", "loggedIn");
                routeToDashboard(userByToken, this.$router);
              })
              .catch((error) => {
                console.error(error);
                this.$store.commit("setLoginStatus", "error");
              });
          }
        )
        .catch((error) => {
          console.error(error);
          this.$store.commit("setLoginStatus", "error");
        });
    },
  },
};
</script>
