<template>
  <v-card>
    <v-container class="py-0" fluid>
      <v-row align-content="space-around">
        <v-col cols="12" sm="3">
          <v-card-title class="pa-0">
            <div>
              <div class="headline">Inventory Statistics</div>
            </div>
          </v-card-title>
        </v-col>
      </v-row>
      <v-row align-content="space-around">
        <v-col cols="12" sm="12">
          <v-card>
            <v-toolbar flat dense>
              <span class="text-subtitle-1">Retained and Settled in Period</span>
            </v-toolbar>
            <div class="chart-vis">
              <org-home-inventory-retained-vs-settled
                :options="retainedVsSettledCasesByMonth"
              />
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import gql from "graphql-tag";
import { mapGetters } from "vuex";
import { buildRetainedVsSettledCasesByMonth } from "../util";
import OrgHomeInventoryRetainedVsSettled from "../charts/OrgHomeInventoryRetainedVsSettled";

export default {
  data() {
    return {
      search: "",
      headers: [
        { text: "Action", value: "caseId", width: "100px" },
        { text: "Type", value: "type" },
        { text: "State", value: "state" },
        { text: "Client", value: "clientName" },
        { text: "Attorney", value: "attorney" },
        { text: "Paralegal", value: "paralegal" },
        { text: "Opened", value: "dateOpened" },
        { text: "Closed", value: "dateClosed" },
        { text: "Reason", value: "reasonClosed" }
      ],
      closedCases: [],
      retainedOnly: false
    };
  },
  components: {
    OrgHomeInventoryRetainedVsSettled
  },
  computed: {
    ...mapGetters(["startDate", "endDate"]),
    retainedVsSettledCasesByMonth() {
      if (!this.retainedCases || !this.settledCases || !this.organization)
        return {};
      let options = buildRetainedVsSettledCasesByMonth(
        this.organization,
        this.retainedCases,
        this.settledCases
      );
      return {
        series: options.series,
        xAxis: {
          categories: options.attorneys
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0
          },
          series: {
            cursor: "pointer",
            point: {
              events: {
                click: function() {
                  console.log(this.category, this.series.name);
                }
              }
            }
          }
        }
      };
    }
  },
  methods: {},
  apollo: {
    organization: {
      query: gql`
        query OrgHomeInventoryCharts($id: ID!) {
          organization(id: $id) {
            id
            name
            departments {
              id
              name
              users {
                staffCode
              }
            }
          }
        }
      `,
      variables() {
        return {
          id: this.$route.params.id
        };
      }
    },
    settledCases: {
      query: gql`
        query OrgHomeInventory($startDate: String!, $endDate: String!) {
          settledCases: settledCasesInPeriodByAttorneyByMonth(
            startDate: $startDate
            endDate: $endDate
          ) {
            count
            month
            year
            yearMonth
            attorney
          }
        }
      `,
      variables() {
        return {
          id: this.$route.params.id,
          startDate: this.startDate,
          endDate: this.endDate
        };
      },
      skip() {
        return this.startDate === null || this.endDate === null;
      }
    },
    retainedCases: {
      query: gql`
        query OrgHomeInventoryRetained($startDate: String!, $endDate: String!) {
          retainedCases: netRetainedCasesInPeriodByAttorneyByMonth(
            startDate: $startDate
            endDate: $endDate
          ) {
            count
            month
            year
            yearMonth
            attorney
          }
        }
      `,
      variables() {
        return {
          id: this.$route.params.id,
          startDate: this.startDate,
          endDate: this.endDate
        };
      },
      skip() {
        return this.startDate === null || this.endDate === null;
      }
    }
  }
};
</script>

<style scoped></style>
