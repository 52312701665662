<template>
  <v-card>
    <v-container class="py-0" fluid>
      <v-row align-content="space-around">
        <v-col cols="12" sm="2">
          <v-card-title class="pa-0">
            <div>
              <div class="headline">Leads</div>
              <div class="text-subtitle-1 font-weight-thin">
                {{ totalFilteredLeads }} Leads
                <v-icon size="15" @click="refreshQuery">mdi-refresh</v-icon>
              </div>
              <div class="text-subtitle-1">
                Alert: {{ handledByAlert.yesNotLHP }} ({{
                  handledByAlert.yesNotLHPPercentage
                }}%)
              </div>
              <div class="subtitle-2">
                Alert (w/ LHP): {{ handledByAlert.yes }} ({{
                  handledByAlert.yesPercentage
                }}%)
              </div>
            </div>
          </v-card-title>
        </v-col>
        <v-col cols="12" sm="3" class="d-flex flex-row justify-space-between">
          <div>
            <div class="text-subtitle-2">Wanted</div>
            <div class="text-center text-h4">{{ performance.wanted }}</div>
          </div>
          <div>
            <div class="text-subtitle-2">Retained</div>
            <div class="text-center text-h4">{{ performance.retained }}</div>
          </div>
          <div>
            <div class="text-subtitle-2">Performance</div>
            <div class="text-center text-h4">{{ performance.percentage }}%</div>
          </div>
          <div>
            <v-checkbox label="Exclude Closed" v-model="excludeClosed" />
          </div>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="1" class="mt-5">
          <v-btn x-small @click="setDay('sub')">
            <v-icon dark>
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <v-btn x-small @click="setDay('add')">
            <v-icon dark>
              mdi-chevron-right
            </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" sm="2">
          <v-menu
            ref="menu"
            v-model="dateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                label="Date Range"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker range v-model="date" no-title scrollable>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="3">
          <v-text-field
            @keyup="updateSearch"
            append-icon="mdi-magnify"
            label="Search"
            hide-details
            single-line
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="1">
          <vue-json-to-csv :json-data="filteredLeads" csv-title="Leads_Report">
            <v-btn class="ml-5 mt-3"><v-icon>mdi-download</v-icon></v-btn>
          </vue-json-to-csv>
        </v-col>
      </v-row>
    </v-container>
    <v-data-table
      :footer-props="{
        'items-per-page-options': [-1],
      }"
      hide-default-footer
      :headers="headers"
      :items="leadsInPeriod"
      :loading="$apollo.loading"
      :search="search"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      @update:sort-by="updateSort('by', $event)"
      @update:sort-desc="updateSort('order', $event)"
      ref="intakeLeadsTable"
      @current-items="getFiltered"
    >
      <template v-slot:item.caseId="props">
        <a
          class="go-to-case-link mr-2"
          target="_blank"
          :href="
            `https://cs-web.airdesksolutions.com/SA/CaseSummary.aspx?CaseID=${props.item.caseId}`
          "
        >
          <v-icon size="15">mdi-open-in-new</v-icon>
        </a>
      </template>
      <template v-slot:item.clientName="props">
        <a class="go-to-case-link" @click="openCaseDialog(props.item)">
          {{ props.item.clientName }}
        </a>
      </template>
      <template v-slot:item.referralSource="props">
        <v-edit-dialog
          @open="openEditDialog(props.item.caseId)"
          @close="closeEditDialog"
        >
          {{ props.item.referralSource }}
          <template v-slot:input>
            <v-autocomplete
              :items="referralSourceOptions"
              :value="
                getValue(props.item.referralSource, referralSourceOptions)
              "
              @change="
                saveReferralSource(
                  $event,
                  'referralSource',
                  referralSourceOptions
                )
              "
              dense
            ></v-autocomplete>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:item.intakeRank="props">
        <v-edit-dialog
          @open="openEditDialog(props.item.caseId)"
          @close="closeEditDialog"
        >
          <v-rating
            :value="intakeRank(props.item.intakeRank)"
            background-color="white"
            color="yellow accent-4"
            dense
            hover
            readonly
            size="12"
            v-if="intakeRank(props.item.intakeRank) > 0"
          ></v-rating>
          <v-icon
            color="grey"
            size="12"
            v-if="intakeRank(props.item.intakeRank) === 0"
            >mdi-cancel</v-icon
          >
          <template v-slot:input>
            <v-select
              :items="rankOptions"
              :value="getValue(props.item.intakeRank, rankOptions)"
              @change="saveStatus($event, 'intakeRank', rankOptions, 73)"
              dense
            ></v-select>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:item.status="props">
        <v-edit-dialog
          @open="openEditDialog(props.item.caseId)"
          @close="closeEditDialog"
        >
          {{ trimStatus(props.item.status) }}
          <template v-slot:input>
            <v-select
              :items="statusOptions"
              :value="getValue(props.item.status, statusOptions)"
              @change="saveStatus($event, 'status', statusOptions, 1)"
              dense
            ></v-select>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:item.dateOfLastIntakeNote="props">
        {{ formatTimeAgo(props.item.dateOfLastIntakeNote) }}
      </template>
      <template v-slot:item.wantedDate="props">
        <v-edit-dialog
          @open="openEditDialog(props.item.caseId)"
          @close="closeEditDialog"
        >
          {{ props.item.wantedDate }}
          <template v-slot:input>
            <v-date-picker
              :value="props.item.wantedDate"
              no-title
              scrollable
              @change="saveWanted"
            />
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:item.office="props">
        <v-edit-dialog
          @open="openEditDialog(props.item.caseId)"
          @close="closeEditDialog"
        >
          {{ props.item.office }}
          <template v-slot:input>
            <v-select
              :items="officeOptions"
              :value="getValue(props.item.office, officeOptions)"
              @change="saveOffice"
              dense
            ></v-select>
          </template>
        </v-edit-dialog>
      </template>
    </v-data-table>
    <v-dialog v-model="caseDialog" max-width="900px">
      <org-home-intake-leads-case-dialog
        :caseId="selectedCaseId"
        :lead="selectedLead"
        :date="date"
        :rankOptions="rankOptions"
        :statusOptions="statusOptions"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import gql from "graphql-tag";
import { mapGetters } from "vuex";
import findIndex from "lodash/findIndex";
import groupBy from "lodash/groupBy";
import filter from "lodash/filter";
import debounce from "lodash/debounce";
import { ORG_HOME_INTAKE_LEADS } from "../graphql/cases.gql";
import format from "date-fns/format";
import addDays from "date-fns/addDays";
import subDays from "date-fns/subDays";
import * as timeago from "timeago.js";
import OrgHomeIntakeLeadsCaseDialog from "./OrgHomeIntakeLeadsCaseDialog";
import VueJsonToCsv from "vue-json-to-csv";

export default {
  data() {
    return {
      search: "",
      headers: [
        { text: "Action", value: "caseId", width: "100px" },
        { text: "Alert", value: "info.handledByBackup" },
        { text: "Client", value: "clientName" },
        { text: "Phone Number", value: "phoneNumber" },
        { text: "Type", value: "type" },
        // { text: "Sub", value: "subType" },
        { text: "Source", value: "referralSource" },
        { text: "CR Source", value: "callrailSource" },
        { text: "Other Source", value: "referralSourceOther" },
        { text: "Agent", value: "agent" },
        { text: "Case Rank", value: "caseRank" },
        { text: "Intake Rank", value: "intakeRank" },
        { text: "Status", value: "status" },
        { text: "Opened", value: "dateOpened" },
        { text: "Wanted", value: "wantedDate" },
        { text: "Retained", value: "retainedDate" },
        { text: "Closed", value: "dateClosed" },
        { text: "State", value: "state" },
        { text: "Office", value: "office" },
      ],
      leadsInPeriod: [],
      totalFilteredLeads: 0,
      filteredLeads: [],
      selectedCaseId: "",
      selectedLead: null,
      excludeClosed: false,
      wantedDate: "",
      officeOptions: [
        { text: "(2 - WA Main) Spokane Valley Office", value: "8" },
        { text: "(1 - UT Main) Sandy Office", value: "5" },
        { text: "(3 - ID Main) Meridian Office", value: "7" },
        { text: "Sea-Tac", value: "9" },
        { text: "Other", value: "10" },
        { text: "Wyoming", value: "11" },
        { text: "Montana", value: "12" },
        { text: "Colorado", value: "13" },
        { text: "Oregon", value: "14" },
      ],
      rankOptions: [
        { text: "0 Star - Don't Want", value: 1156 },
        {
          text: "1 Star - Minor Injuries or Questionable Liability",
          value: 1157,
        },
        { text: "2 Star - Soft Tissue and Clear Liability", value: 1158 },
        { text: "3 Star - Serious Injury", value: 1159 },
        { text: "4 Star - Catastrophic Injury", value: 1160 },
      ],
      statusOptions: [
        { text: "INT 00 - Lead", value: 1079 },
        { text: "INT 01 - Follow Up", value: 1080 },
        { text: "INT 02 - Investigator Appointment", value: 1081 },
        { text: "REF OUT 1 - Pending", value: 1055 },
        { text: "REF OUT 2 - Rejected", value: 1056 },
        { text: "REF OUT 3 - Accepted", value: 1057 },
      ],
      date: [
        format(new Date(), "yyyy-MM-dd"),
        format(new Date(), "yyyy-MM-dd"),
      ],
      dateMenu: false,
      caseDialog: false,
    };
  },
  components: {
    OrgHomeIntakeLeadsCaseDialog,
    VueJsonToCsv,
  },
  computed: {
    ...mapGetters(["currentUser", "startDate", "endDate"]),
    performance() {
      if (!this.leadsInPeriod)
        return {
          wanted: 0,
          retained: 0,
          percentage: 0,
        };
      let wanted = this.filteredLeads.reduce((prev, cur) => {
        if (cur.wantedDate && !cur.isMassTort) return prev + 1;
        else return prev;
      }, 0);
      let retained = this.filteredLeads.reduce((prev, cur) => {
        if (cur.retainedDate && !cur.isMassTort) return prev + 1;
        else return prev;
      }, 0);
      let percentage = Math.round((retained / wanted) * 100 * 10) / 10;
      return {
        wanted,
        retained,
        percentage: percentage ? percentage : 0,
      };
    },
    sortBy() {
      let saved = JSON.parse(localStorage.getItem("RyBase.leadsSortBy"));
      if (!saved) return ["caseId"];
      return saved;
    },
    sortDesc() {
      let saved = JSON.parse(localStorage.getItem("RyBase.leadsSortDesc"));
      if (!saved) return [true];
      return saved;
    },
    referralSourceOptions() {
      if (!this.advertisingSources) return [];
      return filter(
        this.advertisingSources.map((a) => {
          return { text: a.contact, value: a.contactId };
        }),
        (a) => {
          return a.text !== "Ref-Apex Chat";
        }
      );
    },
    handledByAlert() {
      if (!this.leadsInPeriod) return { yes: 0, no: 0, blank: 0 };
      let yes = filter(this.filteredLeads, (l) => {
        return l.info.handledByBackup === "Yes";
      }).length;
      let yesPercentage = ((yes / this.filteredLeads.length) * 100).toFixed(2);
      let yesNotLHP = filter(this.filteredLeads, (l) => {
        return (
          l.info.handledByBackup === "Yes" &&
          l.referralSource !== "Ref-Legal Help Partners"
        );
      }).length;
      let yesNotLHPPercentage = (
        (yesNotLHP / this.filteredLeads.length) *
        100
      ).toFixed(2);
      let no = filter(this.filteredLeads, (l) => {
        return l.info.handledByBackup === "No";
      }).length;
      let blank = filter(this.filteredLeads, (l) => {
        return (
          l.info.handledByBackup !== "Yes" && l.info.handledByBackup !== "No"
        );
      }).length;
      return {
        yes,
        yesPercentage,
        yesNotLHP,
        yesNotLHPPercentage,
        no,
        blank,
      };
    },
  },
  apollo: {
    leadsInPeriod: {
      query: ORG_HOME_INTAKE_LEADS,
      variables() {
        return {
          startDate: this.date[0],
          endDate: this.date[1],
          excludeClosed: this.excludeClosed,
        };
      },
      skip() {
        if (!this.currentUser) return true;
        if (!this.date[0] || !this.date[1]) return true;
        return false;
      },
    },
    advertisingSources: {
      query: gql`
        query IntakePPCAdvertisingSources {
          advertisingSources {
            contact
            contactId
          }
        }
      `,
    },
  },
  methods: {
    updateSearch: debounce(function(e) {
      this.search = e.target.value;
    }, 500),
    refreshQuery() {
      this.$apollo.queries.leadsInPeriod.refetch();
      return false;
    },
    getFiltered(results) {
      this.totalFilteredLeads = results.length;
      this.filteredLeads = results.map((r) => {
        return {
          ...r,
          reasonClosed:
            r.dateClosed && r.reasonClosed !== "Corrupted Data"
              ? r.reasonClosed
              : "",
          dateClosed: r.dateClosed ? r.dateClosed : "",
          retainedDate: r.retainedDate ? r.retainedDate : "",
          wantedDate: r.wantedDate ? r.wantedDate : "",
          referralSourceOther: r.referralSourceOther
            ? r.referralSourceOther
            : "",
          attorney: r.attorney ? r.attorney : "",
          paralegal: r.paralegal ? r.paralegal : "",
          investigator: r.investigator ? r.investigator : "",
          dateOfLastIntakeNote: r.dateOfLastIntakeNote
            ? r.dateOfLastIntakeNote
            : "",
          caseRank: r.caseRank ? r.caseRank : "",
          info: "",
        };
      });
      return false;
    },
    updateSort(type, event) {
      if (type === "by") {
        localStorage.setItem("RyBase.leadsSortBy", JSON.stringify(event));
      } else if (type === "order") {
        localStorage.setItem("RyBase.leadsSortDesc", JSON.stringify(event));
      }
    },
    intakeRank(rank) {
      if (!rank) return null;
      let num = rank.substr(0, 1);
      return parseInt(num, 10);
    },
    trimStatus(status) {
      if (status) return status.substr(0, 7);
      return "";
    },
    formatTimeAgo(date) {
      if (!date) return "";
      return timeago.format(new Date(date));
    },
    openCaseDialog(lead) {
      this.selectedCaseId = lead.caseId;
      this.selectedLead = lead;
      this.caseDialog = true;
      return false;
    },
    setDay(direction) {
      let startDay = new Date(`${this.date[0]}T01:00:00.000-07:00`);
      let newDay;
      if (direction === "add") {
        newDay = format(addDays(startDay, 1), "yyyy-MM-dd");
      } else {
        newDay = format(subDays(startDay, 1), "yyyy-MM-dd");
      }
      this.date = [newDay, newDay];
      return false;
    },
    saveWanted(date) {
      if (!date) return false;
      this.$apollo
        .mutate({
          mutation: gql`
            mutation InsertWantedDate($dateInput: WantedDate!) {
              insertSaWantedDate(dateInput: $dateInput) {
                wantedDate
              }
            }
          `,
          variables: {
            dateInput: {
              case_id: this.selectedCaseId,
              wanted_date: date,
            },
          },
          update: (store, { data: { insertSaWantedDate } }) => {
            this.updateCache(store, insertSaWantedDate, "wantedDate");
          },
        })
        .then(() => {
          this.$refs["intakeLeadsTable"].$el.click();
        })
        .catch((error) => {
          console.error(error);
        });
      return false;
    },
    saveOffice(officeId) {
      if (!officeId) return false;
      this.$apollo
        .mutate({
          mutation: gql`
            mutation InsertSaOffice($office: OfficeInput!) {
              insertSaOffice(office: $office) {
                officeId
              }
            }
          `,
          variables: {
            office: {
              case_id: this.selectedCaseId,
              office_id: parseInt(officeId, 10),
            },
          },
          update: (store, { data: { insertSaOffice } }) => {
            let officeData = {
              office: this.getText(insertSaOffice.officeId, this.officeOptions),
            };
            this.updateCache(store, officeData, "office");
          },
        })
        .then(() => {
          this.$refs["intakeLeadsTable"].$el.click();
        })
        .catch((error) => {
          console.error(error);
        });
      return false;
    },
    saveRank(rankId) {
      if (!rankId) return false;
      this.$apollo
        .mutate({
          mutation: gql`
            mutation InsertCaseRankStatus($status: CaseStatus!) {
              insertSaRankStatus(status: $status) {
                statusId
              }
            }
          `,
          variables: {
            status: {
              case_id: this.selectedCaseId,
              status_id: parseInt(rankId, 10),
            },
          },
          update: (store, { data: { insertSaRankStatus } }) => {
            let rankData = {
              intakeRank: this.getText(
                insertSaRankStatus.statusId,
                this.rankOptions
              ),
            };
            this.updateCache(store, rankData, "intakeRank");
          },
        })
        .then(() => {
          this.$refs["intakeLeadsTable"].$el.click();
        })
        .catch((error) => {
          console.error(error);
        });
      return false;
    },
    saveStatus(val, type, options, statusType) {
      if (!val) return false;
      this.$apollo
        .mutate({
          mutation: gql`
            mutation UpdateCaseStatus($status: CaseStatus!) {
              insertSaRankStatus(status: $status) {
                statusId
              }
            }
          `,
          variables: {
            status: {
              case_id: this.selectedCaseId,
              status_id: val,
              type: statusType,
            },
          },
          update: (store, { data: { upsertSaStatus } }) => {
            let data = {
              [type]: this.getText(upsertSaStatus.statusId, options),
            };
            this.updateCache(store, data, type);
          },
        })
        .then(() => {
          this.$refs["intakeLeadsTable"].$el.click();
        })
        .catch((error) => {
          console.error(error);
        });
      return false;
    },
    saveReferralSource(val, type, options) {
      if (!val) return false;
      this.$apollo
        .mutate({
          mutation: gql`
            mutation UpdateCaseReferralSource(
              $advertisingSource: AdvertisingSourceInput!
            ) {
              upsertSaPaidAdvertising(advertisingSource: $advertisingSource) {
                contactId
              }
            }
          `,
          variables: {
            advertisingSource: {
              case_id: this.selectedCaseId,
              contact_id: val,
            },
          },
          update: (store, { data: { upsertSaPaidAdvertising } }) => {
            let data = {
              [type]: this.getText(upsertSaPaidAdvertising.contactId, options),
            };
            this.updateCache(store, data, type);
          },
        })
        .then(() => {
          this.$refs["intakeLeadsTable"].$el.click();
        })
        .catch((error) => {
          console.error(error);
        });
      return false;
    },
    updateCache(store, insertData, field) {
      let caseId = this.selectedCaseId;
      const data = store.readQuery({
        query: ORG_HOME_INTAKE_LEADS,
        variables: {
          startDate: this.date[0],
          endDate: this.date[1],
          excludeClosed: this.excludeClosed,
        },
      });
      let index = findIndex(data.leadsInPeriod, (c) => {
        return c.caseId === caseId;
      });
      data.leadsInPeriod[index][field] = insertData[field];
      store.writeQuery({
        query: ORG_HOME_INTAKE_LEADS,
        variables: {
          startDate: this.date[0],
          endDate: this.date[1],
          excludeClosed: this.excludeClosed,
        },
        data,
      });
    },
    getValue(val, options) {
      let values = groupBy(options, "text");
      if (!values[val]) return null;
      return values[val][0].value;
    },
    getText(val, options) {
      let values = groupBy(options, "value");
      return values[val][0].text;
    },
    openEditDialog(caseId) {
      this.selectedCaseId = caseId;
      return false;
    },
    closeEditDialog() {
      this.selectedCaseId = "";
      return false;
    },
  },
};
</script>

<style scoped></style>
